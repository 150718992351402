import { View, Text } from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const StatBoxChartLine = (props) => {
  if (props.data) {
    return (
      <View
        style={{
          flex: 1,
          height: props.largeSize ? 500 : 310,
          backgroundColor: "#F2F2F2",
          borderRadius: 15,
          padding: 20,
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 16,
              color: Colors.text,
            }}
          >
            {props.title}
          </Text>
          <Ionicons
            name={props.iconname}
            color={props.iconcolor ? props.iconcolor : Colors.secondary}
            size={30}
          />
        </View>

        {props.value && (
          <View
            style={{
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 30,
                color: Colors.text,
              }}
            >
              {props.value}
            </Text>
          </View>
        )}

        <View
          style={{
            flexDirection: "row-reverse",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 10,
              color: Colors.secondText,
            }}
          >
            {props.period}
          </Text>
        </View>

        <View
          style={{
            flexDirection: "row-reverse",
            alignItems: "center",
            width: props.fullSize ? "100%" : "auto",
            justifyContent: "center",
            height: props.fullSize ? 550 : props.largeSize ? 320 : 170,
          }}
        >
          <Line
            data={props.data}
            options={{
              plugins: {
                legend: {
                  position: "top",
                  rtl: true,
                  labels: {
                    font: {
                      family: "IranSans-Light",
                      size: 9,
                    },
                    color: Colors.secondText,
                    boxWidth: 15,
                  },
                },
                tooltip: {
                  bodyFont: {
                    family: "IranSans-Medium",
                    size: 12,
                  },
                  titleFont: {
                    family: "IranSans-Light",
                    size: 8,
                  },
                  boxWidth: 25,
                  padding: 10,
                },
              },
            }}
          />
        </View>
      </View>
    )
  } else {
    return (
      <View
        style={{
          flex: 1,
          height: 310,
          backgroundColor: "#F2F2F2",
          borderRadius: 15,
          padding: 20,
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 16,
              color: Colors.text,
            }}
          >
            {props.title}
          </Text>
          <Ionicons
            name={props.iconname}
            color={props.iconcolor ? props.iconcolor : Colors.secondary}
            size={30}
          />
        </View>
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 30,
              color: Colors.secondText,
            }}
          >
            داده ای وجود ندارد
          </Text>
        </View>
      </View>
    )
  }
}

export default StatBoxChartLine
