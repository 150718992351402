import React from "react"
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native"
import Colors from "../constants/Colors"

export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER"

export const FilterGroupParent = ({ children }) => {
  return (
    <View
      style={{
        height: 45,
        marginBottom: 20,
        alignItems: "flex-end",
        maxWidth: "100%",
        paddingLeft: 20,
      }}
    >
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ gap: 20 }}
        style={{ flexDirection: "row-reverse", width: "100%" }}
      >
        {children}
      </ScrollView>
    </View>
  )
}

export default function FilterGroup({
  title,
  dispatchFormState,
  list,
  active_value,
  id,
}) {
  return (
    <View
      style={{
        flexDirection: "row-reverse",
        alignItems: "center",
        height: 40,
      }}
    >
      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontSize: 12,
          color: Colors.text,
        }}
      >
        {title}:{" "}
      </Text>

      {list.map((item) => (
        <TouchableOpacity
          key={item.id}
          style={{
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 10,
            backgroundColor: active_value == item.id ? Colors.blue : "#F2F2F2",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
          }}
          onPress={() => {
            dispatchFormState({
              type: SET_ACTIVE_FILTER,
              id: id,
              value: item.id,
            })
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 12,
              color: active_value == item.id ? "#fff" : Colors.text,
            }}
          >
            {item.title}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  )
}
