import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import Btn from "../../components/Btn"
import Input from "../../components/Input"
import Picker from "../../components/Picker"
import axios from "axios"
import { logOut } from "../../components/Functions"
import { Ionicons } from "@expo/vector-icons"
import FancySelector from "../../components/FancySelector"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_COURSE = "SET_COURSE"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_COURSE:
      let course_data = action.data

      return {
        ...state,
        values: {
          ...state.values,
          course: course_data,

          is_private: course_data.private,

          department:
            course_data.department == "sales"
              ? { item: "فروش", id: "sales" }
              : course_data.department == "graphic"
              ? { item: "گرافیک", id: "graphic" }
              : course_data.department == "hr"
              ? { item: "منابع انسانی", id: "hr" }
              : course_data.department == "site"
              ? { item: "سایت", id: "site" }
              : null,

          type: course_data.spot_id
            ? { item: "اسپات", id: "spot" }
            : { item: "عادی", id: "normal" },
        },
      }

    default:
      return state
  }
}

const SetCourseScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const course_id = props.route.params.course_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      title: null,
      department: null,
      type: null,
      course: null,
      is_private: null,
      slug: "",
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_course = async () => {
    const url = Config.aioUrl + "/lms/course/get?id=" + course_id + "&light=1"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_COURSE, data: response.data.data })
      })
      .catch(function (error) {})
  }

  const set_course = async () => {
    const url = Config.aioUrl + "/lms/course/set"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    if (course_id) formdata.append("course_id", course_id)
    formdata.append("title", formState.values.title)
    formdata.append(
      "slug",
      formState.values.slug
        .replaceAll(" ", "-")
        .replaceAll(".", "-")
        .replaceAll("_", "-")
        .replaceAll(",", "-")
        .replaceAll("/", "-")
    )
    formdata.append("is_private", formState.values.is_private)
    formdata.append("department", formState.values.department.id)
    if (formState.values.type.id == "spot")
      formdata.append("spot_id", formState.values.spot_id)
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      if (course_id) get_course()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title={course_id ? "ویرایش دوره" : "افزودن دوره"} />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <View style={{ flex: 1 }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ paddingBottom: 50 }}
          >
            {((!course_id && !formState.values.loading) ||
              (course_id &&
                formState.values.course &&
                !formState.values.loading)) && (
              <View>
                <FancySelector
                  id="is_private"
                  column={2}
                  onChange={(id, value) => inputChangeHandler(id, value[0])}
                  options={[
                    { item: "دوره مخصوص مشتری", id: "0" },
                    { item: "دوره مخصوص پرسنل", id: "1" },
                  ]}
                  flatListStyle={{ direction: "rtl" }}
                  insert={[`${formState.values.is_private}`]}
                  single
                />

                <Input
                  label="عنوان دوره"
                  id="title"
                  onChange={inputChangeHandler}
                  insert={course_id ? formState.values.course.title : false}
                />

                <Input
                  label="نامک"
                  id="slug"
                  onChange={inputChangeHandler}
                  insert={course_id ? formState.values.course.slug || "" : ""}
                />

                <Picker
                  label="دپارتمان"
                  id="department"
                  onChange={inputChangeHandler}
                  options={[
                    { item: "فروش", id: "sales" },
                    { item: "گرافیک", id: "graphic" },
                    { item: "سایت", id: "site" },
                    { item: "منابع انسانی", id: "hr" },
                  ]}
                  insert={course_id ? formState.values.department : false}
                  noSearch
                />
                <Picker
                  label="نوع دوره"
                  id="type"
                  onChange={inputChangeHandler}
                  options={[
                    { item: "عادی", id: "normal" },
                    { item: "اسپات", id: "spot" },
                  ]}
                  insert={course_id ? formState.values.type : false}
                  noSearch
                />
                {formState.values.type && (
                  <View>
                    {formState.values.type.id == "spot" && (
                      <Input
                        label="آیدی دوره در اسپات پلیر"
                        id="spot_id"
                        onChange={inputChangeHandler}
                        insert={
                          course_id ? formState.values.course.spot_id : false
                        }
                      />
                    )}
                  </View>
                )}
                <Btn
                  title={course_id ? "ثبت تغییرات" : "افزودن دوره"}
                  onPress={() => {
                    set_course()
                  }}
                />
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default SetCourseScreen
