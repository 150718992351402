import React, { useReducer, useCallback, useState, act } from "react"
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native"
import Colors from "../../constants/Colors"
import { separate } from "../../components/Functions"
import { useEffect } from "react"
import Config from "../../constants/Config"
import moment from "moment-jalaali"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Header from "../../components/Header"
import Btn from "../../components/Btn"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_INVOICES = "SET_INVOICES"
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
const SET_EMPLOYEES = "SET_EMPLOYEES"
const SET_ACTIVE_EMPLOYEE = "SET_ACTIVE_EMPLOYEE"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case ALERT_SHOW:
      return {
        ...state,
        showAlert: action.show,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.data,
      }
    case SET_ACTIVE_EMPLOYEE:
      return {
        ...state,
        active_employee: action.value,
      }
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.data,
      }
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        active_section: action.value,
      }
    default:
      return state
  }
}

const Bill = (props) => {
  const { item } = props.item
  const { delete_invoice, capabilities } = props
  const [clicked, setClicked] = useState(false)

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        height: 150,
        backgroundColor: "#f2f2f2",
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: clicked ? 50 : 10,
        position: "relative",
      }}
      onPress={() => {
        setClicked((prev) => !prev)
      }}
    >
      <View style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
          #{item.order_id}
        </Text>

        <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
          {separate(item.amount.toString())} تومان
        </Text>
      </View>

      <View
        style={{ flexDirection: "row-reverse", justifyContent: "space-between", marginTop: 20 }}
      >
        <View style={{ alignItems: "flex-end" }}>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 12,
              color: Colors.text,
              marginBottom: 10,
            }}
          >
            نام مشتری
          </Text>
          <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
            {item.customer_name}
          </Text>
        </View>

        <View style={{ alignItems: "flex-start" }}>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 12,
              color: Colors.text,
              marginBottom: 10,
            }}
          >
            تاریخ ثبت
          </Text>
          <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
            {moment.unix(item.creation_date).format("jYYYY/jM/jD HH:mm")}
          </Text>
        </View>
      </View>

      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontSize: 11,
          color: Colors.text + 89,
          marginTop: 15,
        }}
      >
        {props?.employee?.name
          ? `ساخته شده توسط ${props.employee?.name}`
          : `شماره کاربر: ${item.user_phone_number}`}
      </Text>

      {clicked && item.status != "paid" && (
        <View
          style={{ flexDirection: "row-reverse", justifyContent: "space-between", marginTop: 12 }}
        >
          <Btn
            icon="logo-whatsapp"
            onPress={() => {
              window.open("whatsapp://send?phone=+" + item.user_phone_number)
            }}
            color={Colors.green}
            wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
          />

          {capabilities.includes("transaction_add_income") && (
            <Btn
              // title="ثبت دستی"
              icon="checkmark-done"
              onPress={() => {
                props.navigation.navigate("AddTransaction", {
                  order_id: item.order_id,
                  customer_name: "",
                  invoice_cost: item.amount,
                })
              }}
              color={"#333"}
              wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
            />
          )}

          <Btn
            // title="مشاهده"
            icon="eye"
            onPress={() => {
              window.open("https://pay.aminh.pro?id=" + item.order_id, "_blank")
            }}
            color={Colors.blue}
            wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
          />

          {capabilities.includes("invoice_delete_unpaid") && (
            <Btn
              // title="حذف"
              icon="trash"
              onPress={() => {
                delete_invoice(item.order_id)
              }}
              color={Colors.primary}
              wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
            />
          )}
        </View>
      )}
    </TouchableOpacity>
  )
}

const UnpaidInvoicesScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    showAlert: false,
    loading: false,

    employees: [],
    active_employee: "all",

    invoices: [],
    active_section: "new",
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const extractEmployees = (invoices = []) => {
    const employees = []

    invoices
      .filter((invoice) => invoice.employee)
      .forEach((invoice) => {
        const isExist = employees.find((employee) => employee.id == invoice.employee.id)

        if (!isExist) employees.push(invoice.employee)
      })

    dispatchFormState({ type: SET_EMPLOYEES, data: employees })
  }

  const get_invoices = async (section) => {
    const url = Config.aioUrl + "/invoice/getunpaid?section=" + section
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        extractEmployees([...response.data.data])
        console.log(response.data.data)

        dispatchFormState({ type: SET_INVOICES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const delete_invoice = async (order_id) => {
    const url = Config.aioUrl + "/invoice/delete"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("order_id", order_id)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
        get_invoices()
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_invoices("new")
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="صورتحساب های رها" noBack />

      {formState.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}

      {!formState.loading && (
        <View style={{ height: "100%", flex: 1, paddingHorizontal: DESKTOP ? "30%" : 0 }}>
          <View style={{ width: "100%", justifyContent: "center" }}>
            <View style={{ flexDirection: "row-reverse", alignItems: "center", height: 40 }}>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>
                دسته نمایش:{" "}
              </Text>

              <TouchableOpacity
                style={{
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  borderRadius: 10,
                  backgroundColor: formState.active_section == "new" ? Colors.blue : "#F2F2F2",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                }}
                onPress={() => {
                  dispatchFormState({ type: SET_ACTIVE_SECTION, value: "new" })
                  get_invoices("new")
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                    color: formState.active_section == "new" ? "#fff" : Colors.text,
                  }}
                >
                  جدید
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  borderRadius: 10,
                  backgroundColor: formState.active_section == "project" ? Colors.blue : "#F2F2F2",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                }}
                onPress={() => {
                  dispatchFormState({ type: SET_ACTIVE_SECTION, value: "project" })
                  get_invoices("project")
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                    color: formState.active_section == "project" ? "#fff" : Colors.text,
                  }}
                >
                  درون پروژه
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <View style={{ flexDirection: "row-reverse", alignItems: "center", height: 40 }}>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>
                پرسنل:{" "}
              </Text>

              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={{ flex: 1, flexDirection: "row-reverse" }}
              >
                {formState.employees?.map((employee) => (
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.active_employee == employee.id ? Colors.blue : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() =>
                      dispatchFormState({ type: SET_ACTIVE_EMPLOYEE, value: employee.id })
                    }
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: formState.active_employee == employee.id ? "#fff" : Colors.text,
                      }}
                    >
                      {employee.name}
                    </Text>
                  </TouchableOpacity>
                ))}

                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    borderRadius: 10,
                    backgroundColor: formState.active_employee == "all" ? Colors.blue : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => dispatchFormState({ type: SET_ACTIVE_EMPLOYEE, value: "all" })}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: formState.active_employee == "all" ? "#fff" : Colors.text,
                    }}
                  >
                    همه
                  </Text>
                </TouchableOpacity>
              </ScrollView>
            </View>
          </View>

          <FlatList
            style={{ paddingBottom: 50 }}
            showsVerticalScrollIndicator={false}
            data={
              formState.active_employee === "all"
                ? formState.invoices
                : formState.invoices.filter(
                    (invoice) => invoice.employee?.id == formState.active_employee
                  )
            }
            keyExtractor={(item) => item.order_id}
            renderItem={(item, index) => (
              <Bill
                item={item}
                navigation={props.navigation}
                delete_invoice={delete_invoice}
                capabilities={capabilities}
                employee={item.item.employee}
              />
            )}
          />
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default UnpaidInvoicesScreen
