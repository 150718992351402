import { useReducer, useCallback } from "react";
import { View, Dimensions } from "react-native";
import Colors from "../../constants/Colors";
import Btn from "../../components/Btn";
import Input from "../../components/Input";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../constants/Config";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;
import { CommonActions } from "@react-navigation/native";
import { logOut } from "../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    default:
      return state;
  }
};

const CustomerAddScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      showAlert: false,
      loading: false,
      customer_firstname: false,
      customer_lastname: false,
      customer_mobile: false,
    },
    validities: {},
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity });
    },
    [dispatchFormState]
  );

  const add_customer = async () => {
    const url = Config.aioUrl + "/customer/add";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    let formdata = new FormData();
    formdata.append("first_name", formState.values.customer_firstname);
    formdata.append("last_name", formState.values.customer_lastname);
    formdata.append("mobile", formState.values.customer_mobile);

    if (!formState.values.customer_firstname || !formState.values.customer_lastname || !formState.values.customer_mobile) {
      alert("اطلاعات نمی تواند خالی باشد.");
    } else {
      dispatchFormState({ type: SET_LOADING, loading: true });
      axios({
        method: "post",
        url: url,
        data: formdata,
        headers: reqHedear,
      })
        .then(function (response) {
          if (response.data.code == 401) {
            alert("نیاز است مجددا به سیستم ورود کنید.");
            logOut();
          }
          dispatchFormState({ type: SET_LOADING, loading: false });
          alert(response.data.msg);
          if (response.data.success) {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  {
                    name: "CustomerAdd",
                  },
                ],
              })
            );
          }
        })
        .catch(function (error) {
          dispatchFormState({ type: SET_LOADING, loading: false });
          console.log(error);
        });
    }
  };

  return (
    <View style={{ height: "100%", overflow: "hidden", backgroundColor: Colors.bg, paddingHorizontal: 25 }}>
      <Header title="افزودن مشتری" />
      <View style={{ paddingHorizontal: DESKTOP ? "30%" : 0, flex: 1, height: "100%" }}>
        <View style={{ marginTop: 20 }}>
          <Input label="نام مشتری" id="customer_firstname" onChange={inputChangeHandler} />
          <Input label="نام خانوادگی مشتری" id="customer_lastname" onChange={inputChangeHandler} />
          <Input label="شماره موبایل مشتری" id="customer_mobile" onChange={inputChangeHandler} onlyNumber convertDigits mobile />
        </View>

        <Btn
          title="افزودن"
          onPress={() => {
            add_customer();
          }}
          loading={formState.values.loading}
          wrapperStyle={{ marginTop: 30, zIndex: 1 }}
        />
      </View>
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
};

export default CustomerAddScreen;
