import { useRef, useEffect } from "react"
import { View, Text, TouchableOpacity, ScrollView } from "react-native"
import Colors from "../constants/Colors"

const Journey = (props) => {
  const { navigation, steps, setSteps, activeIndex, setActiveIndex } = props
  const scrollViewRef = useRef(null)
  useEffect(() => {
    scrollViewRef.current?.scrollTo({ x: 90 * activeIndex, animated: true })
  }, [activeIndex, setActiveIndex])
  return (
    <View style={{ width: "100%", marginBottom: 20 }}>
      <ScrollView
        ref={scrollViewRef}
        style={{
          width: "100%",
          transform: [{ scaleX: -1 }],
        }}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row-reverse",
            justifyContent: "space-evenly",
            width: "100%",
            alignItems: "center",
            marginBottom: 20,
            marginTop: 5,
            transform: [{ scaleX: -1 }],
          }}
        >
          {steps
            .sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((item, index) => (
              <TouchableOpacity
                style={{
                  width: 120,
                  height: 80,
                  borderWidth: 1,
                  borderColor:
                    item.status == "active" ? Colors.green : Colors.secondText,
                  borderRadius: 15,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                  backgroundColor:
                    item.status == "active" ? Colors.green : "transparent",
                }}
                key={item.id}
                onPress={() => {
                  setSteps((prev) => {
                    let prevState = prev
                    for (let i = 0; i < prevState.length; i++) {
                      if (prevState[i].name == item.name) {
                        prevState[i].status = "active"
                      } else {
                        prevState[i].status = "inactive"
                      }
                    }
                    return prevState
                  })
                  setActiveIndex(index)
                  navigation.navigate(item.name)
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 24,
                    color: item.status == "active" ? "#fff" : Colors.secondText,
                  }}
                >
                  {index + 1}
                </Text>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: item.status == "active" ? "#fff" : Colors.secondText,
                  }}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            ))}
        </View>
      </ScrollView>
    </View>
  )
}

export default Journey
