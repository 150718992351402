import { createNativeStackNavigator } from "@react-navigation/native-stack"
import FormSiteScreen, {
  screenOptions as FormSiteScreenOptions,
} from "../screens/Customer/FormSiteScreen"
import DashboardScreen, {
  screenOptions as DashboardScreenOptions,
} from "../screens/Customer/DashboardScreen"
import SupportListScreen, {
  screenOptions as SupportListScreenOptions,
} from "../screens/Customer/Support/SupportListScreen"
import AddTicketScreen, {
  screenOptions as AddTicketScreenOptions,
} from "../screens/Customer/Support/AddTicketScreen"
import TicketScreen, {
  screenOptions as TicketScreenOptions,
} from "../screens/Customer/Support/TicketScreen"

import LessonScreen, {
  screenOptions as LessonScreennOptions,
} from "../screens/LMS/LessonScreen"
import CoursesListScreen, {
  screenOptions as CoursesListScreenOptions,
} from "../screens/LMS/CoursesListScreen"
import CourseScreen, {
  screenOptions as CourseScreenOptions,
} from "../screens/LMS/CourseScreen"

import { useSelector } from "react-redux"

import FormLogoScreen, {
  screenOptions as FormLogoScreenOptions,
} from "../screens/Customer/FormLogoScreen"

const SupportStackNavigator = createNativeStackNavigator()

const SupportNavigator = () => {
  return (
    <SupportStackNavigator.Navigator>
      <SupportStackNavigator.Screen
        name="SupportList"
        component={SupportListScreen}
        options={{ ...SupportListScreenOptions, title: "تیکت ها" }}
      />

      <SupportStackNavigator.Screen
        name="Ticket"
        component={TicketScreen}
        options={{ ...TicketScreenOptions, title: "تیکت" }}
      />

      <SupportStackNavigator.Screen
        name="AddTicket"
        component={AddTicketScreen}
        options={{ ...AddTicketScreenOptions, title: "افزودن تیکت" }}
      />
    </SupportStackNavigator.Navigator>
  )
}

const CustomerStackNavigator = createNativeStackNavigator()

const CustomerNavigator = () => {
  const role = useSelector((store) => store.user.user_data.role)
  return (
    <CustomerStackNavigator.Navigator>
      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="Dashboard"
          component={DashboardScreen}
          options={{ ...DashboardScreenOptions, title: "داشبورد" }}
        />
      )}

      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="Support"
          component={SupportNavigator}
          options={{ headerShown: false, drawerPosition: "right" }}
        />
      )}

      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="FormSite"
          component={FormSiteScreen}
          options={{ ...FormSiteScreenOptions, title: "فرم اطلاعات سایت" }}
        />
      )}

      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="FormLogo"
          component={FormLogoScreen}
          options={{ ...FormLogoScreenOptions, title: "فرم اطلاعات لوگو" }}
        />
      )}

      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="CoursesList"
          component={CoursesListScreen}
          options={{ ...CoursesListScreenOptions, title: "دوره ها" }}
        />
      )}

      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="Course"
          component={CourseScreen}
          options={{ ...CourseScreenOptions, title: "دوره" }}
        />
      )}

      {role == "customer" && (
        <CustomerStackNavigator.Screen
          name="Lesson"
          component={LessonScreen}
          options={{ ...LessonScreennOptions, title: "درس" }}
        />
      )}
    </CustomerStackNavigator.Navigator>
  )
}

export default CustomerNavigator
