import React from "react"
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native"
import Colors from "../constants/Colors"

export const Row = ({ children, index }) => {
  const isHeader = !(index + 1)
  const differentStyle = (index + 1) % 2 == 0 || isHeader

  const color = isHeader ? "rgb(51, 51, 51)" : differentStyle && Colors.cardBg

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row-reverse",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
        paddingHorizontal: 15,
        paddingVertical: 12,
        backgroundColor: color,
        borderRadius: 10,
        height: 40,
      }}
    >
      {children}
    </TouchableOpacity>
  )
}

export const Cell = ({ children, header }) => (
  <Text
    style={{
      flex: 1,
      width: 100,
      textAlign: "center",
      verticalAlign: "middle",
      fontSize: header ? 6 : 9,
      color: header ? "#fff" : Colors.text,
      fontFamily: "IranSans-Medium",
    }}
  >
    {children}
  </Text>
)

export const TBody = ({ children }) => (
  <ScrollView
    showsHorizontalScrollIndicator={false}
    style={{
      width: "100%",
      flex: 1,
      marginBottom: 20,
    }}
  >
    {children}
  </ScrollView>
)

const Table = ({ children }) => {
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={{
        width: "100%",
        flexDirection: "row-reverse",
      }}
    >
      <View
        style={{
          width: "auto",
          position: "relative",
        }}
      >
        {children}
      </View>
    </ScrollView>
  )
}

export default Table
