import React, { useReducer, useCallback } from "react"
import { View, FlatList, Text, ScrollView } from "react-native"
import Input from "../../../components/Input"
import Btn from "../../../components/Btn"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import moment from "moment-jalaali"
import { Ionicons } from "@expo/vector-icons"
import Colors from "../../../constants/Colors"
import FancySelector from "../../../components/FancySelector"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const TOGGLE_ADD = "TOGGLE_ADD"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.values,
          [action.name]: action.value,
        },
      }
    case TOGGLE_ADD:
      return {
        ...state,
        values: {
          ...state.values,
          is_add_show: state.values.is_add_show ? false : true,
        },
      }
    default:
      return state
  }
}

const Stat = (props) => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontSize: 12,
          color: Colors.text,
          marginBottom: 3,
          direction: "rtl",
        }}
      >
        {props.title}
      </Text>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 10,
          color: Colors.secondText,
        }}
      >
        {props.subtitle}
      </Text>
    </View>
  )
}

const Touch = (props) => {
  const { item } = props
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: item.color ? item.color : "#F2F2F2",
        borderRadius: 15,
        marginVertical: 5,
        padding: 15,
        direction: "ltr",
        opacity: item.success == "1" ? 1 : 0.5,
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "row-reverse", columnGap: 5 }}>
          <Ionicons name={item.icon} size={14} color={"#fff"} />
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: "#fff",
            }}
          >
            {item.title}
          </Text>
        </View>
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 14,
            color: "#fff",
          }}
        >
          {moment.unix(item.time).format("jYYYY/jM/jD HH:mm")}
        </Text>
      </View>
      {item.description != "" && (
        <View>
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 12,
              color: "#fff",
              marginTop: 10,
            }}
          >
            {item.description}
          </Text>
        </View>
      )}
    </View>
  )
}

const TouchTab = (props) => {
  const token = useSelector((store) => store.user.token)
  const lead = props.route.params.lead
  const touches = props.route.params.touches
  const can_select_touches = props.route.params.can_select_touches
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      note_content: "",
      loading: {
        set_touch: false,
      },
      selected_touch_type: null,
      selected_touch_success: null,
      description: null,
      is_add_show: false,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const set_touch = async () => {
    if (
      !formState.values.selected_touch_type ||
      !formState.values.selected_touch_success
    ) {
      alert("انتخاب ها ناقص است")
      return
    }

    const url = Config.aioUrl + "/crm/lead/touch"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("lead_id", lead.id)
    formdata.append("type_id", formState.values.selected_touch_type[0])
    formdata.append("success", formState.values.selected_touch_success[0])
    formdata.append("description", formState.values.description)
    dispatchFormState({ type: SET_LOADING, name: "set_touch", value: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_LOADING,
          name: "set_touch",
          value: false,
        })
        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          name: "set_touch",
          value: false,
        })
      })
  }
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingTop: formState.values.is_add_show ? 0 : 10,
      }}
    >
      {formState.values.is_add_show && (
        <View>
          <FancySelector
            column={3}
            id="selected_touch_type"
            title="نوع تاچ"
            options={can_select_touches}
            onChange={inputChangeHandler}
            single
          />
          <FancySelector
            column={2}
            id="selected_touch_success"
            title="وضعیت تاچ"
            options={[
              { item: "موفق", id: "1", icon: "checkmark-done" },
              { item: "ناموفق", id: "0", icon: "close" },
            ]}
            onChange={inputChangeHandler}
            single
          />
          <Input label="توضیح" id="description" onChange={inputChangeHandler} />
        </View>
      )}

      <View style={{ flexDirection: "row" }}>
        {formState.values.is_add_show && (
          <View style={{ flex: 1, marginRight: 10 }}>
            <Btn
              title="بستن"
              onPress={() => {
                dispatchFormState({ type: TOGGLE_ADD })
              }}
              color={Colors.error}
            />
          </View>
        )}
        <View style={{ flex: 3 }}>
          <Btn
            title={formState.values.is_add_show ? "ثبت تاچ" : "افزودن"}
            onPress={() => {
              if (formState.values.is_add_show) {
                set_touch()
              } else {
                dispatchFormState({ type: TOGGLE_ADD })
              }
            }}
            loading={formState.values.loading.set_touch}
          />
        </View>
      </View>

      {!formState.values.is_add_show && (
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ flex: 1, marginBottom: 20, marginTop: 10 }}
        >
          <View
            style={{
              backgroundColor: "#F2F2F2",
              paddingHorizontal: 15,
              paddingVertical: 15,
              borderRadius: 15,
              flexDirection: "row",
              width: "100%",
            }}
          >
            {touches.map((itemData, index) => (
              <>
                {console.log(itemData)}
                <Stat
                  key={index}
                  title={
                    itemData.total == itemData.success
                      ? itemData.success
                      : `(${itemData.success}) ${itemData.total}`
                  }
                  subtitle={itemData.title}
                />
              </>
            ))}
          </View>
          <FlatList
            data={lead.touches}
            keyExtractor={(item) => item.time}
            renderItem={(itemData) => <Touch item={itemData.item} />}
          />
        </ScrollView>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default TouchTab
