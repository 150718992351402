import { useState } from "react"
import { View, Text, TouchableOpacity, Dimensions } from "react-native"
import { Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import { StatusBar } from "expo-status-bar"
import Colors from "../constants/Colors"
import { CommonActions } from "@react-navigation/native"
import Notifications from "./Notifications"
import { Entypo } from "@expo/vector-icons"
import ActiveSessions from "./ActiveSessions"

const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const Header = (props) => {
  const navigation = useNavigation()

  const noNotif = props.noNotif ? props.noNotif : false
  const noActiveSessions = props.noActiveSessions
    ? props.noActiveSessions
    : false

  const [showNotifs, setShowNotifs] = useState(false)
  const [showActiveSessions, setShowActiveSessions] = useState(false)

  return (
    <View
      style={{
        width: "100%",
        height: 120,
        paddingTop: 40,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 25,
        zIndex: 3,
      }}
    >
      <StatusBar style="light" />

      {/* //? Back button */}
      {!props.noBack && (
        <TouchableOpacity
          style={{
            width: 50,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={() => {
            if (props.reset) {
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [
                    {
                      name: props.reset,
                    },
                  ],
                })
              )
            } else {
              navigation.goBack()
            }
          }}
        >
          <Ionicons name="chevron-back" color={Colors.primary} size={30} />
        </TouchableOpacity>
      )}

      {/* //? Notifs */}
      {!noNotif && (
        <View style={{ zIndex: 3, marginHorizontal: 5 }}>
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              minHeight: 45,
              backgroundColor: Colors.error,
              borderRadius: 12,
            }}
            onPress={() => {
              setShowNotifs((prev) => !prev)
              setShowActiveSessions(false)
            }}
          >
            <Ionicons name="notifications-outline" color="#fff" size={24} />
          </TouchableOpacity>
          {showNotifs && <Notifications />}
        </View>
      )}

      {/* //? Active devices */}
      {!noActiveSessions && props.noBack && (
        <View style={{ zIndex: 3, marginHorizontal: 5 }}>
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              width: 45,
              minHeight: 45,
              backgroundColor: Colors.green,
              borderRadius: 12,
            }}
            onPress={() => {
              setShowActiveSessions((prev) => !prev)
              setShowNotifs(false)
            }}
          >
            <Entypo name="tablet-mobile-combo" color="#fff" size={22} />
          </TouchableOpacity>

          {showActiveSessions && (
            <ActiveSessions
              noNotif={noNotif}
              setShowActiveSessions={setShowActiveSessions}
            />
          )}
        </View>
      )}

      {/* //? Reload button */}
      {props.reload && (
        <TouchableOpacity
          style={{
            width: 50,
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: 15,
          }}
          onPress={() => {
            props.reload()
          }}
        >
          <Ionicons name="reload" color={Colors.text} size={25} />
        </TouchableOpacity>
      )}

      <View
        style={{
          flex: 5,
          justifyContent: "center",
          alignItems: "flex-end",
          marginRight: 10,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: DESKTOP ? 18 : 15,
            color: Colors.text,
          }}
        >
          {props.title}
        </Text>
      </View>
      {!props.noToggle && (
        <TouchableOpacity
          id="toggleDrawer"
          style={{
            // flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: 45,
            height: 45,
            backgroundColor: Colors.primary,
            borderRadius: 12,
          }}
          onPress={() => {
            navigation.toggleDrawer()
          }}
        >
          <Ionicons name="grid-outline" color="#fff" size={24} />
        </TouchableOpacity>
      )}
    </View>
  )
}

export default Header
