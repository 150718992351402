import { useReducer, useEffect } from "react"
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
} from "react-native"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import { useSelector } from "react-redux"
import axios from "axios"
import Config from "../../constants/Config"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import { logOut } from "../../components/Functions"
import StatBox from "../../components/StatBox"
import StatBoxChartDoughnut from "../../components/StatBoxChartDoughnut"
import StatBoxChartLine from "../../components/StatBoxChartLine"
import { separate } from "../../components/Functions"
import moment from "moment-jalaali"
import FilterGroup, { FilterGroupParent } from "../../components/FilterGroup"
import AnalyseUserCard from "../../components/AnalyseUserCard"
import { Ionicons } from "@expo/vector-icons"
import InputNote from "../../components/InputNote"
import { useState } from "react"
import SpeedStep from "../../components/SpeedStep"

const SET_LOADING = "SET_LOADING"
const SET_OVERALL_DATA = "SET_OVERALL_DATA"
const SET_ACTIVE_PERIOD = "SET_ACTIVE_PERIOD"
const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER"
const SET_MONTHLY_DATA = "SET_MONTHLY_DATA"
const SET_SPEED_MODAL_SHOW = "SET_SPEED_MODAL_SHOW"

const AnalyseDataBox = ({ title, value, isPrice }) => (
  <View
    style={{
      backgroundColor: Colors.cardBg,
      borderRadius: 20,
      padding: 30,
      justifyContent: "center",
      alignItems: "center",
      gap: 20,
      flex: 1,
    }}
  >
    <Text
      style={{
        color: Colors.text,
        fontFamily: "IranSans-medium",
        fontSize: 8,
        textAlign: "center",
        lineHeight: 25,
      }}
    >
      {title}
    </Text>

    <Text
      style={{
        color: Colors.text,
        fontFamily: "IranSans-medium",
        fontSize: 14,
        fontWeight: "bold",
      }}
    >
      {value.toLocaleString()} {isPrice && "تومان"}
    </Text>
  </View>
)

const SpeedModal = ({ formState, dispatchFormState, closeSpeedModal }) => {
  const token = useSelector((store) => store.user.token)
  const [speedData, setSpeedData] = useState(null)

  const { userId, monthUnix } = formState.values.selected_item_to_show_speed
  const { total, stages } = speedData || {}

  useEffect(() => {
    if (userId && monthUnix && formState.values.isShowSpeedModal) {
      const url = Config.aioUrl + `/analyse/user-speed?id=${userId}&month=${monthUnix}`

      let reqHedear = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }

      axios({
        method: "get",
        url: url,
        headers: reqHedear,
      })
        .then(function (response) {
          if (response.data.code == 401) {
            alert("نیاز است مجددا به سیستم ورود کنید.")
            logOut()
            return
          }

          setSpeedData(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [formState.values.selected_item_to_show_speed])

  const closeHandler = () => {
    setSpeedData(null)
    closeSpeedModal()
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={formState.values.isShowSpeedModal}
      swipeDirection="down"
    >
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          justifyContent: "center",
          alignItems: "center",
        }}
        activeOpacity={1}
        onPressOut={closeHandler}
      >
        <TouchableWithoutFeedback>
          <View
            style={{
              width: DESKTOP ? "90%" : "100%",
              height: "90%",
              backgroundColor: Colors.bg,
              borderRadius: 25,
              paddingHorizontal: 40,
              paddingVertical: 35,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <View
              style={{
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                marginBottom: 20,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: Colors.text,
                  fontFamily: "IranSans-medium",
                  fontSize: 27,
                  textAlign: "center",
                  lineHeight: 25,
                  fontWeight: "bold",
                }}
              >
                آنالیز سرعت پرسنل
              </Text>

              <TouchableOpacity onPress={closeHandler}>
                <Ionicons name="close" size={28} color="black" style={{}} />
              </TouchableOpacity>
            </View>

            {speedData ? (
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ flex: 1, minHeight: "100%" }}
                contentContainerStyle={{
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 30,
                }}
              >
                <SpeedStep stages={stages} total={total} />

                <View
                  style={{
                    flexDirection: "row-reverse",
                    gap: 20,
                    width: "100%",
                  }}
                >
                  <AnalyseDataBox
                    title="میانگین زمان کل پروژه ماه پرسنل"
                    value={`${Math.floor(total.userMed)} روز`}
                  />

                  <AnalyseDataBox
                    title="میانگین زمان کل پروژه ماه دپارتمان"
                    value={`${Math.floor(total.depMed)} روز`}
                  />
                </View>
              </ScrollView>
            ) : (
              <View
                style={{
                  width: "100%",
                  height: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size="large" color={Colors.primary} />
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    marginTop: 20,
                  }}
                >
                  در حال بارگذاری اطلاعات...
                </Text>
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </Modal>
  )
}

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: action.value,
        },
      }

    case SET_MONTHLY_DATA:
      return {
        ...state,
        values: {
          ...state.values,
          monthly: action.value,
        },
      }

    case SET_SPEED_MODAL_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          isShowSpeedModal: action.value,
          selected_item_to_show_speed: {
            monthUnix: action.monthUnix,
            userId: action.userId,
          },
        },
      }

    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_OVERALL_DATA:
      let analysis_data = action.data.analysis
      let income_profit_variation_data = {
        labels: [],
        datasets: [
          {
            label: "حقوق ناخالص",
            data: [],
            borderColor: Colors.primary,
            backgroundColor: Colors.primary,
          },
          {
            label: "حقوق خالص",
            data: [],
            borderColor: Colors.error,
            backgroundColor: Colors.error,
          },
          {
            label: "سود ناخالص",
            data: [],
            borderColor: Colors.green,
            backgroundColor: Colors.green,
          },
        ],
      }

      let indicator_profitrate_variation_data = {
        labels: [],
        datasets: [
          {
            label: "شاخص",
            data: [],
            borderColor: Colors.blue,
            backgroundColor: Colors.blue,
          },
          {
            label: "نرخ سود",
            data: [],
            borderColor: Colors.green,
            backgroundColor: Colors.green,
          },
        ],
      }

      let eam_variation_data = {
        labels: [],
        datasets: [
          {
            label: "جریمه",
            data: [],
            borderColor: Colors.primary,
            backgroundColor: Colors.primary,
          },
          {
            label: "تاخیر",
            data: [],
            borderColor: Colors.error,
            backgroundColor: Colors.error,
          },
        ],
      }

      for (let i = analysis_data.length - 1; i >= 0; i--) {
        income_profit_variation_data.labels.push(analysis_data[i].date)
        income_profit_variation_data.datasets[0].data.push(analysis_data[i].user_income.impure)
        income_profit_variation_data.datasets[1].data.push(analysis_data[i].user_income.pure)
        income_profit_variation_data.datasets[2].data.push(
          analysis_data[i].points.money_made_by_staff
        )

        indicator_profitrate_variation_data.labels.push(analysis_data[i].date)
        indicator_profitrate_variation_data.datasets[0].data.push(
          analysis_data[i].points.projects_indicator2
        )
        indicator_profitrate_variation_data.datasets[1].data.push(
          (
            analysis_data[i].points.money_made_by_staff / analysis_data[i].user_income.impure
          ).toFixed(2)
        )

        eam_variation_data.labels.push(analysis_data[i].date)
        eam_variation_data.datasets[0].data.push(analysis_data[i].eam_data.number_of_penalty)
        eam_variation_data.datasets[1].data.push(analysis_data[i].eam_data.number_of_late)
      }
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          income_profit_variation_data: income_profit_variation_data,
          indicator_profitrate_variation_data: indicator_profitrate_variation_data,
          eam_variation_data: eam_variation_data,
        },
      }
    case SET_ACTIVE_PERIOD:
      return {
        ...state,
        values: {
          ...state.values,
          active_period: action.value,
        },
      }
    default:
      return state
  }
}

const AnalyseUserScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  let user_id = props.route.params.user_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      data: null,
      monthly: null,
      active_period: "this_month",
      income_profit_variation_data: null,
      indicator_profitrate_variation_data: null,
      eam_variation_data: null,
      isShowSpeedModal: false,

      selected_item_to_show_speed: {
        monthUnix: null,
        userId: null,
      },
    },
    validities: {},

    filters: {
      display_types: [
        { id: "overall", title: "کلی" },
        { id: "monthly", title: "ماهانه" },
      ],
      active_display_type_id: "overall",
    },
  })

  const get_overall_data = async (user_id) => {
    const url = Config.aioUrl + "/pes/analyse?user_id=" + user_id

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        console.clear()
        console.log("get_overall_data", response.data)

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_OVERALL_DATA, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        console.log(error)
      })
  }

  const get_monthly_data = async (user_id, disabledLoading) => {
    const url = Config.aioUrl + "/analyse/user-data?id=" + user_id

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    if (!disabledLoading) dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        console.clear()
        console.log("get_monthly_data", response.data)

        if (!disabledLoading) dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_MONTHLY_DATA, value: response.data.data })
      })
      .catch(function (error) {
        if (!disabledLoading) dispatchFormState({ type: SET_LOADING, loading: false })
        console.log(error)
      })
  }

  const getHandler = (disabledLoading) => {
    if (user_id) {
      if (formState.filters.active_display_type_id === "overall") {
        get_overall_data(user_id)
      } else {
        get_monthly_data(user_id, disabledLoading)
      }
    }
  }

  useEffect(() => {
    getHandler()
  }, [formState.filters])

  const openSpeedModal = (user_id, target) => {
    dispatchFormState({
      type: SET_SPEED_MODAL_SHOW,
      value: true,
      monthUnix: target,
      userId: user_id,
    })
  }

  const closeSpeedModal = () => {
    dispatchFormState({
      type: SET_SPEED_MODAL_SHOW,
      value: false,
      monthUnix: null,
      userId: null,
    })
  }

  return (
    <View
      style={{
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        paddingHorizontal: 25,
      }}
    >
      <Header reload={getHandler} title="آنالیز پرسنل" noToggle noNotif noActiveSessions />

      <FilterGroupParent>
        <FilterGroup
          title="نوع نمایش"
          id="active_display_type_id"
          list={formState.filters.display_types}
          active_value={formState.filters.active_display_type_id}
          dispatchFormState={dispatchFormState}
        />
      </FilterGroupParent>

      <View
        style={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!formState.values.loading &&
          (formState.filters.active_display_type_id === "overall" ? (
            // First Tab Start - Overall
            <ScrollView showsVerticalScrollIndicator={false} style={{ width: "100%" }}>
              {formState.values.data && (
                <>
                  <View
                    style={{
                      flex: 1,
                      height: "100%",
                      width: "100%",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        height: 310,
                        backgroundColor: Colors.secondary,
                        borderRadius: 15,
                        padding: 20,
                        marginBottom: 10,
                        marginLeft: 10,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          height: 128,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 15,
                        }}
                      >
                        <Image
                          source={{
                            uri: formState.values.data.user_data.avatar,
                          }}
                          style={{ width: 128, height: 128, borderRadius: 64 }}
                        />
                      </View>

                      <View
                        style={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 14,
                            color: "#fff",
                          }}
                        >
                          {formState.values.data.user_data.name}
                        </Text>
                      </View>

                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row-reverse",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: 20,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 14,
                            color: "#fff",
                          }}
                        >
                          سابقه
                        </Text>
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 14,
                            color: "#fff",
                          }}
                        >
                          {formState.values.data.user_data.employment_date != null
                            ? moment
                                .unix(formState.values.data.user_data.employment_date)
                                .toNow(true)
                            : "بدون زمان"}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 3,
                        flexDirection: "row-reverse",
                        columnGap: 10,
                      }}
                    >
                      <View style={{ flex: 2 }}>
                        <View
                          style={{
                            flex: 1,
                            width: "100%",
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <StatBox
                            title="سود ناخالص"
                            iconname="cash-outline"
                            value={separate(
                              formState.values.data.analysis[0].points.money_made_by_staff
                            )}
                            change={
                              formState.values.data.analysis[0].points.money_made_by_staff == 0
                                ? false
                                : (
                                    (formState.values.data.analysis[0].points.money_made_by_staff -
                                      formState.values.data.analysis[1].points
                                        .money_made_by_staff) /
                                    formState.values.data.analysis[1].points.money_made_by_staff
                                  ).toFixed(2) * 100
                            }
                            period={"نسبت به ماه گذشته"}
                          />
                          <StatBox
                            title="شاخص پروژه"
                            iconname="md-file-tray-full"
                            value={
                              formState.values.data.analysis[0].points.projects_indicator2 > 999
                                ? separate(
                                    formState.values.data.analysis[0].points.projects_indicator2
                                  )
                                : formState.values.data.analysis[0].points.projects_indicator2
                            }
                            // secondValue={
                            //   formState.values.data.analysis[0].points.projects_indicator1 +
                            //   "(1) " +
                            //   formState.values.data.analysis[0].points.projects_indicator2 +
                            //   "(2) " +
                            //   formState.values.data.analysis[0].points.projects_indicator3 +
                            //   "(3)"
                            // }
                            change={
                              formState.values.data.analysis[1].points.projects_indicator2 != 0
                                ? (
                                    (formState.values.data.analysis[0].points.projects_indicator2 -
                                      formState.values.data.analysis[1].points
                                        .projects_indicator2) /
                                    formState.values.data.analysis[1].points.projects_indicator2
                                  ).toFixed(2) * 100
                                : 0
                            }
                            period={"نسبت به ماه گذشته"}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            width: "100%",
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <StatBox
                            title="حقوق دریافتی"
                            iconname="journal-outline"
                            value={separate(formState.values.data.analysis[0].user_income.pure)}
                            secondValue={separate(
                              formState.values.data.analysis[0].user_income.impure
                            )}
                            change={
                              formState.values.data.analysis[0].user_income.pure != 0
                                ? (
                                    (formState.values.data.analysis[0].user_income.pure -
                                      formState.values.data.analysis[1].user_income.pure) /
                                    formState.values.data.analysis[1].user_income.pure
                                  ).toFixed(2) * 100
                                : false
                            }
                            period={"نسبت به ماه گذشته"}
                          />
                          <StatBox
                            title="نرخ سودرسانی"
                            iconname="pulse"
                            value={
                              formState.values.data.analysis[0].user_income.impure != 0
                                ? (
                                    formState.values.data.analysis[0].points.money_made_by_staff /
                                    formState.values.data.analysis[0].user_income.impure
                                  ).toFixed(2)
                                : 0
                            }
                            change={
                              formState.values.data.analysis[0].user_income.impure != 0
                                ? (
                                    (formState.values.data.analysis[0].points.money_made_by_staff /
                                      formState.values.data.analysis[0].user_income.impure -
                                      formState.values.data.analysis[1].points.money_made_by_staff /
                                        formState.values.data.analysis[1].user_income.impure) /
                                    (formState.values.data.analysis[1].points.money_made_by_staff /
                                      formState.values.data.analysis[1].user_income.impure)
                                  ).toFixed(2) * 100
                                : false
                            }
                            period={"نسبت به ماه گذشته"}
                          />
                        </View>
                      </View>

                      <View style={{ flex: 1, width: "100%" }}>
                        <StatBoxChartDoughnut
                          title="وضعیت تردد"
                          iconname="time-outline"
                          value={formState.values.data.analysis[0].eam_data.WorktimeInMonth / 8}
                          period={"روز کاری در این ماه"}
                          data={
                            formState.values.data.analysis[0].eam_data.Worked != 0
                              ? {
                                  labels: ["به موقع", "تاخیر", "جریمه", "ثبت نشده"],
                                  datasets: [
                                    {
                                      data: [
                                        formState.values.data.analysis[0].eam_data
                                          .number_of_worked -
                                          (formState.values.data.analysis[0].eam_data
                                            .number_of_late +
                                            formState.values.data.analysis[0].eam_data
                                              .number_of_penalty),
                                        formState.values.data.analysis[0].eam_data.number_of_late,
                                        formState.values.data.analysis[0].eam_data
                                          .number_of_penalty,
                                        formState.values.data.analysis[0].eam_data.WorktimeInMonth /
                                          8 -
                                          formState.values.data.analysis[0].eam_data
                                            .number_of_worked,
                                      ],
                                      backgroundColor: [
                                        Colors.green,
                                        Colors.error,
                                        Colors.primary,
                                        Colors.secondText,
                                      ],
                                      borderColor: ["#F2F2F2", "#F2F2F2", "#F2F2F2", "#F2F2F2"],
                                      borderWidth: 4,
                                    },
                                  ],
                                }
                              : false
                          }
                        />
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row-reverse",
                      columnGap: 10,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <StatBoxChartLine
                        title="تغییرات حقوق و سود ناخالص"
                        iconname="trending-up"
                        value={" "}
                        data={
                          formState.values.income_profit_variation_data != null
                            ? formState.values.income_profit_variation_data
                            : false
                        }
                      />
                    </View>

                    <View style={{ flex: 1 }}>
                      <StatBoxChartLine
                        title="تغییرات شاخص و نرخ سود"
                        iconname="trending-up"
                        value={" "}
                        data={
                          formState.values.indicator_profitrate_variation_data != null
                            ? formState.values.indicator_profitrate_variation_data
                            : false
                        }
                      />
                    </View>

                    <View style={{ flex: 1 }}>
                      <StatBoxChartLine
                        title="تغییرات تردد"
                        iconname="trending-up"
                        value={" "}
                        data={
                          formState.values.eam_variation_data != null
                            ? formState.values.eam_variation_data
                            : false
                        }
                      />
                    </View>
                  </View>
                </>
              )}
            </ScrollView>
          ) : (
            // Second Tab Start - Monthly
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ width: "100%", marginBottom: 35 }}
            >
              {formState.values.monthly &&
                Object.keys(formState.values.monthly)
                  .slice() // Take A Copy From ARRAY
                  .reverse() // Reverse Copied ARRAY
                  .map((target) => {
                    const { data, projects } = formState.values.monthly[target]

                    const isSiteDepartment = projects[0].department === "site"

                    return (
                      <View
                        style={{
                          width: "100%",
                          marginVertical: 35,
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          gap: 35,
                        }}
                      >
                        <View
                          style={{
                            width: "35%",
                            gap: 20,
                          }}
                        >
                          <AnalyseDataBox title="شاخص کل داده شده" value={data.providedTotalRate} />

                          <View style={{ flexDirection: "row-reverse", gap: 20 }}>
                            <AnalyseDataBox title="شاخص کل حساب شده" value={data.kpiTotalRate} />

                            <AnalyseDataBox
                              title="شاخص کل تسویه شده"
                              value={data.checkoutTotalRate}
                            />
                          </View>

                          <View style={{ flexDirection: "row-reverse", gap: 20 }}>
                            <AnalyseDataBox
                              title="مبلغ کل تسویه شده"
                              value={data.hadCheckoutPrices}
                              isPrice={true}
                            />

                            <AnalyseDataBox
                              title="مبلغ کل پروژه ها"
                              value={data.totalPrices}
                              isPrice={true}
                            />
                          </View>

                          <View style={{ flexDirection: "row-reverse", gap: 20 }}>
                            <AnalyseDataBox
                              title="شاخص عددی تسویه شده پروژه های اصلی"
                              value={data.addMainCheckoutRate}
                            />

                            <AnalyseDataBox
                              title="شاخص عددی پروژه های اصلی"
                              value={data.addMainRate}
                            />
                          </View>

                          <View style={{ flexDirection: "row-reverse", gap: 20 }}>
                            <AnalyseDataBox
                              title="شاخص عددی تسویه شده پروژه های فرعی"
                              value={data.addPartCheckoutRate}
                            />

                            <AnalyseDataBox
                              title="شاخص عددی پروژه های فرعی"
                              value={data.addPartRate}
                            />
                          </View>

                          <View style={{ flexDirection: "row-reverse", gap: 20 }}>
                            <AnalyseDataBox
                              title="شاخص رقمی پروژه های فرعی"
                              value={data.partDigitRate}
                            />

                            <AnalyseDataBox
                              title="مبلغ پروژه های فرعی"
                              value={data.partPrices}
                              isPrice={true}
                            />
                          </View>

                          <View style={{ flexDirection: "row-reverse", gap: 20 }}>
                            <AnalyseDataBox
                              title="شاخص کل تسویه نشده"
                              value={data.notCheckoutTotalRate}
                            />

                            <AnalyseDataBox
                              title="مبلغ کل تسویه نشده"
                              value={data.notCheckoutPrices}
                              isPrice={true}
                            />
                          </View>
                        </View>

                        <View
                          style={{
                            flex: 1,
                            height: "100%",
                            flexGrow: "grow",
                          }}
                        >
                          {projects.length > 0 ? (
                            <>
                              {projects
                                .slice()
                                .filter((item) => !item.isChange)
                                .map((item, index) => (
                                  <AnalyseUserCard
                                    isSiteDepartment={isSiteDepartment}
                                    item={{
                                      stage_id: item.stage_id,
                                      index: index,
                                      id: item.id,
                                      customer_name: item.customer.name,
                                      package: item.service?.service_title || "تغییرات",
                                      price: item.cost,
                                      site: item.customer.website,
                                      end_date: item.end_date,
                                      color: item.color,
                                      duration: item.duration,
                                      kpi_date: item.kpi_date,
                                      danger: item.danger,
                                      time: target,
                                      isChange: item.isChange,
                                    }}
                                    reload={getHandler}
                                  />
                                ))}
                            </>
                          ) : (
                            <InputNote
                              content={`پرسنل در ماه ${moment
                                .unix(target)
                                .format("jMMMM jYYYY")} پروژه ای نداشته است!`}
                            />
                          )}

                          {isSiteDepartment && ( // Is Site Department? Show Change Projects.
                            <>
                              <View
                                style={{
                                  marginVertical: 25,
                                  borderBottomWidth: 5,
                                  borderBottomColor: Colors.cardBg,
                                  borderStyle: "dashed",
                                }}
                              ></View>

                              {projects.slice().filter((item) => item.isChange).length > 0 ? (
                                <>
                                  {projects
                                    .slice()
                                    .filter((item) => item.isChange)
                                    .map((item, index) => (
                                      <AnalyseUserCard
                                        isSiteDepartment={isSiteDepartment}
                                        item={{
                                          stage_id: item.stage_id,
                                          index: index,
                                          id: item.id,
                                          customer_name: item.customer.name,
                                          package: item.service?.service_title || "تغییرات",
                                          price: item.cost,
                                          site: item.customer.website,
                                          end_date: item.end_date,
                                          color: item.color,
                                          duration: item.duration,
                                          kpi_date: item.kpi_date,
                                          danger: item.danger,
                                          time: target,
                                          isChange: item.isChange,
                                        }}
                                        reload={getHandler}
                                      />
                                    ))}
                                </>
                              ) : (
                                <InputNote
                                  content={`پرسنل در ماه ${moment
                                    .unix(target)
                                    .format("jMMMM jYYYY")} پروژه تغییراتی نداشته است!`}
                                />
                              )}
                            </>
                          )}
                        </View>

                        <View
                          style={{
                            width: 90,
                            height: "100%",
                            backgroundColor: Colors.cardBg,
                            borderRadius: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.text,
                              fontFamily: "IranSans-medium",
                              fontSize: 18,
                              textAlign: "center",
                              lineHeight: 25,
                              transform: [{ rotate: "-90deg" }],
                            }}
                          >
                            {moment.unix(target).format("jMMMM jYYYY")}
                          </Text>

                          <TouchableOpacity
                            onPress={() => openSpeedModal(user_id, target)}
                            style={{ position: "absolute", bottom: 30 }}
                          >
                            <Ionicons name="speedometer" size={28} color="black" style={{}} />
                          </TouchableOpacity>
                        </View>
                      </View>
                    )
                  })}

              <SpeedModal
                formState={formState}
                dispatchFormState={dispatchFormState}
                closeSpeedModal={closeSpeedModal}
              />
            </ScrollView>
          ))}

        {formState.values.loading && <ActivityIndicator size="large" color={Colors.primary} />}
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
}

export default AnalyseUserScreen
