import { useCallback, useReducer, useEffect, useState } from "react";
import { View, ScrollView, Text, StyleSheet, Dimensions } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Input from "../../components/Input";
import Btn from "../../components/Btn";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../components/Functions";
import Config from "../../constants/Config";
import Picker from "../../components/Picker";
import { separate } from "../../components/Functions";
import { CommonActions } from "@react-navigation/native";
import File from "../../components/File";
import { ReactTags } from "react-tag-autocomplete";
import style from "../../components/Taglist.module.css";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FUNDS = "SET_FUNDS";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_FUNDS:
      let funds = action.data;
      return {
        ...state,
        values: {
          ...state.values,
          funds: funds,
          tags: action.tags,
        },
      };
    default:
      return state;
  }
};

const AddTransactionScreen = (props) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const onAdd = useCallback(
    (newTag) => {
      setSelectedTags([...selectedTags, newTag]);
    },
    [selectedTags]
  );

  const onDelete = useCallback(
    (tagIndex) => {
      setSelectedTags(selectedTags.filter((_, i) => i !== tagIndex));
    },
    [selectedTags]
  );

  const token = useSelector((store) => store.user.token);
  const capabilities = useSelector((store) => store.user.capabilities);
  const user_data = useSelector((store) => store.user.user_data);
  const order_id = props.route.params.order_id ? props.route.params.order_id : 0;
  const customer_name = props.route.params.customer_name ? props.route.params.customer_name : false;
  const invoice_cost = props.route.params.invoice_cost ? props.route.params.invoice_cost : false;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      title: "",
      income: { item: "ورودی", id: "1" },
      gate: { item: "کارت به کارت", id: "cbc" },
      amount: 0,
      transaction_file: null,
      fund: null,
      funds: [],
      tags: [],
    },
    validities: {},
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity });
    },
    [dispatchFormState]
  );

  const formValidity = (form) => {
    const areTrue = Object.values(form.validities).every((value) => value != false);
    return areTrue;
  };

  const add_transaction = async () => {
    //Check validity
    if (order_id == 0 && !formState.values.title) {
      alert("عنوان الزامیست");
      return;
    }
    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    const url = Config.aioUrl + "/transaction/add";
    let formdata = new FormData();
    formdata.append("income", parseInt(formState.values.income.id));
    if (order_id != 0) formdata.append("order_id", order_id);
    if (order_id == 0) formdata.append("title", formState.values.title);
    if (order_id == 0) formdata.append("fund_id", formState.values.fund.id);
    formdata.append("amount", formState.values.amount);
    formdata.append("gate", formState.values.gate.id);

    if (!capabilities.includes("transaction_add_without_receipt") && !formState.values.transaction_file) {
      alert("آپلود رسید اجباریست");
      return;
    }
    if (formState.values.transaction_file) {
      formdata.append("file", formState.values.transaction_file.file, formState.values.transaction_file.name);
    }

    if (selectedTags.length != 0) {
      let tags_str = "";
      for (let i = 0; i < selectedTags.length; i++) {
        tags_str += selectedTags[i].value + (i + 1 == selectedTags.length ? "" : ",");
      }
      formdata.append("tags", tags_str);
    }

    if (order_id == 0 && formState.values.amount == 0) {
      alert("مبلغ نامشخص می باشد.");
      return;
    }

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "AddTransaction",
                },
              ],
            })
          );
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const get_funds = async () => {
    const url = Config.aioUrl + "/transaction/getFunds";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: SET_FUNDS, data: response.data.data, tags: response.data.tags });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  useEffect(() => {
    const blured = props.navigation.addListener("blur", () => {
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: "Sales",
            },
            {
              name: "AddTransaction",
            },
          ],
        })
      );
    });

    return blured;
  }, [props.navigation]);

  useEffect(() => {
    get_funds();
  }, []);

  console.log(selectedTags);
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <View style={{ flex: 1 }}>
        <Header title="ثبت تراکنش" noBack />
        {!formState.values.loading && (
          <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50, paddingHorizontal: DESKTOP ? "30%" : 0 }}>
            {order_id != 0 && (
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                }}
              >
                <View style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                  <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>#{order_id}</Text>
                  <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>{customer_name}</Text>
                  <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>{separate(invoice_cost.toString())} تومان</Text>
                </View>
              </View>
            )}
            {order_id == 0 && <Input label="عنوان" id="title" onChange={inputChangeHandler} />}

            {order_id == 0 && <Picker label="تنخواه" id="fund" onChange={inputChangeHandler} options={formState.values.funds} />}
            <Picker
              label="روش پرداخت"
              id="gate"
              onChange={inputChangeHandler}
              options={[
                { item: "کارت به کارت", id: "cbc" },
                { item: "پایا", id: "paya" },
                { item: "زرین لینک", id: "zarinlink" },
                { item: "نقدی", id: "cash" },
              ]}
              insert={order_id != 0 ? formState.values.gate : false}
            />
            {order_id == 0 && (
              <Picker
                label="نوع"
                id="income"
                onChange={inputChangeHandler}
                options={[
                  { item: "ورودی", id: "1" },
                  { item: "خروجی", id: "0" },
                ]}
              />
            )}

            {order_id == 0 && <Input label="مبلغ" id="amount" onChange={inputChangeHandler} validity convertDigits />}
            {formState.values.amount != 0 && (
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
                  {separate(formState.values.amount) + " تومان"}
                </Text>
              </View>
            )}
            <View style={{ width: "100%", marginBottom: 30 }}>
              <File
                id="transaction_file"
                title="تصویر رسید"
                extension="jpg"
                max_size={300}
                max_width={480}
                max_height={1080}
                content_type="image/jpeg"
                required={true}
                value={formState.values.transaction_file}
                editOptions={{
                  compress: 0.8,
                  resizeHeight: 1080,
                  resizeWidth: 480,
                }}
                onChange={inputChangeHandler}
              />
            </View>
            {formState.values.tags.length != 0 && user_data.department == "lead" && (
              <ReactTags
                labelText="تگ های تراکنش"
                selected={selectedTags}
                suggestions={formState.values.tags}
                onAdd={onAdd}
                onDelete={onDelete}
                noOptionsText="تگ یافت نشد"
                placeholderText="افزودن تگ"
              />
            )}
            <Btn
              title="ثبت تراکنش"
              onPress={() => {
                add_transaction();
              }}
              loading={formState.values.loading}
              wrapperStyle={{ marginTop: 30 }}
            />
          </ScrollView>
        )}
      </View>
    </View>
  );
};

const Styles = StyleSheet.create({
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
});

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default AddTransactionScreen;
