import { useReducer, useCallback, useEffect } from "react"
import { ScrollView, View, Text } from "react-native"
import { useSelector } from "react-redux"
import Input from "../../components/Input"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import Config from "../../constants/Config"
import axios from "axios"
import Header from "../../components/Header"
import { encrypt, decrypt, logOut } from "../../components/Functions"
import { CommonActions } from "@react-navigation/native"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_FORM:
      let form = action.data
      const can_edit = action.can_edit
      return {
        ...state,
        values: {
          ...state.values,
          form: form,
          can_edit: can_edit,
        },
      }
    default:
      return state
  }
}

const AddSiteScreen = (props) => {
  const site_id = props.route.params.site_id
  const token = useSelector((store) => store.user.token)
  const key_encrypt = useSelector((store) => store.user.key_encrypt)
  const key_decrypt = useSelector((store) => store.user.key_decrypt)
  const capabilities = useSelector((store) => store.user.capabilities)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      url: "",
      first_name: "",
      last_name: "",
      phone: "",
      melli_code: "",
      hosting_provider: "",
      hosting_email: "",
      hosting_password: "",
      hosting_panel: "",
      hosting_panel_username: "",
      hosting_panel_password: "",
      hosting_panel_api: "",
      wp_admin_username: "",
      wp_admin_password: "",
      wp_backdoor: "",
      nic_email: "",
      nic_handle: "",
      nic_password: "",
      nic_answer: "",
      enamad_username: "",
      enamad_password: "",
      samandehi_username: "",
      samandehi_password: "",
      online_gateway: "",
      description: "",
      form: null,
      can_edit: [],
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/asc/site/set"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    if (site_id) formdata.append("site_id", site_id)
      
    formdata.append("url", formState.values.url)
    formdata.append("first_name", formState.values.first_name)
    formdata.append("last_name", formState.values.last_name)
    formdata.append("phone", formState.values.phone)
    formdata.append("melli_code", formState.values.melli_code)
    formdata.append("hosting_provider", formState.values.hosting_provider)
    formdata.append(
      "hosting_email",
      await encrypt(formState.values.hosting_email, key_encrypt)
    )
    formdata.append(
      "hosting_password",
      await encrypt(formState.values.hosting_password, key_encrypt)
    )
    formdata.append("hosting_panel", formState.values.hosting_panel)
    formdata.append(
      "hosting_panel_username",
      await encrypt(formState.values.hosting_panel_username, key_encrypt)
    )
    formdata.append(
      "hosting_panel_password",
      await encrypt(formState.values.hosting_panel_password, key_encrypt)
    )
    formdata.append(
      "hosting_panel_api",
      await encrypt(formState.values.hosting_panel_api, key_encrypt)
    )
    formdata.append(
      "wp_admin_username",
      await encrypt(formState.values.wp_admin_username, key_encrypt)
    )
    formdata.append(
      "wp_admin_password",
      await encrypt(formState.values.wp_admin_password, key_encrypt)
    )
    formdata.append(
      "wp_backdoor",
      await encrypt(formState.values.wp_backdoor, key_encrypt)
    )
    formdata.append(
      "nic_email",
      await encrypt(formState.values.nic_email, key_encrypt)
    )
    formdata.append(
      "nic_handle",
      await encrypt(formState.values.nic_handle, key_encrypt)
    )
    formdata.append(
      "nic_password",
      await encrypt(formState.values.nic_password, key_encrypt)
    )
    formdata.append(
      "nic_answer",
      await encrypt(formState.values.nic_answer, key_encrypt)
    )
    formdata.append(
      "enamad_username",
      await encrypt(formState.values.enamad_username, key_encrypt)
    )
    formdata.append(
      "enamad_password",
      await encrypt(formState.values.enamad_password, key_encrypt)
    )
    formdata.append(
      "samandehi_username",
      await encrypt(formState.values.samandehi_username, key_encrypt)
    )
    formdata.append(
      "samandehi_password",
      await encrypt(formState.values.samandehi_password, key_encrypt)
    )
    formdata.append(
      "online_gateway",
      await encrypt(formState.values.online_gateway, key_encrypt)
    )
    formdata.append(
      "description",
      await encrypt(formState.values.description, key_encrypt)
    )

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        if (site_id) get_form()

        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_form = async () => {
    const url = Config.aioUrl + "/asc/site/get?id=" + site_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let axios_response = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        return response.data
      })
      .catch(function (error) {})

    let form = await axios_response.data

    for (let key in form) {
      if (
        key == "hosting_email" ||
        key == "hosting_password" ||
        key == "hosting_panel_username" ||
        key == "hosting_panel_password" ||
        key == "hosting_panel_api" ||
        key == "wp_admin_username" ||
        key == "wp_admin_password" ||
        key == "wp_backdoor" ||
        key == "nic_email" ||
        key == "nic_handle" ||
        key == "nic_password" ||
        key == "nic_answer" ||
        key == "enamad_username" ||
        key == "enamad_password" ||
        key == "samandehi_username" ||
        key == "samandehi_password" ||
        key == "online_gateway" ||
        key == "description"
      ) {
        form[key] = await decrypt(form[key] || "", key_decrypt)
      }
    }

    console.clear()
    console.log("EXPORT THIS DATA: ", axios_response)

    dispatchFormState({
      type: SET_FORM,
      data: form,
      can_edit: axios_response.can_edit ? axios_response.can_edit : [],
    })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      if (site_id) get_form()
    })

    return focused
  }, [props.navigation])

  // useEffect(() => {
  //   const blured = props.navigation.addListener("blur", () => {
  //     props.navigation.dispatch(
  //       CommonActions.reset({
  //         index: 1,
  //         routes: [
  //           {
  //             name: "AddSite",
  //           },
  //         ],
  //       })
  //     )
  //   })

  //   return blured
  // }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header
        title={site_id ? "اطلاعات سایت #" + site_id : "اطلاعات سایت"}
        noToggle
      />
      {!formState.values.loading && (
        <ScrollView
          style={{
            width: "100%",
            height: "100%",
          }}
          showsVerticalScrollIndicator={false}
        >
          <View style={{ flexDirection: "row-reverse" }}>
            <View style={{ flex: 1, marginLeft: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  هویتی
                </Text>
              </View>
              {((formState.values.form && formState.values.form.first_name) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("first_name")) && (
                <Input
                  label="نام"
                  id="first_name"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.first_name
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("first_name") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.last_name) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("last_name")) && (
                <Input
                  label="نام خانوادگی"
                  id="last_name"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form ? formState.values.form.last_name : ""
                  }
                  editable={
                    formState.values.can_edit.includes("last_name") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.phone) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("phone")) && (
                <Input
                  label="شماره موبایل"
                  id="phone"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form ? formState.values.form.phone : ""
                  }
                  editable={
                    formState.values.can_edit.includes("phone") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.melli_code) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("melli_code")) && (
                <Input
                  label="کد ملی"
                  id="melli_code"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.melli_code
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("melli_code") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
            </View>
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  هاست
                </Text>
              </View>
              {((formState.values.form &&
                formState.values.form.hosting_provider) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("hosting_provider")) && (
                <Input
                  label="شرکت هاستینگ"
                  id="hosting_provider"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_provider
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("hosting_provider") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.hosting_email) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("hosting_email")) && (
                <Input
                  label="ایمیل هاستینگ"
                  id="hosting_email"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_email
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("hosting_email") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.hosting_password) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("hosting_password")) && (
                <Input
                  label="پسورد هاستینگ"
                  id="hosting_password"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_password
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("hosting_password") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.hosting_panel) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("hosting_panel")) && (
                <Input
                  label="پنل هاست"
                  id="hosting_panel"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_panel
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("hosting_panel") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.hosting_panel_username) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes(
                  "hosting_panel_username"
                )) && (
                <Input
                  label="نام کاربری پنل هاست"
                  id="hosting_panel_username"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_panel_username
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes(
                      "hosting_panel_username"
                    ) ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.hosting_panel_password) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes(
                  "hosting_panel_password"
                )) && (
                <Input
                  label="پسورد پنل هاست"
                  id="hosting_panel_password"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_panel_password
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes(
                      "hosting_panel_password"
                    ) ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.hosting_panel_api) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("hosting_panel_api")) && (
                <Input
                  label="کلید سی پنل"
                  id="hosting_panel_api"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.hosting_panel_api
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("hosting_panel_api") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
            </View>
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  ایرانیک
                </Text>
              </View>
              {((formState.values.form && formState.values.form.nic_email) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("nic_email")) && (
                <Input
                  label="ایمیل ایرانیک"
                  id="nic_email"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form ? formState.values.form.nic_email : ""
                  }
                  editable={
                    formState.values.can_edit.includes("nic_email") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.nic_handle) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("nic_handle")) && (
                <Input
                  label="نیک هندل"
                  id="nic_handle"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.nic_handle
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("nic_handle") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.nic_password) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("nic_password")) && (
                <Input
                  label="پسورد ایرانیک"
                  id="nic_password"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.nic_password
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("nic_password") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.nic_answer) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("nic_answer")) && (
                <Input
                  label="پاسخ امنیتی ایرانیک"
                  id="nic_answer"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.nic_answer
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("nic_answer") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
            </View>
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  وردپرس
                </Text>
              </View>
              {((formState.values.form && formState.values.form.url) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("url")) && (
                <Input
                  label="آدرس سایت"
                  id="url"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form ? formState.values.form.url : ""
                  }
                  editable={
                    formState.values.can_edit.includes("url") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.wp_admin_username) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("wp_admin_username")) && (
                <Input
                  label="نام کاربری مدیر کل"
                  id="wp_admin_username"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.wp_admin_username
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("wp_admin_username") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.wp_admin_password) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("wp_admin_password")) && (
                <Input
                  label="پسورد مدیر کل"
                  id="wp_admin_password"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.wp_admin_password
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("wp_admin_password") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.wp_backdoor) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("wp_backdoor")) && (
                <Input
                  label="ایندکس"
                  id="wp_backdoor"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.wp_backdoor
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("wp_backdoor") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
            </View>
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  نمادها
                </Text>
              </View>
              {((formState.values.form &&
                formState.values.form.enamad_username) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("enamad_username")) && (
                <Input
                  label="نام کاربری اینماد"
                  id="enamad_username"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.enamad_username
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("enamad_username") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.enamad_password) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("enamad_password")) && (
                <Input
                  label="پسورد اینماد"
                  id="enamad_password"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.enamad_password
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("enamad_password") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.samandehi_username) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("samandehi_username")) && (
                <Input
                  label="نام کاربری ساماندهی"
                  id="samandehi_username"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.samandehi_username
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("samandehi_username") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form &&
                formState.values.form.samandehi_password) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("samandehi_password")) && (
                <Input
                  label="پسورد ساماندهی"
                  id="samandehi_password"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.samandehi_password
                      : ""
                  }
                  editable={
                    formState.values.can_edit.includes("samandehi_password") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
            </View>
            <View style={{ flex: 1, marginRight: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  سایر
                </Text>
              </View>
              {((formState.values.form &&
                formState.values.form.online_gateway) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("online_gateway")) && (
                <Input
                  label="اطلاعات درگاه پرداخت"
                  id="online_gateway"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.online_gateway
                      : ""
                  }
                  multiline
                  editable={
                    formState.values.can_edit.includes("online_gateway") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
              {((formState.values.form && formState.values.form.description) ||
                !site_id ||
                capabilities.includes("asc_get_sites_all") ||
                formState.values.can_edit.includes("online_gateway")) && (
                <Input
                  label="سایر توضیحات"
                  id="description"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.form
                      ? formState.values.form.description
                      : ""
                  }
                  multiline
                  editable={
                    formState.values.can_edit.includes("online_gateway") ||
                    capabilities.includes("asc_get_sites_all") ||
                    (!site_id && capabilities.includes("asc_add_site"))
                      ? true
                      : false
                  }
                />
              )}
            </View>
          </View>

          {(formState.values.can_edit.length != 0 || !site_id) && (
            <Btn
              title={site_id ? "ثبت ویرایش" : "افزودن سایت"}
              onPress={async () => {
                send_form()
              }}
              wrapperStyle={{ marginVertical: 50 }}
              loading={formState.values.loading}
            />
          )}
        </ScrollView>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default AddSiteScreen
