import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import { Ionicons } from "@expo/vector-icons"
import Btn from "../../components/Btn"
import axios from "axios"
import { logOut } from "../../components/Functions"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_COURSES = "SET_COURSES"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_COURSES:
      return {
        ...state,
        values: {
          ...state.values,
          courses: action.data,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = () => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingBottom: 0,
            paddingTop: 15,
            paddingHorizontal: 20,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 5, marginRight: 30, opacity: 0 }}>
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 2 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>

      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const EditButton = ({ item, capabilities, navigation, myDepartment }) => {
  const isPrivate = item.is_private
  const isSpotplayer = item.spot_id ? true : false

  console.log(myDepartment, item.department)

  const isMyDepartment =
    item.department === myDepartment || myDepartment === "lead"

  let isShowButton = false

  const goToEdit = () => {
    if (!isSpotplayer)
      navigation.navigate("EditCourse", {
        course_id: item.id,
      })

    if (isSpotplayer)
      navigation.navigate("SetCourse", {
        course_id: item.id,
      })
  }

  if (
    isPrivate &&
    (capabilities.includes("lms_private_edit_all_course") ||
      (isMyDepartment &&
        capabilities.includes("lms_private_edit_own_department_course")))
  ) {
    isShowButton = true
  }

  if (
    !isPrivate &&
    (capabilities.includes("lms_public_edit_all_course") ||
      (isMyDepartment &&
        capabilities.includes("lms_public_edit_own_department_course")))
  ) {
    isShowButton = true
  }

  if (isShowButton) {
    return (
      <>
        <TouchableOpacity
          onPress={goToEdit}
          style={{
            width: 80,
            height: 25,
            backgroundColor: Colors.primary,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-regular",
              fontSize: 10,
              color: "#fff",
            }}
          >
            ویرایش
          </Text>
        </TouchableOpacity>
      </>
    )
  } else {
    return <></>
  }
}

const CoursesListScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)
  const user_data = useSelector((store) => store.user.user_data)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: true,
      courses: [],
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_courses = async () => {
    const url = Config.aioUrl + "/lms/course/list"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_COURSES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_courses()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <View
        style={{
          paddingHorizontal: 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Header title="لیست دوره ها" />

        {/* <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "30%" : 25 }}> */}
        <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "25%" : 25 }}>
          {formState.values.loading ? (
            <LoadingPlaceHolder />
          ) : (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ paddingBottom: 50 }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row-reverse",
                  marginBottom: 30,
                }}
              >
                {capabilities.includes("lms_add_course") && (
                  <Btn
                    title="افزودن دوره جدید"
                    color={Colors.blue}
                    titleStyle={{ fontSize: 12 }}
                    onPress={() => {
                      props.navigation.navigate("SetCourse", {
                        course_id: null,
                      })
                    }}
                    wrapperStyle={{ flex: 1, marginLeft: 3 }}
                  />
                )}
                <View style={{ flex: 2, marginRight: 3 }}></View>
              </View>

              {formState.values.courses.map((item) => (
                <TouchableOpacity
                  key={item.id}
                  style={{
                    width: "100%",
                    backgroundColor: "#F2F2F2",
                    borderRadius: 15,
                    padding: 20,
                    marginBottom: 10,
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    // ! Go to view the course ...
                    if (item.isStaff) {
                      props.navigation.navigate("EditCourse")
                    }
                  }}
                >
                  <View
                    style={{
                      flex: 4,
                      flexDirection: "row-reverse",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Ionicons name="school" color={Colors.text} size={24} />
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 14,
                        color: Colors.text,
                        marginRight: 5,
                      }}
                    >
                      {item.title}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    {/* //? Course department */}
                    <View
                      style={{
                        width: 80,
                        height: 25,
                        backgroundColor: Colors.green,
                        borderRadius: 7,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-regular",
                          fontSize: 10,
                          color: "#fff",
                        }}
                      >
                        {item.department
                          .replace("site", "سایت")
                          .replace("graphic", "گرافیک")
                          .replace("sales", "فروش")
                          .replace("seo", "سئو")
                          .replace("hr", "منابع انسانی")}
                      </Text>
                    </View>

                    {/* //? Course is spot player or no */}
                    {item.spot_id && (
                      <View
                        style={{
                          width: 80,
                          height: 25,
                          backgroundColor: Colors.blue,
                          borderRadius: 7,
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 3,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-regular",
                            fontSize: 10,
                            color: "#fff",
                          }}
                        >
                          اسپات پلیر
                        </Text>
                      </View>
                    )}

                    {/* //? Edit course Button Handler */}
                    <EditButton
                      item={item}
                      capabilities={capabilities}
                      navigation={props.navigation}
                      myDepartment={user_data.department}
                    />
                  </View>
                </TouchableOpacity>
              ))}
            </ScrollView>
          )}
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default CoursesListScreen
