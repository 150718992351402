import { useState } from "react"
import { View, Dimensions } from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Journey from "../../components/Journey"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import BasicInformationTab from "./FormLogoTabs/BasicInformationTab"
import BrandInformationTab from "./FormLogoTabs/BrandInformationTab"
import DesignStyleTab from "./FormLogoTabs/DesignStyleTab"
import RatingLogosTab from "./FormLogoTabs/RatingLogosTab"
import FavoriteLogosTab from "./FormLogoTabs/FavoriteLogosTab"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
const Tab = createMaterialTopTabNavigator()

const FormLogoScreen = (props) => {
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const steps = [
    {
      id: 5,
      name: "لوگو مورد علاقه",
      status: "inactive",
      component: FavoriteLogosTab,
    },
    {
      id: 4,
      name: "امتیاز به لوگو",
      status: "inactive",
      component: RatingLogosTab,
    },
    {
      id: 3,
      name: "سبک طراحی",
      status: "inactive",
      component: DesignStyleTab,
    },
    {
      id: 2,
      name: "اطلاعات برند",
      status: "inactive",
      component: BrandInformationTab,
    },
    {
      id: 1,
      name: "اطلاعات پایه",
      status: "active",
      component: BasicInformationTab,
    },
  ]

  const [stepsState, setStepsState] = useState(steps)
  const [activeIndex, setActiveIndex] = useState(0)

  const setStepHandler = (nextTitle, nextIndex) => {
    setStepsState((prev) => {
      let prevState = [...prev]

      prevState.map((item) => {
        if (item.name == nextTitle) {
          item.status = "active"
        } else {
          item.status = "inactive"
        }
      })

      return prevState
    })

    props.navigation.navigate(nextTitle)

    if (nextIndex) {
      setActiveIndex(nextIndex)
    } else {
      setActiveIndex((prev) => prev + 1)
    }
  }

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        flex: 1,
      }}
    >
      <Header title="فرم خدمات لوگو" noToggle noNotif noActiveSessions />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 0,
          flex: 1,
          height: "100%",
        }}
      >
        <Tab.Navigator
          initialRouteName="اطلاعات پایه"
          swipeEnabled={false}
          sceneContainerStyle={{ backgroundColor: "#fff" }}
          tabBar={(props) => (
            <Journey
              steps={stepsState}
              setSteps={setStepsState}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              {...props}
            />
          )}
        >
          {steps.map((step) => (
            <Tab.Screen
              key={step.name}
              name={step.name}
              component={step.component}
              initialParams={{
                setActiveIndex: setActiveIndex,
                setSteps: setStepHandler,
                project_id: project_id,
                is_customer: is_customer,
              }}
            />
          ))}
        </Tab.Navigator>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default FormLogoScreen
