import { useReducer, useEffect } from "react"
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { useSelector } from "react-redux"
import ProjectCard from "../../components/ProjectCard"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config, { isDevelopeMode } from "../../constants/Config"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const windowWidth = Dimensions.get("window").width

const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_PROJECTS = "SET_PROJECTS"
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
const SET_ACTIVE_STAFF = "SET_ACTIVE_STAFF"
const SET_ACTIVE_SORT = "SET_ACTIVE_SORT"

const formReducer = (state, action) => {
  switch (action.type) {
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_PROJECTS:
      let department_staffs = []
      for (let i = 0; i < action.data.length; i++) {
        department_staffs.push(...action.data[i].staffs)
      }
      department_staffs = department_staffs.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      )
      return {
        ...state,
        values: {
          ...state.values,
          projects: action.data,
          stages: action.stages,
          department_staffs: department_staffs,
          projects_filtered_by_staff: [],
        },
      }
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        values: {
          ...state.values,
          active_section: action.value,
        },
      }
    case SET_ACTIVE_STAFF:
      let projects_filtered_by_staff = state.values.projects.filter((item) => {
        for (let i = 0; i < item.staffs.length; i++) {
          if (item.staffs[i].id == action.value) {
            return true
          }
        }
        return false
      })
      return {
        ...state,
        values: {
          ...state.values,
          active_staff: action.value,
          projects_filtered_by_staff: projects_filtered_by_staff,
        },
      }

    case SET_ACTIVE_SORT:
      return {
        ...state,
        values: {
          ...state.values,
          active_sort: action.value,
        },
      }

    default:
      return state
  }
}

const LoadingPlaceHolder = () => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingBottom: 0,
            paddingTop: 25,
            paddingHorizontal: 20,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 5, marginRight: 30 }}>
            <PlaceholderLine height={25} />
          </View>
          <View style={{ flex: 2 }}>
            <PlaceholderLine height={25} />
          </View>
        </View>
      </Placeholder>

      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            overflow: "hidden",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              minWidth: 1510,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              minWidth: 1510,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const ProjectsScreen = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const user_data = useSelector((store) => store.user.user_data)
  const user_id = user_data?.id
  const DESKTOP = windowWidth > 800 ? true : false
  const token = useSelector((store) => store.user.token)
  const user_department = useSelector(
    (store) => store.user.user_data.department
  )

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      showAlert: false,
      projects: [],
      stages: [],
      active_section: "me",
      department_staffs: [],
      active_staff: "all",
      sorts: [
        { id: "AGREEMENT_DATE", name: "تاریخ قرارداد" },
        { id: "STAGE_ENTRANCE", name: "تاریخ ورود به استیج" },
      ],
      active_sort: "AGREEMENT_DATE",
      projects_filtered_by_staff: [],
    },
  })

  const get_projects = async (options) => {
    let url = ""
    if (options.me) {
      url = Config.aioUrl + "/projects/get?me=true"
    } else {
      url = Config.aioUrl + "/projects/get?department=" + options.department
    }
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: SET_PROJECTS,
          data: response.data.data,
          stages: response.data.stages,
        })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  useEffect(() => {
    // ! REMOVE
    // if (isDevelopeMode) {
    //   props.navigation.navigate("Project", {
    //     id: 476,
    //   })

    //   return () => {}
    // }
    // ! REMOVE

    // const focused = props.navigation.addListener("focus", async () => {
    if (capabilities.includes("projects_get_all")) {
      dispatchFormState({ type: SET_ACTIVE_SECTION, value: "site" })
      dispatchFormState({ type: SET_ACTIVE_STAFF, value: "all" })
      get_projects({ department: "site" })
    } else {
      dispatchFormState({ type: SET_ACTIVE_SECTION, value: "me" })
      get_projects({ me: true })
    }
    // })

    // return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header
        title="لیست پروژه ها"
        noBack
        reload={() => {
          if (capabilities.includes("projects_get_all")) {
            dispatchFormState({ type: SET_ACTIVE_SECTION, value: "site" })
            dispatchFormState({ type: SET_ACTIVE_STAFF, value: "all" })
            get_projects({ department: "site" })
          } else {
            dispatchFormState({ type: SET_ACTIVE_SECTION, value: "me" })
            get_projects({ me: true })
          }
        }}
      />

      {!formState.values.loading && (
        <>
          {(capabilities.includes("projects_get_own_department") ||
            capabilities.includes("projects_get_all")) && (
            <View
              style={{ height: 40, marginBottom: 20, alignItems: "flex-end" }}
            >
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={{ flexDirection: "row-reverse", width: "100%" }}
              >
                <View
                  style={{
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    دسته نمایش:{" "}
                  </Text>
                  {(user_department == "graphic" ||
                    user_department == "site") && (
                    <TouchableOpacity
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_section == "me"
                            ? Colors.blue
                            : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_SECTION,
                          value: "me",
                        })
                        get_projects({ me: true })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color:
                            formState.values.active_section == "me"
                              ? "#fff"
                              : Colors.text,
                        }}
                      >
                        من
                      </Text>
                    </TouchableOpacity>
                  )}

                  {((user_department == "site" &&
                    capabilities.includes("projects_get_own_department")) ||
                    capabilities.includes("projects_get_all")) && (
                    <TouchableOpacity
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_section == "site"
                            ? Colors.blue
                            : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_SECTION,
                          value: "site",
                        })
                        dispatchFormState({
                          type: SET_ACTIVE_STAFF,
                          value: "all",
                        })
                        get_projects({ department: "site" })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color:
                            formState.values.active_section == "site"
                              ? "#fff"
                              : Colors.text,
                        }}
                      >
                        دپارتمان سایت
                      </Text>
                    </TouchableOpacity>
                  )}

                  {((user_department == "graphic" &&
                    capabilities.includes("projects_get_own_department")) ||
                    capabilities.includes("projects_get_all")) && (
                    <TouchableOpacity
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_section == "graphic"
                            ? Colors.blue
                            : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_SECTION,
                          value: "graphic",
                        })
                        dispatchFormState({
                          type: SET_ACTIVE_STAFF,
                          value: "all",
                        })
                        get_projects({ department: "graphic" })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color:
                            formState.values.active_section == "graphic"
                              ? "#fff"
                              : Colors.text,
                        }}
                      >
                        دپارتمان گرافیک
                      </Text>
                    </TouchableOpacity>
                  )}

                  {((user_department == "sales" &&
                    capabilities.includes("projects_get_own_department")) ||
                    capabilities.includes("projects_get_all")) && (
                    <TouchableOpacity
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_section == "sales"
                            ? Colors.blue
                            : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_SECTION,
                          value: "sales",
                        })
                        dispatchFormState({
                          type: SET_ACTIVE_STAFF,
                          value: "all",
                        })
                        get_projects({ department: "sales" })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color:
                            formState.values.active_section == "sales"
                              ? "#fff"
                              : Colors.text,
                        }}
                      >
                        دپارتمان فروش
                      </Text>
                    </TouchableOpacity>
                  )}

                  {(formState.values.active_section == "site" ||
                    formState.values.active_section == "graphic") && (
                    <View
                      style={{
                        marginRight: 30,
                        flexDirection: "row-reverse",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Medium",
                          fontSize: 12,
                          color: Colors.text,
                        }}
                      >
                        پرسنل :{" "}
                      </Text>
                      <TouchableOpacity
                        style={{
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                          borderRadius: 10,
                          backgroundColor:
                            formState.values.active_staff == "all"
                              ? Colors.blue
                              : "#F2F2F2",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 5,
                        }}
                        onPress={() => {
                          dispatchFormState({
                            type: SET_ACTIVE_STAFF,
                            value: "all",
                          })
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color:
                              formState.values.active_staff == "all"
                                ? "#fff"
                                : Colors.text,
                          }}
                        >
                          همه
                        </Text>
                      </TouchableOpacity>
                      {formState.values.department_staffs.map((item, index) => (
                        <TouchableOpacity
                          key={index}
                          style={{
                            paddingVertical: 5,
                            paddingHorizontal: 10,
                            borderRadius: 10,
                            backgroundColor:
                              formState.values.active_staff == item.id
                                ? Colors.blue
                                : "#F2F2F2",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: 5,
                          }}
                          onPress={() => {
                            dispatchFormState({
                              type: SET_ACTIVE_STAFF,
                              value: item.id,
                            })
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "IranSans-Regular",
                              fontSize: 12,
                              color:
                                formState.values.active_staff == item.id
                                  ? "#fff"
                                  : Colors.text,
                            }}
                          >
                            {item.name}
                          </Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  )}
                </View>

                {(user_id == 1 || user_id == 2) && (
                  <View
                    style={{
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      marginLeft: 20,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 12,
                        color: Colors.text,
                      }}
                    >
                      ترتیب نمایش :{" "}
                    </Text>
                    {formState.values.sorts.map((item, index) => (
                      <TouchableOpacity
                        key={index}
                        style={{
                          paddingVertical: 5,
                          paddingHorizontal: 10,
                          borderRadius: 10,
                          backgroundColor:
                            formState.values.active_sort == item.id
                              ? Colors.blue
                              : "#F2F2F2",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 5,
                        }}
                        onPress={() => {
                          dispatchFormState({
                            type: SET_ACTIVE_SORT,
                            value: item.id,
                          })
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color:
                              formState.values.active_sort == item.id
                                ? "#fff"
                                : Colors.text,
                          }}
                        >
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                )}
              </ScrollView>
            </View>
          )}

          {formState.values.projects.length != 0 && (
            <View style={{ flex: 1, flexDirection: "row" }}>
              <FlatList
                data={formState.values.stages.slice(0).reverse()}
                keyExtractor={(item) => item.id}
                horizontal
                style={{ flexDirection: "row-reverse" }}
                renderItem={(stageData) => {
                  let stageProjects = []
                  if (formState.values.projects_filtered_by_staff.length != 0) {
                    stageProjects =
                      formState.values.projects_filtered_by_staff.filter(
                        (item) => item.stage == stageData.item.id
                      )
                  } else {
                    stageProjects = formState.values.projects.filter(
                      (item) => item.stage == stageData.item.id
                    )
                  }

                  let isSortByStageEntrance =
                    formState.values.active_sort === "STAGE_ENTRANCE"

                  let sortedStageProjects = isSortByStageEntrance
                    ? stageProjects
                        .slice()
                        .sort((a, b) => a.stage_entrance - b.stage_entrance)
                    : stageProjects

                  return (
                    <View style={{ width: 300, marginLeft: 15 }}>
                      <View
                        style={{
                          backgroundColor: Colors.secondary,
                          height: 65,
                          alignItems: "center",
                          paddingHorizontal: 20,
                          borderRadius: 15,
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          marginBottom: 10,
                          borderTopWidth: 4,
                          borderTopColor: stageData.item.color,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 16,
                            color: "#fff",
                          }}
                        >
                          {stageData.item.title}
                        </Text>
                        <View
                          style={{
                            width: 34,
                            height: 34,
                            borderRadius: 17,
                            backgroundColor: stageData.item.color,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "IranSans-Medium",
                              fontSize: 16,
                              color: "#fff",
                            }}
                          >
                            {stageProjects.length}
                          </Text>
                        </View>
                      </View>

                      <FlatList
                        showsVerticalScrollIndicator={false}
                        data={sortedStageProjects}
                        keyExtractor={(item) => item.id}
                        renderItem={(ProjectData) => (
                          <ProjectCard
                            item={ProjectData.item}
                            stageColor={stageData.item.color}
                            onPress={
                              Platform.OS == "web"
                                ? {
                                    screen: "Project",
                                    params: { id: ProjectData.item.id },
                                  }
                                : () => {
                                    props.navigation.navigate("Project", {
                                      id: ProjectData.item.id,
                                    })
                                  }
                            }
                          />
                        )}
                      />
                    </View>
                  )
                }}
              />
            </View>
          )}
        </>
      )}

      {formState.values.loading && <LoadingPlaceHolder />}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default ProjectsScreen
