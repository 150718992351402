import { useReducer, useEffect, useCallback } from "react"
import { View, Text, FlatList, ActivityIndicator, Dimensions, TouchableOpacity } from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config from "../../constants/Config"
import moment from "moment-jalaali"
const windowWidth = Dimensions.get("window").width

const SET_LOADING = "SET_LOADING"
const SET_REMINDERS = "SET_REMINDERS"
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
const SET_ACTIVE_TIME = "SET_ACTIVE_TIME"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }

    case SET_REMINDERS:
      return {
        ...state,
        values: {
          ...state.values,
          reminders: action.data,
          staffs: action.staffs,
        },
      }

    case SET_ACTIVE_SECTION:
      return {
        ...state,
        values: {
          ...state.values,
          active_section: action.value,
        },
      }

    case SET_ACTIVE_TIME:
      return {
        ...state,
        values: {
          ...state.values,
          active_time: action.value,
        },
      }

    default:
      return state
  }
}

const Reminder = (props) => {
  const { item, navigation } = props
  return (
    <TouchableOpacity
      style={{
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        paddingVertical: 20,
        paddingLeft: 20,
        paddingRight: 0,
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onPress={() => {
        navigation.navigate("Lead", { id: item.lead_data.id })
      }}
    >
      <View style={{ flex: 5 }}>
        <View
          style={{
            width: 90,
            height: 25,
            backgroundColor: item.reminder_data.done == "1" ? Colors.green : Colors.primary,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>
            {moment.unix(item.reminder_data.time).format("jYYYY/jM/jD HH:mm")}
          </Text>
        </View>

        <View
          style={{
            width: 90,
            height: 25,
            backgroundColor: Colors.text,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>
            {item.reminder_data.employee.name}
          </Text>
        </View>
      </View>

      <View style={{ flex: 5 }}>
        <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>
          {item.lead_data.name}
        </Text>
        <Text
          style={{
            fontFamily: "IranSans-regular",
            fontSize: 10,
            color: Colors.text,
            marginTop: 7,
            textAlign: "right",
          }}
        >
          {item.lead_data.phone}
        </Text>
        <Text
          style={{ fontFamily: "IranSans-regular", fontSize: 10, color: Colors.text, marginTop: 7 }}
        >
          {item.reminder_data.description}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            transform: [{ rotate: "-90deg" }],
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          #{item.reminder_data.id}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const RemindersScreen = (props) => {
  const DESKTOP = windowWidth > 800 ? true : false
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)
  const user_data = useSelector((store) => store.user.user_data)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      reminders: [],
      active_section: "me",
      active_time: "today",
      staffs: [],
    },
  })

  const get_reminders = async (me) => {
    const url = Config.aioUrl + "/crm/reminders/get"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        const staffs = []

        response.data.data.all.forEach((reminder) => {
          const staff = {
            id: reminder?.reminder_data?.employee?.id,
            name: reminder?.reminder_data?.employee?.name,
          }

          const isExist = staffs.find((item) => item.id === staff.id)

          if (!isExist && staff.id !== user_data.id) staffs.push(staff)
        })

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: SET_REMINDERS,
          data: response.data.data,
          staffs: staffs,
        })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", async () => {
      get_reminders()
    })

    return focused
  }, [props.navigation])

  const renderOptions = useCallback(() => {
    const sources = formState.values

    const isMe = sources.active_section === "me"

    let values = (isMe ? sources.reminders.me : sources.reminders.all) || []

    const today = moment().startOf("day")
    const yesterday = moment().subtract(1, "days").startOf("day")
    const tomorrow = moment().add(1, "days").startOf("day")

    if (sources.active_time === "yesterday") {
      values = values.filter((item) =>
        moment.unix(+item.reminder_data.time).isSame(yesterday, "day")
      )
    }

    if (sources.active_time === "today") {
      values = values.filter((item) => moment.unix(+item.reminder_data.time).isSame(today, "day"))
    }

    if (sources.active_time === "tomorrow") {
      values = values.filter((item) =>
        moment.unix(+item.reminder_data.time).isSame(tomorrow, "day")
      )
    }

    if (formState.values.active_section !== "me" && formState.values.active_section !== "all") {
      values = values.filter(
        (item) => `user_${item.reminder_data.employee.id}` == formState.values.active_section
      )
    }

    return values.sort((a, b) => b.reminder_data.time - a.reminder_data.time)
  }, [formState.values.reminders, formState.values.active_section, formState.values.active_time])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="یاداوری ها" noBack />
      {formState.values.loading && (
        <ActivityIndicator size="large" color={Colors.primary} style={{ marginTop: 30 }} />
      )}
      {!formState.values.loading && (
        <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "30%" : 0 }}>
          <View style={{ flexDirection: "row-reverse", alignItems: "center", height: 40 }}>
            <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>
              بخش:{" "}
            </Text>

            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor: formState.values.active_section == "me" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({ type: SET_ACTIVE_SECTION, value: "me" })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.active_section == "me" ? "#fff" : Colors.text,
                }}
              >
                من
              </Text>
            </TouchableOpacity>

            {capabilities.includes("crm_get_all_reminders") && (
              <TouchableOpacity
                style={{
                  paddingVertical: 5,
                  width: 60,
                  borderRadius: 10,
                  backgroundColor:
                    formState.values.active_section == "all" ? Colors.blue : "#F2F2F2",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                }}
                onPress={() => {
                  dispatchFormState({ type: SET_ACTIVE_SECTION, value: "all" })
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                    color: formState.values.active_section == "all" ? "#fff" : Colors.text,
                  }}
                >
                  همه
                </Text>
              </TouchableOpacity>
            )}

            {capabilities.includes("crm_get_all_reminders") &&
              formState.values.active_section !== "me" &&
              formState.values.staffs?.map((staff) => (
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    minWidth: 60,
                    borderRadius: 10,
                    paddingHorizontal: 10,
                    backgroundColor:
                      formState.values.active_section == `user_${staff.id}`
                        ? Colors.blue
                        : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({ type: SET_ACTIVE_SECTION, value: `user_${staff.id}` })
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color:
                        formState.values.active_section == `user_${staff.id}`
                          ? "#fff"
                          : Colors.text,
                    }}
                  >
                    {staff.name}
                  </Text>
                </TouchableOpacity>
              ))}
          </View>

          <View
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              height: 40,
              marginBottom: 25,
            }}
          >
            <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>
              زمان:{" "}
            </Text>

            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor: formState.values.active_time == "all" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => dispatchFormState({ type: SET_ACTIVE_TIME, value: "all" })}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.active_time == "all" ? "#fff" : Colors.text,
                }}
              >
                همه
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor:
                  formState.values.active_time == "yesterday" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => dispatchFormState({ type: SET_ACTIVE_TIME, value: "yesterday" })}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.active_time == "yesterday" ? "#fff" : Colors.text,
                }}
              >
                دیروز
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor: formState.values.active_time == "today" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => dispatchFormState({ type: SET_ACTIVE_TIME, value: "today" })}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.active_time == "today" ? "#fff" : Colors.text,
                }}
              >
                امروز
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor:
                  formState.values.active_time == "tomorrow" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => dispatchFormState({ type: SET_ACTIVE_TIME, value: "tomorrow" })}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.active_time == "tomorrow" ? "#fff" : Colors.text,
                }}
              >
                فردا
              </Text>
            </TouchableOpacity>
          </View>

          {formState.values.reminders.length != 0 && (
            <FlatList
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, height: "100%" }}
              data={renderOptions()}
              keyExtractor={(item) => item.reminder_data.id}
              renderItem={(itemData) => (
                <Reminder item={itemData.item} navigation={props.navigation} />
              )}
            />
          )}
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default RemindersScreen
