import { useReducer, useCallback, useEffect } from "react"
import { ActivityIndicator, ScrollView, Text, View } from "react-native"
import { useSelector } from "react-redux"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import Btn from "../../../components/Btn"
import Colors from "../../../constants/Colors"
import File from "../../../components/File"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import ExpoPicker from "../../../components/ExpoPicker"
import Range from "../../../components/Range"
import FancySelector from "../../../components/FancySelector"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_GET_LOADING = "SET_GET_LOADING"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_GET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          get_loading: action.loading,
        },
      }
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,

          traditional_or_modern_percent:
            action.data.logoform_traditional_or_modern_percent,

          friendly_or_formal_percent:
            action.data.logoform_friendly_or_formal_percent,

          expensive_or_affordable_percent:
            action.data.logoform_expensive_or_affordable_percent,

          has_previous_logo: action.data?.logoform_has_previous_logo,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      }

    default:
      return state
  }
}

const BrandInformationTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const role = useSelector((store) => store.user.user_data.role)
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const setActiveIndex = props.route.params.setActiveIndex
  const setSteps = props.route.params.setSteps

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      // ! NEED TO CHANGE
      has_previous_logo: null, // 0 *OR* 1 / null is do`t select
      previous_logo_file: null,
      what_like_in_previous_logo: "",
      favorite_color: "",
      un_favorite_color: "",
      where_use_logo: "",
      logo_supervisor: "",
      traditional_or_modern_percent: 50,
      friendly_or_formal_percent: 50,
      expensive_or_affordable_percent: 50,
      // !

      loading: false,
      get_loading: false,
      form: null,
      is_staff_in_project: null,
      form_send_progress: 0,
      is_form_section_completed: false,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/logoform/update"

    console.clear()
    console.log(formState.values)

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    // ! NEED TO CHANGE
    formdata.append("project_id", project_id)
    formdata.append("has_previous_logo", formState.values.has_previous_logo)
    formdata.append("previous_logo_file", formState.values.previous_logo_file)
    formdata.append(
      "what_like_in_previous_logo",
      formState.values.what_like_in_previous_logo
    )
    formdata.append("favorite_color", formState.values.favorite_color)
    formdata.append("un_favorite_color", formState.values.un_favorite_color)
    formdata.append("where_use_logo", formState.values.where_use_logo)
    formdata.append("logo_supervisor", formState.values.logo_supervisor)
    formdata.append(
      "traditional_or_modern_percent",
      formState.values.traditional_or_modern_percent
    )
    formdata.append(
      "friendly_or_formal_percent",
      formState.values.friendly_or_formal_percent
    )
    formdata.append(
      "expensive_or_affordable_percent",
      formState.values.expensive_or_affordable_percent
    )
    // !

    dispatchFormState({ type: SET_LOADING, loading: true })

    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        return response.data.success
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const checkIsComplete = (response) => {
    let is_form_section_completed = false

    if (
      response.data.data.logoform_favorite_color &&
      response.data.data.logoform_un_favorite_color &&
      response.data.data.logoform_where_use_logo &&
      response.data.data.logoform_logo_supervisor &&
      response.data.data.logoform_traditional_or_modern_percent &&
      response.data.data.logoform_friendly_or_formal_percent &&
      response.data.data.logoform_expensive_or_affordable_percent
    ) {
      is_form_section_completed = true
    }

    return is_form_section_completed
  }

  const get_form = async () => {
    dispatchFormState({ type: SET_GET_LOADING, loading: true })

    const url =
      Config.aioUrl + "/project/forms/logoform/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: checkIsComplete(response),
        })
      })
      .catch(function (error) {})
      .finally(() =>
        dispatchFormState({ type: SET_GET_LOADING, loading: false })
      )
  }

  useEffect(() => {
    get_form()
  }, [])

  const isNotAccessToEditInputs =
    (formState.values.is_staff_in_project &&
      capabilities.includes("project_logoform_update_own_project")) ||
    is_customer ||
    capabilities.includes("project_logoform_update_own_department")
      ? false
      : true

  if (formState.values.get_loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 16,
            color: Colors.text,
            marginTop: 20,
          }}
        >
          در حال بارگذاری اطلاعات...
        </Text>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: 15,
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote
          title="اطلاعات این صفحه تکمیل شده است."
          icon="information-circle-outline"
          backgroundColor={Colors.green}
          textColor="#fff"
        />
      )}
      {console.log(formState.values.form)}

      {formState.values.form && (
        <>
          <FancySelector
            id="has_previous_logo"
            column={2}
            onChange={inputChangeHandler}
            title="آیا لوگو فعلی دارید؟"
            options={[
              { item: "خیر", id: "0", icon: "thumbs-down-outline" },
              { item: "بله", id: "1", icon: "thumbs-up-outline" },
            ]}
            disabled={isNotAccessToEditInputs}
            insert={
              formState.values.form
                ? [`${formState.values.form.logoform_has_previous_logo}`]
                  ? [`${formState.values.form.logoform_has_previous_logo}`]
                  : false
                : false
            }
            single
          />

          {formState.values.has_previous_logo == "1" && (
            <>
              <File
                id="previous_logo_file"
                title="تصویر لوگوی شما"
                extension="jpg"
                content_type="image/jpeg"
                max_size={2048}
                max_width={1080}
                max_height={1080}
                min_width={300}
                min_height={300}
                token={token}
                onChange={inputChangeHandler}
                insert={
                  !formState.values.previous_logo_file && formState.values.form
                    ? formState.values.form.logoform_previous_logo_file
                      ? formState.values.form.logoform_previous_logo_file
                      : formState.values.previous_logo_file
                    : formState.values.previous_logo_file
                }
                value={formState.values.previous_logo_file}
                wrapperStyle={{ marginTop: 10 }}
                downloadUrl={Config.aioUrl + "/project/forms/getLogoFormFile"}
                downloadParams={[
                  {
                    key: "bucket",
                    value:
                      !formState.values.previous_logo_file &&
                      formState.values.form
                        ? formState.values.form.logoform_previous_logo_file
                          ? formState.values.form.logoform_previous_logo_file
                              .bucket
                          : formState.values.previous_logo_file
                        : formState.values.previous_logo_file,
                  },
                  {
                    key: "bucket_key",
                    value:
                      !formState.values.previous_logo_file &&
                      formState.values.form
                        ? formState.values.form.logoform_previous_logo_file
                          ? formState.values.form.logoform_previous_logo_file
                              .bucket_key
                          : formState.values.previous_logo_file
                        : formState.values.previous_logo_file,
                  },
                ]}
                editOptions={{
                  compress: 0.8,
                  resizeHeight: 1080,
                  resizeWidth: 1080,
                }}
                uploadUrl={Config.aioUrl + "/project/forms/logoform/fileupload"}
                uploadParams={[{ key: "project_id", value: project_id }]}
                standalone
                required={formState.values.has_previous_logo == "1"}
              />

              <Input
                disabled={isNotAccessToEditInputs}
                label="نکات مثبت و منفی لوگوی فعلی شما"
                id="what_like_in_previous_logo"
                onChange={inputChangeHandler}
                required={formState.values.has_previous_logo == "1"}
                multiline
                insert={
                  formState.values.form
                    ? formState.values.form.logoform_what_like_in_previous_logo
                    : ""
                }
              />
            </>
          )}
        </>
      )}

      <Input
        disabled={isNotAccessToEditInputs}
        label="رنگی که در برند خود می پسندید؟"
        id="favorite_color"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form
            ? formState.values.form.logoform_favorite_color
            : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="رنگی که در برند خود نمی پسندید؟"
        id="un_favorite_color"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form
            ? formState.values.form.logoform_un_favorite_color
            : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="لوگو بیشتر در کجا استفاده می شود؟"
        id="where_use_logo"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form
            ? formState.values.form.logoform_where_use_logo
            : ""
        }
        placeholder="برای مثال تابلو، مهر، پروفایل اینستاگرام و..."
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="تصمیم نهایی با یک نفر است یا تیم؟"
        id="logo_supervisor"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form
            ? formState.values.form.logoform_logo_supervisor
            : ""
        }
      />

      <InputNote content="موارد زیر را متناسب با ویژگی های برند خود، به چپ و راست بکشید." />

      <Range
        leftText="سنتی"
        rightText="مدرن"
        type="PRO"
        disabled={isNotAccessToEditInputs}
        id="traditional_or_modern_percent"
        onChange={inputChangeHandler}
        leftColor={Colors.blue}
        rightColor={Colors.blue}
        insert={formState.values.traditional_or_modern_percent}
        fixedValue={true}
      />

      <Range
        leftText="دوستانه"
        rightText="رسمی"
        type="PRO"
        disabled={isNotAccessToEditInputs}
        id="friendly_or_formal_percent"
        onChange={inputChangeHandler}
        leftColor={Colors.blue}
        rightColor={Colors.blue}
        insert={formState.values.friendly_or_formal_percent}
        fixedValue={true}
      />

      <Range
        leftText="گران"
        rightText="ارزان"
        type="PRO"
        disabled={isNotAccessToEditInputs}
        id="expensive_or_affordable_percent"
        onChange={inputChangeHandler}
        leftColor={Colors.blue}
        rightColor={Colors.blue}
        insert={formState.values.expensive_or_affordable_percent}
        fixedValue={true}
      />

      {!isNotAccessToEditInputs && (
        <Btn
          title={
            formState.values.form_send_progress > 0 &&
            formState.values.form_send_progress < 1
              ? "در حال ارسال اطلاعات...(" +
                (formState.values.form_send_progress * 100).toFixed(0) +
                "%)"
              : "ثبت اطلاعات فرم"
          }
          onPress={async () => {
            let result = await send_form()

            if (result) {
              await get_form()

              setSteps("سبک طراحی")
            }
          }}
          wrapperStyle={{ marginVertical: 50 }}
        />
      )}
    </ScrollView>
  )
}

export default BrandInformationTab
