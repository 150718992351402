import { useEffect, useReducer, useCallback } from "react"
import { View, Text, StyleSheet, FlatList, Dimensions } from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { useSelector } from "react-redux"
import Btn from "../../components/Btn"
import axios from "axios"
import Config from "../../constants/Config"
import { logOut } from "../../components/Functions"
import moment from "moment-jalaali"
import Datepicker from "../../components/Datepicker"
import Picker from "../../components/Picker"
import Input from "../../components/Input"
import { ActivityIndicator } from "react-native-web"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const SET_LOADING = "SET_LOADING"
const INPUT_CHANGE = "INPUT_CHANGE"
const SET_STAFFS = "SET_STAFFS"
const SET_DAYOFF = "SET_DAYOFF"
const SET_DAYOFF_COUNT = "SET_DAYOFF_COUNT"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.name]: action.value,
          },
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: action.data,
        },
      }
    case SET_DAYOFF:
      return {
        ...state,
        values: {
          ...state.values,
          dayoff: action.data,
        },
      }
    case SET_DAYOFF_COUNT:
      return {
        ...state,
        values: {
          ...state.values,
          dayoffCount: action.data,
        },
      }
    default:
      return state
  }
}

const GrantDayoffScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        add_dayoff: false,
        get_dayoff: false,
      },
      staffs: [],
      selectedStaff: null,
      time_start_date: null,
      time_start_hour: null,
      time_start_minute: null,
      time_end_date: null,
      time_end_hour: null,
      time_end_minute: null,
      description: null,
      dayoff: [],
    },
  })

  const capabilities = useSelector((store) => store.user.capabilities)

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const add_dayoff = async () => {
    if (
      !formState.values.selectedStaff ||
      !formState.values.time_start_date ||
      !formState.values.time_start_hour ||
      !formState.values.time_start_minute ||
      !formState.values.time_end_date ||
      !formState.values.time_end_hour ||
      !formState.values.time_end_minute
    ) {
      alert("همه فیلدها الزامیست")
      return
    }
    const url = Config.aioUrl + "/eam/dayoff/add"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    const time_start =
      parseInt(formState.values.time_start_date) -
      86399 +
      parseInt(formState.values.time_start_hour.id) * 60 * 60 +
      parseInt(formState.values.time_start_minute.id) * 60
    const time_end =
      parseInt(formState.values.time_end_date) -
      86399 +
      parseInt(formState.values.time_end_hour.id) * 60 * 60 +
      parseInt(formState.values.time_end_minute.id) * 60
    formdata.append("user_id", formState.values.selectedStaff.id)
    formdata.append("time_start", time_start)
    formdata.append("time_end", time_end)
    formdata.append("description", formState.values.description)
    dispatchFormState({ type: SET_LOADING, name: "add_dayoff", value: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_LOADING,
          name: "add_dayoff",
          value: false,
        })
        alert(response.data.msg)
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({
          type: SET_LOADING,
          name: "add_dayoff",
          value: false,
        })
      })
  }

  const get_staffs = async () => {
    const url = Config.aioUrl + "/staffs/get"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_STAFFS, data: response.data.data })
      })
      .catch(function (error) {})
  }

  const get_dayoff = async () => {
    const url = formState.values.selectedStaff
      ? Config.aioUrl +
        "/eam/dayoff/get?id=" +
        formState.values.selectedStaff.id
      : false
    if (!url) return
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, name: "get_dayoff", value: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_LOADING,
          name: "get_dayoff",
          value: false,
        })
        dispatchFormState({ type: SET_DAYOFF, data: response.data.data })

        dispatchFormState({
          type: SET_DAYOFF_COUNT,
          data: {
            dayoff: response.data.daysOff,
            workDuration: response.data.workDuration,
          },
        })
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          name: "get_dayoff",
          value: false,
        })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_staffs()
    })

    return focused
  }, [props.navigation])

  useEffect(() => {
    if (capabilities.includes("eam_leave_get_all")) {
      get_dayoff()
    }
  }, [formState.values.selectedStaff])

  const minutes = [
    { item: "00", id: "00" },
    { item: "01", id: "01" },
    { item: "02", id: "02" },
    { item: "03", id: "03" },
    { item: "04", id: "04" },
    { item: "05", id: "05" },
    { item: "06", id: "06" },
    { item: "07", id: "07" },
    { item: "08", id: "08" },
    { item: "09", id: "09" },
    { item: "10", id: "10" },
    { item: "11", id: "11" },
    { item: "12", id: "12" },
    { item: "13", id: "13" },
    { item: "14", id: "14" },
    { item: "15", id: "15" },
    { item: "16", id: "16" },
    { item: "17", id: "17" },
    { item: "18", id: "18" },
    { item: "19", id: "19" },
    { item: "20", id: "20" },
    { item: "21", id: "21" },
    { item: "22", id: "22" },
    { item: "23", id: "23" },
    { item: "24", id: "24" },
    { item: "25", id: "25" },
    { item: "26", id: "26" },
    { item: "27", id: "27" },
    { item: "28", id: "28" },
    { item: "29", id: "29" },
    { item: "30", id: "30" },
    { item: "31", id: "31" },
    { item: "32", id: "32" },
    { item: "33", id: "33" },
    { item: "34", id: "34" },
    { item: "35", id: "35" },
    { item: "36", id: "36" },
    { item: "37", id: "37" },
    { item: "38", id: "38" },
    { item: "39", id: "39" },
    { item: "40", id: "40" },
    { item: "41", id: "41" },
    { item: "42", id: "42" },
    { item: "43", id: "43" },
    { item: "44", id: "44" },
    { item: "45", id: "45" },
    { item: "46", id: "46" },
    { item: "47", id: "47" },
    { item: "48", id: "48" },
    { item: "49", id: "49" },
    { item: "50", id: "50" },
    { item: "51", id: "51" },
    { item: "52", id: "52" },
    { item: "53", id: "53" },
    { item: "54", id: "54" },
    { item: "55", id: "55" },
    { item: "56", id: "56" },
    { item: "57", id: "57" },
    { item: "58", id: "58" },
    { item: "59", id: "59" },
  ]

  const hours = [
    { item: "00", id: "00" },
    { item: "01", id: "01" },
    { item: "02", id: "02" },
    { item: "03", id: "03" },
    { item: "04", id: "04" },
    { item: "05", id: "05" },
    { item: "06", id: "06" },
    { item: "07", id: "07" },
    { item: "08", id: "08" },
    { item: "09", id: "09" },
    { item: "10", id: "10" },
    { item: "11", id: "11" },
    { item: "12", id: "12" },
    { item: "13", id: "13" },
    { item: "14", id: "14" },
    { item: "15", id: "15" },
    { item: "16", id: "16" },
    { item: "17", id: "17" },
    { item: "18", id: "18" },
    { item: "19", id: "19" },
    { item: "20", id: "20" },
    { item: "21", id: "21" },
    { item: "22", id: "22" },
    { item: "23", id: "23" },
    { item: "24", id: "24" },
  ]
  return (
    <View style={styles.wrapper}>
      <Header title="ثبت و تایید مرخصی" noBack />
      <View
        style={{
          flexDirection: DESKTOP ? "row-reverse" : "column",
          columnGap: DESKTOP ? 10 : 0,
        }}
      >
        <View style={{ flex: 1, zIndex: 1 }}>
          {formState.values.staffs.length != 0 && (
            <Picker
              label="پرسنل"
              id="selectedStaff"
              onChange={inputChangeHandler}
              options={formState.values.staffs}
            />
          )}

          {capabilities.includes("eam_leave_set") && (
            <>
              <View
                style={{
                  flexDirection: "row-reverse",
                  columnGap: 3,
                  zIndex: 2,
                }}
              >
                <View style={{ flex: 1.2, zIndex: 2 }}>
                  <Datepicker
                    label="تاریخ شروع"
                    id="time_start_date"
                    onChange={inputChangeHandler}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Picker
                    label="دقیقه"
                    id="time_start_minute"
                    onChange={inputChangeHandler}
                    options={minutes}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Picker
                    label="ساعت"
                    id="time_start_hour"
                    onChange={inputChangeHandler}
                    options={hours}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  columnGap: 3,
                  zIndex: 1,
                }}
              >
                <View style={{ flex: 1.2 }}>
                  <Datepicker
                    label="تاریخ پایان"
                    id="time_end_date"
                    onChange={inputChangeHandler}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Picker
                    label="دقیقه"
                    id="time_end_minute"
                    onChange={inputChangeHandler}
                    options={minutes}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Picker
                    label="ساعت"
                    id="time_end_hour"
                    onChange={inputChangeHandler}
                    options={hours}
                  />
                </View>
              </View>
              <Input
                label="توضیحات"
                id="description"
                onChange={inputChangeHandler}
              />

              <Btn
                title="ثبت مرخصی"
                onPress={() => {
                  add_dayoff()
                }}
                color={Colors.secondary}
                loading={formState.values.loading.add_dayoff}
              />
            </>
          )}
        </View>

        {capabilities.includes("eam_leave_get_all") && (
          <View style={{ flex: 1, zIndex: 0 }}>
            {formState.values.loading?.get_dayoff ? (
              <ActivityIndicator size="large" color={Colors.primary} />
            ) : (
              <>
                {formState.values.dayoffCount && (
                  <View
                    style={{
                      backgroundColor: Colors.cardBg,
                      width: "100%",
                      marginBottom: 20,
                      height: 50,
                      borderRadius: 15,
                      padding: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: Colors.text,
                        direction: "rtl",
                      }}
                    >
                      {formState.values.dayoffCount?.dayoff} دقیقه مرخصی باقی
                      مانده (
                      {formState.values.dayoffCount?.dayoff /
                        60 /
                        formState.values.dayoffCount?.workDuration}{" "}
                      روز کاری)
                    </Text>
                  </View>
                )}

                <FlatList
                  data={formState.values.dayoff}
                  showsVerticalScrollIndicator={false}
                  style={{ flex: 1, marginBottom: 30, maxHeight: "66vh" }}
                  keyExtractor={(item) => item.id}
                  renderItem={(itemData) => (
                    <View
                      style={{
                        width: "100%",
                        backgroundColor: "#F2F2F2",
                        borderRadius: 15,
                        paddingVertical: 20,
                        paddingHorizontal: 20,
                        marginBottom: 10,
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: Colors.text,
                          direction: "rtl",
                        }}
                      >
                        {"#" +
                          itemData.item.id +
                          ": " +
                          "از " +
                          moment
                            .unix(itemData.item.time_start)
                            .format("jYYYY/jM/jD HH:mm") +
                          " تا " +
                          moment
                            .unix(itemData.item.time_end)
                            .format("jYYYY/jM/jD HH:mm") +
                          " بدلیل " +
                          itemData.item.description}
                      </Text>
                    </View>
                  )}
                />
              </>
            )}
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default GrantDayoffScreen
