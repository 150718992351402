import { useReducer, useCallback, useEffect } from "react";
import { ScrollView } from "react-native";
import { useSelector } from "react-redux";
import Input from "../../../components/Input";
import InputNote from "../../../components/InputNote";
import Colors from "../../../constants/Colors";
import Btn from "../../../components/Btn";
import Config from "../../../constants/Config";
import axios from "axios";
import { logOut } from "../../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FORM = "SET_FORM";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      };
    default:
      return state;
  }
};

const AddressTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities);
  const project_id = props.route.params.project_id;
  const role = useSelector((store) => store.user.user_data.role);
  const is_customer = props.route.params.is_customer;
  const token = useSelector((store) => store.user.token);
  const setActiveIndex = props.route.params.setActiveIndex;
  const setSteps = props.route.params.setSteps;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      state: "",
      city: "",
      address: "",
      postal_code: "",
      phone: "",
      form: null,
      is_form_section_completed: false,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/update";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("project_id", project_id);
    formdata.append("state", formState.values.state);
    formdata.append("city", formState.values.city);
    formdata.append("address", formState.values.address);
    formdata.append("postal_code", formState.values.postal_code);
    formdata.append("phone", formState.values.phone);

    dispatchFormState({ type: SET_LOADING, loading: true });
    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        return response.data.success;
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const get_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/get?project_id=" + project_id;

    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        let is_form_section_completed = false;
        if (
          response.data.data.siteform_state &&
          response.data.data.siteform_city &&
          response.data.data.siteform_address &&
          response.data.data.siteform_postal_code &&
          response.data.data.siteform_phone
        ) {
          is_form_section_completed = true;
        }
        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: is_form_section_completed,
        });
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    get_form();
  }, []);

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote title="اطلاعات مکانی تکمیل شده است." icon="information-circle-outline" backgroundColor={Colors.green} textColor="#fff" />
      )}
      {is_customer && <InputNote title="توضیح" content="این بخش جهت دریافت اینماد و درگاه بانکی الزامیست." icon="information-circle-outline" />}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="استان"
          id="state"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_state : ""}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="شهر"
          id="city"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_city : ""}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="ادامه آدرس"
          id="address"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_address : ""}
        />
      )}
      {is_customer && (
        <InputNote content="مثال: خیابان اصلی - خیابان فرعی - کوچه محل کار شما - پلاک 44 - طبقه 3 - واحد 6" icon="information-circle-outline" />
      )}
      {is_customer && (
        <InputNote
          title="نکته"
          content="در نظر داشته باشید می بایست  کد پستی با آدرس محل سکونت و تلفن ثابت شما مطابقت داشته باشد. سیستم بانکی و اینماد کدپستی و آدرس را تطبیق می دهد و در صورت رد شدن پیگیری مجدد مستلزم پرداخت هزینه است بنابراین نهایت دقت را داشته باشید."
          icon="warning-outline"
          backgroundColor={Colors.primary}
          textColor={"#fff"}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="کدپستی"
          id="postal_code"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_postal_code : ""}
          convertDigits
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="تلفن ثابت"
          id="phone"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_phone : ""}
          convertDigits
        />
      )}
      <Btn
        title="ثبت اطلاعات مکانی"
        onPress={async () => {
          if (
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            capabilities.includes("role_site_full_access") ||
            role == "customer"
          ) {
            let result = await send_form();
            if (result) {
              await get_form();
              setSteps((prev) => {
                let prevState = prev;
                for (let i = 0; i < prevState.length; i++) {
                  if (prevState[i].name == "اطلاعات بانکی") {
                    prevState[i].status = "active";
                  } else {
                    prevState[i].status = "inactive";
                  }
                }
                return prevState;
              });
              props.navigation.navigate("اطلاعات بانکی");
              setActiveIndex(2);
            }
          } else {
            props.navigation.navigate("اطلاعات بانکی");
            setActiveIndex(2);
          }
        }}
        wrapperStyle={{ marginVertical: 50 }}
        loading={formState.values.loading}
      />
    </ScrollView>
  );
};

export default AddressTab;
