import { View, Text, TouchableOpacity, StyleSheet, Image, Platform, ScrollView } from "react-native"
import Colors from "../constants/Colors"
import moment from "moment-jalaali"
import { Link } from "@react-navigation/native"
import { Ionicons } from "@expo/vector-icons"
import { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import Config from "../constants/Config"
import { useSelector } from "react-redux"

const AnalyseUserCard = (props) => {
  const { item, reload } = props

  const [color, setColor] = useState("#F2F2F2")

  const token = useSelector((store) => store.user.token)

  const [dropDown, setDropDown] = useState(false)
  const [projectMonths, setProjectMonths] = useState([])

  const dropDownToggle = () => setDropDown((prev) => !prev)

  const submit = (value) => {
    console.log(value)

    axios
      .post(
        Config.aioUrl + "/project/set-kpi",
        { id: item.id, kpi: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        alert(res.data.msg)
        reload(true)
      })

    dropDownToggle()
  }

  const colorHandler = () => {
    const stage = +item.stage_id
    const endDate = item.end_date

    // Add one for the current day
    const daysPassed = moment().diff(moment.unix(endDate), "days") + 1
    // Check is timestamp bigger than current timestamp
    const isFuture = moment.now() / 1000 < endDate

    console.log(daysPassed)

    // Project amount paid
    if (stage === 9 || stage === 10) return setColor("#4CAF50")

    // Canceled project
    if (stage === 29) return setColor("#673AB7")

    // Not paid amount and less than 7 days left
    if (stage !== 9 && stage !== 10 && daysPassed <= 7 && !isFuture) return setColor("#FF5722")

    // Not paid amount and more than 7 days left
    if (stage !== 9 && stage !== 10 && daysPassed > 7) return setColor("#D32F2F")

    // Another stage
    setColor("#F2F2F2")
  }

  useEffect(() => {
    colorHandler()
  }, [item])

  useEffect(() => {
    const threeMonthLaterFromNow = moment(moment.now()).add(3, "months").unix()

    const defrentFrom1401Esfand = moment
      .unix(threeMonthLaterFromNow)
      .diff(moment("1401-12-01", "jYYYY-jMM-jDD"), "months")

    const months = []

    for (let index = 0; index <= defrentFrom1401Esfand; index++) {
      const monthOBJ = {
        name: moment.unix(threeMonthLaterFromNow).subtract(index, "month").format("jMMMM jYYYY"),
        unix: moment.unix(threeMonthLaterFromNow).subtract(index, "month").unix(),
      }

      const isExist = months.find((item) => item.name == monthOBJ.name)

      if (!isExist) {
        months.push(monthOBJ)
      }
    }

    setProjectMonths(months)
  }, [])

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: color,
        borderRadius: 15,
        marginVertical: 5,
        paddingVertical: 7,
        direction: "ltr",
        display: "flex",

        zIndex: (props.item.isChange ? 500 : 1000) - props.item.index,
      }}
    >
      <View style={{ width: "100%", flexDirection: "row-reverse" }}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            style={{
              transform: [{ rotate: "-90deg" }],
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color: color === "#F2F2F2" ? "#000" : "#fff",
            }}
          >
            #{item.id}
          </Text>
        </View>

        <View style={{ flex: 6, justifyContent: "center", alignItems: "flex-end" }}>
          <Text
            style={{
              color: color === "#F2F2F2" ? "#000" : "#fff",
              fontFamily: "Iransans-Medium",
              fontSize: 16,
              marginVertical: 5,
            }}
          >
            {item.customer_name}
          </Text>
          <Text
            style={{
              color: color === "#F2F2F2" ? "#000" : "#fff",
              fontFamily: "Iransans-Light",
              fontSize: 14,
              marginVertical: 5,
            }}
          >
            {item.package}
          </Text>
          {props.isSiteDepartment && (
            <Text
              style={{
                color: color === "#F2F2F2" ? "#000" : "#fff",
                fontFamily: "Iransans-Light",
                fontSize: 14,
                marginVertical: 5,
              }}
            >
              {item?.site || "سایت مشخص نیست"}
            </Text>
          )}
        </View>

        <View
          style={{
            flex: 2,
            paddingLeft: 15,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <View style={Styles(color).LeftSectionsContainer}>
            <Text style={Styles(color).date}>{item.price.toLocaleString()} تومان</Text>
          </View>

          <View style={Styles(color).LeftSectionsContainer}>
            <Text style={Styles(color).date}>
              تاریخ تحویل:{" "}
              {item.end_date != null
                ? moment.unix(item.end_date).format("jYYYY/jM/jD")
                : "بدون تایم"}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: Colors.text,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                width: 100,
                padding: 5,
                position: "relative",
              }}
              onPress={dropDownToggle}
            >
              <Text style={{ ...Styles(color).date, ...{ color: "white" } }}>
                {item.kpi_date ? "حساب شده" : "حساب نشده"}
              </Text>

              {dropDown && (
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  style={{
                    width: 100,
                    height: 200,
                    backgroundColor: Colors.text,
                    position: "absolute",
                    top: 15,
                    borderRadius: 5,
                  }}
                  contentContainerStyle={{
                    paddingHorizontal: 5,
                    paddingVertical: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {projectMonths.map((item) => (
                    <TouchableOpacity
                      onPress={() => submit(item.unix)}
                      style={{ paddingVertical: 15 }}
                    >
                      <Text style={{ ...Styles(color).date, ...{ color: "white" } }}>
                        {item.name}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              )}
            </TouchableOpacity>

            {item.danger ? (
              <Ionicons name="warning" size={22} color="white" />
            ) : (
              <View
                style={{
                  backgroundColor: Colors.text,
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 30,
                  padding: 5,
                }}
              >
                <Text style={{ ...Styles(color).date, ...{ color: "white" } }}>
                  {item.duration}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

const Styles = (color) =>
  StyleSheet.create({
    dateTitle: {
      color: color === "#F2F2F2" ? "#000" : "#fff",
      fontFamily: "IranSans-Light",
      fontSize: 7,
    },
    LeftSectionsContainer: {
      width: "100%",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      marginBottom: 5,
    },
    LeftSectionsContainer_staffs: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginBottom: 5,
    },
    date: {
      color: color === "#F2F2F2" ? "#000" : "#fff",
      fontFamily: "IranSans-Light",
      fontSize: 10,
    },
  })

export default AnalyseUserCard
