import { useReducer, useCallback, useEffect } from "react"
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native"
import Colors from "../../../constants/Colors"
import Btn from "../../../components/Btn"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import moment from "moment-jalaali"
import * as Linking from "expo-linking"
import * as Clipboard from "expo-clipboard"
import FancySelector from "../../../components/FancySelector"
import Input from "../../../components/Input"
import Picker from "../../../components/Picker"
import { Ionicons } from "@expo/vector-icons"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_LEAD = "SET_LEAD"
const TOGGLE_EDIT = "TOGGLE_EDIT"
const TOGGLE_RESULT = "TOGGLE_RESULT"
const TOGGLE_SHOW_FLAGS = "TOGGLE_SHOW_FLAGS"
const SET_ACTIVE_FLAG = "SET_ACTIVE_FLAG"
const TOGGLE_SHOW_EDIT_RESPONSIBLE = "TOGGLE_SHOW_EDIT_RESPONSIBLE"
const SET_STAFFS = "SET_STAFFS"
const SET_NEW_RESPONSIBLE = "SET_NEW_RESPONSIBLE"

const EditResponsibleBox = ({
  get_staffs,
  formState,
  inputChangeHandler,
  dispatchFormState,
  token,
  lead,
}) => {
  useEffect(() => {
    get_staffs()
  }, [])

  const edit_responsible = async () => {
    if (!formState.values.selected_responsible) {
      alert("شخصی را به عنوان مسئول انتخاب کنید!")
      return
    }

    const url = Config.aioUrl + "/crm/lead/set-responsible"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("responsible_id", formState.values.selected_responsible.id)
    formdata.append("lead_id", lead.id)

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401 || response.data.code == 403) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })

        alert(response.data.msg)

        if (!response.data.success) return false

        dispatchFormState({ type: TOGGLE_SHOW_EDIT_RESPONSIBLE })

        dispatchFormState({
          type: SET_NEW_RESPONSIBLE,
          value: response.data.responsible,
        })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  console.log(formState.values.staffs)

  return (
    <View
      style={{
        backgroundColor: Colors.bg,
        flexWrap: "wrap",
        padding: 20,
        borderRadius: 10,
        marginTop: 20,
      }}
    >
      <View>
        <Picker
          id="selected_responsible"
          onChange={inputChangeHandler}
          label="پرسنل جدید"
          options={formState.values.staffs}
        />

        <Btn
          title="ثبت تغییر مسئول"
          color={Colors.blue}
          onPress={() => {
            edit_responsible()
          }}
          wrapperStyle={{ marginBottom: 5, marginTop: 10 }}
        />
      </View>
    </View>
  )
}

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.name]: action.value,
          },
        },
      }
    case SET_LEAD:
      let touches = [{ title: "کل", success: 0, total: 0 }]
      for (let j = 0; j < action.data.touches.length; j++) {
        let already_exists = false
        let touch_index = null
        for (let k = 0; k < touches.length; k++) {
          if (touches[k].title == action.data.touches[j].title) {
            touch_index = k
            already_exists = true
          }
        }
        if (action.data.touches[j].success == 1) {
          touches[0].success++
          touches[0].total++
        } else {
          touches[0].total++
        }
        if (already_exists) {
          touches[touch_index].total++
          if (action.data.touches[j].success == 1) touches[touch_index].success++
        } else {
          touches.push({ ...action.data.touches[j], total: 1 })
        }
      }

      let can_select_touches = action.touches
      for (let i = 0; i < can_select_touches.length; i++) {
        can_select_touches[i].item = can_select_touches[i]["title"]
        delete can_select_touches[i].title
      }

      return {
        ...state,
        values: {
          ...state.values,
          lead: action.data,
          touches: touches,
          can_select_touches: can_select_touches,
        },
      }
    case TOGGLE_EDIT:
      let newEdit = state.values.edit ? false : true
      return {
        ...state,
        values: {
          ...state.values,
          edit: newEdit,
        },
      }
    case TOGGLE_RESULT:
      return {
        ...state,
        values: {
          ...state.values,
          is_result_show: state.values.is_result_show ? false : true,
        },
      }
    case TOGGLE_SHOW_EDIT_RESPONSIBLE:
      return {
        ...state,
        values: {
          ...state.values,
          show_edit_responsible: state.values.show_edit_responsible ? false : true,
        },
      }
    case TOGGLE_SHOW_FLAGS:
      let showStatusToggle = state.values.showFlags ? false : true

      return {
        ...state,
        values: {
          ...state.values,
          showFlags: showStatusToggle,
        },
      }
    case SET_ACTIVE_FLAG:
      return {
        ...state,
        values: {
          ...state.values,
          active_flag: action.value,
        },
      }
    case SET_NEW_RESPONSIBLE:
      return {
        ...state,
        values: {
          ...state.values,
          responsible_user: action.value,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: action.value,
        },
      }
    default:
      return state
  }
}

const FlagSelectOption = ({ item, index, set_flag, dispatchFormState }) => (
  <TouchableOpacity
    style={{
      flexDirection: "row-reverse",
      marginVertical: 10,
      borderTopColor: "#F2F2F2",
      borderTopWidth: index == 0 ? 0 : 1,
      height: 30,
      paddingTop: 20,
      paddingRight: 20,
    }}
    onPress={async () => {
      dispatchFormState({ type: TOGGLE_SHOW_FLAGS })
      const set_result = await set_flag(item.id)

      if (set_result.success) {
        dispatchFormState({
          type: SET_ACTIVE_FLAG,
          value: item,
        })
      }
    }}
  >
    <View
      style={{
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: item.color,
      }}
    ></View>
    <Text
      style={{
        fontFamily: "IranSans-Regular",
        marginRight: 10,
        fontSize: 12,
        color: Colors.text,
      }}
    >
      {item.title}
    </Text>
  </TouchableOpacity>
)

const InfoTab = (props) => {
  const lead = props.route.params.lead
  const flags = props.route.params.flags
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {},
      edit: false,
      is_result_show: false,
      result_final: null,
      result_description: null,
      // Flags
      active_flag: lead.flag || {
        color: "",
        title: "",
        id: "",
      },
      //
      showFlags: false,
      flags: flags || [],
      show_edit_responsible: false,
      staffs: [],
      selected_responsible: null,
      responsible_user: lead.responsible,
    },
    validities: {},
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value)
  }

  const set_result = async () => {
    if (!formState.values.result_final || !formState.values.result_description) {
      alert("اطلاعات ناقص است")
      return
    }

    const url = Config.aioUrl + "/crm/lead/result/set"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("lead_id", lead.id)
    formdata.append("result", formState.values.result_final[0])

    if (formState.values.result_description)
      formdata.append("description", formState.values.result_description)
    dispatchFormState({ type: SET_LOADING, name: "set_result", value: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_LOADING,
          name: "set_result",
          value: false,
        })
        alert(response.data.msg)
        get_lead()
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          name: "set_result",
          value: false,
        })
      })
  }

  const set_flag = async (id) => {
    const url = Config.aioUrl + "/crm/lead/set-flag"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("lead_id", lead.id)
    formdata.append("flag_id", id)

    let res_value = null

    await axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    }).then(function (response) {
      if (response.data.code == 401) {
        alert("نیاز است مجددا به سیستم ورود کنید.")
        logOut()
      }

      res_value = response.data

      alert(response.data.msg)
    })

    return res_value
  }

  const get_staffs = async () => {
    const url = Config.aioUrl + "/staffs/get?department=sales"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
          return false
        }

        if (response.data.code == 403) {
          alert("شما به این بخش دسترسی ندارید!")
          dispatchFormState({ type: TOGGLE_SHOW_EDIT_RESPONSIBLE })
          return false
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_STAFFS, value: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const pastLeadEntry = false

  return (
    <View style={{ height: "100%", overflow: "hidden", backgroundColor: Colors.bg }}>
      <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50, paddingTop: 0 }}>
        {lead && (
          <View style={{ zIndex: 2 }}>
            <View
              style={{
                width: "100%",
                marginTop: 15,
                flexDirection: "row-reverse",
                zIndex: 2,
              }}
            >
              <View style={{ flex: 1, marginLeft: 5 }}>
                <Btn
                  title="واتسپ"
                  color={Colors.green}
                  onPress={() => {
                    Linking.openURL("whatsapp://send?phone=+" + lead.phone)
                  }}
                />
              </View>

              {/* Flags select start */}
              <View style={{ flex: 1, marginRight: 5 }}>
                <Btn
                  title={formState.values.active_flag.title}
                  color={formState.values.active_flag.color}
                  onPress={() => {
                    dispatchFormState({ type: TOGGLE_SHOW_FLAGS })
                  }}
                  // loading={formState.values.loading}
                />
                {formState.values.showFlags && (
                  <View
                    style={{
                      position: "absolute",
                      top: 55,
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: 15,
                      shadowColor: "rgba(0,0,0,0.3)",
                      shadowOffset: { width: 0, height: 0 },
                      shadowRadius: 3,
                      marginLeft: 2,
                      paddingBottom: 15,
                    }}
                  >
                    {formState.values.flags
                      .filter((item) => item.id !== formState.values.active_flag.id)
                      .map((item, index) => (
                        <FlagSelectOption
                          key={item.id}
                          item={item}
                          index={index}
                          dispatchFormState={dispatchFormState}
                          set_flag={set_flag}
                        />
                      ))}
                  </View>
                )}
              </View>
              {/* Flags select end */}
            </View>
            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  مشخصات
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {lead.name}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    copyToClipboard(lead.phone)
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {lead.phone}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  درخواست ها
                </Text>
              </View>

              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 14,
                  color: Colors.text,
                  marginVertical: 5,
                }}
              >
                {lead.services
                  .replace("_", " ")
                  .replace("-", " ")
                  .replace("course", "دوره")
                  .replace("logomoon", "لوگومون")
                  .replace("logo", "لوگو")
                  .replace("site", "سایت")
                  .replace("app", "اپلیکیشن")
                  .replace("other", "موارد دیگر")}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row-reverse",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  backgroundColor: "#F2F2F2",
                  flex: 1,
                  paddingHorizontal: 15,
                  paddingVertical: 15,
                  borderRadius: 15,
                  marginTop: 15,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                    textAlign: "center",
                  }}
                >
                  ورود
                </Text>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                    textAlign: "center",
                  }}
                >
                  {moment.unix(lead.created_time).fromNow()}
                </Text>
              </View>

              {lead.touch_modified_time && (
                <View
                  style={{
                    backgroundColor: "#F2F2F2",
                    flex: 1,
                    paddingHorizontal: 15,
                    paddingVertical: 15,
                    borderRadius: 15,
                    marginTop: 15,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 16,
                      color: Colors.text,
                      paddingBottom: 15,
                      textAlign: "center",
                    }}
                  >
                    آخرین تاچ
                  </Text>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                      textAlign: "center",
                    }}
                  >
                    {moment.unix(lead.touch_modified_time).fromNow()}
                  </Text>
                </View>
              )}
            </View>

            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  ریشه
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    منبع
                  </Text>
                </View>

                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                  }}
                >
                  {lead.source}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    مدیوم
                  </Text>
                </View>

                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                  }}
                >
                  {lead.medium}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    ارسال کننده
                  </Text>
                </View>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                  }}
                >
                  {lead.sender}
                </Text>
              </View>
            </View>

            {/* Past Lead */}

            {pastLeadEntry && (
              <View
                style={{
                  backgroundColor: "#BDBDBD80",
                  width: "100%",
                  paddingHorizontal: 15,
                  paddingVertical: 15,
                  borderRadius: 15,
                  marginTop: 15,
                  gap: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 16,
                      color: Colors.text,
                      paddingBottom: 10,
                    }}
                  >
                    ورودی گذشته
                  </Text>
                </View>

                <View style={{ marginBottom: 20 }}>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {pastLeadEntry.resultDescription}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                        marginBottom: 5,
                      }}
                    >
                      تاریخ ورود قبلی
                    </Text>
                  </View>

                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {pastLeadEntry.created_time}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                      }}
                    >
                      تاریخ ثبت وضعیت
                    </Text>
                  </View>

                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {pastLeadEntry.setResultTime}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                      }}
                    >
                      منبع
                    </Text>
                  </View>

                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {pastLeadEntry.source}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                      }}
                    >
                      مدیوم
                    </Text>
                  </View>

                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {pastLeadEntry.medium}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                      }}
                    >
                      ارسال کننده
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {pastLeadEntry.sender}
                  </Text>
                </View>
              </View>
            )}

            {/* Responsible */}
            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  مسئول
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Image
                      source={{ uri: formState.values.responsible_user.avatar }}
                      style={{ width: 36, height: 36, borderRadius: 18 }}
                    />
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                        marginVertical: 5,
                        marginRight: 5,
                      }}
                    >
                      {formState.values.responsible_user.name}
                    </Text>
                  </View>
                </View>

                {capabilities.includes("crm_edit_leads_responsible") && (
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: 30,
                      height: 30,
                      backgroundColor: formState.values.show_edit_responsible
                        ? Colors.primary
                        : Colors.blue,
                      borderRadius: 3,
                      marginHorizontal: 3,
                    }}
                    onPress={() => {
                      dispatchFormState({ type: TOGGLE_SHOW_EDIT_RESPONSIBLE })
                    }}
                  >
                    <Ionicons
                      name={formState.values.show_edit_responsible ? "close" : "pencil"}
                      color="#fff"
                      size={16}
                    />
                  </TouchableOpacity>
                )}
              </View>

              {formState.values.show_edit_responsible &&
                capabilities.includes("crm_edit_leads_responsible") && (
                  <EditResponsibleBox
                    get_staffs={get_staffs}
                    formState={formState}
                    inputChangeHandler={inputChangeHandler}
                    dispatchFormState={dispatchFormState}
                    token={token}
                    lead={lead}
                  />
                )}
            </View>

            <Btn
              title={formState.values.is_result_show ? "انصراف از ثبت" : "ثبت نتیجه نهایی"}
              color={Colors.secondary}
              onPress={() => {
                dispatchFormState({ type: TOGGLE_RESULT })
              }}
              wrapperStyle={{ marginVertical: 15 }}
            />

            {formState.values.is_result_show && (
              <View>
                <FancySelector
                  column={2}
                  id="result_final"
                  title="تعیین تکلیف نهایی"
                  options={[
                    { item: "تبدیل شد", id: "1", icon: "thumbs-up-outline" },
                    { item: "سوخته", id: "0", icon: "thumbs-down-outline" },
                  ]}
                  onChange={inputChangeHandler}
                  single
                />
                <Input label="توضیح" id="result_description" onChange={inputChangeHandler} />
                <Btn
                  title="ثبت نتیجه"
                  onPress={() => {
                    set_result()
                  }}
                  loading={formState.values.loading.set_result}
                />
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
}

export default InfoTab
