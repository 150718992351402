import { useReducer, useCallback, useEffect } from "react"
import { View, Text, ScrollView, ActivityIndicator, TouchableOpacity, Image } from "react-native"
import Colors from "../../../constants/Colors"
import Btn from "../../../components/Btn"
import { useSelector } from "react-redux"
import Picker from "../../../components/Picker"
import Datepicker from "../../../components/Datepicker"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import moment from "moment-jalaali"
import * as Linking from "expo-linking"
import * as Clipboard from "expo-clipboard"
import WebsiteLauncher from "../../../components/WebsiteLauncher"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_PROJECT = "SET_PROJECT"
const TOGGLE_EDIT = "TOGGLE_EDIT"
const TOGGLE_SHOW_STAGES = "TOGGLE_SHOW_STAGES"
const SET_STAGE_LOADING = "SET_STAGE_LOADING"
const SET_ACTIVE_STAGE = "SET_ACTIVE_STAGE"
const SET_WEBSITEBUILDER_MODAL_OPEN = "SET_WEBSITEBUILDER_MODAL_OPEN"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      }

    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
          alertmsg: action.msg ? action.msg : "",
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_STAGE_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          stageLoading: action.loading,
        },
      }
    case SET_WEBSITEBUILDER_MODAL_OPEN:
      return {
        ...state,
        values: {
          ...state.values,
          isWebsiteBuilderModalOpen: action.value,
        },
      }
    case SET_PROJECT:
      let active_stage = {}
      for (let i = 0; i < action.data.can_be_selected_stages.length; i++) {
        if (action.data.can_be_selected_stages[i].id == action.data.stage_id) {
          active_stage = {
            color: action.data.can_be_selected_stages[i].color,
            title: action.data.can_be_selected_stages[i].title,
            id: action.data.can_be_selected_stages[i].id,
          }
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          project: action.data,
          staffs: action.data.staffs,
          active_stage: active_stage,
        },
      }
    case SET_ACTIVE_STAGE:
      let newActiveStage = {}
      for (let i = 0; i < state.values.project.can_be_selected_stages.length; i++) {
        if (state.values.project.can_be_selected_stages[i].id == action.stage_id) {
          newActiveStage = {
            color: state.values.project.can_be_selected_stages[i].color,
            title: state.values.project.can_be_selected_stages[i].title,
            id: state.values.project.can_be_selected_stages[i].id,
          }
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          active_stage: newActiveStage,
        },
      }
    case TOGGLE_EDIT:
      let newEdit = state.values.edit ? false : true
      return {
        ...state,
        values: {
          ...state.values,
          edit: newEdit,
        },
      }
    case TOGGLE_SHOW_STAGES:
      let newshowStages = state.values.showStages ? false : true
      return {
        ...state,
        values: {
          ...state.values,
          showStages: newshowStages,
        },
      }
    default:
      return state
  }
}

const InfoTab = (props) => {
  const project_id = props.route.params.id
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      domain_name: "",
      contract_date: "",
      start_date: "",
      end_date: "",
      staffs: [],
      showAlert: false,
      project: false,
      loading: false,
      alertmsg: "",
      edit: false,
      active_stage: {
        color: "",
        title: "",
        id: "",
      },
      isWebsiteBuilderModalOpen: false,
      showStages: false,
      stageLoading: false,
    },
    validities: {},
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const get_project = async (disableLoading) => {
    const url = Config.aioUrl + "/project/get?id=" + project_id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    if (!disableLoading) {
      dispatchFormState({ type: SET_LOADING, loading: true })
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_PROJECT, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const edit_project = async () => {
    const url = Config.aioUrl + "/project/edit"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let reqData = JSON.stringify({
      id: project_id,
      staffs: formState.values.staffs,
      contract_date:
        formState.values.contract_date == ""
          ? formState.values.project.contract_date
          : formState.values.contract_date,
      start_date:
        formState.values.start_date == ""
          ? formState.values.project.start_date
          : formState.values.start_date,
      end_date:
        formState.values.end_date == ""
          ? formState.values.project.end_date
          : formState.values.end_date,
    })
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: TOGGLE_EDIT })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const set_stage = async (stage_id) => {
    const url = Config.aioUrl + "/project/stage/set"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("id", project_id)
    formdata.append("stage_id", stage_id)
    dispatchFormState({ type: SET_STAGE_LOADING, loading: true })
    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_STAGE_LOADING, loading: false })
        alert(response.data.msg)
        return response.data
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_STAGE_LOADING, loading: false })
      })
  }

  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value)
  }

  useEffect(() => {
    get_project()
  }, [])

  const is_primary_zero = () => {
    for (let i = 0; i < formState.values.project.project_items.length; i++) {
      if (
        formState.values.project.project_items[i].service_type == "primary" &&
        parseInt(formState.values.project.project_items[i].service_price) == 0
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const startConfirmDomain = () => {
    const domain = formState.values.domain_name
      .trim()
      ?.replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replace("/", "")

    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/

    if (!domain) {
      alert("نام دامنه را وارد کنید")
      return
    } else if (!domainRegex.test(domain)) {
      alert("نام دامنه به اشتباه وارد شده است، لطفا دامنه درست را وارد کنید")
      return
    }

    const isBuyedBefore = confirm(`آیا دامنه (${domain}) رو از قبل خریداری کرده اید؟`)

    if (!isBuyedBefore) {
      const isConfirmed = confirm(`آیا از خرید و ثبت دامنه (${domain}) اطمینان دارید؟`)
      if (!isConfirmed) return
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }

    const data = {
      domain: `https://${domain}`,
      register_domain: !isBuyedBefore,
    }

    axios
      .post(Config.aioUrl + "/asc/site/process", data, config)
      .then((response) => {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        } else if (response.data.code == 403) {
          alert("دسترسی شما به این قسمت مجاز نمیباشد، لطفا به مدیریت اطلاع دهید")
        }

        alert(response.data.msg)

        get_project()
      })
      .catch(function (error) {
        alert(error?.response?.data?.message)
      })
      .finally(() => dispatchFormState({ type: SET_LOADING, loading: false }))
  }

  return (
    <View
      style={{
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        paddingHorizontal: 10,
      }}
    >
      {!formState.values.project && (
        <View
          style={{
            width: "100%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 16,
              color: Colors.text,
              marginTop: 20,
            }}
          >
            در حال بارگذاری اطلاعات...
          </Text>
        </View>
      )}

      <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50, paddingTop: 0 }}>
        {formState.values.project && !formState.values.edit && (
          <View style={{ zIndex: 2 }}>
            <View
              style={{
                width: "100%",
                marginTop: 15,
                flexDirection: "row-reverse",
                zIndex: 2,
              }}
            >
              <View style={{ flex: 1, marginLeft: 5 }}>
                <Btn
                  title="واتسپ"
                  color={Colors.green}
                  onPress={() => {
                    Linking.openURL(
                      "whatsapp://send?phone=+" + formState.values.project.customer_mobile
                    )
                  }}
                />
              </View>
              <View style={{ flex: 1, marginRight: 5 }}>
                <Btn
                  title={formState.values.active_stage.title}
                  color={formState.values.active_stage.color}
                  onPress={() => {
                    dispatchFormState({ type: TOGGLE_SHOW_STAGES })
                  }}
                  loading={formState.values.stageLoading}
                />
                {formState.values.showStages && (
                  <View
                    style={{
                      position: "absolute",
                      top: 55,
                      backgroundColor: "#fff",
                      width: "100%",
                      borderRadius: 15,
                      shadowColor: "rgba(0,0,0,0.3)",
                      shadowOffset: { width: 0, height: 0 },
                      shadowRadius: 3,
                      marginLeft: 2,
                      paddingBottom: 15,
                    }}
                  >
                    {formState.values.project.can_be_selected_stages
                      .filter((item) => item.id !== formState.values.active_stage.id)
                      .map((item, index) => (
                        <TouchableOpacity
                          key={item.id}
                          style={{
                            flexDirection: "row-reverse",
                            marginVertical: 10,
                            borderTopColor: "#F2F2F2",
                            borderTopWidth: index == 0 ? 0 : 1,
                            height: 30,
                            paddingTop: 20,
                            paddingRight: 20,
                          }}
                          onPress={async () => {
                            dispatchFormState({ type: TOGGLE_SHOW_STAGES })
                            const stage_result = await set_stage(item.id)
                            if (stage_result.success) {
                              dispatchFormState({
                                type: SET_ACTIVE_STAGE,
                                stage_id: item.id,
                              })
                            }
                          }}
                        >
                          <View
                            style={{
                              width: 10,
                              height: 10,
                              borderRadius: 5,
                              backgroundColor: item.color,
                            }}
                          ></View>
                          <Text
                            style={{
                              fontFamily: "IranSans-Regular",
                              marginRight: 10,
                              fontSize: 12,
                              color: Colors.text,
                            }}
                          >
                            {item.title}
                          </Text>
                        </TouchableOpacity>
                      ))}
                  </View>
                )}
              </View>
            </View>

            {formState.values.project.start_status && (
              <View
                style={{
                  backgroundColor: "#F2F2F2",
                  width: "100%",
                  paddingHorizontal: 15,
                  paddingVertical: 20,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <View
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                    // position: "absolute",
                    // top: 7,
                    // right: 7,
                    flexDirection: "row-reverse",
                    gap: 10,
                  }}
                >
                  {formState.values.project.start_status === "pending" ? (
                    <ActivityIndicator size="small" color={Colors.text} />
                  ) : formState.values.project.start_status === "success" ? (
                    <View
                      style={{
                        width: 21,
                        height: 21,
                        borderRadius: 5000,
                        backgroundColor: Colors.green,
                      }}
                    ></View>
                  ) : (
                    <View
                      style={{
                        width: 21,
                        height: 21,
                        borderRadius: 5000,
                        backgroundColor: Colors.primary,
                      }}
                    ></View>
                  )}

                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 13,
                      color: Colors.text,
                    }}
                  >
                    {formState.values.project.start_status === "pending"
                      ? "درحال راه اندازی سایت"
                      : formState.values.project.start_status === "success"
                      ? "راه اندازی سایت انجام شد"
                      : "راه اندازی با خطا مواجه شد"}

                    {formState.values.project.start_status !== "success" && (
                      <Text
                        style={{
                          fontFamily: "IranSans-Medium",
                          fontSize: 13,
                          color: Colors.text,
                          marginRight: 5,
                        }}
                      >
                        (
                        <TouchableOpacity
                          onPress={() => {
                            get_project()
                          }}
                        >
                          <Text style={{ color: Colors.blue, paddingHorizontal: 2 }}>
                            بررسی مجدد
                          </Text>
                        </TouchableOpacity>
                        )
                      </Text>
                    )}
                  </Text>
                </View>
              </View>
            )}

            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  مشتری
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {formState.values.project.customer_first_name +
                      " " +
                      formState.values.project.customer_last_name}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    copyToClipboard(formState.values.project.customer_mobile)
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                    }}
                  >
                    {formState.values.project.customer_mobile}
                  </Text>
                </TouchableOpacity>
              </View>
              {formState.values.project.website != undefined &&
                formState.values.project.website != null && (
                  <View
                    style={{
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 14,
                          color: Colors.text,
                        }}
                      >
                        آدرس سایت
                      </Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        copyToClipboard(formState.values.project.website)
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 14,
                          color: Colors.text,
                        }}
                      >
                        {formState.values.project.website}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
            </View>

            {/* {capabilities.includes("asc_reseller_buy_access") &&
              formState.values.project.stage_id == 4 && (
                <>
                  <InputNote
                    title="مسئولیت دامنه سایت"
                    content="در وارد کردن نام دامنه دقت کنید، خرید دامنه اشتباه به مسئولیت طراح پروژه (شخص خرید کننده) میباشد و چنانچه دامنه به اشتباه خریداری شود وی ملزم به پرداخت مبلغ میباشد."
                    icon="information-circle-outline"
                    backgroundColor={Colors.primary}
                    textColor="#fff"
                    wrapperStyle={{
                      marginTop: 15,
                    }}
                  />

                  <View
                    style={{
                      backgroundColor: "#F2F2F2",
                      width: "100%",
                      paddingHorizontal: 15,
                      paddingVertical: 15,
                      borderRadius: 15,
                      marginTop: 10,
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontFamily: "IranSans-Medium",
                          fontSize: 16,
                          color: Colors.text,
                          paddingBottom: 15,
                        }}
                      >
                        دامنه سایت
                      </Text>
                    </View>

                    <View
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 10,
                        flexDirection: "row",
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Btn
                          title="شروع عملیات"
                          color={Colors.primary}
                          onPress={startConfirmDomain}
                        />
                      </View>

                      <View style={{ flex: 2 }}>
                        <Input
                          label="نام دامنه"
                          id="domain_name"
                          onChange={inputChangeHandler}
                          editable
                          labelWrapperStyle={{ backgroundColor: "#F2F2F2" }}
                          textAlign="center"
                        />
                      </View>
                    </View>
                  </View>
                </>
              )} */}

            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  آیتم ها
                </Text>
              </View>
              {formState.values.project.project_items.map((item, index) => (
                <View key={index} style={{ flexDirection: "row-reverse", alignItems: "center" }}>
                  <View
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 5,
                      backgroundColor:
                        item.service_type == "extra" && item.service_price != item.paid_amount
                          ? Colors.primary
                          : Colors.green,
                      marginLeft: 5,
                    }}
                  ></View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: Colors.text,
                      marginVertical: 5,
                    }}
                  >
                    {item.service_title}
                  </Text>
                </View>
              ))}
            </View>
            {/* {formState.values.project.department == "site" && ( */}
            {(formState.values.project.department == "site" ||
              formState.values.project.department == "graphic") && (
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ marginLeft: 5, flex: 1 }}>
                  <Btn
                    title="سلیقه یابی"
                    color={Colors.secondary}
                    onPress={() => {
                      if (formState.values.project.department == "site") {
                        props.navigation.navigate("FormSite", {
                          project_id: project_id,
                          is_customer: false,
                        })
                      }
                      if (formState.values.project.department == "graphic") {
                        props.navigation.navigate("FormLogo", {
                          project_id: project_id,
                          is_customer: false,
                        })
                      }
                    }}
                    wrapperStyle={{ marginTop: 15 }}
                  />
                </View>

                {formState.values.project.department == "site" &&
                  capabilities.includes("asc_reseller_buy_access") &&
                  formState.values.project.stage_id == 4 && (
                    <>
                      <View style={{ marginRight: 5, marginLeft: 5, flex: 1 }}>
                        <Btn
                          title="راه اندازی سایت"
                          color={Colors.primary}
                          onPress={() => {
                            dispatchFormState({ type: SET_WEBSITEBUILDER_MODAL_OPEN, value: true })
                          }}
                          wrapperStyle={{ marginTop: 15 }}
                        />
                      </View>

                      <WebsiteLauncher
                        projectId={project_id}
                        isOpen={formState.values.isWebsiteBuilderModalOpen}
                        setIsOpen={(value) => {
                          get_project(true)

                          dispatchFormState({ type: SET_WEBSITEBUILDER_MODAL_OPEN, value })
                        }}
                      />
                    </>
                  )}

                {formState.values.project.department == "site" && (
                  <>
                    {formState.values.project.stage_id >= 4 &&
                      formState.values.project.stage_id < 10 &&
                      !is_primary_zero() && (
                        <View style={{ marginRight: 5, flex: 1 }}>
                          <Btn
                            title="اطلاعات سایت"
                            color={Colors.secondary}
                            onPress={() => {
                              props.navigation.navigate("SiteInfo", {
                                project_id: project_id,
                              })
                            }}
                            wrapperStyle={{ marginTop: 15 }}
                          />
                        </View>
                      )}
                    {formState.values.project.stage_id == 10 &&
                      capabilities.includes("role_site_full_access") &&
                      !is_primary_zero() && (
                        <View style={{ marginRight: 5, flex: 1 }}>
                          <Btn
                            title="اطلاعات سایت"
                            color={Colors.secondary}
                            onPress={() => {
                              props.navigation.navigate("SiteInfo", {
                                project_id: project_id,
                              })
                            }}
                            wrapperStyle={{ marginTop: 15 }}
                          />
                        </View>
                      )}
                  </>
                )}
              </View>
            )}

            <View
              style={{
                flexDirection: "row-reverse",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  backgroundColor: "#F2F2F2",
                  flex: 1,
                  paddingHorizontal: 15,
                  paddingVertical: 15,
                  borderRadius: 15,
                  marginTop: 15,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                    textAlign: "center",
                  }}
                >
                  قرارداد
                </Text>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                    textAlign: "center",
                  }}
                >
                  {moment.unix(formState.values.project.contract_date).format("jYYYY/jM/jD")}
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: "#F2F2F2",
                  flex: 1,
                  paddingHorizontal: 15,
                  paddingVertical: 15,
                  borderRadius: 15,
                  marginTop: 15,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                    textAlign: "center",
                  }}
                >
                  شروع
                </Text>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                    textAlign: "center",
                  }}
                >
                  {moment.unix(formState.values.project.start_date).format("jYYYY/jM/jD")}
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: "#F2F2F2",
                  flex: 1,
                  paddingHorizontal: 15,
                  paddingVertical: 15,
                  borderRadius: 15,
                  marginTop: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                    textAlign: "center",
                  }}
                >
                  پایان
                </Text>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: Colors.text,
                    textAlign: "center",
                  }}
                >
                  {moment.unix(formState.values.project.end_date).format("jYYYY/jM/jD")}
                </Text>
              </View>
            </View>

            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    paddingBottom: 15,
                  }}
                >
                  پرسنل
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {formState.values.project.staffs.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      marginLeft: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Image
                      source={{ uri: item.avatar }}
                      style={{ width: 36, height: 36, borderRadius: 18 }}
                    />
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 14,
                        color: Colors.text,
                        marginVertical: 5,
                        marginRight: 5,
                      }}
                    >
                      {item.name}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        )}

        {formState.values.edit && (
          <View style={{ zIndex: 2 }}>
            <View
              style={{
                width: "100%",
                zIndex: 2,
                flexDirection: "row-reverse",
                marginTop: 15,
              }}
            >
              <Datepicker
                label="قرارداد"
                blank="انتخاب"
                id="contract_date"
                onChange={inputChangeHandler}
                column={3}
                wrapperStyle={{ marginLeft: "2%" }}
                preSelected={moment
                  .unix(formState.values.project.contract_date)
                  .format("jYYYY/jM/jD")}
              />
              <Datepicker
                label="شروع"
                blank="انتخاب"
                id="start_date"
                onChange={inputChangeHandler}
                column={3}
                wrapperStyle={{ marginHorizontal: "2%" }}
                preSelected={moment.unix(formState.values.project.start_date).format("jYYYY/jM/jD")}
              />
              <Datepicker
                label="پایان"
                blank="انتخاب"
                id="end_date"
                onChange={inputChangeHandler}
                column={3}
                wrapperStyle={{ marginRight: "2%" }}
                preSelected={moment.unix(formState.values.project.end_date).format("jYYYY/jM/jD")}
              />
            </View>

            <Picker
              id="staffs"
              onChange={inputChangeHandler}
              label="پرسنل"
              options={formState.values.project.can_be_selected_staffs}
              isMulti
              insert={formState.values.project.staffs}
            />
          </View>
        )}

        {formState.values.project.stage_id != 10 &&
          formState.values.project.stage_id != 18 &&
          capabilities.includes("project_edit_all") && (
            <Btn
              title={formState.values.edit ? "ثبت تغییرات" : "ویرایش"}
              color={formState.values.edit ? Colors.primary : Colors.blue}
              onPress={() => {
                if (formState.values.edit) {
                  edit_project()
                } else {
                  dispatchFormState({ type: TOGGLE_EDIT })
                }
              }}
              loading={formState.values.loading}
              wrapperStyle={{ marginTop: 30, zIndex: 1 }}
            />
          )}
        {formState.values.edit && (
          <Btn
            title={"انصراف از ویرایش"}
            color={Colors.secondary}
            onPress={() => {
              dispatchFormState({ type: TOGGLE_EDIT })
            }}
            wrapperStyle={{ marginTop: 30 }}
          />
        )}
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
}

export default InfoTab
