import { useEffect, useReducer, useState, useCallback } from "react"
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { useSelector } from "react-redux"
import Btn from "../../components/Btn"
import axios from "axios"
import Config from "../../constants/Config"
import { logOut } from "../../components/Functions"
import moment from "moment-jalaali"
import Datepicker from "../../components/Datepicker"
import Picker from "../../components/Picker"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import { CommonActions } from "@react-navigation/native"
import { hours, minutes } from "../../constants/Clock"

const SET_LOADING = "SET_LOADING"
const SET_ENTRANCE = "SET_ENTRANCE"
const SET_STAFFS = "SET_STAFFS"
const SET_RECORDS = "SET_RECORDS"
const INPUT_CHANGE = "INPUT_CHANGE"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.name]: action.value,
          },
        },
      }
    case SET_ENTRANCE:
      return {
        ...state,
        values: {
          ...state.values,
          entrance: state.values.entrance == 1 ? 0 : 1,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: action.data,
        },
      }
    case SET_RECORDS:
      return {
        ...state,
        values: {
          ...state.values,
          records: action.data,
        },
      }
    default:
      return state
  }
}

const RecordDay = (props) => {
  const { itemData } = props
  const [day_open, set_day_open] = useState(false)
  return (
    <View>
      <TouchableOpacity
        style={{
          width: "100%",
          backgroundColor:
            itemData.item.type == "penalty"
              ? Colors.primary
              : itemData.item.type == "late"
              ? Colors.error
              : itemData.item.type == "early"
              ? Colors.green
              : itemData.item.type == "off"
              ? Colors.blue
              : itemData.item.type == "normal" && itemData.item.data.length != 0
              ? Colors.green
              : "#F2F2F2",
          borderRadius: 15,
          paddingVertical: 20,
          paddingHorizontal: 20,
          marginBottom: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onPress={() => set_day_open((prev) => !prev)}
      >
        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color:
                itemData.item.type != "holiday" && itemData.item.type != "normal"
                  ? "#fff"
                  : itemData.item.day_title == "آدینه"
                  ? Colors.primary
                  : itemData.item.type == "holiday"
                  ? Colors.primary
                  : Colors.text,
              textAlign: "right",
            }}
          >
            {itemData.item.date}
            {itemData.item.type == "holiday" &&
              itemData.item.data.map((item_data, index) =>
                item_data.title ? "   " + item_data.title : ""
              )}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 10,
              color:
                itemData.item.type != "holiday"
                  ? "#fff"
                  : itemData.item.day_title == "آدینه"
                  ? Colors.primary
                  : itemData.item.type == "holiday"
                  ? Colors.primary
                  : Colors.text,
            }}
          >
            {itemData.item.day_title == "آدینه" ? "جمعه" : itemData.item.day_title}
          </Text>
        </View>
      </TouchableOpacity>
      {itemData.item.data.length != 0 && itemData.item.type !== "holiday" && day_open && (
        <TouchableOpacity
          style={{
            width: "100%",
            borderColor: "#F2F2F2",
            borderWidth: 1,
            borderRadius: 15,
            paddingVertical: 20,
            paddingHorizontal: 20,
            marginBottom: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => set_day_open((prev) => !prev)}
        >
          {itemData.item.data.map((item_data, index) => (
            <View
              key={item_data.id}
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginVertical: 5,
              }}
            >
              {item_data.type == "off" && (
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingHorizontal: 20,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.blue,
                    }}
                  >
                    مرخصی
                  </Text>
                </View>
              )}
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  {moment.unix(item_data.time).format("HH:mm")}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  {item_data.entrance == "1"
                    ? item_data.type == "off"
                      ? "از"
                      : "ورود"
                    : item_data.type == "off"
                    ? "تا"
                    : "خروج"}
                </Text>
              </View>
            </View>
          ))}
        </TouchableOpacity>
      )}
    </View>
  )
}

const RecordsScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        get_records: false,
        add_record: false,
      },
      entrance: 1,
      records: [],
      staffs: [],
      selectedStaff: null,
      time_date: null,
      time_hour: null,
      time_minute: null,
      entrance: null,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const get_staffs = async () => {
    const url = Config.aioUrl + "/staffs/get?eam_type=aminhco"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_STAFFS, data: response.data.data })
      })
      .catch(function (error) {})
  }

  const process_records = async (records) => {
    let processed_records = []
    const numberOfDays = [1, 2, 3, 4, 5, 6].includes(moment().jMonth() + 1)
      ? 31
      : moment().jMonth() + 1 == 12
      ? 29
      : 30
    for (let i = 1; i <= numberOfDays; i++) {
      if (i > moment().jDate()) break
      const day_beginning = moment()
        .startOf("jmonth")
        .add(i - 1, "day")
        .unix()
      const day_end = moment().startOf("jmonth").add(i, "day").unix() - 1
      let day_data = []
      let day_type = "normal"
      for (let j = 0; j < records.length; j++) {
        if (parseInt(records[j].time) >= day_beginning && parseInt(records[j].time) <= day_end) {
          if (day_type != "penalty" && day_type != "late" && records[j].type == "early")
            day_type = "early"
          if (day_type != "penalty" && records[j].type == "late") day_type = "late"
          if (records[j].type == "penalty") day_type = "penalty"
          if (records[j].type == "off") day_type = "off"
          if (records[j].type == "holiday") day_type = "holiday"
          day_data.push(records[j])
        }
      }
      processed_records.push({
        day: i,
        day_title: moment.unix(day_beginning).format("dddd"),
        date: moment().jYear() + "" + (moment().jMonth() + 1) + "" + i,
        type: day_type,
        data: day_data,
      })
    }

    return processed_records.sort((a, b) => b.day - a.day)
  }

  // const process_records = async (records) => {
  //   let processed_records = []
  //   const numberOfDays = [1, 2, 3, 4, 5, 6].includes(moment().jMonth() + 1)
  //     ? 31
  //     : moment().jMonth() + 1 == 12
  //     ? 29
  //     : 30
  //   for (let i = 1; i <= numberOfDays; i++) {
  //     if (i > moment().jDate()) break
  //     const day_beginning = moment()
  //       .startOf("jmonth")
  //       .add(i - 1, "day")
  //       .unix()
  //     const day_end = moment().startOf("jmonth").add(i, "day").unix() - 1
  //     let day_data = []
  //     let day_type = "normal"
  //     for (let j = 0; j < records.length; j++) {
  //       if (parseInt(records[j].time) >= day_beginning && parseInt(records[j].time) <= day_end) {
  //         if (
  //           day_type != "penalty" &&
  //           day_type != "late" &&
  //           (records[j].type == "early" || records[j].type == "normal")
  //         )
  //           day_type = "early"
  //         if (day_type != "penalty" && records[j].type == "late") day_type = "late"
  //         if (records[j].type == "penalty") day_type = "penalty"
  //         day_data.push(records[j])
  //       }
  //     }
  //     processed_records.push({
  //       day: i,
  //       day_title: moment.unix(day_beginning).format("dddd"),
  //       date: moment().jYear() + "/" + (moment().jMonth() + 1) + "/" + i,
  //       type: day_type,
  //       data: day_data,
  //     })
  //   }

  //   return processed_records.sort((a, b) => b.day - a.day)
  // }

  const get_records = async () => {
    const url = formState.values.selectedStaff
      ? Config.aioUrl + "/eam/records/get?staff_id=" + formState.values.selectedStaff.id
      : false
    if (!url) return
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, name: "get_records", value: true })
    let response_records = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.code == 200) {
          return response
        } else {
          return false
        }
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "get_records", value: false })
      })

    if (!response_records) {
      alert("در دریافت رکوردها خطایی رخ داد")
      return
    }

    let processed_records = await process_records(response_records.data.data)
    dispatchFormState({ type: SET_LOADING, name: "get_records", value: false })
    dispatchFormState({ type: SET_RECORDS, data: processed_records })
  }

  const add_record = async () => {
    if (
      !formState.values.entrance ||
      !formState.values.time_date ||
      !formState.values.time_hour ||
      !formState.values.time_minute
    ) {
      alert("ابتدا تایم را مشخص کنید.")
      return
    }
    const url = Config.aioUrl + "/eam/records/add"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("staff_id", formState.values.selectedStaff.id)
    const time =
      parseInt(formState.values.time_date) -
      86399 +
      parseInt(formState.values.time_hour.id) * 60 * 60 +
      parseInt(formState.values.time_minute.id) * 60
    formdata.append("time", time)
    formdata.append("entrance", formState.values.entrance.id)

    dispatchFormState({ type: SET_LOADING, name: "add_record", value: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        alert(response.data.msg)
        dispatchFormState({ type: SET_LOADING, name: "add_record", value: false })
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: "Records",
              },
            ],
          })
        )
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "add_record", value: false })
      })
  }
  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_staffs()
    })

    return focused
  }, [props.navigation])

  useEffect(() => {
    get_records()
  }, [formState.values.selectedStaff])

  return (
    <View style={styles.wrapper}>
      <Header title="کنترل تردد" noBack />
      <View
        style={{
          flex: 1,
          height: "100%",
          flexDirection: DESKTOP ? "row-reverse" : "column",
          columnGap: DESKTOP ? 10 : 0,
        }}
      >
        {formState.values.staffs.length !== 0 && (
          <View style={{ flex: 1, zIndex: 2 }}>
            <Picker
              label="پرسنل"
              id="selectedStaff"
              onChange={inputChangeHandler}
              options={formState.values.staffs}
            />
            <Picker
              label="نوع"
              id="entrance"
              onChange={inputChangeHandler}
              options={[
                { item: "ورود", id: 1 },
                { item: "خروج", id: 0 },
              ]}
            />
            <View style={{ flexDirection: "row-reverse", columnGap: 3, zIndex: 2 }}>
              <View style={{ flex: 1.2, zIndex: 2 }}>
                <Datepicker
                  timePicker
                  label="تاریخ شروع"
                  id="time_date"
                  onChange={inputChangeHandler}
                />
              </View>
              <View style={{ flex: 1 }}>
                <Picker
                  label="دقیقه"
                  id="time_minute"
                  onChange={inputChangeHandler}
                  options={minutes}
                />
              </View>
              <View style={{ flex: 1 }}>
                <Picker label="ساعت" id="time_hour" onChange={inputChangeHandler} options={hours} />
              </View>
            </View>
            <Btn
              title="ثبت تردد"
              onPress={() => {
                add_record()
              }}
              color={Colors.secondary}
              loading={formState.values.loading.add_record}
            />
          </View>
        )}
        {formState.values.staffs.length == 0 && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
        <View style={{ flex: 1, height: "100%", justifyContent: "center", alignItems: "center" }}>
          {!formState.values.loading.get_records && (
            <FlatList
              style={{ width: "100%", flex: 1, height: "100%" }}
              data={formState.values.records}
              keyExtractor={(item) => item.day}
              showsVerticalScrollIndicator={false}
              renderItem={(itemData) => <RecordDay itemData={itemData} />}
            />
          )}
          {formState.values.loading.get_records && (
            <ActivityIndicator size="large" color={Colors.primary} />
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default RecordsScreen
