import { useReducer, useEffect } from "react"
import { View, Text, TouchableOpacity, Image } from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"
import * as DocumentPicker from "expo-document-picker"
import axios from "axios"
import { edit_image } from "../components/Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_DOWNLOAD_PROGRESS = "SET_DOWNLOAD_PROGRESS"
const SET_SELECTED = "SET_SELECTED"
const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS"
const SET_UPLOAD_SUCCESS = "SET_UPLOAD_SUCCESS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        value: action.value,
      }
    case SET_DOWNLOAD_PROGRESS:
      return {
        ...state,
        download_progress: action.value,
      }
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        upload_progress: action.value,
      }
    case SET_SELECTED:
      return {
        ...state,
        selected: action.value,
      }
    case SET_UPLOAD_SUCCESS:
      return {
        ...state,
        upload_success: action.value,
      }

    default:
      return state
  }
}

const File = (props) => {
  const { id } = props

  const onChange = !props.standalone ? props.onChange : false

  const [formState, dispatchFormState] = useReducer(formReducer, {
    required: props.required ? props.required : false,
    title: props.title ? props.title : false,
    extension: props.extension ? props.extension : false,
    max_size: props.max_size ? props.max_size : false,
    max_width: props.max_width ? props.max_width : false,
    max_height: props.max_height ? props.max_height : false,
    min_width: props.min_width ? props.min_width : false,
    min_height: props.min_height ? props.min_height : false,
    value: false,
    token: props.token ? props.token : false,
    selected: props.value ? true : false,
    uploadUrl: props.uploadUrl ? props.uploadUrl : false,
    upload_progress: 0,
    downloadUrl: props.downloadUrl ? props.downloadUrl : false,
    download_progress: 0,
    upload_success: false,
    noDownload: props.noDownload ? props.noDownload : false,
  })

  useEffect(() => {
    if (!props.standalone) {
      onChange(id, formState.value)
    }
  }, [id, formState, onChange])

  const insert = props.insert ? props.insert : false
  const downloadParams = props.downloadParams ? props.downloadParams : false

  useEffect(() => {
    if (insert) {
      dispatchFormState({
        type: SET_SELECTED,
        value: true,
      })
    }
  }, [insert])

  const get_file = async () => {
    let formdata = new FormData()
    if (downloadParams) {
      for (let i = 0; i < downloadParams.length; i++) {
        formdata.append(downloadParams[i].key, downloadParams[i].value)
      }
    }
    const file = await axios(formState.downloadUrl, {
      headers: { Authorization: "Bearer " + formState.token },
      method: "post",
      data: formdata,
      responseType: "blob",
      onDownloadProgress: (progress) => {
        dispatchFormState({
          type: SET_DOWNLOAD_PROGRESS,
          value: progress.loaded / insert.size,
        })
      },
    })
    const blobUrl = window.URL.createObjectURL(new Blob([file.data]))
    const link = document.createElement("a")
    link.href = blobUrl
    link.setAttribute("download", insert.bucket_key)
    document.body.appendChild(link)
    link.click()
  }

  const send_file = async (file) => {
    let final_file = file
    dispatchFormState({
      type: SET_UPLOAD_SUCCESS,
      value: false,
    })
    //Edit before sending if needed
    const final_name_array = file.name.split(".")
    const file_extension = final_name_array[final_name_array.length - 1]
    if (
      props.editOptions &&
      (file_extension == "jpg" ||
        file_extension == "jpeg" ||
        file_extension == "png")
    ) {
      final_file = await edit_image(final_file, {
        compress: props.editOptions.compress
          ? props.editOptions.compress
          : false,
        resizeHeight: props.editOptions.resizeHeight
          ? props.editOptions.resizeHeight
          : false,
        resizeWidth: props.editOptions.resizeWidth
          ? props.editOptions.resizeWidth
          : false,
        format: props.editOptions.format ? props.editOptions.format : false,
      })
    }

    //Check file validation
    const is_file_valid = await file_validator(final_file)
    if (is_file_valid.validation) {
      let formdata = new FormData()
      formdata.append("file", final_file.file, final_file.name)
      formdata.append("id", id)
      if (props.uploadParams) {
        for (let i = 0; i < props.uploadParams.length; i++) {
          formdata.append(
            props.uploadParams[i].key,
            props.uploadParams[i].value
          )
        }
      }

      axios
        .post(formState.uploadUrl, formdata, {
          headers: { Authorization: "Bearer " + formState.token },
          onUploadProgress: (progress) => {
            dispatchFormState({
              type: SET_UPLOAD_PROGRESS,
              value: progress.loaded / progress.total,
            })
          },
        })
        .then(function (response) {
          if (response.data.success) {
            dispatchFormState({
              type: SET_UPLOAD_SUCCESS,
              value: true,
            })
            if (props.doAfterUpload) {
              props.doAfterUpload()
            }
          } else {
            dispatchFormState({
              type: SET_UPLOAD_SUCCESS,
              value: false,
            })
            dispatchFormState({
              type: SET_SELECTED,
              value: false,
            })
            alert(response.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      dispatchFormState({
        type: SET_UPLOAD_SUCCESS,
        value: false,
      })
      dispatchFormState({
        type: SET_SELECTED,
        value: false,
      })
      alert(is_file_valid.error)
    }
  }

  const file_validator = async (file) => {
    const final_name_array = file.name.split(".")
    const file_extension = final_name_array[final_name_array.length - 1]
    const file_size = file.size / 1024 //KB
    let result = { validation: true, error: "" }

    if (file_extension.toLowerCase() != formState.extension.toLowerCase()) {
      result = { validation: false, error: "فرمت فایل اشتباه است." }
      return result
    }

    if (
      formState.max_size != 0 &&
      formState.max_size != null &&
      formState.max_size != false
    ) {
      if (file_size > formState.max_size) {
        result = {
          validation: false,
          error:
            "حجم فایل زیاد است. حداکثر حجم مجاز: " +
            formState.max_size +
            " کیلو بایت",
        }
        return result
      }
    }

    if (
      file_extension == "jpg" ||
      file_extension == "jpeg" ||
      file_extension == "png"
    ) {
      const getImageSize = async (uri) =>
        new Promise((resolve) => {
          Image.getSize(uri, (width, height) => {
            resolve([width, height])
          })
        })

      const [img_width, img_height] = await getImageSize(file.uri)

      let resolutions_error = "ابعاد تصویر درست نیست. "
      if (
        props.max_width != 0 &&
        props.max_width != null &&
        props.max_width != false
      )
        resolutions_error += "حداکثر عرض: " + props.max_width
      if (
        props.min_width != 0 &&
        props.min_width != null &&
        props.min_width != false
      )
        resolutions_error += "حداقل عرض: " + props.min_width
      if (
        props.max_height != 0 &&
        props.max_height != null &&
        props.max_height != false
      )
        resolutions_error += "حداکثر ارتفاع: " + props.max_height
      if (
        props.min_height != 0 &&
        props.min_height != null &&
        props.min_height != false
      )
        resolutions_error += "حداقل ارتفاع: " + props.min_height
      if (
        (props.max_width != 0 &&
          props.max_width != null &&
          props.max_width != false &&
          img_width > props.max_width) ||
        (props.min_width != 0 &&
          props.min_width != null &&
          props.min_width != false &&
          img_width < props.min_width) ||
        (props.max_height != 0 &&
          props.max_height != null &&
          props.max_height != false &&
          img_height > props.max_height) ||
        (props.min_height != 0 &&
          props.min_height != null &&
          props.min_height != false &&
          img_height < props.min_height)
      ) {
        result = { validation: false, error: resolutions_error }
        return result
      }
    }
    return result
  }

  return (
    <View
      style={{
        width: "100%",
        marginBottom: 30,
        ...props.wrapperStyle,
        position: "relative",
      }}
    >
      <View style={{ width: "100%", flexDirection: "row-reverse" }}>
        <View style={{ flex: 3, justifyContent: "center", paddingRight: 10 }}>
          <View
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 14,
                textAlign: "right",
              }}
            >
              {formState.title + (formState.required == 0 ? " - اختیاری" : "")}
            </Text>
            {formState.selected && (
              <View
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 7.5,
                  backgroundColor:
                    (insert && !formState.value) || formState.upload_success
                      ? Colors.green
                      : Colors.error,
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 3,
                }}
              >
                <Ionicons name="checkmark" size={10} color="#fff" />
              </View>
            )}
          </View>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 12,
              textAlign: "right",
            }}
          >
            {formState.extension == "jpg"
              ? "jpg, jpeg, png"
              : formState.extension}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          {((!props.standalone &&
            (!insert ||
              (insert && !formState.selected) ||
              (formState.selected && formState.value))) ||
            (props.standalone &&
              !formState.upload_success &&
              (!insert ||
                (insert && !formState.selected) ||
                (formState.selected && formState.value)))) && (
            <TouchableOpacity
              style={{
                display: "flex",
                width: 45,
                height: 45,
                backgroundColor: formState.selected
                  ? Colors.error
                  : Colors.secondary,
                borderRadius: 7,
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={async () => {
                if (
                  (props.standalone && !formState.selected) ||
                  !props.standalone
                ) {
                  let file = await DocumentPicker.getDocumentAsync({})
                  file = file.assets[0]
                  if (props.standalone) {
                    dispatchFormState({
                      type: SET_SELECTED,
                      value: true,
                    })
                    dispatchFormState({
                      type: INPUT_CHANGE,
                      value: file,
                    })
                    await send_file(file)
                  } else {
                    let final_file = file

                    console.log(file)
                    //Edit before sending if needed
                    const final_name_array = final_file.name.split(".")
                    const file_extension =
                      final_name_array[final_name_array.length - 1]
                    if (
                      props.editOptions &&
                      (file_extension.toLowerCase() == "jpg" ||
                        file_extension.toLowerCase() == "jpeg" ||
                        file_extension.toLowerCase() == "png")
                    ) {
                      final_file = await edit_image(final_file, {
                        compress: props.editOptions.compress
                          ? props.editOptions.compress
                          : false,
                        resizeHeight: props.editOptions.resizeHeight
                          ? props.editOptions.resizeHeight
                          : false,
                        resizeWidth: props.editOptions.resizeWidth
                          ? props.editOptions.resizeWidth
                          : false,
                        format: props.editOptions.format
                          ? props.editOptions.format
                          : false,
                      })
                    }

                    //Check file validation
                    const is_file_valid = await file_validator(final_file)
                    console.log("is_file_valid", is_file_valid)
                    if (is_file_valid) {
                      dispatchFormState({
                        type: SET_SELECTED,
                        value: true,
                      })
                      dispatchFormState({
                        type: INPUT_CHANGE,
                        value: final_file,
                      })
                    } else {
                      alert(is_file_valid.error)
                    }
                  }
                }
              }}
            >
              {formState.upload_progress > 0 &&
                formState.upload_progress < 1 && (
                  <Text
                    style={{
                      color: "#fff",
                      fontFamily: "IranSans-Regular",
                      fontSize: 10,
                    }}
                  >
                    %{(formState.upload_progress * 100).toFixed(0)}
                  </Text>
                )}
              {(formState.upload_progress == 0 ||
                formState.upload_progress == 1) && (
                <Ionicons name="cloud-upload-outline" size={24} color="#fff" />
              )}
            </TouchableOpacity>
          )}
          {((insert && formState.selected && !formState.value) ||
            formState.upload_success) && (
            <TouchableOpacity
              style={{
                display: "flex",
                width: 45,
                height: 45,
                backgroundColor: Colors.green,
                borderRadius: 7,
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={async () => {
                if (
                  formState.download_progress == 0 ||
                  formState.download_progress == 1
                ) {
                  if (!formState.noDownload) get_file()
                }
              }}
            >
              {(formState.download_progress == 0 ||
                formState.download_progress == 1) && (
                <Ionicons
                  name={
                    !formState.noDownload
                      ? "cloud-download-outline"
                      : "checkmark"
                  }
                  size={24}
                  color="#fff"
                />
              )}
              {formState.download_progress > 0 &&
                formState.download_progress < 1 && (
                  <Text
                    style={{
                      color: "#fff",
                      fontFamily: "IranSans-Regular",
                      fontSize: 10,
                    }}
                  >
                    %{(formState.download_progress * 100).toFixed(0)}
                  </Text>
                )}
            </TouchableOpacity>
          )}

          {((insert && formState.selected && !formState.value) ||
            formState.upload_success) && (
            <TouchableOpacity
              style={{
                display: "flex",
                width: 45,
                height: 45,
                backgroundColor: Colors.primary,
                borderRadius: 7,
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 10,
              }}
              onPress={async () => {
                if (confirm("با آپلود مجدد، فایل قبلی پاک خواهد شد")) {
                  dispatchFormState({
                    type: SET_SELECTED,
                    value: false,
                  })
                  dispatchFormState({
                    type: INPUT_CHANGE,
                    value: false,
                  })
                }
              }}
            >
              <Ionicons name="trash-outline" size={24} color="#fff" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  )
}

export default File
