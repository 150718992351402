import { useReducer, useEffect, useCallback } from "react"
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config from "../../constants/Config"
import LeadCard from "../../components/LeadCard"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
import Input from "../../components/Input"
const windowWidth = Dimensions.get("window").width

const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_LEADS = "SET_LEADS"
const SET_MEDIUMS = "SET_MEDIUMS"
const SET_STAFFS = "SET_STAFFS"
const SET_ACTIVE_SOURCE = "SET_ACTIVE_SOURCE"
const SET_ACTIVE_SERVICE = "SET_ACTIVE_SERVICE"
const SET_ACTIVE_MEDIUM = "SET_ACTIVE_MEDIUM"
const SET_ACTIVE_STAFF = "SET_ACTIVE_STAFF"
const SET_FLAGS = "SET_FLAGS"
const SET_ACTIVE_FLAG = "SET_ACTIVE_FLAG"
const INPUT_CHANGE = "INPUT_CHANGE"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_LEADS:
      let leads = action.data
      for (let i = 0; i < leads.length; i++) {
        let touches = [{ title: "کل", success: 0, total: 0 }]
        for (let j = 0; j < leads[i].touches.length; j++) {
          let already_exists = false
          let touch_index = null
          for (let k = 0; k < touches.length; k++) {
            if (touches[k].title == leads[i].touches[j].title) {
              touch_index = k
              already_exists = true
            }
          }
          if (leads[i].touches[j].success == 1) {
            touches[0].success++
            touches[0].total++
          } else {
            touches[0].total++
          }
          if (already_exists) {
            touches[touch_index].total++
            if (leads[i].touches[j].success == 1) touches[touch_index].success++
          } else {
            touches.push({ ...leads[i].touches[j], total: 1 })
          }
        }
        leads[i].touches = touches
      }
      return {
        ...state,
        values: {
          ...state.values,
          leads: leads,
        },
      }
    case SET_ACTIVE_SOURCE:
      return {
        ...state,
        values: {
          ...state.values,
          active_source: action.value,
        },
      }
    case SET_ACTIVE_SERVICE:
      return {
        ...state,
        values: {
          ...state.values,
          active_service: action.value,
        },
      }
    case SET_MEDIUMS:
      console.log("action.value", action.value)

      return {
        ...state,
        values: {
          ...state.values,
          mediums: [{ id: "all", title: "همه" }, ...action.value],
        },
      }
    case SET_ACTIVE_MEDIUM:
      return {
        ...state,
        values: {
          ...state.values,
          active_medium: action.value,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: [{ id: "all", title: "همه" }, ...action.value],
        },
      }
    case SET_ACTIVE_STAFF:
      return {
        ...state,
        values: {
          ...state.values,
          active_staff: action.value,
        },
      }
    case SET_FLAGS:
      return {
        ...state,
        values: {
          ...state.values,
          flags: [{ id: "all", title: "همه", color: "#333" }, ...action.value],
        },
      }
    case SET_ACTIVE_FLAG:
      return {
        ...state,
        values: {
          ...state.values,
          active_flag: action.value,
        },
      }

    default:
      return state
  }
}

const LoadingPlaceHolder = () => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingBottom: 0,
            paddingTop: 25,
            paddingHorizontal: 20,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 5, marginRight: 30 }}>
            <PlaceholderLine height={25} />
          </View>
          <View style={{ flex: 2 }}>
            <PlaceholderLine height={25} />
          </View>
        </View>
      </Placeholder>

      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            overflow: "hidden",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              minWidth: 1510,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: 65,
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
              <View
                style={{
                  width: "60%",
                  height: 20,
                  borderRadius: 500,
                  backgroundColor: "#F9F9F9",
                }}
              ></View>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              minWidth: 1510,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>

            <View
              style={{
                flex: 1,
                marginRight: 30,
                backgroundColor: "#EFEFEF",
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                height: "50vh",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            ></View>
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const InternationalLeadsScreen = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const DESKTOP = windowWidth > 800 ? true : false
  const token = useSelector((store) => store.user.token)
  const user_department = useSelector((store) => store.user.user_data.department)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      showAlert: false,
      leads: [],
      // stages: [
      //   {
      //     id: 1,
      //     title: "پیگیری نشده",
      //     color: "#EF9A9A",
      //     min_touch: 0,
      //     max_touch: 0,
      //   },
      //   {
      //     id: 2,
      //     title: "۱ تا ۳ پیگیری",
      //     color: "#D32F2F",
      //     min_touch: 1,
      //     max_touch: 3,
      //   },
      //   {
      //     id: 3,
      //     title: "۴ تا ۷ پیگیری",
      //     color: "#7986CB",
      //     min_touch: 4,
      //     max_touch: 7,
      //   },
      //   {
      //     id: 3,
      //     title: "۸ تا ۱۵ پیگیری",
      //     color: "#009688",
      //     min_touch: 8,
      //     max_touch: 15,
      //   },
      //   {
      //     id: 3,
      //     title: "۱۶ تا ۲۴ پیگیری",
      //     color: "#FFEB3B",
      //     min_touch: 16,
      //     max_touch: 24,
      //   },
      //   {
      //     id: 3,
      //     title: "۲۵ تا ۹۹ پیگیری",
      //     color: "#03A9F4",
      //     min_touch: 25,
      //     max_touch: 99,
      //   },
      // ],
      stages: [
        {
          id: 1,
          title: "پیگیری نشده",
          color: "#EF9A9A",
          min_touch: 0,
          max_touch: 0,
        },
        {
          id: 2,
          title: "1 پیگیری",
          color: "#D32F2F",
          min_touch: 1,
          max_touch: 1,
        },
        {
          id: 3,
          title: "2 پیگیری",
          color: "#7986CB",
          min_touch: 2,
          max_touch: 2,
        },
        {
          id: 4,
          title: "3 پیگیری",
          color: "#009688",
          min_touch: 3,
          max_touch: 3,
        },
        {
          id: 5,
          title: "4 پیگیری",
          color: "#FFEB3B",
          min_touch: 4,
          max_touch: 4,
        },
        {
          id: 6,
          title: "5 پیگیری",
          color: "#03A9F4",
          min_touch: 5,
          max_touch: 5,
        },
        {
          id: 7,
          title: "6 پیگیری",
          color: "#303F9F",
          min_touch: 6,
          max_touch: 6,
        },
        {
          id: 8,
          title: "7 پیگیری",
          color: "#E64A19",
          min_touch: 7,
          max_touch: 7,
        },
        {
          id: 8,
          title: "8 و بیش از 8 پیگیری",
          color: "#000000",
          min_touch: 8,
          max_touch: 9999999,
        },
      ],
      sources: [
        { id: "all", title: "همه" },
        { id: "instagram", title: "اینستاگرام" },
        { id: "telegram", title: "تلگرام" },
        { id: "landing", title: "لندینگ سایت" },
        { id: "google", title: "گوگل" },
        { id: "wom", title: "معرفی" },
      ],
      services: [
        { id: "all", title: "همه" },
        { id: "logo", title: "لوگو" },
        { id: "site", title: "سایت" },
        { id: "app", title: "اپلیکیشن" },
        { id: "course", title: "دوره" },
        { id: "other", title: "موارد دیگر" },
      ],
      staffs: [{ id: "all", title: "همه" }],
      mediums: [{ id: "all", title: "همه" }],
      active_source: "all",
      active_service: "all",
      active_medium: "all",
      active_staff: "all",
      flags: [{ id: "all", title: "همه", color: "#333" }],
      active_flag: "all",
      leads_filtered_by_staff: [],
      search: "",
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_staffs = async (leads) => {
    let staffs = []

    await leads.forEach(
      (item) =>
        item.responsible &&
        !staffs.find((staff) => staff.id == item.responsible.id) &&
        staffs.push({
          title: item.responsible.name,
          id: item.responsible.id,
        })
    )

    dispatchFormState({
      type: SET_STAFFS,
      value: staffs,
    })
  }

  const get_flags = () => {
    const url = Config.aioUrl + "/crm/lead/get-flag"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    }).then(function (response) {
      if (response.data.code == 401) {
        alert("نیاز است مجددا به سیستم ورود کنید.")
        logOut()
      }

      dispatchFormState({ type: SET_FLAGS, value: response.data.flags })
    })
  }

  const get_leads = async ({ me }) => {
    const url = Config.aioUrl + "/crm/leads/get?region=in&me=" + me

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        get_staffs(response.data.data)

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_LEADS, data: response.data.data })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_mediums = () => {
    dispatchFormState({
      type: SET_ACTIVE_MEDIUM,
      value: "all",
    })

    let mediumsList = []
    let mediumsObjList = []

    let activeSourceleads = formState.values.leads.filter(
      (lead) => lead.source == formState.values.active_source
    )

    activeSourceleads.map((item) => {
      if (!mediumsList?.includes(item.medium)) {
        mediumsList.push(item.medium)
      }
    })

    mediumsList.map((item) => mediumsObjList.push({ id: item, title: item.toUpperCase() }))

    dispatchFormState({
      type: SET_MEDIUMS,
      value: mediumsObjList,
    })
  }

  const filter_leads = (stageData) => {
    const search = (item) => {
      let name = item.name
      let phone = item.phone
      let value = formState.values.search.toLowerCase()

      let string = `${phone} ${name}`.toLowerCase()

      return string.includes(value) ? true : false
    }

    let newLeads = formState.values.leads.filter((item) => {
      let condition =
        (item.flag.id == formState.values.active_flag || formState.values.active_flag == "all") &&
        (item.responsible?.id == formState.values.active_staff ||
          formState.values.active_staff == "all") &&
        (item.source == formState.values.active_source ||
          formState.values.active_source == "all") &&
        (item.services
          .slice()
          .replace("logomoon", "course")
          ?.includes(formState.values.active_service) ||
          formState.values.active_service == "all") &&
        (item.medium == formState.values.active_medium ||
          formState.values.active_medium == "all") &&
        search(item)

      return condition ? true : false
    })

    console.log(newLeads)

    // const stageLeads = newLeads.filter(
    //   (item) =>
    //     item.touches[0].total >= stageData.item.min_touch &&
    //     item.touches[0].total <= stageData.item.max_touch
    // )

    const stageLeads = newLeads.filter(
      (item) =>
        item.touches[0].success >= stageData.item.min_touch &&
        item.touches[0].success <= stageData.item.max_touch
    )

    return stageLeads
  }

  useEffect(() => {
    if (capabilities.includes("crm_get_all_active_leads")) {
      get_leads({ me: 0 })
      get_flags()
    } else {
      if (capabilities.includes("crm_get_own_leads")) {
        get_leads({ me: 1 })
        get_flags()
      } else {
        alert("شما دسترسی به بخش لید ها ندارید، لطفا موضوع را به مسئول خود گزارش دهید.")
      }
    }

    return () => {}
  }, [])

  useEffect(() => {
    get_mediums()
  }, [formState.values.active_source])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header
        title="لیدهای بین‌الملل در گردش"
        noBack
        reload={() => {
          if (capabilities.includes("crm_get_all_active_leads")) {
            get_leads({ me: 0 })
          } else {
            if (capabilities.includes("crm_get_own_leads")) {
              get_leads({ me: 1 })
            } else {
              alert("شما دسترسی به بخش لید ها ندارید، لطفا موضوع را به مسئول خود گزارش دهید.")
            }
          }
        }}
      />

      {!formState.values.loading && (
        <>
          <Input
            label="جستجو در لید ها"
            id="search"
            onChange={inputChangeHandler}
            maxLength={20}
            validity
            wrapperStyle={{ marginTop: 0, marginBottom: 20 }}
          />

          <View
            style={{
              height: 90,
              marginBottom: 20,
              alignItems: "flex-end",
              maxWidth: "100%",
              paddingLeft: 20,
            }}
          >
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: "row-reverse", width: "100%" }}
            >
              {capabilities.includes("crm_get_all_active_leads") && (
                <View
                  style={{
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    پرسنل نمایشی:{" "}
                  </Text>

                  {formState.values.staffs.map((item) => (
                    <TouchableOpacity
                      key={item.id}
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_staff == item.id ? Colors.blue : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_STAFF,
                          value: item.id,
                        })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: formState.values.active_staff == item.id ? "#fff" : Colors.text,
                        }}
                      >
                        {item.title}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>
              )}

              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  height: 40,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  مدیوم نمایشی:{" "}
                </Text>

                {formState.values.mediums.map((item) => (
                  <TouchableOpacity
                    key={item.id}
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_medium == item.id ? Colors.blue : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({
                        type: SET_ACTIVE_MEDIUM,
                        value: item.id,
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: formState.values.active_medium == item.id ? "#fff" : Colors.text,
                      }}
                    >
                      {item.title
                        .replace("DM", "دایرکت")
                        .replace("PAID-AD", "پولی")
                        .replace("JARYAN", "جریان")
                        .replace("UNDEFINED", "سایر")
                        .replace("DIRECT", "مستقیم")
                        .replace("CPC", "کلیکی")}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>

              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  height: 40,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  سورس نمایشی:{" "}
                </Text>

                {formState.values.sources.map((item) => (
                  <TouchableOpacity
                    key={item.id}
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_source == item.id ? Colors.blue : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({
                        type: SET_ACTIVE_SOURCE,
                        value: item.id,
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: formState.values.active_source == item.id ? "#fff" : Colors.text,
                      }}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>

              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  height: 40,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  خدمت نمایشی:{" "}
                </Text>

                {formState.values.services.map((item) => (
                  <TouchableOpacity
                    key={item.id}
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_service == item.id ? Colors.blue : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({
                        type: SET_ACTIVE_SERVICE,
                        value: item.id,
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: formState.values.active_service == item.id ? "#fff" : Colors.text,
                      }}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
            </ScrollView>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{
                flexDirection: "row-reverse",
                width: "100%",
                marginTop: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  height: 40,
                  marginLeft: 15,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  پرچم نمایشی:{" "}
                </Text>

                {formState.values.flags.map((item) => (
                  <TouchableOpacity
                    key={item.id}
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_flag == item.id ? Colors.blue : "#F2F2F2",
                      borderColor: item.color,
                      borderBottomWidth: 3,
                      borderStyle: "solid",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({
                        type: SET_ACTIVE_FLAG,
                        value: item.id,
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: formState.values.active_flag == item.id ? "#fff" : Colors.text,
                      }}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
            </ScrollView>
          </View>

          <View style={{ flex: 1, flexDirection: "row" }}>
            <FlatList
              data={formState.values.stages.slice(0).reverse()}
              keyExtractor={(item) => item.id}
              horizontal
              style={{ flexDirection: "row-reverse" }}
              renderItem={(stageData) => {
                let stageLeads = filter_leads(stageData)

                return (
                  <View style={{ width: 300, marginLeft: 15 }}>
                    <View
                      style={{
                        backgroundColor: Colors.secondary,
                        height: 65,
                        alignItems: "center",
                        paddingHorizontal: 20,
                        borderRadius: 15,
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                        marginBottom: 10,
                        borderTopWidth: 4,
                        borderTopColor: stageData.item.color,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Medium",
                          fontSize: 16,
                          color: "#fff",
                        }}
                      >
                        {stageData.item.title}
                      </Text>
                      <View
                        style={{
                          width: 34,
                          height: 34,
                          borderRadius: 17,
                          backgroundColor: stageData.item.color,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 16,
                            color: "#fff",
                          }}
                        >
                          {stageLeads.length}
                        </Text>
                      </View>
                    </View>

                    <FlatList
                      showsVerticalScrollIndicator={false}
                      data={stageLeads}
                      keyExtractor={(item) => item.id}
                      renderItem={(LeadData) => (
                        <LeadCard
                          item={LeadData.item}
                          stageColor={stageData.item.color}
                          onPress={
                            Platform.OS == "web"
                              ? {
                                  screen: "Lead",
                                  params: { id: LeadData.item.id },
                                }
                              : () => {
                                  props.navigation.navigate("Lead", {
                                    id: LeadData.item.id,
                                  })
                                }
                          }
                        />
                      )}
                    />
                  </View>
                )
              }}
            />
          </View>
        </>
      )}

      {formState.values.loading && <LoadingPlaceHolder />}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default InternationalLeadsScreen
