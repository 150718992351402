import { View, Text, TouchableOpacity, ActivityIndicator } from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"

const Btn = (props) => {
  const { onPress, loading } = props
  return (
    <View
      style={{
        width: "100%",
        height: 50,
        backgroundColor: props.color ? props.color : Colors.primary,
        borderRadius: 7,
        shadowColor: props.color ? props.color : Colors.primary,
        shadowOpacity: 1,
        shadowRadius: 3,
        elevation: 3,
        opacity: loading ? "70%" : "100%",
        ...props.wrapperStyle,
      }}
    >
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        disabled={loading}
        onPress={onPress}
      >
        {loading && <ActivityIndicator size="large" color="#fff" />}
        {!loading && !props.icon && (
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 16,
              color: "#ffffff",
              ...props.titleStyle,
            }}
          >
            {props.title}
          </Text>
        )}
        {!loading && props.icon && (
          <Ionicons name={props.icon} size={24} color="#fff" />
        )}
      </TouchableOpacity>
    </View>
  )
}

export default Btn
