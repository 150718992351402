import { useReducer, useCallback } from "react";
import { View, StyleSheet, Image, Dimensions } from "react-native";
import Input from "../../components/Input";
import Colors from "../../constants/Colors";
import Btn from "../../components/Btn";
import axios from "axios";
import Config from "../../constants/Config";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};
const LoginOneScreen = (props) => {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    phone: null,
    code: 98,
    loading: false,
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const sendOtp = async () => {
    let reqHedear = new Headers();
    reqHedear.append("Content-Type", "application/json");

    let reqData = new FormData();
    reqData.append("phone", parseInt(formState.code).toString() + parseInt(formState.phone).toString());

    const url = Config.aioUrl + "/auth/login/sendotp";

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        
        dispatchFormState({ type: SET_LOADING, loading: false });
        if (response.data.success) {
          props.navigation.navigate("LoginTwo", { phone: parseInt(formState.code).toString() + parseInt(formState.phone).toString() });
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatchFormState({ type: SET_LOADING, loading: true });
      });
  };

  return (
    <View style={styles.wrapper}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Image style={{ width: 100, height: 100 }} source={require("../../assets/img/logoR-500.png")} />
      </View>
      <View style={{ width: "100%", marginVertical: 80, flexDirection: "row" }}>
        <View style={{ marginLeft: 10, flex: 1, flexDirection: "row" }}>
          <View style={{ flex: 1, marginRight: 5 }}>
            <Input label="کد" id="code" onChange={inputChangeHandler} textAlign="center" insert={98} convertDigits />
          </View>
          <View style={{ flex: 5 }}>
            <Input
              autoFocus
              onSubmitEditing={() => {
                sendOtp();
              }}
              label="شماره موبایل"
              id="phone"
              onChange={inputChangeHandler}
              textAlign="center"
              convertDigits
            />
          </View>
        </View>
      </View>
      <Btn
        title="دریافت کد تایید"
        onPress={() => {
          sendOtp();
        }}
        loading={formState.loading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: DESKTOP ? "30%" : 15,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
});

export const screenOptions = {
  headerShown: false,
};

export default LoginOneScreen;
