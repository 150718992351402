import React, { useCallback, useEffect, useReducer } from "react"
import {
  Dimensions,
  Image,
  Modal,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { Entypo } from "@expo/vector-icons"
import axios from "axios"
import Config from "../constants/Config"
import { capitalize, encrypt, generatePassword, removeDomainPrefix } from "./Functions"
import Input from "./Input"
import Btn from "./Btn"
import { ActivityIndicator } from "react-native-web"
import { useSelector } from "react-redux"
import InputNote from "./InputNote"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const RESET_STATE = "RESET_STATE"

const SELECT_PLUGIN = "SELECT_PLUGIN"
const SELECT_THEME = "SELECT_THEME"
const SELECT_CONFIG = "SELECT_CONFIG"
const SELECT_LANGUAGE = "SELECT_LANGUAGE"

const SET_PLUGIN_LIST = "SET_PLUGIN_LIST"
const SET_THEME_LIST = "SET_THEME_LIST"
const SET_CONFIG_LIST = "SET_CONFIG_LIST"
const SELECT_NEEDHSOT_AND_DOMAIN = "SELECT_NEEDHSOT_AND_DOMAIN"
const SET_PRESET_LIST = "SET_PRESET_LIST"
const SELECT_PRESET = "SELECT_PRESET"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }

    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      }

    case SET_PRESET_LIST:
      return {
        ...state,
        presets: action.value,
      }

    case RESET_STATE:
      return {
        ...state,
        selectedPlugins: [],
        selectedConfigs: [],
        selectedNeedHostAndDomain: [],
        selectedThemes: "",
        selectedLanguages: "",
        dbName: "",
        dbUserName: "",
        dbPassword: "",
        websiteDomain: "",
      }

    case SELECT_PRESET:
      return {
        ...state,
        selectedPlugins: action.value?.plugins,
        selectedConfigs: action.value?.configs,
        selectedThemes: action.value?.theme,
      }

    case SELECT_PLUGIN: {
      const needRemove = state.selectedPlugins?.includes(action.slug)

      if (needRemove) {
        return {
          ...state,
          selectedPlugins: [...state.selectedPlugins].filter((slug) => slug !== action.slug),
        }
      } else {
        return {
          ...state,
          selectedPlugins: [...state.selectedPlugins, action.slug],
        }
      }
    }

    case SELECT_CONFIG: {
      const needRemove = state.selectedConfigs?.includes(action.slug)

      if (needRemove) {
        return {
          ...state,
          selectedConfigs: [...state.selectedConfigs].filter((slug) => slug !== action.slug),
        }
      } else {
        return {
          ...state,
          selectedConfigs: [...state.selectedConfigs, action.slug],
        }
      }
    }

    case SELECT_NEEDHSOT_AND_DOMAIN: {
      const needRemove = state.selectedNeedHostAndDomain?.includes(action.slug)

      if (needRemove) {
        return {
          ...state,
          selectedNeedHostAndDomain: [...state.selectedNeedHostAndDomain].filter(
            (slug) => slug !== action.slug
          ),
        }
      } else {
        return {
          ...state,
          selectedNeedHostAndDomain: [...state.selectedNeedHostAndDomain, action.slug],
        }
      }
    }

    case SELECT_THEME: {
      const needRemove = state.selectedThemes?.includes(action.slug)

      if (needRemove) {
        return {
          ...state,
          selectedThemes: "",
        }
      } else {
        return {
          ...state,
          selectedThemes: action.slug,
        }
      }
    }

    case SELECT_LANGUAGE: {
      const needRemove = state.selectedLanguages?.includes(action.slug)

      if (needRemove) {
        return {
          ...state,
          selectedLanguages: "",
        }
      } else {
        return {
          ...state,
          selectedLanguages: action.slug,
        }
      }
    }

    case SET_PLUGIN_LIST:
      return {
        ...state,
        plugins: action.value,
      }

    case SET_THEME_LIST:
      return {
        ...state,
        themes: action.value,
      }

    case SET_CONFIG_LIST:
      return {
        ...state,
        configs: action.value,
      }

    default:
      return state
  }
}

const CheckBoxButton = ({ title = "", selected = false, slug, selectChange, groupSlug }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        selectChange(groupSlug, slug)
      }}
      style={{
        flexDirection: "row",
        gap: 15,
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingHorizontal: 25,
        paddingVertical: 20,
        borderRadius: 8,
        backgroundColor: Colors.cardBg,
      }}
    >
      <Text
        style={{
          color: Colors.text,
          fontFamily: "IranSans-Light",
          fontSize: 17,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {title}
      </Text>

      <View
        style={{
          width: 25,
          height: 25,
          borderColor: Colors.primary,
          borderWidth: 3,
          borderRadius: 5,
          backgroundColor: selected && Colors.primary,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selected && <Entypo name="check" size={18} color="#FFFFFF" />}
      </View>
    </TouchableOpacity>
  )
}

const ListGroup = ({
  title,
  options = [],
  selectChange,
  groupSlug,
  selectedOptions,
  property,
  subProperty,
}) => {
  return (
    <View
      style={{
        width: "100%",
        borderRadius: 20,
        padding: 30,
        backgroundColor: "#f2f2f265",
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Text
          style={{
            color: Colors.secondText,
            fontFamily: "IranSans-medium",
            fontSize: 25,
            textAlign: "center",
            lineHeight: 25,
            fontWeight: "bold",
          }}
        >
          -
        </Text>

        <Text
          style={{
            color: Colors.secondText,
            fontFamily: "IranSans-medium",
            fontSize: 20,
            textAlign: "center",
            lineHeight: 25,
            fontWeight: "bold",
          }}
        >
          {title}
        </Text>
      </View>

      <View
        style={{
          width: "100%",
          paddingHorizontal: 10,
          flexDirection: "row-reverse",
          gap: 30,
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        {options.map((item) => (
          <CheckBoxButton
            slug={item?.[property]}
            title={`${capitalize(item?.[subProperty]).replaceAll("-", " ")}`}
            selectChange={selectChange}
            groupSlug={groupSlug}
            selected={selectedOptions.includes(item?.[property])}
          />
        ))}
      </View>
    </View>
  )
}

export default function WebsiteLauncher({ isOpen, setIsOpen, projectId }) {
  const DESKTOP = Dimensions.get("window").width > 800 ? true : false
  const token = useSelector((store) => store.user.token)

  const key_encrypt = useSelector((store) => store.user.key_encrypt)
  const key_decrypt = useSelector((store) => store.user.key_decrypt)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    plugins: [],
    themes: [],
    configs: [],
    needHostAndDomain: [
      { id: "BUY_HOST", name: "خرید هاست" },
      { id: "BUY_DOMAIN", name: "خرید (ثبت) دامنه" },
    ],
    languages: [
      { id: "EN", name: "انگلیسی" },
      { id: "FA", name: "فارسی" },
    ],
    selectedPlugins: [],
    selectedConfigs: [],
    selectedNeedHostAndDomain: [],
    selectedThemes: "",
    selectedLanguages: "",

    dbName: "",
    dbUserName: "",
    dbPassword: "",
    websiteDomain: "",

    presets: [],

    loading: false,
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const closeHandler = formState.loading
    ? () => alert("لطفا تا پایان عملیات صبوری کنید")
    : () => {
        dispatchFormState({ type: RESET_STATE })
        setIsOpen(false)
      }

  const selectChange = (groupSlug, slug) => {
    if (groupSlug === "plugin") {
      dispatchFormState({ type: SELECT_PLUGIN, slug })
    } else if (groupSlug === "language") {
      dispatchFormState({ type: SELECT_LANGUAGE, slug })
    } else if (groupSlug === "configs") {
      dispatchFormState({ type: SELECT_CONFIG, slug })
    } else if (groupSlug === "needHostAndDomain") {
      dispatchFormState({ type: SELECT_NEEDHSOT_AND_DOMAIN, slug })
    } else {
      dispatchFormState({ type: SELECT_THEME, slug })
    }
  }

  const reciveDatas = () => {
    dispatchFormState({ type: SET_LOADING, value: true })

    const reqHedear = {
      "Content-Type": "application/json",
    }

    axios
      .get(Config.aioUrl + "/asc/market/list", { headers: reqHedear })
      .then(({ data }) => {
        if (data.success) {
          const plugins = data.data?.filter((file) => file.type === "plugin")
          const themes = data.data?.filter((file) => file.type === "theme")

          console.log(data.configs)

          dispatchFormState({ type: SET_PLUGIN_LIST, value: plugins })
          dispatchFormState({ type: SET_THEME_LIST, value: themes })
          dispatchFormState({
            type: SET_CONFIG_LIST,
            value: data.configs.map((config) => ({ id: config, name: config?.toUpperCase() })),
          })
          dispatchFormState({ type: SET_PRESET_LIST, value: data.presets })
        } else {
          alert(data.message)
        }
      })
      .catch((error) => {
        console.log(error)
        closeHandler()
      })
      .finally(() => dispatchFormState({ type: SET_LOADING, value: false }))
  }

  useEffect(() => {
    reciveDatas()
  }, [])

  const letsLunch = () => {
    const isDataValid =
      formState.dbName && formState.dbUserName && formState.dbPassword && formState.websiteDomain

    if (!isDataValid) {
      alert("لطفا تمام فیلدها را پر کنید")
      return
    }

    const langIsValid = formState.selectedLanguages

    if (!langIsValid) {
      alert("لطفا زبان مورد نظر خود را انتخاب کنید")
      return
    }

    dispatchFormState({ type: SET_LOADING, value: true })

    const reqConfigs = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 60000 * 10,
    }

    const websiteDomain = formState.websiteDomain
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replaceAll("/", "")

    const reqUrl = `https://${websiteDomain}/install.php`

    const plugins = []

    formState.selectedPlugins.forEach((folder_name) => {
      const plugin = formState.plugins.find((item) => item.folder_name === folder_name)

      plugins.push({
        folder_name: plugin?.folder_name,
        file_name: plugin?.file_name || plugin?.folder_name,
      })
    })

    const reqData = {
      lang: formState.selectedLanguages?.toLowerCase(),
      db_name: formState.dbName,
      db_user: formState.dbUserName,
      db_pass: formState.dbPassword,
      configs: formState.selectedConfigs,
      theme: formState.selectedThemes,
      plugins,
    }

    axios
      .post(reqUrl, reqData, reqConfigs)
      .then(async ({ data }) => {
        if (data.success) {
          const adminUsername = "teamaminh"
          const adminPassword = data.data.admin_pass
          const userName = data.data.user_name
          const userPassword = data.data.user_pass

          const reqConfigs = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }

          console.log(adminUsername, encrypt(adminUsername, key_encrypt))

          const reqData = {
            domain: `https://${websiteDomain}`,
            project_id: projectId,
            admin_name: await encrypt(adminUsername, key_encrypt),
            admin_pass: await encrypt(adminPassword, key_encrypt),
            user_name: await encrypt(userName, key_encrypt),
            user_pass: await encrypt(userPassword, key_encrypt),
          }

          axios
            .post(Config.aioUrl + "/asc/site/start", reqData, reqConfigs)
            .then(({ data }) => {
              // if (data.success) {
              alert(data.message || data.msg)
              // }

              if (data.code == 200) {
                closeHandler()
              }
            })
            .catch((error) => {
              console.log(error)
              alert("مشکلی در ایجاد سایت وجود دارد! مجدد امتحان کنید.")
            })
            .finally(() => {
              dispatchFormState({ type: SET_LOADING, value: false })
            })
        } else {
          alert(data.message || data.msg || "مشکلی در ایجاد سایت وجود دارد! مجدد امتحان کنید.")
          dispatchFormState({ type: SET_LOADING, value: false })
        }
      })
      .catch((error) => {
        console.log(error)
        alert("مشکلی در ایجاد سایت وجود دارد! مجدد امتحان کنید.")
        dispatchFormState({ type: SET_LOADING, value: false })
      })
  }

  const letsByReseller = (isBuyedBefore) => {
    const domain = formState.websiteDomain
      .trim()
      ?.replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replace("/", "")

    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/

    if (!domain) {
      alert("نام دامنه را وارد کنید")
      return
    } else if (!domainRegex.test(domain)) {
      alert("نام دامنه به اشتباه وارد شده است، لطفا دامنه درست را وارد کنید")
      return
    }

    if (!isBuyedBefore) {
      const isConfirmed = confirm(`آیا از خرید و ثبت دامنه (${domain}) اطمینان دارید؟`)
      if (!isConfirmed) return
    }

    const langIsValid = formState.selectedLanguages

    if (!langIsValid) {
      alert("لطفا زبان مورد نظر خود را انتخاب کنید")
      return
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }

    const plugins = []

    formState.selectedPlugins.forEach((folder_name) => {
      const plugin = formState.plugins.find((item) => item.folder_name === folder_name)

      plugins.push({
        folder_name: plugin?.folder_name,
        file_name: plugin?.file_name || plugin?.folder_name,
      })
    })

    const adminUsername = "teamaminh"
    const adminPassword = generatePassword()
    const userName = removeDomainPrefix(domain)
    const userPassword = generatePassword()

    const data = {
      domain: `https://${domain}`,
      project_id: projectId,
      register_domain: !isBuyedBefore,
      lang: formState.selectedLanguages?.toLowerCase(),
      configs: formState.selectedConfigs,
      theme: formState.selectedThemes,
      plugins,

      wp_customer_username: userName,
      wp_customer_password: userPassword,
      wp_admin_username: adminUsername,
      wp_admin_password: adminPassword,
    }

    axios
      .post(Config.aioUrl + "/asc/site/process", data, config)
      .then(async (response) => {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        } else if (response.data.code == 403) {
          alert("دسترسی شما به این قسمت مجاز نمیباشد، لطفا به مدیریت اطلاع دهید")
        }

        console.log(response.data)
        alert(response.data.msg)

        if (response.data.success) {
          const reqData = {
            domain: `https://${domain}`,
            project_id: projectId,
            reseller: true,
            admin_name: await encrypt(adminUsername, key_encrypt),
            admin_pass: await encrypt(adminPassword, key_encrypt),
            user_name: await encrypt(userName, key_encrypt),
            user_pass: await encrypt(userPassword, key_encrypt),
          }

          console.log(reqData)

          const reqConfigs = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }

          axios
            .post(Config.aioUrl + "/asc/site/start", reqData, reqConfigs)
            .then(({ data }) => {
              if (data.code == 200) {
                closeHandler()
              }
            })
            .catch((error) => {
              console.log(error)
              alert("مشکلی در ایجاد سایت وجود دارد! مجدد امتحان کنید.")
            })
            .finally(() => {
              dispatchFormState({ type: SET_LOADING, value: false })
            })
        } else {
          closeHandler()
          dispatchFormState({ type: SET_LOADING, value: false })
        }
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message || error || "لغو عملیات")
        dispatchFormState({ type: SET_LOADING, value: false })
      })
    // .finally(() => dispatchFormState({ type: SET_LOADING, loading: false }))
  }

  const insertPreset = (plugins, configs, theme) => {
    dispatchFormState({
      type: SELECT_PRESET,
      value: {
        plugins,
        configs,
        theme,
      },
    })
  }

  return (
    <Modal animationType="fade" transparent={true} visible={isOpen} swipeDirection="down">
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          justifyContent: "center",
          alignItems: "center",
        }}
        activeOpacity={1}
        onPressOut={closeHandler}
      >
        <TouchableWithoutFeedback>
          <View
            style={{
              width: DESKTOP ? "90%" : "100%",
              height: DESKTOP ? "90%" : "100%",
              backgroundColor: Colors.bg,
              borderRadius: 25,
              paddingHorizontal: 40,
              paddingVertical: 35,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <View
              style={{
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                paddingHorizontal: 0,
                marginBottom: 20,
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <MaterialCommunityIcons name="web" size={38} color={Colors.text} />

                <Text
                  style={{
                    color: Colors.text,
                    fontFamily: "IranSans-medium",
                    fontSize: 27,
                    textAlign: "center",
                    lineHeight: 25,
                    fontWeight: "bold",
                  }}
                >
                  راه اندازی سایت
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 20,
                  gap: 12,
                }}
              >
                <TouchableOpacity onPress={closeHandler}>
                  <Ionicons name="close" size={32} color={Colors.text} />
                </TouchableOpacity>
              </View>
            </View>

            {formState.loading && (
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size="large" color={Colors.primary} />
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    marginTop: 20,
                  }}
                >
                  در حال انجام عملیات...
                </Text>
              </View>
            )}

            {!formState.loading && (
              <ScrollView
                style={{ flex: 1, width: "100%", marginVertical: 20 }}
                contentContainerStyle={{ gap: 40 }}
                showsVerticalScrollIndicator={false}
              >
                <View
                  style={{
                    flexDirection: DESKTOP ? "row" : "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 5,
                  }}
                >
                  {formState.presets &&
                    formState.presets.map((preset) => (
                      <Btn
                        key={preset.id}
                        wrapperStyle={{
                          width: DESKTOP ? "47%" : "100%",
                          paddingVertical: 15,
                          elevation: 0,
                          shadowOpacity: 0,
                          height: 60,
                        }}
                        title={`انتخاب فیلتر ${preset.name}`}
                        color={Colors.secondary}
                        onPress={() => {
                          insertPreset(preset.plugins, preset.configs, preset.theme)
                        }}
                      />
                    ))}
                </View>

                <ListGroup
                  title="زبان ها"
                  groupSlug="language"
                  selectChange={selectChange}
                  options={formState.languages}
                  selectedOptions={formState.selectedLanguages}
                  property="id"
                  subProperty="name"
                />

                <ListGroup
                  title="قالب ها"
                  groupSlug="theme"
                  selectChange={selectChange}
                  options={formState.themes}
                  selectedOptions={formState.selectedThemes}
                  property="folder_name"
                  subProperty="folder_name"
                />

                <ListGroup
                  title="افزونه ها"
                  groupSlug="plugin"
                  selectChange={selectChange}
                  options={formState.plugins}
                  selectedOptions={formState.selectedPlugins}
                  property="folder_name"
                  subProperty="folder_name"
                />

                <ListGroup
                  title="کانفیگ ها"
                  groupSlug="configs"
                  selectChange={selectChange}
                  options={formState.configs}
                  selectedOptions={formState.selectedConfigs}
                  property="id"
                  subProperty="name"
                />

                <ListGroup
                  title="نوع عملیات"
                  groupSlug="needHostAndDomain"
                  selectChange={selectChange}
                  options={formState.needHostAndDomain}
                  selectedOptions={formState.selectedNeedHostAndDomain}
                  property="id"
                  subProperty="name"
                />

                {formState.selectedNeedHostAndDomain.includes("BUY_DOMAIN") && (
                  <InputNote
                    title="مسئولیت دامنه سایت"
                    content="در وارد کردن نام دامنه دقت کنید، خرید دامنه اشتباه به مسئولیت طراح پروژه (شخص خرید کننده) میباشد و چنانچه دامنه به اشتباه خریداری شود وی ملزم به پرداخت مبلغ میباشد."
                    icon="information-circle-outline"
                    backgroundColor={Colors.primary}
                    textColor="#fff"
                    wrapperStyle={{
                      marginTop: 15,
                      marginBottom: 0,
                    }}
                  />
                )}

                <View
                  style={{
                    flexDirection: !DESKTOP ? "column" : "row-reverse",
                    gap: 15,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: !DESKTOP
                        ? "100%"
                        : formState.selectedNeedHostAndDomain.includes("BUY_HOST")
                        ? "100%"
                        : "22%",
                    }}
                  >
                    <Input
                      label="دامنه سایت"
                      id="websiteDomain"
                      value={formState.websiteDomain}
                      onChange={inputChangeHandler}
                    />
                  </View>

                  <View
                    style={{
                      width: !DESKTOP ? "100%" : "22%",
                      display: formState.selectedNeedHostAndDomain.includes("BUY_HOST") && "none",
                    }}
                  >
                    <Input
                      label="نام دیتابیس"
                      id="dbName"
                      value={formState.dbName}
                      onChange={inputChangeHandler}
                    />
                  </View>

                  <View
                    style={{
                      width: !DESKTOP ? "100%" : "22%",
                      display: formState.selectedNeedHostAndDomain.includes("BUY_HOST") && "none",
                    }}
                  >
                    <Input
                      label="نام کاربری دیتابیس"
                      id="dbUserName"
                      value={formState.dbUserName}
                      onChange={inputChangeHandler}
                    />
                  </View>

                  <View
                    style={{
                      width: !DESKTOP ? "100%" : "22%",
                      display: formState.selectedNeedHostAndDomain.includes("BUY_HOST") && "none",
                    }}
                  >
                    <Input
                      label="رمز دیتابیس"
                      id="dbPassword"
                      value={formState.dbPassword}
                      onChange={inputChangeHandler}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: !DESKTOP ? "column" : "row-reverse",
                    gap: 15,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Btn
                    wrapperStyle={{ width: !DESKTOP ? "100%" : "45%" }}
                    title="راه اندازی سایت"
                    onPress={letsLunch}
                  />

                  <Btn
                    wrapperStyle={{ width: !DESKTOP ? "100%" : "45%" }}
                    title="خرید هاست و راه اندازی سایت"
                    onPress={letsByReseller}
                  /> */}

                  <Btn
                    wrapperStyle={{ width: !DESKTOP ? "100%" : "100%" }}
                    title="شروع عملیات راه اندازی سایت"
                    onPress={() => {
                      if (formState.selectedNeedHostAndDomain.includes("BUY_HOST")) {
                        letsByReseller(!formState.selectedNeedHostAndDomain.includes("BUY_DOMAIN"))
                      } else {
                        if (formState.selectedNeedHostAndDomain.includes("BUY_DOMAIN"))
                          return alert("امکان خرید دامنه بدون خرید هاست درحال حاضر ممکن نیست")

                        letsLunch()
                      }
                    }}
                  />
                </View>
              </ScrollView>
            )}
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </Modal>
  )
}
