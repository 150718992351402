import { useReducer, useCallback } from "react"
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  ScrollView,
  Switch,
} from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import * as Clipboard from "expo-clipboard"
import Picker from "./Picker"
import Btn from "./Btn"
import Config from "../constants/Config"
import axios from "axios"
import { useSelector } from "react-redux"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_MODAL = "SET_MODAL"
const SET_EDIT = "SET_EDIT"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_MODAL:
      return {
        ...state,
        modalVisible: action.value,
      }
    case SET_EDIT:
      return {
        ...state,
        edit: state.edit ? false : true,
      }
    default:
      return state
  }
}

const SiteUser = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    loading: false,
    modalVisible: false,
    type: null,
    edit: false,
  })
  const navigation = useNavigation()
  const { id, first_name, last_name, url, getSitesList, active } = props
  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value)
  }

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const request_access = async () => {
    if (formState.type) {
      const url = Config.aioUrl + "/asc/access/request"

      let reqHedear = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }

      let formdata = new FormData()

      formdata.append("site_id", id)
      formdata.append("type", parseInt(formState.type.id))
      formdata.append("edit", formState.edit ? 1 : 0)

      dispatchFormState({ type: SET_LOADING, loading: true })

      axios({
        method: "post",
        url: url,
        headers: reqHedear,
        data: formdata,
      })
        .then(function (response) {
          dispatchFormState({ type: SET_LOADING, loading: false })

          alert(response.data.msg)

          if (response.data.success) {
            getSitesList()
          }
        })
        .catch(function (error) {
          dispatchFormState({ type: SET_LOADING, loading: false })

          console.log(error)
        })
    } else {
      alert("انتخاب سطح دسترسی الزامیست")
    }
  }

  const terminate_access = async () => {
    const url = Config.aioUrl + "/asc/access/terminate"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    let formdata = new FormData()
    formdata.append("site_id", id)
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
        if (response.data.success) {
          getSitesList()
        }
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        console.log(error)
      })
  }
  return (
    <View
      style={{
        height: 80,
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        flexDirection: "row-reverse",
        marginVertical: 5,
      }}
    >
      <Modal
        animationType="fade"
        transparent={true}
        visible={formState.modalVisible}
        swipeDirection="down"
      >
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          activeOpacity={1}
          onPressOut={() => {
            dispatchFormState({ type: SET_MODAL, value: false })
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: "30%",
                marginTop: "15%",
                marginLeft: "30%",
                backgroundColor: Colors.bg,
                borderRadius: 25,
                paddingHorizontal: 25,
                paddingVertical: 35,
              }}
            >
              <ScrollView style={{ maxHeight: 200 }}>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    دسترسی ویرایش
                  </Text>
                  <Switch
                    trackColor={{ false: "#767577", true: "#767577" }}
                    thumbColor={
                      formState.edit ? Colors.primary : Colors.primary
                    }
                    onValueChange={() => {
                      dispatchFormState({ type: SET_EDIT })
                    }}
                    value={formState.edit}
                    style={{ marginHorizontal: 10 }}
                  />
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    دسترسی مشاهده
                  </Text>
                </View>
                <Picker
                  label="سطح دسترسی"
                  id="type"
                  onChange={inputChangeHandler}
                  options={[
                    { item: "وردپرس", id: "1" },
                    { item: "پنل هاست", id: "2" },
                    { item: "وردپرس و پنل هاست", id: "3" },
                    { item: "نمادها", id: "4" },
                    { item: "وردپرس و نمادها", id: "5" },
                    { item: "پنل هاست و نمادها", id: "6" },
                    { item: "پنل هاست، وردپرس و نمادها", id: "7" },
                    { item: "درگاه", id: "8" },
                    { item: "وردپرس و درگاه", id: "9" },
                    { item: "پنل هاست و درگاه", id: "10" },
                    { item: "پنل هاست، وردپرس و درگاه", id: "11" },
                    { item: "هاستینگ", id: "12" },
                    { item: "هاستینگ و وردپرس", id: "13" },
                    { item: "ایرانیک", id: "14" },
                    { item: "وردپرس و ایرانیک", id: "15" },
                    { item: "پنل هاست و ایرانیک", id: "16" },
                    { item: "وردپرس، پنل هاست و ایرانیک", id: "17" },
                    { item: "کامل سطح یک", id: "18" },
                  ]}
                />
              </ScrollView>
              <Btn
                title="دریافت دسترسی"
                onPress={async () => {
                  await request_access()
                  dispatchFormState({ type: SET_MODAL, value: false })
                }}
                loading={formState.loading}
                color={Colors.green}
                wrapperStyle={{ marginTop: 20 }}
              />
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          {id}
        </Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
        }}
      >
        <Text
          onPress={() => {
            copyToClipboard(url)
          }}
          style={styles.headerText}
        >
          {url}
        </Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
        }}
      >
        <Text
          onPress={() => {
            copyToClipboard(first_name)
          }}
          style={styles.headerText}
        >
          {first_name}
        </Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
        }}
      >
        <Text
          onPress={() => {
            copyToClipboard(last_name)
          }}
          style={styles.headerText}
        >
          {last_name}
        </Text>
      </View>
      
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
          flexDirection: "row",
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            backgroundColor: active ? Colors.green : Colors.primary,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            if (active) {
              if (confirm("دسترسی شما حذف شود؟")) {
                terminate_access()
              }
            } else {
              dispatchFormState({ type: SET_MODAL, value: true })
            }
          }}
        >
          <Ionicons
            name={active ? "lock-open-outline" : "lock-closed-outline"}
            color="#fff"
            size={16}
          />
        </TouchableOpacity>
        {active && (
          <TouchableOpacity
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: 24,
              height: 24,
              backgroundColor: Colors.blue,
              borderRadius: 3,
              marginHorizontal: 3,
            }}
            onPress={() => {
              navigation.navigate("AddSite", { site_id: id })
            }}
          >
            <Ionicons name="eye-outline" color="#fff" size={16} />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: "IranSans-Regular",
    fontSize: 12,
    color: Colors.text,
  },
})

export default SiteUser
