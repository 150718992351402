import { StatusBar } from "expo-status-bar"
import { configureStore } from "@reduxjs/toolkit"
import { Provider } from "react-redux"
import { NavigationContainer } from "@react-navigation/native"
import { useFonts } from "expo-font"
import AppLoading from "expo-app-loading"
import userReducer from "./store/user"
import MainNavigator from "./navigation/MainNavigation"
import ErrorBoundary from "./components/ErrorBoundary"
import { useEffect } from "react"
import Config, { redirects } from "./constants/Config"

const store = configureStore({
  reducer: {
    user: userReducer,
  },
})
document.body.style.overflow = "hidden"

export default function App() {
  const linking = {
    prefixes: [
      "exp://",
      "localhost:19006",
      "http://localhost:19006",
      "aio.aminh.pro",
      "http://aio.aminh.pro",
      "https://aio.aminh.pro",
    ],
    config: {
      screens: {
        // OutsiderNavigator: {
        //   screens: {
        //     GetPrice: "page/price",
        //     LogoVideo: "page/logovideo",
        //     SiteVideo: "page/sitevideo",
        //   },
        // },
        // Auth: {
        //   screens: {
        //     Splash: "auth/loading",
        //     LoginOne: "auth/login",
        //     LoginTwo: "auth/verify",
        //     EnableNotif: "auth/notifications",
        //     SetKey: "auth/key",
        //   },
        // },
        // StaffNavigator: {
        //   screens: {
        //     Drawer: {
        //       screens: {
        //         Projects: {
        //           path: "pm/projects/:storage?",
        //           stringify: {
        //             storage: (storage) => "",
        //           },
        //         },
        //       },
        //     },
        //     Project: {
        //       screens: {
        //         info: "pm/project/:id/info",
        //         note: "pm/project/:id/note",
        //         file: "pm/project/:id/file",
        //         bills: "pm/project/:id/bills",
        //       },
        //     },
        //   },
        // },
      },
    },
  }

  let [fontsLoaded] = useFonts({
    "IranSans-UltraLight": require("./assets/fonts/IRANSansUltraLight.ttf"),
    "IranSans-Light": require("./assets/fonts/IRANSansLight.ttf"),
    "IranSans-Regular": require("./assets/fonts/IRANSans.ttf"),
    "IranSans-Medium": require("./assets/fonts/IRANSansMedium.ttf"),
  })

  useEffect(() => {
    const pageURL = document.location.href

    redirects.forEach(({ from, to }) => {
      const needRedirect = pageURL.includes(from)

      if (needRedirect) document.location.href = to
    })

    console.log(`Version: ${Config.appVersion}`)
  }, [])

  if (!fontsLoaded) {
    return <AppLoading />
  } else {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <NavigationContainer linking={linking}>
            <MainNavigator name="Main" />
          </NavigationContainer>
        </Provider>
      </ErrorBoundary>
    )
  }
}
