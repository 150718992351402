import { useCallback, useEffect, useReducer } from "react"
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Input from "../../components/Input"
import { useSelector } from "react-redux"
import SiteUser from "../../components/SiteUser"
import filter from "lodash.filter"
import axios from "axios"
import Config from "../../constants/Config"
import { logOut } from "../../components/Functions"

const SEARCH = "SEARCH"
const SET_LOADING = "SET_LOADING"
const SET_SITES = "SET_SITES"
const formReducer = (state, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          query: action.query,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SITES:
      return {
        ...state,
        values: {
          ...state.values,
          sites: action.data,
        },
      }
    default:
      return state
  }
}

const UserRequestAccessScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      data: [],
      sites: [],
      loading: false,
    },
  })

  const getSitesList = async () => {
    const url = Config.aioUrl + "/asc/site/list"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_SITES,
          data: response.data.data.sort((a, b) => b.active - a.active),
        })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    getSitesList()
  }, [])

  const handleSearch = useCallback(
    (id, text) => {
      const contains = (item, query) => {
        if (
          `${item.first_name} ${item.last_name} ${item.url}`.includes(query)
        ) {
          return true
        }
        return false
      }
      const data = filter(formState.values.sites, (item) =>
        contains(item, text)
      )
      dispatchFormState({ type: SEARCH, data, query: text })
    },
    [dispatchFormState, formState.values.sites]
  )
  
  return (
    <View style={styles.wrapper}>
      <Header title="دریافت دسترسی" noBack />

      <Input
        label="به دنبال چه می گردی؟"
        id="search"
        onChange={handleSearch}
        maxLength={20}
        validity
      />
      <Text
        style={{
          color: Colors.text,
          fontFamily: "Iransans-Medium",
          fontSize: 14,
          marginBottom: 10,
          marginTop: 30,
        }}
      >
        {formState.values.data.length} مورد یافت شد
      </Text>
      {!formState.values.loading && (
        <View style={{ flex: 1, width: "100%", height: "100%" }}>
          <View style={{ flexDirection: "row-reverse" }}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
                flex: 1,
              }}
            >
              <Text style={styles.headerText}>کد سایت</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                flex: 2,
              }}
            >
              <Text style={styles.headerText}>آدرس سایت</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                flex: 2,
              }}
            >
              <Text style={styles.headerText}>نام</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                flex: 2,
                height: 50,
              }}
            >
              <Text style={styles.headerText}>نام خانوادگی</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                flex: 2,
                height: 50,
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
              }}
            >
              <Text style={styles.headerText}>عملیات</Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <FlatList
              data={formState.values.data}
              renderItem={(item) => (
                <SiteUser
                  id={item.item.id}
                  first_name={item.item.first_name}
                  last_name={item.item.last_name}
                  url={item.item.url}
                  getSitesList={getSitesList}
                  active={item.item.active}
                />
              )}
              keyExtractor={(item) => item.id}
            />
          </View>
        </View>
      )}
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
  headerText: {
    fontFamily: "IranSans-Medium",
    fontSize: 14,
    color: "#fff",
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default UserRequestAccessScreen
