import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  FlatList,
  ScrollView,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Input from "../../components/Input"
import Config from "../../constants/Config"
import axios from "axios"
import { logOut } from "../../components/Functions"
import moment from "moment-jalaali"
import filter from "lodash.filter"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_TICKETS = "SET_TICKETS"
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
const SET_ACTIVE_TYPE = "SET_ACTIVE_TYPE"
const SEARCH = "SEARCH"
const SET_STAFFS = "SET_STAFFS"
const SET_ACTIVE_STAFF = "SET_ACTIVE_STAFF"
const RESET_FILTER = "RESET_FILTER"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case RESET_FILTER:
      return {
        ...state,
        values: {
          ...state.values,
          active_staff: action.active_staff,
          active_section: action.active_section,
          active_type: action.active_type,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: action.value,
        },
      }
    case SET_ACTIVE_STAFF:
      return {
        ...state,
        values: {
          ...state.values,
          active_staff: action.value,
        },
      }
    case SET_TICKETS:
      return {
        ...state,
        values: {
          ...state.values,
          tickets: action.data,
        },
      }
    case SET_ACTIVE_TYPE:
      return {
        ...state,
        values: {
          ...state.values,
          active_type: action.value,
          active_staff: "همه",
        },
      }
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        values: {
          ...state.values,
          active_section: action.value,
          active_staff: "همه",
        },
      }
    case SEARCH:
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          query: action.query,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const TicketListScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_data = useSelector((store) => store.user.user_data)
  const capabilities = useSelector((store) => store.user.capabilities)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      tickets: [],
      data: [],
      staffs: [],
      active_staff: "همه",
      active_section: user_data.department == "lead" ? "site" : "me",
      active_type: "open",
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_tickets = async (filters) => {
    const url =
      Config.aioUrl +
      "/ticket/list?type=" +
      filters.type +
      "&section=" +
      filters.section

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        let sortedData = response.data.data
        if (filters.type == "open") {
          sortedData = sortedData.sort((a) => {
            if (a.status == "answered") {
              return 1
            }
            if (a.status == "needanswer" || a.status == "pending") {
              return -1
            }
            return 0
          })
        }
        dispatchFormState({ type: SET_TICKETS, data: sortedData })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const handleSearch = useCallback(
    (id, text) => {
      const contains = (item, query) => {
        if (
          `${item.customer_first_name} ${item.customer_last_name} ${item.id} ${item.title} ${item.customer_id} ${item.responsible_name}`.includes(
            query
          )
        ) {
          return true
        }
        return false
      }
      const data = filter(formState.values.tickets, (item) =>
        contains(item, text)
      )
      dispatchFormState({ type: SEARCH, data, query: text })
    },
    [dispatchFormState, formState.values.tickets]
  )

  const getStaffs = (tickets) => {
    let staffsList = []

    staffsList.push("همه")

    tickets.map((item) => {
      if (!staffsList.includes(item.responsible_name)) {
        staffsList.push(item.responsible_name)
      }
    })

    dispatchFormState({
      type: SET_STAFFS,
      value: staffsList,
    })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      dispatchFormState({
        type: RESET_FILTER,
        active_staff: "همه",
        active_section: user_data.department == "lead" ? "site" : "me",
        active_type: "open",
      })

      get_tickets({
        type: formState.values.active_type,
        section: formState.values.active_section,
      })
    })

    return focused
  }, [props.navigation])

  useEffect(() => {
    if (
      capabilities.includes("support_ticket_get_own_department") ||
      capabilities.includes("support_ticket_get_all")
    ) {
      getStaffs(formState.values.data)
    }
  }, [formState.values.data])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="تیکت ها" noBack />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 0,
          height: "100%",
          flex: 1,
        }}
      >
        <View style={{ width: "100%", justifyContent: "center" }}>
          <View
            style={{
              flexDirection: "row-reverse",
              alignItems: "center",
              height: 40,
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 12,
                color: Colors.text,
                minWidth: 80,
              }}
            >
              دسته نمایش:{" "}
            </Text>
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor:
                  formState.values.active_type == "open"
                    ? Colors.blue
                    : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({ type: SET_ACTIVE_TYPE, value: "open" })
                get_tickets({
                  type: "open",
                  section: formState.values.active_section,
                })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color:
                    formState.values.active_type == "open"
                      ? "#fff"
                      : Colors.text,
                }}
              >
                باز
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor:
                  formState.values.active_type == "closed"
                    ? Colors.blue
                    : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({ type: SET_ACTIVE_TYPE, value: "closed" })
                get_tickets({
                  type: "closed",
                  section: formState.values.active_section,
                })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color:
                    formState.values.active_type == "closed"
                      ? "#fff"
                      : Colors.text,
                }}
              >
                بسته
              </Text>
            </TouchableOpacity>
          </View>

          {(capabilities.includes("support_ticket_get_own_department") ||
            capabilities.includes("support_ticket_get_all")) && (
            <View
              style={{
                flexDirection: "row-reverse",
                alignItems: "center",
                height: 40,
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Medium",
                  fontSize: 12,
                  color: Colors.text,
                  minWidth: 80,
                }}
              >
                بخش:{" "}
              </Text>
              {user_data.department != "lead" && (
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    width: 60,
                    borderRadius: 10,
                    backgroundColor:
                      formState.values.active_section == "me"
                        ? Colors.blue
                        : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({ type: SET_ACTIVE_SECTION, value: "me" })
                    get_tickets({
                      type: formState.values.active_type,
                      section: "me",
                    })
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color:
                        formState.values.active_section == "me"
                          ? "#fff"
                          : Colors.text,
                    }}
                  >
                    من
                  </Text>
                </TouchableOpacity>
              )}
              {(capabilities.includes("support_ticket_get_all") ||
                user_data.department == "site") && (
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    width: 60,
                    borderRadius: 10,
                    backgroundColor:
                      formState.values.active_section == "site"
                        ? Colors.blue
                        : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({
                      type: SET_ACTIVE_SECTION,
                      value: "site",
                    })
                    get_tickets({
                      type: formState.values.active_type,
                      section: "site",
                    })
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color:
                        formState.values.active_section == "site"
                          ? "#fff"
                          : Colors.text,
                    }}
                  >
                    سایت
                  </Text>
                </TouchableOpacity>
              )}
              {(capabilities.includes("support_ticket_get_all") ||
                user_data.department == "sales") && (
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    width: 60,
                    borderRadius: 10,
                    backgroundColor:
                      formState.values.active_section == "sales"
                        ? Colors.blue
                        : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({
                      type: SET_ACTIVE_SECTION,
                      value: "sales",
                    })
                    get_tickets({
                      type: formState.values.active_type,
                      section: "sales",
                    })
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color:
                        formState.values.active_section == "sales"
                          ? "#fff"
                          : Colors.text,
                    }}
                  >
                    فروش
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}

          {(capabilities.includes("support_ticket_get_own_department") ||
            capabilities.includes("support_ticket_get_all")) &&
            formState.values.active_section != "me" && (
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={{ flexDirection: "row-reverse", width: "100%" }}
              >
                <View
                  style={{
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      height: 40,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 12,
                        color: Colors.text,
                        minWidth: 80,
                      }}
                    >
                      پرسنل:{" "}
                    </Text>

                    {formState.values.staffs.map((item) => (
                      <TouchableOpacity
                        key={item}
                        style={{
                          paddingVertical: 5,
                          paddingHorizontal: 20,
                          borderRadius: 10,
                          backgroundColor:
                            formState.values.active_staff == item
                              ? Colors.blue
                              : "#F2F2F2",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 5,
                        }}
                        onPress={() => {
                          dispatchFormState({
                            type: SET_ACTIVE_STAFF,
                            value: item,
                          })
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color:
                              formState.values.active_staff == item
                                ? "#fff"
                                : Colors.text,
                          }}
                        >
                          {item}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                </View>
              </ScrollView>
            )}
        </View>

        {!formState.values.loading && (
          <View style={{ flex: 1, height: "100%", marginTop: 10 }}>
            <Input
              label="به دنبال چه می گردی؟"
              id="search"
              onChange={handleSearch}
              maxLength={20}
              validity
            />

            <FlatList
              data={formState.values.data}
              keyExtractor={(item) => item.id}
              showsVerticalScrollIndicator={false}
              renderItem={(item) => {
                if (
                  item.item.responsible_name == formState.values.active_staff ||
                  formState.values.active_staff == "همه"
                ) {
                  return (
                    <TouchableOpacity
                      style={{
                        width: "100%",
                        backgroundColor: "#F2F2F2",
                        borderRadius: 15,
                        paddingVertical: 20,
                        paddingLeft: 20,
                        paddingRight: 0,
                        marginBottom: 10,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onPress={() => {
                        props.navigation.navigate("Ticket", {
                          ticket_id: item.item.id,
                        })
                      }}
                    >
                      <View style={{ flex: 5 }}>
                        <View
                          style={{
                            width: 80,
                            height: 25,
                            backgroundColor:
                              item.item.status === "answered"
                                ? Colors.green
                                : item.item.status === "closed"
                                ? Colors.primary
                                : item.item.status === "pending"
                                ? Colors.error
                                : Colors.blue,
                            borderRadius: 7,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "IranSans-regular",
                              fontSize: 10,
                              color: "#fff",
                            }}
                          >
                            {item.item.status === "answered"
                              ? "پاسخ داده شده"
                              : item.item.status === "closed"
                              ? "بسته"
                              : item.item.status === "pending"
                              ? "در حال بررسی"
                              : "در انتظار پاسخ"}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: 80,
                            height: 25,
                            backgroundColor: Colors.secondary,
                            borderRadius: 7,
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "IranSans-regular",
                              fontSize: 10,
                              color: "#fff",
                            }}
                          >
                            {moment.unix(item.item.creation_date).fromNow()}
                          </Text>
                        </View>
                      </View>
                      <View style={{ flex: 5 }}>
                        <Text
                          style={{
                            fontFamily: "IranSans-Medium",
                            fontSize: 12,
                            color: Colors.text,
                          }}
                        >
                          {item.item.customer_first_name +
                            " " +
                            item.item.customer_last_name}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "IranSans-regular",
                            fontSize: 10,
                            color: Colors.text,
                            marginTop: 7,
                          }}
                        >
                          {item.item.title}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "IranSans-regular",
                            fontSize: 10,
                            color: Colors.text,
                            marginTop: 7,
                          }}
                        >
                          {item.item.department == "site" ? "سایت" : "فروش"}
                          {" - " + item.item.responsible_name}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Text
                          style={{
                            transform: [{ rotate: "-90deg" }],
                            fontFamily: "IranSans-Medium",
                            fontSize: 14,
                            color: Colors.text,
                          }}
                        >
                          # {item.item.id}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )
                } else {
                  return <></>
                }
              }}
              style={{ height: "100%", flex: 1 }}
            />
          </View>
        )}

        {formState.values.loading && (
          <View style={{ flex: 1, height: "100%", marginTop: 10 }}>
            <Input
              label="به دنبال چه می گردی؟"
              id="search"
              onChange={handleSearch}
              maxLength={20}
              validity
              editable={false}
            />
            <LoadingPlaceHolder />
            <LoadingPlaceHolder />
            <LoadingPlaceHolder />
            <LoadingPlaceHolder />
          </View>
        )}
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default TicketListScreen
