import { View, Text } from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"

const StatBox = (props) => {
  return (
    <View
      style={{
        flex: 1,
        height: 150,
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        padding: 20,
        marginBottom: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 16,
            color: Colors.text,
          }}
        >
          {props.title}
        </Text>
        <Ionicons
          name={props.iconname}
          color={props.iconcolor ? props.iconcolor : Colors.secondary}
          size={30}
        />
      </View>
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 30,
            color: Colors.text,
          }}
        >
          {props.value}
        </Text>
        {props.secondValue && (
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 18,
              color: Colors.text,
            }}
          >
            {"(" + props.secondValue + ") "}
          </Text>
        )}
      </View>
      {props.change && (
        <View
          style={{
            flexDirection: "row-reverse",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Ionicons
            name={props.change >= 0 ? "arrow-up-outline" : "arrow-down-outline"}
            color={props.change >= 0 ? Colors.green : Colors.primary}
            size={14}
          />
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 12,
              color: props.change >= 0 ? Colors.green : Colors.primary,
              marginLeft: 5,
            }}
          >
            {"%" + Math.abs(props.change)}
          </Text>
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 10,
              color: Colors.secondText,
            }}
          >
            {props.period}
          </Text>
        </View>
      )}
    </View>
  )
}

export default StatBox
