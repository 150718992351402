import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import SitesListScreen, {
  screenOptions as SitesListScreenOptions,
} from "../screens/ASC/SitesListScreen"
import AddSiteScreen, {
  screenOptions as AddSiteScreenOptions,
} from "../screens/ASC/AddSiteScreen"
import UserRequestAccessScreen, {
  screenOptions as UserRequestAccessScreenOptions,
} from "../screens/ASC/UserRequestAccessScreen"
import SiteInfoScreen, {
  screenOptions as SiteInfoScreenOptoins,
} from "../screens/ASC/SiteInfoScreen"
import MarketListScreen, {
  screenOptions as MarketListScreenOptoins,
} from "../screens/ASC/MarketListScreen"
import AddMarketItemScreen, {
  screenOptions as AddMarketItemScreenOptions,
} from "../screens/ASC/AddMarketItemScreen"
import UptimeScreen, {
  screenOptions as UptimeScreenOptions,
} from "../screens/ASC/UptimeScreen"

const ASCStackNavigator = createNativeStackNavigator()
const ASCNavigator = () => {
  return (
    <ASCStackNavigator.Navigator>
      <ASCStackNavigator.Screen
        name="SitesList"
        component={SitesListScreen}
        options={{ ...SitesListScreenOptions, title: "لیست سایت ها" }}
      />

      <ASCStackNavigator.Screen
        name="Uptime"
        component={UptimeScreen}
        options={{ ...UptimeScreenOptions, title: "لیست سایت ها" }}
      />

      <ASCStackNavigator.Screen
        name="UserRequestAccess"
        component={UserRequestAccessScreen}
        options={{ ...UserRequestAccessScreenOptions, title: "درخواست دسترسی" }}
      />

      <ASCStackNavigator.Screen
        name="AddSite"
        component={AddSiteScreen}
        options={{ ...AddSiteScreenOptions, title: "اطلاعات سایت" }}
      />

      <ASCStackNavigator.Screen
        name="SiteInfo"
        component={SiteInfoScreen}
        options={{ ...SiteInfoScreenOptoins, title: "اطلاعات سایت" }}
      />

      <ASCStackNavigator.Screen
        name="MarketList"
        component={MarketListScreen}
        options={{ ...MarketListScreenOptoins, title: "لیست مارکت" }}
      />

      <ASCStackNavigator.Screen
        name="AddMarketItem"
        component={AddMarketItemScreen}
        options={{ ...AddMarketItemScreenOptions, title: "آیتم مارکت" }}
      />
    </ASCStackNavigator.Navigator>
  )
}

export default ASCNavigator
