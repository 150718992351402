import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import MakeInvoiceScreen, { screenOptions as MakeInvoiceScreenOptions } from "../screens/Sales/MakeInvoiceScreen";
import UnpaidInvoicesScreen, { screenOptions as UnpaidInvoicesScreenOptions } from "../screens/Sales/UnpaidInvoicesScreen";
import AddTransactionScreen, { screenOptions as AddTransactionScreenOptions } from "../screens/Sales/AddTransactionScreen";
import PendingTransactionsScreen, { screenOptions as PendingTransactionsScreenOptions } from "../screens/Sales/PendingTransactionsScreen";
import TransactionsScreen, { screenOptions as TransactionsScreenOptions } from "../screens/Sales/TransactionsScreen";
import { useSelector } from "react-redux";

const SalesStackNavigator = createNativeStackNavigator();
const SalesNavigator = () => {
  const capabilities = useSelector((store) => store.user.capabilities);
  return (
    <SalesStackNavigator.Navigator>
      {capabilities.includes("invoice_create") && (
        <SalesStackNavigator.Screen
          name="MakeInvoice"
          component={MakeInvoiceScreen}
          options={{ ...MakeInvoiceScreenOptions, title: "ساخت صوتحساب" }}
          initialParams={{ project_id: false }}
        />
      )}
      {capabilities.includes("invoice_getunpaid") && (
        <SalesStackNavigator.Screen
          name="UnpaidInvoices"
          component={UnpaidInvoicesScreen}
          options={{
            ...UnpaidInvoicesScreenOptions,
            title: "صورتحساب های رها",
          }}
        />
      )}

      {capabilities.includes("transactions_get_pending") && (
        <SalesStackNavigator.Screen
          name="PendingTransactions"
          component={PendingTransactionsScreen}
          options={{ ...PendingTransactionsScreenOptions, title: "صورتحساب های در انتظار تایید" }}
        />
      )}
      {capabilities.includes("transactions_see_menu_in_aio") && (
        <SalesStackNavigator.Screen
          name="Transactions"
          component={TransactionsScreen}
          options={{
            ...TransactionsScreenOptions,
            title: "تراکنش ها",
          }}
        />
      )}
      {(capabilities.includes("transaction_add_income") || capabilities.includes("transaction_add_outcome")) && (
        <SalesStackNavigator.Screen
          name="AddTransaction"
          component={AddTransactionScreen}
          options={{
            ...AddTransactionScreenOptions,
            title: "افزودن تراکنش",
          }}
          initialParams={{ invoice_id: false, customer_name: false, invoice_cost: false }}
        />
      )}
    </SalesStackNavigator.Navigator>
  );
};

export default SalesNavigator;
