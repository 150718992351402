import { useReducer, useEffect } from "react"
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  ActivityIndicator,
  FlatList,
} from "react-native"
import { useSelector } from "react-redux"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import axios from "axios"
import Config from "../../constants/Config"
import { separate, version_compare, decrypt } from "../../components/Functions"
import Checkbox from "expo-checkbox"
import InputNote from "../../components/InputNote"

const SET_LOADING = "SET_LOADING"
const SET_SITE = "SET_SITE"
const SET_DISK = "SET_DISK"
const SET_DOMAINS = "SET_DOMAINS"
const SET_EMAILS = "SET_EMAILS"
const SET_FTPS = "SET_FTPS"
const SET_MARKETLIST = "SET_MARKETLIST"
const SET_CHECKED = "SET_CHECKED"
const SET_LOG = "SET_LOG"
const SET_SITE_DATA = "SET_SITE_DATA"
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      let updatedLoading = {
        ...state.values.loading,
      }
      updatedLoading[action.name] = action.loading
      return {
        ...state,
        values: {
          ...state.values,
          loading: updatedLoading,
        },
      }
    case SET_SITE:
      return {
        ...state,
        values: {
          ...state.values,
          info: action.data,
        },
      }
    case SET_DISK:
      return {
        ...state,
        values: {
          ...state.values,
          disk: action.data,
        },
      }
    case SET_DOMAINS:
      return {
        ...state,
        values: {
          ...state.values,
          domains: action.data,
        },
      }
    case SET_EMAILS:
      return {
        ...state,
        values: {
          ...state.values,
          emails: action.data,
        },
      }
    case SET_FTPS:
      return {
        ...state,
        values: {
          ...state.values,
          ftps: action.data,
        },
      }
    case SET_MARKETLIST:
      return {
        ...state,
        values: {
          ...state.values,
          marketlist: action.data,
        },
      }
    case SET_CHECKED:
      let newChecked = state.values.checked
      if (action.act == "add") {
        newChecked.push(action.name)
      } else {
        newChecked = state.values.checked.filter(function (value, index, arr) {
          return value != action.name
        })
      }
      let uniqueChecked = [...new Set(newChecked)]

      return {
        ...state,
        values: {
          ...state.values,
          checked: uniqueChecked,
        },
      }

    case SET_LOG:
      return {
        ...state,
        values: {
          ...state.values,
          log: action.data,
        },
      }
    case SET_SITE_DATA:
      let form = action.data
      return {
        ...state,
        values: {
          ...state.values,
          site_data: form,
        },
      }
    default:
      return state
  }
}

const SiteInfoScreen = (props) => {
  const site_id = props.route.params.site_id
  const token = useSelector((store) => store.user.token)
  const key_decrypt = useSelector((store) => store.user.key_decrypt)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        info: false,
        disk: false,
        update: false,
      },
      info: {
        plugins: [],
        active_theme: {
          Name: "",
          Version: "",
        },
      },
      disk: { bytes_used: "0", byte_limit: 0 },
      domains: {
        addon_domains: [],
        main_domain: "",
        sub_domains: [],
        parked_domains: [],
      },
      emails: [],
      ftps: [],
      checked: [],
      log: null,
      site_data: null,
    },
    validities: {},
  })

  const get_wp_info = async () => {

    let url = Config.aioUrl + "/asc/market/list"
    let reqHedear = {
      "Content-Type": "application/json",
    }
    dispatchFormState({ type: SET_LOADING, loading: true, name: "info" })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (market_response) {
        console.log(market_response)
        url =
          formState.values.site_data.url +
          "/wp-content/plugins/asc-client/api/info/wp/"

        reqHedear = {
          "Content-Type": "application/json",
        }

        let reqData = JSON.stringify({
          password: formState.values.site_data.wp_admin_password,
        })

        axios({
          method: "post",
          url: url,
          data: reqData,
          headers: reqHedear,
        })
          .then(function (info_response) {
            console.log(info_response)

            if (!info_response.data.success) {
              alert(
                "از طرف سیستم 'سایت' دسترسی داده نشد!\nممکن هست نام کاربری یا رمز عبور سایت را به اشتباه وارد کرده باشید."
              )
            }

            versionChecker(info_response.data.data, market_response.data.data)
          })
          .catch(function (error) {
            console.log(error)
            dispatchFormState({
              type: SET_LOADING,
              loading: false,
              name: "info",
            })
          })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false, name: "info" })
        console.log(error)
      })
  }

  const versionChecker = (info_data, market_data) => {
    let newInfo = info_data
    for (let i = 0; i < newInfo.plugins.length; i++) {
      for (let j = 0; j < market_data.length; j++) {
        if (newInfo.plugins[i].TextDomain == market_data[j].folder_name) {
          if (
            version_compare(
              market_data[j].version,
              newInfo.plugins[i].Version
            ) == 1
          ) {
            newInfo.plugins[i]["update_available"] = true
            newInfo.plugins[i]["update_version"] = market_data[j].version
          }
        }
      }
    }
    for (let i = 0; i < newInfo.themes.length; i++) {
      for (let j = 0; j < market_data.length; j++) {
        if (newInfo.themes[i].TextDomain == market_data[j].folder_name) {
          if (
            version_compare(
              market_data[j].version,
              newInfo.themes[i].Version
            ) == 1
          ) {
            newInfo.themes[i]["update_available"] = true
            newInfo.themes[i]["update_version"] = market_data[j].version
          }
        }
      }
    }

    dispatchFormState({ type: SET_MARKETLIST, data: market_data })
    dispatchFormState({ type: SET_SITE, data: newInfo })
    dispatchFormState({ type: SET_LOADING, loading: false, name: "info" })
  }

  const get_cpanel_info = async () => {
    const url =
      formState.values.site_data.url +
      "/wp-content/plugins/asc-client/api/info/cpanel/"
    let reqHedear = {
      "Content-Type": "application/json",
    }
    dispatchFormState({ type: SET_LOADING, loading: true, name: "disk" })
    let reqData = JSON.stringify({
      password: formState.values.site_data.wp_admin_password,
      cpanel_username: formState.values.site_data.hosting_panel_username,
      cpanel_api_key: formState.values.site_data.hosting_panel_api,
    })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: reqData,
    })
      .then(function (response) {
        dispatchFormState({ type: SET_LOADING, loading: false, name: "disk" })

        if (!response.data.success) {
          alert(
            "از طرف سیستم 'سی پنل' دسترسی داده نشد!\nممکن هست نام کاربری یا رمز عبور سی پنل را به اشتباه وارد کرده باشید."
          )
        }

        dispatchFormState({ type: SET_DISK, data: response.data.data[0].data })
        dispatchFormState({
          type: SET_DOMAINS,
          data: response.data.data[1].data,
        })
        dispatchFormState({
          type: SET_EMAILS,
          data: response.data.data[2].data,
        })
        dispatchFormState({ type: SET_FTPS, data: response.data.data[3].data })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false, name: "disk" })
      })
  }

  const get_site = async () => {
    const url = Config.aioUrl + "/asc/site/get?id=" + site_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    let axios_response = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        return response.data
      })
      .catch(function (error) {})

    let form = await axios_response.data

    console.log("axios_response.data", axios_response.data)

    for (let key in form) {
      if (
        key == "hosting_email" ||
        key == "hosting_password" ||
        key == "hosting_panel_username" ||
        key == "hosting_panel_password" ||
        key == "hosting_panel_api" ||
        key == "wp_admin_username" ||
        key == "wp_admin_password" ||
        key == "wp_backdoor" ||
        key == "nic_email" ||
        key == "nic_handle" ||
        key == "nic_password" ||
        key == "nic_answer" ||
        key == "enamad_username" ||
        key == "enamad_password" ||
        key == "samandehi_username" ||
        key == "samandehi_password" ||
        key == "online_gateway" ||
        key == "description"
      ) {
        form[key] = await decrypt(form[key] || "", key_decrypt)
      }
    }
    dispatchFormState({
      type: SET_SITE_DATA,
      data: form,
    })
  }

  const update = async () => {
    let url =
      formState.values.site_data.url +
      "/wp-content/plugins/asc-client/api/update/plugins-themes/"

    let reqHedear = {
      "Content-Type": "application/json",
    }

    let reqData = {
      password: formState.values.site_data.wp_admin_password,
      data: [],
    }

    for (let i = 0; i < formState.values.marketlist.length; i++) {
      if (
        formState.values.checked.includes(
          formState.values.marketlist[i].folder_name
        )
      ) {
        reqData.data.push({
          remote_file_url: formState.values.marketlist[i].file_url,
          target_folder_name: formState.values.marketlist[i].folder_name,
          type: formState.values.marketlist[i].type,
        })
      }
    }

    dispatchFormState({ type: SET_LOADING, loading: true, name: "update" })

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: reqData,
    })
      .then(function (response) {
        dispatchFormState({ type: SET_LOG, data: response.data })

        console.log(response.data)

        dispatchFormState({ type: SET_LOADING, loading: false, name: "update" })
      })
      .catch(function (error) {
        console.log(error)

        dispatchFormState({ type: SET_LOADING, loading: false, name: "update" })
      })
  }

  const is_on_update_server = (TextDomain) => {
    for (let i = 0; i < formState.values.marketlist.length; i++) {
      if (formState.values.marketlist[i].folder_name == TextDomain) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_site()
    })

    return focused
  }, [props.navigation])

  useEffect(() => {
    if (formState.values.site_data) {
      get_cpanel_info()
      get_wp_info()
    }
  }, [formState.values.site_data])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      {formState.values.site_data != null ? (
        <Header
          title={
            "اطلاعات سایت" +
            " " +
            formState.values.site_data?.url?.replace(/(^\w+:|^)\/\//, "")
          }
          noToggle
        />
      ) : (
        <Header title={"اطلاعات سایت"} noToggle />
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingBottom: 50 }}
      >
        <View style={{ flexDirection: "row-reverse" }}>
          <View style={Styles.card}>
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>
                اطلاعات هاست
              </Text>
            </View>
            {formState.values.loading.disk && (
              <ActivityIndicator size="large" color={Colors.primary} />
            )}
            {!formState.values.loading.disk && (
              <View style={{ paddingLeft: 20, paddingVertical: 30 }}>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    Disk usage:{" "}
                    {separate(
                      Math.round(
                        formState.values.disk.bytes_used / (1024 * 1024)
                      )
                    )}{" "}
                    MB/
                    {separate(
                      Math.round(
                        formState.values.disk.byte_limit / (1024 * 1024)
                      )
                    )}{" "}
                    MB
                  </Text>
                  <Text
                    style={{
                      fontFamily: "IranSans-UltraLight",
                      fontSize: 12,
                      color: Colors.secondText,
                      marginLeft: 20,
                    }}
                  >
                    %
                    {Math.round(
                      (formState.values.disk.bytes_used /
                        formState.values.disk.byte_limit) *
                        100
                    )}
                  </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    Main domain: {formState.values.domains.main_domain}
                  </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    Sub domains:
                  </Text>
                  {formState.values.domains.sub_domains.length != 0 ? (
                    formState.values.domains.sub_domains.map((item, index) => (
                      <Text
                        key={index}
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: Colors.text,
                        }}
                      >
                        {"   " + item}
                      </Text>
                    ))
                  ) : (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: Colors.text,
                      }}
                    >
                      {"   "}Nothing
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    Parked domains:
                  </Text>
                  {formState.values.domains.parked_domains.length != 0 ? (
                    formState.values.domains.parked_domains.map(
                      (item, index) => (
                        <Text
                          key={index}
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color: Colors.text,
                          }}
                        >
                          {"   " + item}
                        </Text>
                      )
                    )
                  ) : (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: Colors.text,
                      }}
                    >
                      {"   "}Nothing
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    Addon domains:
                  </Text>
                  {formState.values.domains.addon_domains.length != 0 ? (
                    formState.values.domains.addon_domains.map(
                      (item, index) => (
                        <Text
                          key={index}
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color: Colors.text,
                          }}
                        >
                          {"   " + item}
                        </Text>
                      )
                    )
                  ) : (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: Colors.text,
                      }}
                    >
                      {"   "}Nothing
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    Emails:
                  </Text>
                  {formState.values.emails.length != 0 ? (
                    formState.values.emails.map((item, index) => (
                      <Text
                        key={index}
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: Colors.text,
                        }}
                      >
                        {"   " + item.email}
                      </Text>
                    ))
                  ) : (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: Colors.text,
                      }}
                    >
                      {"   "}Nothing
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    FTP accounts:
                  </Text>
                  {formState.values.ftps.length != 0 ? (
                    formState.values.ftps.map((item, index) => (
                      <View key={index} style={{ flexDirection: "row" }}>
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color: Colors.text,
                          }}
                        >
                          {"   " + item.login}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "IranSans-UltraLight",
                            fontSize: 12,
                            color: Colors.secondText,
                            marginLeft: 20,
                          }}
                        >
                          {item.humandiskused}/
                          {item.humandiskquota + "   %" + item.diskusedpercent}
                        </Text>
                      </View>
                    ))
                  ) : (
                    <Text>Nothing</Text>
                  )}
                </View>
              </View>
            )}
          </View>
          <View style={Styles.card}>
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>
                وردپرس
              </Text>
            </View>
            {formState.values.loading.info && (
              <ActivityIndicator size="large" color={Colors.primary} />
            )}
            {!formState.values.loading.info && (
              <View style={{ paddingLeft: 20, paddingVertical: 30 }}>
                <FlatList
                  data={formState.values.info.themes}
                  renderItem={(item) => {
                    const isOnUpdateServer = is_on_update_server(
                      item.item.TextDomain
                    )
                    return (
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "flex-start",
                          marginBottom: 10,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {isOnUpdateServer && (
                          <Checkbox
                            value={formState.values.checked.includes(
                              item.item.TextDomain
                            )}
                            onValueChange={() => {
                              dispatchFormState({
                                type: SET_CHECKED,
                                act: formState.values.checked.includes(
                                  item.item.TextDomain
                                )
                                  ? "remove"
                                  : "add",
                                name: item.item.TextDomain,
                              })
                            }}
                          />
                        )}
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color: Colors.text,
                            marginLeft: isOnUpdateServer ? 5 : 21,
                          }}
                        >
                          {item.item.Name}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "IranSans-UltraLight",
                            fontSize: 12,
                            color: Colors.secondText,
                            marginLeft: 20,
                          }}
                        >
                          {item.item.Version}
                        </Text>
                        {item.item.update_available && (
                          <View
                            style={{
                              paddingHorizontal: 5,
                              paddingVertical: 3,
                              backgroundColor: Colors.primary,
                              borderRadius: 3,
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: 10,
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "IranSans-UltraLight",
                                fontSize: 12,
                                color: "#fff",
                              }}
                            >
                              {item.item.update_version}
                            </Text>
                          </View>
                        )}
                      </View>
                    )
                  }}
                  keyExtractor={(item) => item.TextDomain}
                />
                <FlatList
                  data={formState.values.info.plugins}
                  renderItem={(item) => {
                    const isOnUpdateServer = is_on_update_server(
                      item.item.TextDomain
                    )
                    return (
                      <View
                        style={{
                          width: "100%",
                          justifyContent: "flex-start",
                          marginBottom: 10,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {isOnUpdateServer && (
                          <Checkbox
                            value={formState.values.checked.includes(
                              item.item.TextDomain
                            )}
                            onValueChange={() => {
                              dispatchFormState({
                                type: SET_CHECKED,
                                act: formState.values.checked.includes(
                                  item.item.TextDomain
                                )
                                  ? "remove"
                                  : "add",
                                name: item.item.TextDomain,
                              })
                            }}
                          />
                        )}
                        <Text
                          style={{
                            fontFamily: "IranSans-Regular",
                            fontSize: 12,
                            color: Colors.text,
                            marginLeft: isOnUpdateServer ? 5 : 21,
                          }}
                        >
                          {item.item.Name}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "IranSans-UltraLight",
                            fontSize: 12,
                            color: Colors.secondText,
                            marginLeft: 20,
                          }}
                        >
                          {item.item.Version}
                        </Text>
                        {item.item.update_available && (
                          <View
                            style={{
                              paddingHorizontal: 5,
                              paddingVertical: 3,
                              backgroundColor: Colors.primary,
                              borderRadius: 3,
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: 10,
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "IranSans-UltraLight",
                                fontSize: 12,
                                color: "#fff",
                              }}
                            >
                              {item.item.update_version}
                            </Text>
                          </View>
                        )}
                      </View>
                    )
                  }}
                  keyExtractor={(item) => item.TextDomain}
                />
              </View>
            )}
          </View>
          <View style={Styles.card}>
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>
                عملیات
              </Text>
            </View>
            <Btn
              title="آپدیت"
              wrapperStyle={{ marginBottom: 20 }}
              onPress={() => {
                update()
              }}
              loading={formState.values.loading.update}
            />

            {formState.values.log != null &&
              Object.keys(formState.values.log.data).map((item, index) => {
                return (
                  // <Text key={index}>
                  //   {formState.values.log.data[item].success
                  //     ? item + " : " + "انجام شد"
                  //     : item + " : " + formState.values.log.data[item].error}
                  // </Text>
                  <InputNote
                    title={item}
                    content={
                      formState.values.log.data[item].success
                        ? "انجام شد"
                        : formState.values.log.data[item].error
                    }
                  />
                )
              })}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const Styles = StyleSheet.create({
  card: {
    flex: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    marginVertical: 3,
    marginHorizontal: 5,
    borderRadius: 7,
    paddingHorizontal: 10,
    minHeight: 200,
  },
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
})

export const screenOptions = {
  headerShown: false,
}

export default SiteInfoScreen
