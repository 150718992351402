import { useReducer, useCallback, useEffect } from "react";
import { ScrollView } from "react-native";
import { useSelector } from "react-redux";
import Input from "../../../components/Input";
import InputNote from "../../../components/InputNote";
import Colors from "../../../constants/Colors";
import Btn from "../../../components/Btn";
import Config from "../../../constants/Config";
import axios from "axios";
import { logOut } from "../../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FORM = "SET_FORM";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      };
    default:
      return state;
  }
};

const BusinessTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities);
  const project_id = props.route.params.project_id;
  const role = useSelector((store) => store.user.user_data.role);
  const is_customer = props.route.params.is_customer;
  const token = useSelector((store) => store.user.token);
  const setActiveIndex = props.route.params.setActiveIndex;
  const setSteps = props.route.params.setSteps;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      brand_name: "",
      org_color: "",
      hoze_faaliat: "",
      brand_description: "",
      support_phone_number: "",
      support_whatsapp_number: "",
      instagram_id: "",
      telegram_id: "",
      public_address: "",
      form: null,
      is_form_section_completed: false,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/update";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("project_id", project_id);
    formdata.append("brand_name", formState.values.brand_name);
    formdata.append("org_color", formState.values.org_color);
    formdata.append("hoze_faaliat", formState.values.hoze_faaliat);
    formdata.append("brand_description", formState.values.brand_description);
    formdata.append("support_phone_number", formState.values.support_phone_number);
    formdata.append("support_whatsapp_number", formState.values.support_whatsapp_number);
    formdata.append("instagram_id", formState.values.instagram_id);
    formdata.append("telegram_id", formState.values.telegram_id);
    formdata.append("public_address", formState.values.public_address);

    dispatchFormState({ type: SET_LOADING, loading: true });
    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        return response.data.success;
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const get_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/get?project_id=" + project_id;

    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        let is_form_section_completed = false;
        if (response.data.data.siteform_brand_name && response.data.data.siteform_hoze_faaliat && response.data.data.siteform_brand_description) {
          is_form_section_completed = true;
        }
        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: is_form_section_completed,
        });
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    get_form();
  }, []);

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote title="اطلاعات ضروری کسب و کار تکمیل شده است." icon="information-circle-outline" backgroundColor={Colors.green} textColor="#fff" />
      )}
      {is_customer && (
        <InputNote
          title="توضیح"
          content="این بخش برای نمایش اطلاعات در سایت شما استفاده می شود. برخلاف مراحل قبلی که اطلاعات «محرمانه» بودند، اطلاعات این مرحله داخل سایت شما در معرض نمایش کاربرانتان قرار می گیرند. اگر نمی دانید که دقیقا می خواهید چه چیزی را درون سایتتان نمایش دهید اصلا نگران نباشید، بعدا خودتان با استفاده از آموزش های رایگان ما می توانید اطلاعات این مراحله را در سایتتان ویرایش کنید."
          icon="information-circle-outline"
        />
      )}
      <Input
        disabled={
          (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        label="نام برند یا کسب و کار"
        id="brand_name"
        onChange={inputChangeHandler}
        insert={formState.values.form ? formState.values.form.siteform_brand_name : ""}
      />
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="رنگ سازمانی"
          id="org_color"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_org_color : ""}
        />
      )}
      {is_customer && (
        <InputNote
          content="اگر کد رنگی برای هویت بصری خود دارید وارد کنید و در غیر اینصورت نام رنگ را بنویسید برای مثال «قرمز». در صورتی که به طور کلی رنگ سازمانی ندارید، متاسفانه ضررهای هنگفتی را به برند خود تحمیل خواهید کرد. بنابراین به دپارتمان فروش پیام داده و مشاوره برای طراحی هویت بصری و لوگو دریافت کنید.(اگر ندارید کلمه «ندارم» را بنویسید)"
          icon="information-circle-outline"
        />
      )}
      <Input
        disabled={
          (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        label="حوزه فعالیت"
        id="hoze_faaliat"
        onChange={inputChangeHandler}
        insert={formState.values.form ? formState.values.form.siteform_hoze_faaliat : ""}
      />
      {is_customer && <InputNote content="برای مثال: فروش لوازم یدکی، واردات پسته و..." icon="information-circle-outline" />}
      <Input
        disabled={
          (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        multiline
        label="توضیحات درباره حوزه فعالیت"
        id="brand_description"
        onChange={inputChangeHandler}
        insert={formState.values.form ? formState.values.form.siteform_brand_description : ""}
      />
      {is_customer && (
        <InputNote
          content="اطلاعاتی در مورد کسب و کار شما ،خدمات، محصولات و هر آنچه که میخواهید با سایت به مشتری معرفی بشه برامون بنویسید."
          icon="information-circle-outline"
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="شماره پشتیبانی تلفنی"
          id="support_phone_number"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_support_phone_number : ""}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="شماره واتسپ"
          id="support_whatsapp_number"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_support_whatsapp_number : ""}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="آیدی پیج اینستاگرام"
          id="instagram_id"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_instagram_id : ""}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="آیدی پشتیبانی تلگرام"
          id="telegram_id"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_telegram_id : ""}
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="آدرس شما در سایت"
          id="public_address"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_public_address : ""}
        />
      )}
      {is_customer && <InputNote content="مثال: استان - شهر - خیابان اصلی - خیابان فرعی - کوچه محل کار شما -..." icon="information-circle-outline" />}
      <Btn
        title="ثبت اطلاعات کسب و کار"
        onPress={async () => {
          if (
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            capabilities.includes("role_site_full_access") ||
            role == "customer"
          ) {
            let result = await send_form();
            if (result) {
              await get_form();
              setSteps((prev) => {
                let prevState = prev;
                for (let i = 0; i < prevState.length; i++) {
                  if (prevState[i].name == "سلیقه یابی") {
                    prevState[i].status = "active";
                  } else {
                    prevState[i].status = "inactive";
                  }
                }
                return prevState;
              });
              props.navigation.navigate("سلیقه یابی");
              setActiveIndex(4);
            }
          } else {
            props.navigation.navigate("سلیقه یابی");
            setActiveIndex(4);
          }
        }}
        wrapperStyle={{ marginVertical: 50 }}
        loading={formState.values.loading}
      />
    </ScrollView>
  );
};

export default BusinessTab;
