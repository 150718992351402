import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import SetRecordScreen, {
  screenOptions as SetRecordScreenOptions,
} from "../screens/EAM/SetRecordScreen"
import GrantDayoffScreen, {
  screenOptions as GrantDayoffScreenOptions,
} from "../screens/EAM/GrantDayoffScreen"
import RecordsScreen, {
  screenOptions as RecordsScreenOptions,
} from "../screens/EAM/RecordsScreen"

const EAMStackNavigator = createNativeStackNavigator()
const EAMNavigator = () => {
  return (
    <EAMStackNavigator.Navigator>
      <EAMStackNavigator.Screen
        name="SetRecord"
        component={SetRecordScreen}
        options={{ ...SetRecordScreenOptions, title: "ثبت تردد" }}
      />
      <EAMStackNavigator.Screen
        name="Records"
        component={RecordsScreen}
        options={{ ...RecordsScreenOptions, title: "کنترل تردد" }}
      />
      <EAMStackNavigator.Screen
        name="GrantDayoff"
        component={GrantDayoffScreen}
        options={{ ...GrantDayoffScreenOptions, title: "ثبت مرخصی" }}
      />
    </EAMStackNavigator.Navigator>
  )
}

export default EAMNavigator
