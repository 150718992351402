import { View, Text, TouchableOpacity, StyleSheet, Image, Platform } from "react-native"
import Colors from "../constants/Colors"
import moment from "moment-jalaali"
import { Link } from "@react-navigation/native"
import { ActivityIndicator } from "react-native-web"
import { useEffect, useState } from "react"

const ProjectCard = (props) => {
  const { item } = props
  const stageColor = props.stageColor ? props.stageColor : false
  const Click = Platform.OS == "web" ? Link : TouchableOpacity

  const [color, setColor] = useState("#F2F2F2")
  const isWhite = color !== "#F2F2F2"

  useEffect(() => {
    const stage = +item.stage
    const endDate = item.end_date
    const noEffect = [9, 10, 17, 18, 24, 25, 28, 29].includes(stage)

    // Add one for the current day
    const daysPassed = moment().diff(moment.unix(endDate), "days") + 1
    // Check is timestamp bigger than current timestamp
    const isFuture = moment.now() / 1000 < endDate

    // Not paid amount and less than 7 days left
    if (stage !== 9 && stage !== 10 && daysPassed <= 7 && !isFuture && !noEffect)
      return setColor("#FF5722")

    // Not paid amount and more than 7 days left
    if (stage !== 9 && stage !== 10 && daysPassed > 7 && !noEffect) return setColor("#D32F2F")

    // Another stage
    setColor("#F2F2F2")
  }, [])

  return (
    <Click
      style={{
        width: "100%",
        // backgroundColor: "#F2F2F2",
        backgroundColor: color,
        borderRadius: 15,
        marginVertical: 5,
        paddingVertical: 15,
        direction: "ltr",
        display: "flex",
        position: "relative",
      }}
      onPress={Platform.OS == "web" ? null : props.onPress}
      to={Platform.OS != "web" ? null : props.onPress}
    >
      {/* <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 7,
          right: 7,
          flexDirection: "row-reverse",
          gap: 5,
        }}
      >
        <ActivityIndicator size="small" color={Colors.secondText} />

        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 13,
            color: Colors.secondText,
          }}
        >
          درحال راه اندازی سایت{" "}
        </Text>
      </View> */}

      <View style={{ width: "100%", flexDirection: "row-reverse" }}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            style={{
              transform: [{ rotate: "-90deg" }],
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color: isWhite ? "#FFFFFF" : Colors.secondText,
            }}
          >
            #{item.id}
          </Text>
        </View>
        <View style={{ flex: 6, justifyContent: "center", alignItems: "flex-end" }}>
          <Text
            style={{
              color: isWhite ? "#FFFFFF" : Colors.text,
              fontFamily: "Iransans-Medium",
              fontSize: item.customer_name?.length > 20 ? 12 : 16,
              marginVertical: 5,
            }}
          >
            {item.customer_name}
          </Text>
          <Text
            style={{
              color: isWhite ? "#FFFFFF" : Colors.text,
              fontFamily: "Iransans-Light",
              fontSize: item.package?.length > 22 ? 10 : 14,
              marginVertical: 5,
            }}
          >
            {item.package}
          </Text>
        </View>
        <View
          style={{
            flex: 2,
            paddingLeft: 15,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <View style={Styles(isWhite).LeftSectionsContainer}>
            <View
              style={{
                backgroundColor: isWhite ? "#FFFFFF" : stageColor ? stageColor : Colors.primary,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 2,
                borderRadius: 3,
                width: 20,
                marginLeft: 5,
              }}
            >
              <Text style={Styles(isWhite, color).dateTitle}>شروع</Text>
            </View>
            <Text style={Styles(isWhite).date}>
              {item.start_date != null
                ? moment.unix(item.start_date).format("jYYYY/jM/jD")
                : "بدون تایم"}
            </Text>
          </View>
          <View style={Styles(isWhite).LeftSectionsContainer}>
            <View
              style={{
                backgroundColor: isWhite ? "#FFFFFF" : stageColor ? stageColor : Colors.primary,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 2,
                borderRadius: 3,
                width: 20,
                marginLeft: 5,
              }}
            >
              <Text style={Styles(isWhite, color).dateTitle}>پایان</Text>
            </View>
            <Text style={Styles(isWhite).date}>
              {item.end_date != null
                ? moment.unix(item.end_date).format("jYYYY/jM/jD")
                : "بدون تایم"}
            </Text>
          </View>
          <View style={Styles(isWhite).LeftSectionsContainer_staffs}>
            {item.staffs?.length == 1 &&
              item.staffs.map((item, index) => (
                <View key={index} style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={{ uri: item.avatar }}
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 12,
                      marginRight: 3,
                    }}
                  />
                  <Text
                    style={{
                      fontFamily: "IranSans-Light",
                      fontSize: 12,
                      color: isWhite ? "#FFFFFF" : Colors.text,
                    }}
                  >
                    {item.name}
                  </Text>
                </View>
              ))}
            {item.staffs?.length > 1 &&
              item.staffs.map((item, index) => (
                <View key={index} style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={{ uri: item.avatar }}
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 12,
                      marginRight: 3,
                    }}
                  />
                </View>
              ))}
            {item.staffs?.length == 0 && (
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Light",
                    color: Colors.primary,
                    fontSize: 12,
                  }}
                >
                  بدون پرسنل
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </Click>
  )
}

const Styles = (isWhite, bgColor) =>
  StyleSheet.create({
    dateTitle: {
      color: isWhite ? bgColor : "#fff",
      fontFamily: "IranSans-Light",
      fontSize: 8,
    },
    LeftSectionsContainer: {
      width: "100%",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      marginBottom: 5,
    },
    LeftSectionsContainer_staffs: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginBottom: 5,
    },
    date: {
      color: isWhite ? "#FFFFFF" : Colors.text,
      fontFamily: "IranSans-Light",
      fontSize: 12,
    },
  })

export default ProjectCard
