import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import PostsListScreen, { screenOptions as PostsListScreenOptions } from "../screens/Website/blog/PostsListScreen";
import AddPostScreen, { screenOptions as AddPostScreenOptions } from "../screens/Website/blog/AddPostScreen";
import MediaScreen, { screenOptions as MediaScreenOptions } from "../screens/Website/blog/MediaScreen";
import CategoriesScreen, { screenOptions as CategoriesScreenOptions } from "../screens/Website/blog/CategoriesScreen";
import AddPortfolioScreen, { screenOptions as AddPortfolioScreenOptions } from "../screens/Website/portfolio/AddPortfolioScreen";
import PortfolioListScreen, { screenOptions as PortfolioListScreenOptions } from "../screens/Website/portfolio/PortfolioListScreen";
import PortfolioListEnScreen, { screenOptions as PortfolioListEnScreenOptions } from "../screens/Website/portfolio/PortfolioListEnScreen";

const WebsiteStackNavigator = createNativeStackNavigator();
const WebsiteNavigator = () => {
  return (
    <WebsiteStackNavigator.Navigator>
      <WebsiteStackNavigator.Screen name="PostsList" component={PostsListScreen} options={{ ...PostsListScreenOptions, title: "مدیریت فایل ها" }} />
      <WebsiteStackNavigator.Screen name="Media" component={MediaScreen} options={{ ...MediaScreenOptions, title: "لیست مطالب بلاگ" }} />
      <WebsiteStackNavigator.Screen
        name="AddPost"
        component={AddPostScreen}
        options={{ ...AddPostScreenOptions, title: "افزودن مطلب بلاگ" }}
        initialParams={{ post_id: false }}
      />
      <WebsiteStackNavigator.Screen
        name="PortfolioList"
        component={PortfolioListScreen}
        options={{ ...PortfolioListScreenOptions, title: "لیست نمونه کارها" }}
      />
      <WebsiteStackNavigator.Screen
        name="PortfolioListEn"
        component={PortfolioListEnScreen}
        options={{ ...PortfolioListEnScreenOptions, title: "لیست نمونه کارهای انگلیسی" }}
      />
      <WebsiteStackNavigator.Screen
        name="AddPortfolio"
        component={AddPortfolioScreen}
        options={{ ...AddPortfolioScreenOptions, title: "افزودن نمونه کار" }}
        initialParams={{ portfolio_id: false }}
      />
      <WebsiteStackNavigator.Screen
        name="Categories"
        component={CategoriesScreen}
        options={{ ...CategoriesScreenOptions, title: "دسته بندی های بلاگ" }}
      />
    </WebsiteStackNavigator.Navigator>
  );
};

export default WebsiteNavigator;
