import { useReducer, useEffect, useCallback } from "react"
import { View, ActivityIndicator, Dimensions, ScrollView } from "react-native"
import Colors from "../../../constants/Colors"
import Config from "../../../constants/Config"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Header from "../../../components/Header"
import Btn from "../../../components/Btn"
import File from "../../../components/File"
import Picker from "../../../components/Picker"
import Input from "../../../components/Input"
import { CommonActions } from "@react-navigation/native"
import FancySelector from "../../../components/FancySelector"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_SEND_LOADING = "SET_SEND_LOADING"
const SET_POST = "SET_POST"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SEND_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          send_loading: action.loading,
        },
      }
    case SET_POST:
      let category =
        action.data["category_id"] == 1
          ? { item: "سایت", id: "site" }
          : { item: "لوگو", id: "logo" }
      return {
        ...state,
        values: {
          ...state.values,
          portfolio: action.data,
          category: category,
        },
      }

    default:
      return state
  }
}

const AddPortfolioScreen = (props) => {
  const portfolio_id = props.route.params.portfolio_id
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      portfolio: null,
      category: null,
      title: null,
      slug: null,
      customer_field: null,
      customer_location: null,
      send_loading: false,
      thumbnail: null,
      site_desktop_img: null,
      site_mobile_img: null,
      site_url: null,
      about_brand: null,
      before_after_sketch: null,
      before_after_final: null,
      brand_color_1: null,
      brand_color_2: null,
      brand_color_3: null,
      brand_color_4: null,
      colorability_img_1: null,
      colorability_img_2: null,
      colorability_img_3: null,
      scalability_img: null,
      realworld_img_1: null,
      realworld_img_2: null,
      lang: "fa",
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_post = async (id) => {
    let url = Config.aioUrl + "/website/portfolio/post/get?id=" + id

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        if (!response.data.success) return alert(response.data.msg)

        dispatchFormState({ type: SET_POST, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const set_post = async (status) => {
    if (!portfolio_id) {
      if (
        !formState.values.category ||
        !formState.values.title ||
        !formState.values.slug ||
        !formState.values.customer_field ||
        !formState.values.customer_location ||
        !formState.values.thumbnail
      ) {
        alert("وارد کردن فیلدها اجباریست.")
        return
      }

      if (
        formState.values.category.id == "site" &&
        (!formState.values.site_desktop_img ||
          !formState.values.site_mobile_img ||
          !formState.values.site_url)
      ) {
        alert("وارد کردن فیلدها اجباریست.")
        return
      }

      if (
        formState.values.category.id == "logo" &&
        (!formState.values.about_brand ||
          !formState.values.before_after_sketch ||
          !formState.values.before_after_final ||
          !formState.values.brand_color_1 ||
          !formState.values.brand_color_2 ||
          !formState.values.colorability_img_1 ||
          !formState.values.colorability_img_2 ||
          !formState.values.colorability_img_3 ||
          !formState.values.scalability_img ||
          !formState.values.realworld_img_1 ||
          !formState.values.realworld_img_2)
      ) {
        alert("وارد کردن فیلدها اجباریست.")
        return
      }
    } else {
      if (
        !formState.values.title ||
        !formState.values.slug ||
        !formState.values.customer_field ||
        !formState.values.customer_location
      ) {
        alert("وارد کردن فیلدها اجباریست.")
        return
      }

      if (formState.values.category.id == "site" && !formState.values.site_url) {
        alert("وارد کردن فیلدها اجباریست.")
        return
      }

      if (
        formState.values.category.id == "logo" &&
        (!formState.values.about_brand ||
          !formState.values.brand_color_1 ||
          !formState.values.brand_color_2)
      ) {
        alert("وارد کردن فیلدها اجباریست.")
        return
      }
    }

    const url = Config.aioUrl + "/website/portfolio/post/set"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    if (portfolio_id) formdata.append("portfolio_id", portfolio_id)
    formdata.append("category", formState.values.category.id)
    formdata.append("lang", formState.values.lang[0])
    formdata.append("title", formState.values.title)
    formdata.append("slug", formState.values.slug)
    formdata.append("customer_field", formState.values.customer_field)
    formdata.append("customer_location", formState.values.customer_location)
    if (status) formdata.append("status", status)
    if (formState.values.thumbnail)
      formdata.append("thumbnail", formState.values.thumbnail.file, formState.values.thumbnail.name)

    if (formState.values.category.id == "site") {
      if (formState.values.site_desktop_img)
        formdata.append(
          "site_desktop_img",
          formState.values.site_desktop_img.file,
          formState.values.site_desktop_img.name
        )
      if (formState.values.site_mobile_img)
        formdata.append(
          "site_mobile_img",
          formState.values.site_mobile_img.file,
          formState.values.site_mobile_img.name
        )
      formdata.append("site_url", formState.values.site_url)
    }

    if (formState.values.category.id == "logo") {
      formdata.append("about_brand", formState.values.about_brand)
      if (formState.values.before_after_sketch)
        formdata.append(
          "before_after_sketch",
          formState.values.before_after_sketch.file,
          formState.values.before_after_sketch.name
        )
      if (formState.values.before_after_final)
        formdata.append(
          "before_after_final",
          formState.values.before_after_final.file,
          formState.values.before_after_final.name
        )
      formdata.append("brand_color_1", formState.values.brand_color_1)
      formdata.append("brand_color_2", formState.values.brand_color_2)
      if (formState.values.brand_color_3)
        formdata.append("brand_color_3", formState.values.brand_color_3)
      if (formState.values.brand_color_4)
        formdata.append("brand_color_4", formState.values.brand_color_4)
      if (formState.values.colorability_img_1)
        formdata.append(
          "colorability_img_1",
          formState.values.colorability_img_1.file,
          formState.values.colorability_img_1.name
        )
      if (formState.values.colorability_img_2)
        formdata.append(
          "colorability_img_2",
          formState.values.colorability_img_2.file,
          formState.values.colorability_img_2.name
        )
      if (formState.values.colorability_img_3)
        formdata.append(
          "colorability_img_3",
          formState.values.colorability_img_3.file,
          formState.values.colorability_img_3.name
        )
      if (formState.values.scalability_img)
        formdata.append(
          "scalability_img",
          formState.values.scalability_img.file,
          formState.values.scalability_img.name
        )
      if (formState.values.realworld_img_1)
        formdata.append(
          "realworld_img_1",
          formState.values.realworld_img_1.file,
          formState.values.realworld_img_1.name
        )
      if (formState.values.realworld_img_2)
        formdata.append(
          "realworld_img_2",
          formState.values.realworld_img_2.file,
          formState.values.realworld_img_2.name
        )
    }

    dispatchFormState({ type: SET_SEND_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        alert(response.data.msg)
        dispatchFormState({ type: SET_SEND_LOADING, loading: false })
        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: !portfolio_id ? "AddPortfolio" : "PortfolioList",
                },
              ],
            })
          )
        }
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_SEND_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      if (portfolio_id) {
        get_post(portfolio_id)
      }
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header
        title={portfolio_id ? "ویرایش نمونه کار" : "افزودن نمونه کار"}
        noBack={portfolio_id ? false : true}
      />
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
      {!formState.values.loading &&
        ((formState.values.portfolio && portfolio_id) || !portfolio_id) && (
          <View style={{ flex: 1, height: "100%", width: "100%" }}>
            <View style={{ flex: 1 }}>
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  flex: 1,
                  height: "100%",
                  paddingHorizontal: DESKTOP ? "20%" : 0,
                  paddingBottom: 30,
                }}
              >
                <FancySelector
                  column={2}
                  id="lang"
                  title="زبان نمونه کار"
                  options={[
                    { item: "فارسی", id: "fa" },
                    { item: "انگلیسی", id: "en" },
                  ]}
                  onChange={inputChangeHandler}
                  single
                  insert={portfolio_id ? formState.values.portfolio.lang : "fa"}
                />
                {!portfolio_id && (
                  <Picker
                    label="دسته بندی"
                    id="category"
                    onChange={inputChangeHandler}
                    options={[
                      { item: "لوگو", id: "logo" },
                      { item: "سایت", id: "site" },
                    ]}
                  />
                )}
                {formState.values.category && formState.values.category.id == "site" && (
                  <Input
                    label="آدرس سایت"
                    id="site_url"
                    onChange={inputChangeHandler}
                    insert={portfolio_id ? formState.values.portfolio.metas.site_url : false}
                  />
                )}
                <Input
                  label="عنوان"
                  id="title"
                  onChange={inputChangeHandler}
                  insert={portfolio_id ? formState.values.portfolio.title : false}
                />
                <Input
                  label="نامک"
                  id="slug"
                  onChange={inputChangeHandler}
                  insert={portfolio_id ? formState.values.portfolio.slug : false}
                />
                <Input
                  label="حوزه کاری"
                  id="customer_field"
                  onChange={inputChangeHandler}
                  insert={portfolio_id ? formState.values.portfolio.customer_field : false}
                />
                <Input
                  label="شهر"
                  id="customer_location"
                  onChange={inputChangeHandler}
                  insert={portfolio_id ? formState.values.portfolio.customer_location : false}
                />

                <File
                  id="thumbnail"
                  title="تصویر اصلی - 640x480"
                  extension="jpg"
                  content_type="image/jpeg"
                  required={true}
                  value={formState.values.thumbnail}
                  onChange={inputChangeHandler}
                  max_size={150}
                  max_width={640}
                  max_height={480}
                  insert={portfolio_id ? true : false}
                  noDownload={true}
                />

                {formState.values.category && formState.values.category.id == "site" && (
                  <View>
                    <File
                      id="site_desktop_img"
                      title="تصویر سایت - دسکتاپ"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.site_desktop_img}
                      onChange={inputChangeHandler}
                      max_size={6144}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="site_mobile_img"
                      title="تصویر سایت - موبایل"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.site_mobile_img}
                      onChange={inputChangeHandler}
                      max_size={6144}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                  </View>
                )}

                {formState.values.category && formState.values.category.id == "logo" && (
                  <View>
                    <Input
                      label="درباره برند"
                      id="about_brand"
                      onChange={inputChangeHandler}
                      insert={portfolio_id ? formState.values.portfolio.metas.about_brand : false}
                    />
                    <File
                      id="before_after_sketch"
                      title="قبل و بعد - اسکچ"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.before_after_sketch}
                      onChange={inputChangeHandler}
                      max_size={120}
                      max_width={780}
                      max_height={450}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="before_after_final"
                      title="قبل و بعد - اجرا"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.before_after_sketch}
                      onChange={inputChangeHandler}
                      max_size={120}
                      max_width={780}
                      max_height={450}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <View
                      style={{
                        flexDirection: "row-reverse",
                        columnGap: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Input
                          label="رنگ برند ۱"
                          id="brand_color_1"
                          onChange={inputChangeHandler}
                          insert={
                            portfolio_id
                              ? formState.values.portfolio.metas.brand_color_1
                                ? formState.values.portfolio.metas.brand_color_1.replace("#", "")
                                : false
                              : false
                          }
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Input
                          label="رنگ برند ۲"
                          id="brand_color_2"
                          onChange={inputChangeHandler}
                          insert={
                            portfolio_id
                              ? formState.values.portfolio.metas.brand_color_2
                                ? formState.values.portfolio.metas.brand_color_2.replace("#", "")
                                : false
                              : false
                          }
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row-reverse",
                        columnGap: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Input
                          label="رنگ برند ۳"
                          id="brand_color_3"
                          onChange={inputChangeHandler}
                          insert={
                            portfolio_id
                              ? formState.values.portfolio.metas.brand_color_3
                                ? formState.values.portfolio.metas.brand_color_3.replace("#", "")
                                : false
                              : false
                          }
                        />
                      </View>
                      <View style={{ flex: 1 }}>
                        <Input
                          label="رنگ برند ۴"
                          id="brand_color_4"
                          onChange={inputChangeHandler}
                          insert={
                            portfolio_id
                              ? formState.values.portfolio.metas.brand_color_4
                                ? formState.values.portfolio.metas.brand_color_4.replace("#", "")
                                : false
                              : false
                          }
                        />
                      </View>
                    </View>

                    <File
                      id="colorability_img_1"
                      title="تصویر رنگپذیری۱"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.colorability_img_1}
                      onChange={inputChangeHandler}
                      max_size={90}
                      max_width={500}
                      max_height={500}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="colorability_img_2"
                      title="تصویر رنگپذیری۲"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.colorability_img_2}
                      onChange={inputChangeHandler}
                      max_size={90}
                      max_width={500}
                      max_height={500}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="colorability_img_3"
                      title="تصویر رنگپذیری۳"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.colorability_img_3}
                      onChange={inputChangeHandler}
                      max_size={90}
                      max_width={500}
                      max_height={500}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="scalability_img"
                      title="تصویر مقیاس پذیری"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.scalability_img}
                      onChange={inputChangeHandler}
                      max_size={70}
                      max_width={300}
                      max_height={300}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="realworld_img_1"
                      title="ماکاپ۱ - 550x360"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.realworld_img_1}
                      onChange={inputChangeHandler}
                      max_size={120}
                      max_width={780}
                      max_height={450}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                    <File
                      id="realworld_img_2"
                      title="ماکاپ۲ - 550x360"
                      extension="jpg"
                      content_type="image/jpeg"
                      required={true}
                      value={formState.values.realworld_img_2}
                      onChange={inputChangeHandler}
                      max_size={120}
                      max_width={780}
                      max_height={450}
                      insert={portfolio_id ? true : false}
                      noDownload={true}
                    />
                  </View>
                )}

                <View
                  style={{ flexDirection: "row", justifyContent: "space-between", columnGap: 10 }}
                >
                  <Btn
                    title={portfolio_id ? "ثبت تغییرات" : "ثبت و انتشار"}
                    onPress={() => {
                      set_post("published")
                    }}
                    loading={formState.values.send_loading}
                    wrapperStyle={{ flex: 1 }}
                    titleStyle={{ fontSize: DESKTOP ? 16 : 12 }}
                  />
                  <Btn
                    title={"ذخیره بعنوان پیش نویس"}
                    color={Colors.secondText}
                    onPress={() => {
                      set_post("pending")
                    }}
                    loading={formState.values.send_loading}
                    wrapperStyle={{ flex: 1 }}
                    titleStyle={{ fontSize: DESKTOP ? 16 : 12 }}
                  />
                </View>
              </ScrollView>
            </View>
          </View>
        )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default AddPortfolioScreen
