import React, { useEffect, useReducer } from "react"
import {
  ActivityIndicator,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native"
import Colors, { randomRGB } from "../../constants/Colors"
import Header from "../../components/Header"
import Table, { Cell, Row, TBody } from "../../components/Table"
import Config from "../../constants/Config"
import axios from "axios"
import { logOut } from "../../components/Functions"
import { useSelector } from "react-redux"
import moment from "moment-jalaali"
import StatBoxChartLine from "../../components/StatBoxChartLine"

const FilterGroupParent = ({ children }) => {
  return (
    <View
      style={{
        height: 45,
        marginBottom: 20,
        alignItems: "flex-end",
        maxWidth: "100%",
        paddingLeft: 20,
      }}
    >
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ gap: 20 }}
        style={{ flexDirection: "row-reverse", width: "100%" }}
      >
        {children}
      </ScrollView>
    </View>
  )
}

const FilterGroup = ({ title, dispatchFormState, list, active_value, id }) => {
  return (
    <View
      style={{
        flexDirection: "row-reverse",
        alignItems: "center",
        height: 40,
      }}
    >
      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontSize: 12,
          color: Colors.text,
        }}
      >
        {title}:{" "}
      </Text>

      {list.map((item) => (
        <TouchableOpacity
          key={item.id}
          style={{
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 10,
            backgroundColor: active_value == item.id ? Colors.blue : "#F2F2F2",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 5,
          }}
          onPress={() => {
            dispatchFormState({
              type: SET_ACTIVE_FILTER,
              id: id,
              value: item.id,
            })
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 12,
              color: active_value == item.id ? "#fff" : Colors.text,
            }}
          >
            {item.title}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  )
}

const SET_LOADING = "SET_LOADING"
const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER"
const SET_MEDIUMS = "SET_MEDIUMS"
const SET_DATA = "SET_DATA"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }

    case SET_ACTIVE_FILTER: {
      if (action.id == "active_medium_id") {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.id]: action.value,
          },
        }
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: action.value,

          active_medium_id: "all",
        },
      }
    }

    case SET_MEDIUMS: {
      const list = [{ id: "all", title: "همه" }, ...action.value]

      const mappedList = list.map((item) => {
        let title = item.title?.toUpperCase()

        return {
          ...item,
          title: title
            .replace("DM", "دایرکت")
            .replace("PAID-AD", "پولی")
            .replace("JARYAN", "جریان")
            .replace("UNDEFINED", "سایر")
            .replace("DIRECT", "مستقیم")
            .replace("CHANNEL", "چنل")
            .replace("SEO", "سئو")
            .replace("LANDING", "لندینگ")
            .replace("CALL", "تماس")
            .replace("DEFAULT", "پیشفرض")
            .replace("CPC", "کلیکی"),
        }
      })

      return {
        ...state,
        filters: {
          ...state.filters,
          mediums: mappedList,
        },
      }
    }

    case SET_DATA: {
      if (action.id === "site") {
        return {
          ...state,
          values: {
            ...state.values,
            siteRepots: action.value,
          },
        }
      }

      if (action.id === "sales") {
        return {
          ...state,
          values: {
            ...state.values,
            saleRepots: action.value,
          },
        }
      }

      if (action.id === "graphic") {
        return {
          ...state,
          values: {
            ...state.values,
            graphicRepots: action.value,
          },
        }
      }

      if (action.id === "all") {
        return {
          ...state,
          values: {
            ...state.values,
            allDepartmentsRepots: action.value,
          },
        }
      }

      return state
    }

    default:
      return state
  }
}

export default function CompanyReportsScreen() {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      allDepartmentsRepots: null,
      siteRepots: null,
      graphicRepots: null,
      saleRepots: null,
    },

    filters: {
      departments: [
        { id: "all", title: "کل شرکت" },
        { id: "site", title: "دپارتمان سایت" },
        { id: "graphic", title: "دپارتمان گرافیک" },
        { id: "sales", title: "دپارتمان فروش" },
      ],
      active_department_id: "all",

      display_types: [
        { id: "table", title: "جدول" },
        { id: "chart", title: "نمودار" },
      ],
      active_display_type_id: "table",

      services: [
        { id: "all", title: "همه" },
        { id: "logo", title: "لوگو" },
        { id: "site", title: "سایت" },
        // { id: "app", title: "اپلیکیشن" },
        // { id: "course", title: "دوره" },
        // { id: "other", title: "موارد دیگر" },
      ],
      active_service_id: "all",

      sources: [
        { id: "all", title: "همه" },
        { id: "instagram", title: "اینستاگرام" },
        { id: "telegram", title: "تلگرام" },
        { id: "landing", title: "لندینگ سایت" },
        { id: "google", title: "گوگل" },
        { id: "wom", title: "معرفی" },
      ],
      active_source_id: "all",

      mediums: [{ id: "all", title: "همه" }],
      active_medium_id: "all",
    },
  })

  const token = useSelector((store) => store.user.token)

  const getReports = (type, config) => {
    let url = Config.aioUrl + "/analyse/get-main?type=" + type

    if (config?.source) {
      url += `&source=${config.source}`
    }

    if (config?.medium) {
      url += `&medium=${config.medium}`
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios
      .get(url, {
        headers: headers,
      })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_DATA,
          id: type,
          value: response.data.data,
        })

        if (type === "sales") {
          dispatchFormState({
            type: SET_MEDIUMS,
            id: type,
            value: response.data.mediums,
          })
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch((error) => {
        alert("در دریافت اطلاعات خطایی رخ داده است!")
        console.log(error)
      })
  }

  useEffect(() => {
    if (formState.filters.active_department_id) {
      let config = false

      if (formState.filters.active_department_id === "sales") {
        config = {
          source: formState.filters.active_source_id,
          medium: formState.filters.active_medium_id,
        }
      }

      getReports(formState.filters.active_department_id, config)
    }
  }, [
    formState.filters.active_department_id,
    formState.filters.active_source_id,
    formState.filters.active_medium_id,
  ])

  const unixToDate = (arr = []) => {
    const value = arr.map((arrItem) =>
      moment.unix(arrItem).format("jMMMM jYYYY")
    )

    return value
  }

  const getValueFromObject = (object, property, nextProperty) => {
    // Get object keys
    let objectKeys = Object.keys(object)

    let valuesArr = []

    // Get target property from object and push it to list
    objectKeys.forEach((arrItem) => {
      const value = nextProperty
        ? object[arrItem]?.[property]?.[nextProperty]
        : object[arrItem]?.[property]

      const pushData = Number(value) ? Math.floor(value) : value

      valuesArr.push(pushData)
    })

    return valuesArr
  }

  const getFromTargetProperty = (
    targetChartValues = [],
    getValueFrom,
    targetProperty, // That value you want to be main property
    targetPush // That value you want to push in main property array
  ) => {
    const dataList = getValueFromObject(targetChartValues, getValueFrom)

    // A array with some name as property with array data
    const newDatasSortByTargetProperty = {}

    dataList.forEach((value) => {
      // If true, we have data and is not empty (If be empty, backend send me array intead of object)
      if (!Array.isArray(value) && value) {
        // For on object to get values
        Object.keys(value).forEach((item) => {
          // Main user datas
          const targetValueDate = value?.[item]
          const sortedDataProperty = targetValueDate?.[targetProperty]
          const isPropertyExist =
            newDatasSortByTargetProperty?.[sortedDataProperty]

          // If this user does not exist, create user without data
          if (!isPropertyExist) {
            newDatasSortByTargetProperty[sortedDataProperty] = []
          }

          // Push cost to user special array
          newDatasSortByTargetProperty[sortedDataProperty].push(
            targetValueDate?.[targetPush]
          )
        })
      }
    })

    const finallyData = newDatasSortByTargetProperty

    let itemName, itemValue

    Object.entries({ ...newDatasSortByTargetProperty }).map((item) => {
      // Object key
      itemName = item[0]

      // Object value
      itemValue = item[1]

      // Is every item zero
      const isEveryZero = itemValue.every((value) => value == 0)

      if (isEveryZero) {
        // Delete item
        delete finallyData[itemName]
      }
    })

    // return a object with sortedDataPropertys property, and each user has a array with datas
    // return newDatasSortByTargetProperty
    return finallyData
  }

  const getAverageNormallListAndRemoveZero = (
    object,
    property,
    subProperty
  ) => {
    const list = getValueFromObject(object, property)

    let keys = []

    list.forEach((listItem, index) => {
      // All of this object keys (Key is userID)
      keys = Object.keys(listItem)

      // Loop in userIDs and check is everymonthes this user zero
      keys.forEach((key) => {
        // Check is everymonthes this user zero
        const isEveryZero = list.every(
          (listItem) => listItem?.[key]?.[subProperty] == 0
        )

        // Delete target
        if (isEveryZero) {
          list.map((listItem) => {
            delete listItem?.[key]
          })
        }
      })
    })

    return list
  }

  const getAverage = (object, property, subProperty) => {
    const list = getAverageNormallListAndRemoveZero(
      object,
      property,
      subProperty
    )

    const listAverage = []

    list.forEach((item, index) => {
      const entriesArray = Object.entries(item)

      let sum = 0
      entriesArray.forEach((item) => (sum += Number(item[1]?.[subProperty])))

      // ! LOG
      console.log(`ITEM ${property} IS: ${sum} / ${entriesArray.length}`)

      const average = sum / entriesArray.length

      // Add to list
      listAverage.push(average)
    })

    return listAverage
  }

  const targetChartValues =
    formState.filters.active_department_id === "all"
      ? formState.values.allDepartmentsRepots
      : formState.filters.active_department_id === "site"
      ? formState.values.siteRepots
      : formState.filters.active_department_id === "graphic"
      ? formState.values.graphicRepots
      : formState.filters.active_department_id === "sales"
      ? formState.values.saleRepots
      : false

  const isDisplayChart = formState.filters.active_display_type_id === "chart"
  const isDisplayTable = formState.filters.active_display_type_id === "table"

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="گزارشات" noBack />
      <FilterGroupParent>
        <FilterGroup
          title="نوع نمایش"
          id="active_display_type_id"
          list={formState.filters.display_types}
          active_value={formState.filters.active_display_type_id}
          dispatchFormState={dispatchFormState}
        />

        <FilterGroup
          title="دپارتمان"
          id="active_department_id"
          list={formState.filters.departments}
          active_value={formState.filters.active_department_id}
          dispatchFormState={dispatchFormState}
        />
      </FilterGroupParent>

      {formState.filters.active_department_id == "sales" && (
        <FilterGroupParent>
          <FilterGroup
            title="مدیوم"
            id="active_medium_id"
            list={formState.filters.mediums}
            active_value={formState.filters.active_medium_id}
            dispatchFormState={dispatchFormState}
          />

          <FilterGroup
            title="سورس"
            id="active_source_id"
            list={formState.filters.sources}
            active_value={formState.filters.active_source_id}
            dispatchFormState={dispatchFormState}
          />
        </FilterGroupParent>
      )}

      {formState.values.loading && (
        <View
          style={{
            width: "100%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 16,
              color: Colors.text,
              marginTop: 20,
            }}
          >
            در حال بارگذاری اطلاعات...
          </Text>
        </View>
      )}

      {!formState.values.loading && (
        <View
          style={{
            width: "100%",
            flex: 1,
            marginBottom: 20,
          }}
        >
          {isDisplayTable && (
            <>
              {formState.filters.active_department_id == "all" &&
                formState.values.allDepartmentsRepots && (
                  <Table>
                    <Row>
                      <Cell header>تاریخ</Cell>
                      <Cell header>جمع ورودی</Cell>
                      <Cell header>جمع خروجی</Cell>
                      <Cell header>حقوق خالص</Cell>
                      <Cell header>بیمه</Cell>
                      <Cell header>پرسنل</Cell>
                      <Cell header>تعداد بیعانه</Cell>
                      <Cell header>جمع مبلغ بیعانه</Cell>
                      <Cell header>تعداد تسویه ها</Cell>
                      <Cell header>مبلغ تسویه ها</Cell>

                      <Cell header>مبلغ تبلیغات</Cell>
                      <Cell header>cac سه ماهه</Cell>
                      <Cell header>نرخ ورودی</Cell>
                      <Cell header>سود</Cell>
                      <Cell header>نرخ ورودی به حقوق ناخالص</Cell>
                      <Cell header>میانگین حقوق خالص</Cell>
                      <Cell header>ورودی هر پرسنل</Cell>
                      <Cell header>سود پرسنل</Cell>
                    </Row>

                    <TBody>
                      {Object.keys(formState.values.allDepartmentsRepots)
                        .slice()
                        .reverse()
                        .map((target, index) => {
                          const itemData =
                            formState.values.allDepartmentsRepots[target]

                          return (
                            <Row index={index} key={target}>
                              <Cell>
                                {moment.unix(target).format("jMMMM jYYYY")}
                              </Cell>
                              <Cell>
                                {itemData.incomeSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.outcomeSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.netSalary?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.insurance.toLocaleString()}</Cell>
                              <Cell>{itemData.employee.length}</Cell>
                              <Cell>{itemData.depositCount}</Cell>
                              <Cell>
                                {itemData.depositSum?.toLocaleString()} تومان
                              </Cell>

                              <Cell>{itemData.checkouts}</Cell>
                              <Cell>
                                {itemData.checkoutSum?.toLocaleString()} تومان
                              </Cell>

                              <Cell>
                                {itemData.adSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {Math.floor(itemData.cac)?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>
                                {itemData.incomeRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.profit?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.incomeGrossRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {Math.floor(
                                  itemData.medNetSalary
                                )?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>
                                {itemData.employeeIncome?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>
                                {itemData.employeeProfit?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                            </Row>
                          )
                        })}
                    </TBody>
                  </Table>
                )}

              {formState.filters.active_department_id == "site" &&
                formState.values.siteRepots && (
                  <Table>
                    <Row>
                      <Cell header>تاریخ</Cell>
                      <Cell header>جمع ورودی</Cell>
                      <Cell header>جمع خروجی</Cell>
                      <Cell header>حقوق خالص</Cell>
                      <Cell header>بیمه</Cell>
                      <Cell header>پرسنل</Cell>
                      <Cell header>تعداد بیعانه</Cell>
                      <Cell header>جمع مبلغ بیعانه</Cell>
                      <Cell header>میانگین حقوق خالص</Cell>
                      <Cell header>تعداد تسویه ها</Cell>
                      <Cell header>مبلغ تسویه ها</Cell>
                      <Cell header>مجموع پروژه تسویه ها</Cell>
                      <Cell header>تعداد تغییرات</Cell>
                      <Cell header>جمع مبلغ تغییرات</Cell>

                      <Cell header>تعداد فروشگاهی وی آی پی</Cell>
                      <Cell header>میانگین مبلغ فروشگاهی وی آی پی</Cell>
                      <Cell header>تعداد فروشگاهی فوق حرفه ای</Cell>
                      <Cell header>میانگین مبلغ فروشگاهی فوق حرفه ای</Cell>
                      <Cell header>تعداد فروشگاهی حرفه ای</Cell>
                      <Cell header>میانگین مبلغ فروشگاهی حرفه ای</Cell>

                      <Cell header>تعداد شرکتی وی آی پی</Cell>
                      <Cell header>میانگین مبلغ شرکتی وی آی پی</Cell>
                      <Cell header>تعداد شرکتی فوق حرفه ای</Cell>
                      <Cell header>میانگین مبلغ شرکتی فوق حرفه ای</Cell>
                      <Cell header>تعداد شرکتی حرفه ای</Cell>
                      <Cell header>میانگین مبلغ شرکتی حرفه ای</Cell>

                      <Cell header>سود</Cell>
                      <Cell header>نرخ ورودی</Cell>
                      <Cell header>نرخ ورودی حقوق ناخالص</Cell>
                      <Cell header>ورودی پرسنل</Cell>
                      <Cell header>سود پرسنل</Cell>
                      <Cell header>میانگین تعداد تسویه</Cell>
                      <Cell header>میانگین مبلغ تغییرات</Cell>
                    </Row>

                    <TBody>
                      {Object.keys(formState.values.siteRepots)
                        .slice()
                        .reverse()
                        .map((target, index) => {
                          const itemData = formState.values.siteRepots[target]

                          return (
                            <Row index={index} key={target}>
                              <Cell>
                                {moment.unix(target).format("jMMMM jYYYY")}
                              </Cell>
                              <Cell>
                                {itemData.incomeSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.outcomeSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.netSalary?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.insurance.toLocaleString()}</Cell>
                              <Cell>{itemData.employee.length}</Cell>
                              <Cell>{itemData.depositCount}</Cell>
                              <Cell>
                                {itemData.depositSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.medNetSalary?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.checkouts}</Cell>
                              <Cell>
                                {itemData.checkoutSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.projectCheckoutSum}
                              </Cell>
                              <Cell>{itemData.changeCount}</Cell>
                              <Cell>
                                {itemData.changeSum?.toLocaleString()} تومان
                              </Cell>

                              <Cell>{itemData.vip.count}</Cell>
                              <Cell>
                                {itemData.vip.med?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.fogh.count}</Cell>
                              <Cell>
                                {itemData.fogh.med?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.pro.count}</Cell>
                              <Cell>
                                {itemData.pro.med?.toLocaleString()} تومان
                              </Cell>

                              <Cell>{itemData.corpVip.count}</Cell>
                              <Cell>
                                {itemData.corpVip.med?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.corpFogh.count}</Cell>
                              <Cell>
                                {itemData.corpFogh.med?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.corpPro.count}</Cell>
                              <Cell>
                                {itemData.corpPro.med?.toLocaleString()} تومان
                              </Cell>

                              <Cell>
                                {itemData.profit?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.incomeRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.incomeGrossRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {Math.floor(
                                  itemData.employeeIncome
                                )?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>
                                {Math.floor(
                                  itemData.employeeProfit
                                )?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>{itemData.medCheckouts}</Cell>
                              <Cell>
                                {itemData.medChanges?.toLocaleString()} تومان
                              </Cell>
                            </Row>
                          )
                        })}
                    </TBody>
                  </Table>
                )}

              {formState.filters.active_department_id == "graphic" &&
                formState.values.graphicRepots && (
                  <Table>
                    <Row>
                      <Cell header>تاریخ</Cell>
                      <Cell header>جمع ورودی</Cell>
                      <Cell header>جمع خروجی</Cell>
                      <Cell header>حقوق خالص</Cell>
                      <Cell header>بیمه</Cell>
                      <Cell header>پرسنل</Cell>
                      <Cell header>تعداد بیعانه</Cell>
                      <Cell header>جمع مبلغ بیعانه</Cell>
                      <Cell header>میانگین حقوق خالص</Cell>
                      <Cell header>تعداد تسویه ها</Cell>
                      <Cell header>مبلغ تسویه ها</Cell>
                      <Cell header>مجموع پروژه تسویه ها</Cell>
                      {/* <Cell header>تعداد تغییرات</Cell> */}
                      {/* <Cell header>جمع مبلغ تغییرات</Cell> */}

                      <Cell header>تعداد وی آی پی</Cell>
                      <Cell header>میانگین مبلغ وی آی پی</Cell>
                      <Cell header>تعداد فوق حرفه ای</Cell>
                      <Cell header>میانگین مبلغ فوق حرفه ای</Cell>
                      <Cell header>تعداد حرفه ای</Cell>
                      <Cell header>میانگین مبلغ حرفه ای</Cell>

                      <Cell header>سود</Cell>
                      <Cell header>نرخ ورودی</Cell>
                      <Cell header>نرخ ورودی حقوق ناخالص</Cell>
                      <Cell header>ورودی پرسنل</Cell>
                      <Cell header>سود پرسنل</Cell>
                      <Cell header>میانگین تعداد تسویه</Cell>
                      {/* <Cell header>میانگین مبلغ تغییرات</Cell> */}
                    </Row>

                    <TBody>
                      {Object.keys(formState.values.graphicRepots)
                        .slice()
                        .reverse()
                        .map((target, index) => {
                          const itemData =
                            formState.values.graphicRepots[target]

                          return (
                            <Row index={index} key={target}>
                              <Cell>
                                {moment.unix(target).format("jMMMM jYYYY")}
                              </Cell>
                              <Cell>
                                {itemData.incomeSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.outcomeSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.netSalary?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.insurance.toLocaleString()}</Cell>
                              <Cell>{itemData.employee.length}</Cell>
                              <Cell>{itemData.depositCount}</Cell>
                              <Cell>
                                {itemData.depositSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.medNetSalary?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.checkouts}</Cell>
                              <Cell>
                                {itemData.checkoutSum?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.projectCheckoutSum}
                              </Cell>
                              {/* <Cell>{itemData.changeCount}</Cell>
                              <Cell>
                                {itemData.changeSum?.toLocaleString()} تومان
                              </Cell> */}

                              <Cell>{itemData.vip.count}</Cell>
                              <Cell>
                                {itemData.vip.med?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.fogh.count}</Cell>
                              <Cell>
                                {itemData.fogh.med?.toLocaleString()} تومان
                              </Cell>
                              <Cell>{itemData.pro.count}</Cell>
                              <Cell>
                                {itemData.pro.med?.toLocaleString()} تومان
                              </Cell>

                              <Cell>
                                {itemData.profit?.toLocaleString()} تومان
                              </Cell>
                              <Cell>
                                {itemData.incomeRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.incomeGrossRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.employeeIncome?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>
                                {itemData.employeeProfit?.toLocaleString()}{" "}
                                تومان
                              </Cell>
                              <Cell>{itemData.medCheckouts}</Cell>
                              {/* <Cell>
                                {itemData.medChanges?.toLocaleString()} تومان
                              </Cell> */}
                            </Row>
                          )
                        })}
                    </TBody>
                  </Table>
                )}

              {formState.filters.active_department_id == "sales" &&
                formState.values.saleRepots && (
                  <Table>
                    <Row>
                      <Cell header>تاریخ</Cell>
                      <Cell header>تمام لید های ورودی کل</Cell>
                      <Cell header>تعداد لید های ورودی لوگو</Cell>
                      <Cell header>تعداد لید های ورودی سایت</Cell>
                      <Cell header>تعداد تبدیل های کل </Cell>
                      <Cell header>تعداد تبدیل ها ورودی سایت</Cell>
                      <Cell header>تعداد تبدیل ها ورودی لوگو</Cell>
                      <Cell header>تعداد سوخته های ورودی کل</Cell>
                      <Cell header>تعداد سوخته های سایت</Cell>
                      <Cell header>تعداد سوخته های لوگو</Cell>
                      <Cell header> تعداد تبدیل های کل ماه</Cell>
                      <Cell header>تعداد تبدیل های ماه سایت</Cell>
                      <Cell header>تعداد تبدیل های ماه لوگو</Cell>
                      <Cell header>درصد تبدیل ورودی کل</Cell>
                      <Cell header>درصد سوخته های کل</Cell>
                      <Cell header>درصد سوخته های لوگو</Cell>
                      <Cell header>درصد سوخته های سایت</Cell>
                      <Cell header>درصد تبدیل های لوگو</Cell>
                      <Cell header>درصد تبدیل های سایت</Cell>
                    </Row>

                    <TBody>
                      {Object.keys(formState.values.saleRepots)
                        .slice()
                        .reverse()
                        .map((target, index) => {
                          const itemData = formState.values.saleRepots[target]

                          return (
                            <Row index={index} key={target}>
                              <Cell>
                                {moment.unix(target).format("jMMMM jYYYY")}
                              </Cell>
                              <Cell>{itemData.allLeads?.toLocaleString()}</Cell>
                              <Cell>
                                {itemData.logoLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.siteLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.allConvertedLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.siteConvertedLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.logoConvertedLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.allLostLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.siteLostLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.logoLostLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.monthAllConvertedLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.monthSiteConvertedLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.monthLogoConvertedLeads?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.convertRate?.toLocaleString()}
                              </Cell>
                              <Cell>{itemData.LostRate?.toLocaleString()}</Cell>
                              <Cell>
                                {itemData.logoLostRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.siteLostRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.logoConvertRate?.toLocaleString()}
                              </Cell>
                              <Cell>
                                {itemData.siteConvertedRate?.toLocaleString()}
                              </Cell>
                            </Row>
                          )
                        })}
                    </TBody>
                  </Table>
                )}
            </>
          )}

          {isDisplayChart && (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{ gap: 20 }}
              style={{
                width: "100%",
                flex: 1,
                marginBottom: 20,
              }}
            >
              {targetChartValues && (
                <>
                  {formState.filters.active_department_id !== "sales" && (
                    <>
                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  {
                                    label: "نرخ ورودی",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "incomeRate"
                                    ),
                                    borderColor: Colors.blue,
                                    backgroundColor: Colors.blue,
                                  },
                                  {
                                    label: "نرخ ورودی به حقوق ناخالص",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "incomeGrossRate"
                                    ),
                                    borderColor: Colors.error,
                                    backgroundColor: Colors.error,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  {
                                    label: "حقوق خالص",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "netSalary"
                                    ),
                                    borderColor: Colors.blue,
                                    backgroundColor: Colors.blue,
                                  },
                                  {
                                    label: "حقوق ناخالص",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "grossSalary"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                  {
                                    label: "میانگین حقوق خالص",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "medNetSalary"
                                    ),
                                    borderColor: Colors.error,
                                    backgroundColor: Colors.error,
                                  },
                                  {
                                    label: "بیمه",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "insurance"
                                    ),
                                    borderColor: Colors.green,
                                    backgroundColor: Colors.green,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  {
                                    label: "جمع ورودی",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "incomeSum"
                                    ),
                                    borderColor: Colors.blue,
                                    backgroundColor: Colors.blue,
                                  },
                                  {
                                    label: "جمع خروجی",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "outcomeSum"
                                    ),
                                    borderColor: Colors.green,
                                    backgroundColor: Colors.green,
                                  },
                                  {
                                    label: "سود",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "profit"
                                    ),
                                    borderColor: Colors.purple,
                                    backgroundColor: Colors.purple,
                                  },
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  {
                                    label: "ورودی هر پرسنل",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "employeeIncome"
                                    ),
                                    borderColor: Colors.blue,
                                    backgroundColor: Colors.blue,
                                  },
                                  {
                                    label: "سود هر پرسنل",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "employeeProfit"
                                    ),
                                    borderColor: Colors.secondary,
                                    backgroundColor: Colors.secondary,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  {
                                    label: "مبلغ بیعانه",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "depositSum"
                                    ),
                                    borderColor: Colors.blue,
                                    backgroundColor: Colors.blue,
                                  },
                                  {
                                    label: "مبلغ تسویه",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "checkoutSum"
                                    ),
                                    borderColor: Colors.secondary,
                                    backgroundColor: Colors.secondary,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  {
                                    label: "تعداد بیعانه",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "depositCount"
                                    ),
                                    borderColor: Colors.blue,
                                    backgroundColor: Colors.blue,
                                  },
                                  {
                                    label: "تعداد تسویه",
                                    data: getValueFromObject(
                                      targetChartValues,
                                      "checkouts"
                                    ),
                                    borderColor: Colors.secondary,
                                    backgroundColor: Colors.secondary,
                                  },
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="سود ناخالص هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "perEmployeeGrossProfit",
                                      "name",
                                      "cost"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "perEmployeeGrossProfit",
                                      "cost"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="حقوق خالص هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "perEmployeeNet",
                                      "name",
                                      "cost"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "perEmployeeNet",
                                      "cost"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="حقوق ناخالص هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "perEmployeeGross",
                                      "name",
                                      "cost"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "perEmployeeGross",
                                      "cost"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="نرخ سودرسانی هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "perEmployeeProfitRate",
                                      "name",
                                      "cost"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "perEmployeeProfitRate",
                                      "cost"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="شاخص هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "perEmployeeRate",
                                      "name",
                                      "count"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "perEmployeeRate",
                                      "count"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="تعداد جریمه های هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "eamPenalty",
                                      "name",
                                      "count"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "eamPenalty",
                                      "count"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="تعداد تاخیر های هر پرسنل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "eamLate",
                                      "name",
                                      "count"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),

                                  {
                                    label: "میانگین",
                                    data: getAverage(
                                      targetChartValues,
                                      "eamLate",
                                      "count"
                                    ),
                                    borderColor: Colors.primary,
                                    backgroundColor: Colors.primary,
                                  },
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </>
                  )}

                  {formState.filters.active_department_id === "all" && (
                    <View style={{ flex: 1 }}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row-reverse",
                          columnGap: 10,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "جمع کل هزینه تبلیغات",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "adSum"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                              ],
                            }}
                          />
                        </View>

                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "نرخ سه ماهه CAC",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "cac"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                              ],
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  )}

                  {formState.filters.active_department_id === "site" && (
                    <View style={{ flex: 1 }}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row-reverse",
                          columnGap: 10,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            height={500}
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "میانگین مبلغ پروژه فروشگاهی VIP",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "vip",
                                    "med"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label:
                                    "میانگین مبلغ پروژه فروشگاهی فوق حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "fogh",
                                    "med"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "میانگین مبلغ پروژه فروشگاهی حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "pro",
                                    "med"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                                {
                                  label: "میانگین مبلغ پروژه شرکتی VIP",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "corpVip",
                                    "med"
                                  ),
                                  borderColor: Colors.secondary,
                                  backgroundColor: Colors.secondary,
                                },
                                {
                                  label: "میانگین مبلغ پروژه شرکتی فوق حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "corpFogh",
                                    "med"
                                  ),
                                  borderColor: Colors.text,
                                  backgroundColor: Colors.text,
                                },
                                {
                                  label: "میانگین مبلغ پروژه شرکتی حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "corpPro",
                                    "med"
                                  ),
                                  borderColor: Colors.error,
                                  backgroundColor: Colors.error,
                                },
                                {
                                  label: "میانگین مبلغ تغییرات",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "medChanges"
                                  ),
                                  borderColor: Colors.purple,
                                  backgroundColor: Colors.purple,
                                },
                              ],
                            }}
                          />
                        </View>

                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            height={500}
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "تعداد پروژه فروشگاهی VIP",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "vip",
                                    "count"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label: "تعداد پروژه فروشگاهی فوق حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "fogh",
                                    "count"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "تعداد پروژه فروشگاهی حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "pro",
                                    "count"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                                {
                                  label: "تعداد پروژه شرکتی VIP",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "corpVip",
                                    "count"
                                  ),
                                  borderColor: Colors.secondary,
                                  backgroundColor: Colors.secondary,
                                },
                                {
                                  label: "تعداد پروژه شرکتی فوق حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "corpFogh",
                                    "count"
                                  ),
                                  borderColor: Colors.text,
                                  backgroundColor: Colors.text,
                                },
                                {
                                  label: "تعداد پروژه شرکتی حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "corpPro",
                                    "count"
                                  ),
                                  borderColor: Colors.error,
                                  backgroundColor: Colors.error,
                                },
                                {
                                  label: "تعداد تغییرات",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "changeCount"
                                  ),
                                  borderColor: Colors.purple,
                                  backgroundColor: Colors.purple,
                                },
                              ],
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  )}

                  {formState.filters.active_department_id === "graphic" && (
                    <View style={{ flex: 1 }}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row-reverse",
                          columnGap: 10,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            height={500}
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "میانگین مبلغ پروژه لوگو VIP",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "vip",
                                    "med"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label: "میانگین مبلغ پروژه لوگو فوق حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "fogh",
                                    "med"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "میانگین مبلغ پروژه لوگو حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "pro",
                                    "med"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                              ],
                            }}
                          />
                        </View>

                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            height={500}
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "تعداد پروژه لوگو VIP",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "vip",
                                    "count"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label: "تعداد پروژه لوگو فوق حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "fogh",
                                    "count"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "تعداد پروژه لوگو حرفه ای",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "pro",
                                    "count"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                              ],
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  )}

                  {formState.filters.active_department_id === "sales" && (
                    <View style={{ flex: 1 }}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row-reverse",
                          columnGap: 10,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "درصد سوخت ورودی کل",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "LostRate"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label: "درصد سوخت ورودی لوگو",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "logoLostRate"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "درصد سوخت ورودی سایت",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "siteLostRate"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                              ],
                            }}
                          />
                        </View>

                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "درصد تبدیل ورودی کل",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "convertRate"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label: "درصد تبدیل ورودی لوگو",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "logoConvertRate"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "درصد تبدیل ورودی سایت",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "siteConvertedRate"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                              ],
                            }}
                          />
                        </View>

                        <View style={{ flex: 1 }}>
                          <StatBoxChartLine
                            largeSize
                            data={{
                              labels: unixToDate(
                                Object.keys(targetChartValues)
                              ),
                              datasets: [
                                {
                                  label: "تعداد لیدهای ورودی کل",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "allLeads"
                                  ),
                                  borderColor: Colors.blue,
                                  backgroundColor: Colors.blue,
                                },
                                {
                                  label: "تعداد لیدهای ورودی لوگو",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "logoLeads"
                                  ),
                                  borderColor: Colors.green,
                                  backgroundColor: Colors.green,
                                },
                                {
                                  label: "تعداد لیدهای ورودی سایت",
                                  data: getValueFromObject(
                                    targetChartValues,
                                    "siteLeads"
                                  ),
                                  borderColor: Colors.primary,
                                  backgroundColor: Colors.primary,
                                },
                              ],
                            }}
                          />
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="تعداد کل تبدیل های ماه"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "emMonthLeads",
                                      "name",
                                      "count"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),
                                ],
                              }}
                            />
                          </View>

                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="تعداد تبدیل های ورودی کل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "emConvertedLeads",
                                      "name",
                                      "count"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row-reverse",
                            columnGap: 10,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="تعداد ورودی کل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "emAllLeads",
                                      "name",
                                      "count"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),
                                ],
                              }}
                            />
                          </View>

                          {/* ! Rong Value ! */}
                          <View style={{ flex: 1 }}>
                            <StatBoxChartLine
                              largeSize
                              title="درصد تبدیل ورودی کل"
                              data={{
                                labels: unixToDate(
                                  Object.keys(targetChartValues)
                                ),
                                datasets: [
                                  ...Object.entries(
                                    getFromTargetProperty(
                                      targetChartValues,
                                      "emConvertedRate",
                                      "name",
                                      "rate"
                                    )
                                  ).map((item) => {
                                    const color = randomRGB()

                                    return {
                                      label: item[0],
                                      data: item[1],
                                      borderColor: color,
                                      backgroundColor: color,
                                    }
                                  }),
                                ],
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              )}
            </ScrollView>
          )}
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
}
