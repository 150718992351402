export const isDevelopeMode =
  location.hostname.includes("localhost") ||
  location.hostname.includes("violetonline") ||
  location.hostname.includes("dev-aio") ||
  (location.port.includes("19006") && location.protocol.includes("http:"))

export default {
  aioUrl: isDevelopeMode ? "https://dev-api.aminh.pro/aio/v2" : "https://api.aminh.pro/aio/v2",
  appVersion: 195,
}

export const redirects = [
  {
    from: "/page/sitevideo",
    to: "https://aminh.pro/page/sitevideo",
  },
  {
    from: "/page/logovideo",
    to: "https://aminh.pro/page/logovideo",
  },
]
