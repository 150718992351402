import React, { useReducer, useCallback } from "react"
import { View, FlatList, Text, TouchableOpacity, ScrollView } from "react-native"
import Input from "../../../components/Input"
import Btn from "../../../components/Btn"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import moment from "moment-jalaali"
import { Ionicons } from "@expo/vector-icons"
import Colors from "../../../constants/Colors"
import Datepicker from "../../../components/Datepicker"
import Picker from "../../../components/Picker"
import { hours, minutes } from "../../../constants/Clock"
import { useFocusEffect } from "expo-router"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_REMINDERS = "SET_REMINDERS"
const TOGGLE_ADD = "TOGGLE_ADD"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.values,
          [action.name]: action.value,
        },
      }
    case SET_REMINDERS:
      return {
        ...state,
        reminders: action.data,
      }
    case TOGGLE_ADD:
      return {
        ...state,
        values: {
          ...state.values,
          is_add_show: state.values.is_add_show ? false : true,
        },
      }
    default:
      return state
  }
}

const Reminder = (props) => {
  const { item, done_reminder } = props

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        backgroundColor: item.done == 1 ? Colors.green : "#F2F2F2",
        borderRadius: 15,
        marginVertical: 5,
        padding: 15,
      }}
      onPress={() => {
        if (item.done != 1 && confirm("آيا تیک انجام بخورد؟")) done_reminder(item.id)
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "row-reverse", columnGap: 5 }}>
          <Ionicons name="time-outline" size={14} color={item.done == 1 ? "#FFF" : Colors.text} />
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: item.done == 1 ? "#FFF" : Colors.text,
            }}
          >
            یادآوری برای زمان {moment.unix(item.time).format("jYYYY/jM/jD HH:mm")}
          </Text>
        </View>
      </View>
      {item.description != "" && (
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 12,
            color: item.done == 1 ? "#FFF" : Colors.text,
            marginTop: 10,
          }}
        >
          {item.description}
        </Text>
      )}
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: item.done == 1 ? "#FFF" : Colors.text,
          marginTop: 10,
        }}
      >
        {"افزوده شده توسط " +
          item.sender_name +
          " در " +
          moment.unix(item.added_time).format("jYYYY/jM/jD HH:mm")}
      </Text>
    </TouchableOpacity>
  )
}

const ReminderTab = (props) => {
  const token = useSelector((store) => store.user.token)
  const lead = props.route.params.lead
  const get_lead = props.route.params.get_lead
  const reminders = props.route.params.lead.reminders

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        set_touch: false,
      },
      reminder_date: null,
      reminder_hour: null,
      reminder_minute: null,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const set_reminder = async () => {
    if (
      !formState.values.reminder_date ||
      !formState.values.reminder_hour ||
      !formState.values.reminder_minute
    ) {
      alert("انتخاب ها ناقص است")
      return
    }

    const url = Config.aioUrl + "/crm/lead/reminder/add"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("lead_id", lead.id)
    formdata.append(
      "time",
      parseInt(formState.values.reminder_date) -
        86399 +
        parseInt(formState.values.reminder_hour.id) * 60 * 60 +
        parseInt(formState.values.reminder_minute.id) * 60
    )
    if (formState.values.reminder_description)
      formdata.append("description", formState.values.reminder_description)
    dispatchFormState({ type: SET_LOADING, name: "set_reminder", value: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        get_lead()
        dispatchFormState({
          type: SET_LOADING,
          name: "set_reminder",
          value: false,
        })
        alert(response.data.msg)
        get_lead()
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          name: "set_reminder",
          value: false,
        })
      })
  }

  const done_reminder = async (reminder_id) => {
    console.log(reminder_id)
    const url = Config.aioUrl + "/crm/lead/reminder/done"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("reminder_id", reminder_id)

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        alert(response.data.msg)
        get_lead()
      })
      .catch(function (error) {})
  }

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingTop: formState.values.is_add_show ? 0 : 10,
      }}
    >
      {formState.values.is_add_show && (
        <View style={{ zIndex: 2 }}>
          <View style={{ flexDirection: "row-reverse", columnGap: 3, zIndex: 2 }}>
            <View style={{ flex: 1.2, zIndex: 3 }}>
              <Datepicker label="تاریخ" id="reminder_date" onChange={inputChangeHandler} />
            </View>
            <View style={{ flex: 1 }}>
              <Picker
                label="دقیقه"
                id="reminder_minute"
                onChange={inputChangeHandler}
                options={minutes}
              />
            </View>

            <View style={{ flex: 1 }}>
              <Picker
                label="ساعت"
                id="reminder_hour"
                onChange={inputChangeHandler}
                options={hours}
              />
            </View>
          </View>
          <Input label="توضیح" id="reminder_description" onChange={inputChangeHandler} />
        </View>
      )}

      <View style={{ flexDirection: "row" }}>
        {formState.values.is_add_show && (
          <View style={{ flex: 1, marginRight: 10 }}>
            <Btn
              title="بستن"
              onPress={() => {
                dispatchFormState({ type: TOGGLE_ADD })
              }}
              color={Colors.error}
            />
          </View>
        )}
        <View style={{ flex: 3 }}>
          <Btn
            title={formState.values.is_add_show ? "ثبت یاداوری" : "افزودن"}
            onPress={() => {
              if (formState.values.is_add_show) {
                set_reminder()
              } else {
                dispatchFormState({ type: TOGGLE_ADD })
              }
            }}
            loading={formState.values.loading.set_touch}
          />
        </View>
      </View>

      {!formState.values.is_add_show && (
        <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
          <View style={{ marginBottom: 20, marginTop: 10 }}>
            <FlatList
              data={reminders}
              keyExtractor={(item) => item.added_time}
              renderItem={(itemData) => (
                <Reminder item={itemData.item} done_reminder={done_reminder} />
              )}
            />
          </View>
        </ScrollView>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default ReminderTab
