// import { useReducer, useEffect, useCallback } from "react"
// import { View, ActivityIndicator, Dimensions, ScrollView } from "react-native"
// import Colors from "../../../constants/Colors"
// import Config from "../../../constants/Config"
// import { useSelector } from "react-redux"
// import axios from "axios"
// import { logOut } from "../../../components/Functions"
// import Header from "../../../components/Header"
// import Btn from "../../../components/Btn"
// import Picker from "../../../components/Picker"
// import Input from "../../../components/Input"
// import { Editor } from "react-draft-wysiwyg"
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
// import { convertToRaw, EditorState, ContentState } from "draft-js"
// import draftToHtml from "draftjs-to-html"
// import htmlToDraft from "html-to-draftjs"
// import { CommonActions } from "@react-navigation/native"

// const DESKTOP = Dimensions.get("window").width > 800 ? true : false

// const INPUT_CHANGE = "INPUT_CHANGE"
// const SET_LOADING = "SET_LOADING"
// const SET_SEND_LOADING = "SET_SEND_LOADING"
// const SET_POST = "SET_POST"
// const SET_CATEGORIES = "SET_CATEGORIES"
// const SET_MODAL = "SET_MODAL"

// const formReducer = (state, action) => {
//   switch (action.type) {
//     case INPUT_CHANGE:
//       if (action.id == "content") {
//         return {
//           ...state,
//           values: {
//             ...state.values,
//             content: action.value,
//             content_html: action.content_html,
//           },
//         }
//       }
//       return {
//         ...state,
//         values: {
//           ...state.values,
//           [action.id]: action.value,
//         },
//       }
//     case SET_LOADING:
//       return {
//         ...state,
//         values: {
//           ...state.values,
//           loading: action.loading,
//         },
//       }
//     case SET_SEND_LOADING:
//       return {
//         ...state,
//         values: {
//           ...state.values,
//           send_loading: action.loading,
//         },
//       }
//     case SET_CATEGORIES:
//       let categories = action.data
//       for (let i = 0; i < categories.length; i++) {
//         categories[i].item = categories[i]["title"]
//         delete categories[i].title
//       }
//       return {
//         ...state,
//         values: {
//           ...state.values,
//           categories: action.data,
//         },
//       }
//     case SET_POST:
//       const blocksFromHtml = htmlToDraft(action.data.content)
//       const { contentBlocks, entityMap } = blocksFromHtml
//       const contentState = ContentState.createFromBlockArray(
//         contentBlocks,
//         entityMap
//       )
//       const editorState = EditorState.createWithContent(contentState)
//       const rawContentState = convertToRaw(editorState.getCurrentContent())
//       const markup = draftToHtml(rawContentState)
//       let category = null
//       for (let i = 0; i < state.values.categories.length; i++) {
//         if (state.values.categories[i].id == action.data.category_id) {
//           category = state.values.categories[i]
//         }
//       }

//       let type = null
//       type =
//         action.data.type == "normal"
//           ? { item: "پست عادی", id: "normal" }
//           : { item: "برگه", id: "page" }

//       let thumbnail_type =
//         null(
//           //
//           action.data.thumbnail_type == "normal"
//         ) &&
//         (thumbnail_type = {
//           item: "استاندارد بدون ویدیو و فقط تصویر اصلی",
//           id: "normal",
//         })(
//           //
//           action.data.thumbnail_type == "iframe_url"
//         ) &&
//         (thumbnail_type = {
//           item: "ویدیو به شکل آی فریم همراه کاور",
//           id: "iframe_url",
//         })(
//           //
//           action.data.thumbnail_type == "video_url"
//         ) &&
//         (thumbnail_type = {
//           item: "لینک ویدیو و قرار گرفتن تصویر اصلی به عنوان کاور",
//           id: "video_url",
//         })

//       return {
//         ...state,
//         values: {
//           ...state.values,
//           post: action.data,
//           content: editorState,
//           content_html: markup,
//           category: category,
//           type: type,
//           // ? NEW ===>
//           thumbnail_type: thumbnail_type,
//           iframe_url: action.data.iframe_url,
//           video_url: action.data.video_url,
//           // ? <===
//         },
//       }

//     case SET_MODAL:
//       return {
//         ...state,
//         values: {
//           ...state.values,
//           modalVisible: action.value,
//         },
//       }

//     default:
//       return state
//   }
// }

// const AddPostScreen = (props) => {
//   const post_id = props.route.params.post_id
//   const token = useSelector((store) => store.user.token)

//   const [formState, dispatchFormState] = useReducer(formReducer, {
//     values: {
//       loading: false,
//       post: null,
//       categories: [],
//       type: null,
//       title: null,
//       browser_title: null,
//       keywords: null,
//       meta_description: null,
//       slug: null,
//       category: null,
//       // ? NEW ===>
//       thumbnail_type: null,
//       iframe_url: null,
//       video_url: null,
//       // ? <===
//       thumbnail_id: null,
//       content: null,
//       content_html: null,
//       script: null,
//       reading_time: null,
//       modalVisible: false,
//       send_loading: false,
//     },
//   })

//   const inputChangeHandler = useCallback(
//     // You don't need any changes
//     (id, value) => {
//       dispatchFormState({ type: INPUT_CHANGE, id, value })
//     },
//     [dispatchFormState]
//   )

//   const get_categories = async () => {
//     const url = Config.aioUrl + "/website/blog/category/list"
//     let reqHedear = {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + token,
//     }

//     dispatchFormState({ type: SET_LOADING, loading: true })
//     axios({
//       method: "get",
//       url: url,
//       headers: reqHedear,
//     })
//       .then(function (response) {
//         if (response.data.code == 401) {
//           alert("نیاز است مجددا به سیستم ورود کنید.")
//           logOut()
//         }
//         dispatchFormState({ type: SET_CATEGORIES, data: response.data.data })
//         dispatchFormState({ type: SET_LOADING, loading: false })
//       })
//       .catch(function (error) {
//         dispatchFormState({ type: SET_LOADING, loading: false })
//       })
//   }

//   const get_post = async (id) => {
//     let url = Config.aioUrl + "/website/blog/post/get?id=" + id

//     let reqHedear = {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + token,
//     }

//     dispatchFormState({ type: SET_LOADING, loading: true })
//     axios({
//       method: "get",
//       url: url,
//       headers: reqHedear,
//     })
//       .then(function (response) {
//         if (response.data.code == 401) {
//           alert("نیاز است مجددا به سیستم ورود کنید.")
//           logOut()
//         }
//         dispatchFormState({ type: SET_POST, data: response.data.data })
//         dispatchFormState({ type: SET_LOADING, loading: false })
//       })
//       .catch(function (error) {
//         dispatchFormState({ type: SET_LOADING, loading: false })
//       })
//   }

//   const set_post = async (status) => {
//     if (
//       !formState.values.type ||
//       !formState.values.title ||
//       !formState.values.slug ||
//       !formState.values.content ||
//       !formState.values.thumbnail_type ||
//       !formState.values.reading_time ||
//       !formState.values.thumbnail_id
//     ) {
//       alert("وارد کردن فیلدها اجباریست.")
//       return
//     }
//     if (formState.values.type.id == "normal" && !formState.values.category) {
//       alert("وارد کردن فیلدها اجباریست.")
//       return
//     }

//     const url = Config.aioUrl + "/website/blog/post/set"

//     let reqHedear = {
//       Authorization: "Bearer " + token,
//     }

//     let formdata = new FormData()
//     // ? NEW ====>
//     formdata.append("thumbnail_type", formState.values.thumbnail_type.id)
//     formdata.append("iframe_url", formState.values.iframe_url)
//     formdata.append("video_url", formState.values.video_url)
//     // ? <====

//     formdata.append("type", formState.values.type.id)
//     formdata.append("title", formState.values.title)
//     formdata.append("slug", formState.values.slug)
//     if (formState.values.type.id !== "page")
//       formdata.append("category_id", formState.values.category.id)
//     formdata.append("content", formState.values.content_html)
//     if (formState.values.script)
//       formdata.append("script", formState.values.script)
//     formdata.append("reading_time", formState.values.reading_time)
//     formdata.append("thumbnail_id", formState.values.thumbnail_id)
//     if (formState.values.browser_title)
//       formdata.append("browser_title", formState.values.browser_title)
//     if (formState.values.keywords)
//       formdata.append("keywords", formState.values.keywords)
//     if (formState.values.meta_description)
//       formdata.append("meta_description", formState.values.meta_description)
//     formdata.append("status", status)

//     if (post_id) formdata.append("post_id", post_id)

//     dispatchFormState({ type: SET_SEND_LOADING, loading: true })
//     axios({
//       method: "post",
//       url: url,
//       headers: reqHedear,
//       data: formdata,
//     })
//       .then(function (response) {
//         
//         if (response.data.code == 401) {
//           alert("نیاز است مجددا به سیستم ورود کنید.")
//           logOut()
//         }
//         alert(response.data.msg)
//         dispatchFormState({ type: SET_SEND_LOADING, loading: false })
//         if (response.data.success) {
//           props.navigation.dispatch(
//             CommonActions.reset({
//               index: 1,
//               routes: [
//                 {
//                   name: "PostsList",
//                 },
//               ],
//             })
//           )
//         }
//       })
//       .catch(function (error) {
//         console.log(error)
//         dispatchFormState({ type: SET_SEND_LOADING, loading: false })
//       })
//   }

//   const do_in_order = async () => {
//     await get_categories()
//     if (post_id) {
//       await get_post(post_id)
//     }
//   }

//   useEffect(() => {
//     const focused = props.navigation.addListener("focus", () => {
//       do_in_order()
//     })

//     return focused
//   }, [props.navigation])

//   

//   const onEditorStateChange = (editorState) => {
//     const rawContentState = convertToRaw(editorState.getCurrentContent())
//     const markup = draftToHtml(rawContentState)

//     dispatchFormState({
//       type: INPUT_CHANGE,
//       id: "content",
//       value: editorState,
//       content_html: markup,
//     })
//   }

//   return (
//     <View
//       style={{
//         paddingHorizontal: 25,
//         height: "100%",
//         overflow: "hidden",
//         backgroundColor: Colors.bg,
//       }}
//     >
//       <Header
//         title={post_id ? "ویرایش مطلب" : "افزودن مطلب"}
//         noBack={post_id ? false : true}
//       />

//       {formState.values.loading && (
//         <View>
//           <ActivityIndicator size="large" color={Colors.primary} />
//         </View>
//       )}

//       {!formState.values.loading &&
//         ((formState.values.post && post_id) || !post_id) &&
//         formState.values.categories && (
//           <View style={{ flex: 1, height: "100%", width: "100%" }}>
//             <View style={{ flex: 1 }}>
//               <ScrollView
//                 showsVerticalScrollIndicator={false}
//                 style={{
//                   flex: 1,
//                   height: "100%",
//                   paddingHorizontal: DESKTOP ? "20%" : 0,
//                   paddingBottom: 30,
//                 }}
//               >
//                 <Picker
//                   label="نوع مطلب"
//                   id="type"
//                   onChange={inputChangeHandler}
//                   options={[
//                     { item: "پست عادی", id: "normal" },
//                     { item: "برگه", id: "page" },
//                   ]}
//                   insert={post_id ? formState.values.type : false}
//                 />

//                 <Picker
//                   label="حالت نمایش مطلب"
//                   id="thumbnail_type"
//                   onChange={inputChangeHandler}
//                   options={[
//                     {
//                       item: "استاندارد بدون ویدیو و فقط تصویر اصلی",
//                       id: "normal",
//                     },
//                     {
//                       item: "ویدیو به شکل آی فریم همراه کاور",
//                       id: "iframe_url",
//                     },
//                     {
//                       item: "لینک ویدیو و قرار گرفتن تصویر اصلی به عنوان کاور",
//                       id: "video_url",
//                     },
//                   ]}
//                   insert={post_id ? formState.values.thumbnail_type : false}
//                 />

//                 <Input
//                   label="عنوان"
//                   id="title"
//                   onChange={inputChangeHandler}
//                   insert={post_id ? formState.values.post.title : false}
//                 />

//                 <View
//                   style={{
//                     flexDirection: "row-reverse",
//                     columnGap: 5,
//                     alignItems: "center",
//                   }}
//                 >
//                   <View style={{ flex: 5 }}>
//                     <Input
//                       label="نامک"
//                       id="slug"
//                       onChange={inputChangeHandler}
//                       insert={post_id ? formState.values.post.slug : false}
//                     />
//                   </View>

//                   {post_id && (
//                     <View style={{ flex: 1 }}>
//                       <Btn
//                         title="مشاهده مطلب"
//                         color={Colors.blue}
//                         onPress={() => {
//                           window.open(
//                             "https://aminh.pro" + formState.values.post.slug,
//                             "_blank"
//                           )
//                         }}
//                       />
//                     </View>
//                   )}
//                 </View>

//                 <Input
//                   label="نام نمایشی در مرورگر"
//                   id="browser_title"
//                   onChange={inputChangeHandler}
//                   insert={post_id ? formState.values.post.browser_title : false}
//                 />

//                 <Input
//                   label="کلمات کلیدی"
//                   id="keywords"
//                   onChange={inputChangeHandler}
//                   insert={post_id ? formState.values.post.keywords : false}
//                 />

//                 <Input
//                   label="توضیحات متا"
//                   id="meta_description"
//                   onChange={inputChangeHandler}
//                   insert={
//                     post_id ? formState.values.post.meta_description : false
//                   }
//                 />

//                 {formState.values.type &&
//                   formState.values.type.id == "normal" && (
//                     <Picker
//                       label="دسته بندی"
//                       id="category"
//                       onChange={inputChangeHandler}
//                       options={formState.values.categories}
//                       insert={post_id ? formState.values.category : false}
//                     />
//                   )}

//                 <Input
//                   label="زمان مطالعه"
//                   id="reading_time"
//                   onChange={inputChangeHandler}
//                   insert={post_id ? formState.values.post.reading_time : false}
//                 />

//                 <Input
//                   label="آیدی تصویر اصلی"
//                   id="thumbnail_id"
//                   onChange={inputChangeHandler}
//                   insert={post_id ? formState.values.post.thumbnail_id : false}
//                 />

//                 {formState.values.thumbnail_type != null && (
//                   <>
//                     {formState.values.thumbnail_type.id == "iframe_url" && (
//                       <Input
//                         label="لینک آی فریم ویدیو مطلب"
//                         id="iframe_url"
//                         onChange={inputChangeHandler}
//                         insert={
//                           post_id ? formState.values.post.iframe_url : false
//                         }
//                       />
//                     )}

//                     {formState.values.thumbnail_type.id == "video_url" && (
//                       <Input
//                         label="لینک ویدیو مطلب (MP4, OGG, WEBM)"
//                         id="video_url"
//                         onChange={inputChangeHandler}
//                         insert={
//                           post_id ? formState.values.post.video_url : false
//                         }
//                       />
//                     )}
//                   </>
//                 )}

//                 <View
//                   style={{
//                     width: "100%",
//                     height: 500,
//                     borderWidth: 0.8,
//                     borderRadius: 7,
//                     borderColor: Colors.secondText,
//                     marginVertical: 15,
//                     overflow: "hidden",
//                   }}
//                 >
//                   <Editor
//                     editorState={formState.values.content}
//                     onEditorStateChange={onEditorStateChange}
//                     editorStyle={{
//                       height: 400,
//                       paddingLeft: 15,
//                       paddingRight: 15,
//                       fontFamily: "IranSans-Regular",
//                     }}
//                     toolbar={{
//                       options: [
//                         "inline",
//                         "blockType",
//                         "fontSize",
//                         "list",
//                         "textAlign",
//                         "colorPicker",
//                         "link",
//                         "embedded",
//                         "emoji",
//                         "image",
//                         "remove",
//                         "history",
//                       ],
//                       image: {
//                         urlEnabled: true,
//                         alignmentEnabled: true,
//                         inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
//                         alt: { present: true, mandatory: true },
//                         defaultSize: {
//                           height: "auto",
//                           width: "auto",
//                         },
//                       },
//                     }}
//                   />
//                 </View>

//                 <Input
//                   label="اسکریپت"
//                   id="script"
//                   onChange={inputChangeHandler}
//                   insert={post_id ? formState.values.post.script : false}
//                   multiline
//                 />

//                 <View
//                   style={{
//                     flexDirection: "row",
//                     justifyContent: "space-between",
//                     columnGap: 10,
//                   }}
//                 >
//                   <Btn
//                     title={post_id ? "ثبت تغییرات" : "ثبت و انتشار"}
//                     onPress={() => {
//                       set_post("published")
//                     }}
//                     loading={formState.values.send_loading}
//                     wrapperStyle={{ flex: 1 }}
//                     titleStyle={{ fontSize: DESKTOP ? 16 : 12 }}
//                   />
//                   <Btn
//                     title={"ذخیره بعنوان پیش نویس"}
//                     color={Colors.secondText}
//                     onPress={() => {
//                       set_post("pending")
//                     }}
//                     loading={formState.values.send_loading}
//                     wrapperStyle={{ flex: 1 }}
//                     titleStyle={{ fontSize: DESKTOP ? 16 : 12 }}
//                   />
//                 </View>
//               </ScrollView>
//             </View>
//           </View>
//         )}
//     </View>
//   )
// }

// export const screenOptions = {
//   headerShown: false,
//   drawerPosition: "right",
// }

// export default AddPostScreen

// ! Thats new version 👆
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// !
// ! Thats old version 👇

import { useReducer, useEffect, useCallback } from "react"
import { View, ActivityIndicator, Dimensions, ScrollView } from "react-native"
import Colors from "../../../constants/Colors"
import Config from "../../../constants/Config"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Header from "../../../components/Header"
import Btn from "../../../components/Btn"
import Picker from "../../../components/Picker"
import Input from "../../../components/Input"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToRaw, EditorState, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { CommonActions } from "@react-navigation/native"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_SEND_LOADING = "SET_SEND_LOADING"
const SET_POST = "SET_POST"
const SET_CATEGORIES = "SET_CATEGORIES"
const SET_MODAL = "SET_MODAL"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      if (action.id == "content") {
        return {
          ...state,
          values: {
            ...state.values,
            content: action.value,
            content_html: action.content_html,
          },
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SEND_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          send_loading: action.loading,
        },
      }
    case SET_CATEGORIES:
      let categories = action.data
      for (let i = 0; i < categories.length; i++) {
        categories[i].item = categories[i]["title"]
        delete categories[i].title
      }
      return {
        ...state,
        values: {
          ...state.values,
          categories: action.data,
        },
      }
    case SET_POST:
      const blocksFromHtml = htmlToDraft(action.data.content)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      const editorState = EditorState.createWithContent(contentState)
      const rawContentState = convertToRaw(editorState.getCurrentContent())
      const markup = draftToHtml(rawContentState)
      let category = null
      for (let i = 0; i < state.values.categories.length; i++) {
        if (state.values.categories[i].id == action.data.category_id) {
          category = state.values.categories[i]
        }
      }
      let type = null
      type =
        action.data.type == "normal"
          ? { item: "پست عادی", id: "normal" }
          : { item: "برگه", id: "page" }
      return {
        ...state,
        values: {
          ...state.values,
          post: action.data,
          content: editorState,
          content_html: markup,
          category: category,
          type: type,
        },
      }

    case SET_MODAL:
      return {
        ...state,
        values: {
          ...state.values,
          modalVisible: action.value,
        },
      }

    default:
      return state
  }
}

const AddPostScreen = (props) => {
  const post_id = props.route.params.post_id
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      post: null,
      categories: [],
      type: null,
      title: null,
      browser_title: null,
      keywords: null,
      meta_description: null,
      slug: null,
      category: null,
      thumbnail_id: null,
      content: null,
      content_html: null,
      script: null,
      reading_time: null,
      modalVisible: false,
      send_loading: false,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_categories = async () => {
    const url = Config.aioUrl + "/website/blog/category/list"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_CATEGORIES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_post = async (id) => {
    let url = Config.aioUrl + "/website/blog/post/get?id=" + id

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_POST, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const set_post = async (status) => {
    if (
      !formState.values.type ||
      !formState.values.title ||
      !formState.values.slug ||
      !formState.values.content ||
      !formState.values.reading_time ||
      !formState.values.thumbnail_id
    ) {
      alert("وارد کردن فیلدها اجباریست.")
      return
    }
    if (formState.values.type.id == "normal" && !formState.values.category) {
      alert("وارد کردن فیلدها اجباریست.")
      return
    }

    const url = Config.aioUrl + "/website/blog/post/set"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("type", formState.values.type.id)
    formdata.append("title", formState.values.title)
    formdata.append("slug", formState.values.slug)
    if (formState.values.type.id !== "page")
      formdata.append("category_id", formState.values.category.id)
    formdata.append("content", formState.values.content_html)
    if (formState.values.script)
      formdata.append("script", formState.values.script)
    formdata.append("reading_time", formState.values.reading_time)
    formdata.append("thumbnail_id", formState.values.thumbnail_id)
    if (formState.values.browser_title)
      formdata.append("browser_title", formState.values.browser_title)
    if (formState.values.keywords)
      formdata.append("keywords", formState.values.keywords)
    if (formState.values.meta_description)
      formdata.append("meta_description", formState.values.meta_description)
    formdata.append("status", status)

    if (post_id) formdata.append("post_id", post_id)

    dispatchFormState({ type: SET_SEND_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)
        dispatchFormState({ type: SET_SEND_LOADING, loading: false })
        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "PostsList",
                },
              ],
            })
          )
        }
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_SEND_LOADING, loading: false })
      })
  }

  const do_in_order = async () => {
    await get_categories()
    if (post_id) {
      await get_post(post_id)
    }
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      do_in_order()
    })

    return focused
  }, [props.navigation])

  

  const onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)

    dispatchFormState({
      type: INPUT_CHANGE,
      id: "content",
      value: editorState,
      content_html: markup,
    })
  }

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header
        title={post_id ? "ویرایش مطلب" : "افزودن مطلب"}
        noBack={post_id ? false : true}
      />
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
      {!formState.values.loading &&
        ((formState.values.post && post_id) || !post_id) &&
        formState.values.categories && (
          <View style={{ flex: 1, height: "100%", width: "100%" }}>
            <View style={{ flex: 1 }}>
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  flex: 1,
                  height: "100%",
                  paddingHorizontal: DESKTOP ? "20%" : 0,
                  paddingBottom: 30,
                }}
              >
                <Picker
                  label="نوع مطلب"
                  id="type"
                  onChange={inputChangeHandler}
                  options={[
                    { item: "پست عادی", id: "normal" },
                    { item: "برگه", id: "page" },
                  ]}
                  insert={post_id ? formState.values.type : false}
                />
                <Input
                  label="عنوان"
                  id="title"
                  onChange={inputChangeHandler}
                  insert={post_id ? formState.values.post.title : false}
                />
                <View
                  style={{
                    flexDirection: "row-reverse",
                    columnGap: 5,
                    alignItems: "center",
                  }}
                >
                  <View style={{ flex: 5 }}>
                    <Input
                      label="نامک"
                      id="slug"
                      onChange={inputChangeHandler}
                      insert={post_id ? formState.values.post.slug : false}
                    />
                  </View>
                  {post_id && (
                    <View style={{ flex: 1 }}>
                      <Btn
                        title="مشاهده مطلب"
                        color={Colors.blue}
                        onPress={() => {
                          window.open(
                            "https://aminh.pro" + formState.values.post.slug,
                            "_blank"
                          )
                        }}
                      />
                    </View>
                  )}
                </View>
                <Input
                  label="نام نمایشی در مرورگر"
                  id="browser_title"
                  onChange={inputChangeHandler}
                  insert={post_id ? formState.values.post.browser_title : false}
                />
                <Input
                  label="کلمات کلیدی"
                  id="keywords"
                  onChange={inputChangeHandler}
                  insert={post_id ? formState.values.post.keywords : false}
                />
                <Input
                  label="توضیحات متا"
                  id="meta_description"
                  onChange={inputChangeHandler}
                  insert={
                    post_id ? formState.values.post.meta_description : false
                  }
                />
                {formState.values.type &&
                  formState.values.type.id == "normal" && (
                    <Picker
                      label="دسته بندی"
                      id="category"
                      onChange={inputChangeHandler}
                      options={formState.values.categories}
                      insert={post_id ? formState.values.category : false}
                    />
                  )}
                <Input
                  label="زمان مطالعه"
                  id="reading_time"
                  onChange={inputChangeHandler}
                  insert={post_id ? formState.values.post.reading_time : false}
                />
                <Input
                  label="آیدی تصویر اصلی"
                  id="thumbnail_id"
                  onChange={inputChangeHandler}
                  insert={post_id ? formState.values.post.thumbnail_id : false}
                />

                <View
                  style={{
                    width: "100%",
                    height: 500,
                    borderWidth: 0.8,
                    borderRadius: 7,
                    borderColor: Colors.secondText,
                    marginVertical: 15,
                    overflow: "hidden",
                  }}
                >
                  <Editor
                    editorState={formState.values.content}
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{
                      height: 400,
                      paddingLeft: 15,
                      paddingRight: 15,
                      fontFamily: "IranSans-Regular",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "embedded",
                        "emoji",
                        "image",
                        "remove",
                        "history",
                      ],
                      image: {
                        urlEnabled: true,
                        alignmentEnabled: true,
                        inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
                        alt: { present: true, mandatory: true },
                        defaultSize: {
                          height: "auto",
                          width: "auto",
                        },
                      },
                    }}
                  />
                </View>
                <Input
                  label="اسکریپت"
                  id="script"
                  onChange={inputChangeHandler}
                  insert={post_id ? formState.values.post.script : false}
                  multiline
                />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    columnGap: 10,
                  }}
                >
                  <Btn
                    title={post_id ? "ثبت تغییرات" : "ثبت و انتشار"}
                    onPress={() => {
                      set_post("published")
                    }}
                    loading={formState.values.send_loading}
                    wrapperStyle={{ flex: 1 }}
                    titleStyle={{ fontSize: DESKTOP ? 16 : 12 }}
                  />
                  <Btn
                    title={"ذخیره بعنوان پیش نویس"}
                    color={Colors.secondText}
                    onPress={() => {
                      set_post("pending")
                    }}
                    loading={formState.values.send_loading}
                    wrapperStyle={{ flex: 1 }}
                    titleStyle={{ fontSize: DESKTOP ? 16 : 12 }}
                  />
                </View>
              </ScrollView>
            </View>
          </View>
        )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default AddPostScreen
