import { useEffect, useState } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Image,
  Dimensions,
  ScrollView,
} from "react-native"
import Colors from "../constants/Colors"
import axios from "axios"
import Config from "../constants/Config"
import { useSelector } from "react-redux"
import { Ionicons } from "@expo/vector-icons"
import moment from "moment-jalaali"
import { useNavigation } from "@react-navigation/native"
import InputNote from "./InputNote"
import { Placeholder, ShineOverlay } from "rn-placeholder"
import { logOut } from "./Functions"
import * as Device from "expo-device"

const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const LoadingPlaceHolder = () => {
  return (
    <View style={{ padding: 10, direction: "rtl", direction: "ltr" }}>
      <Placeholder style={{ direction: "rtl" }} Animation={ShineOverlay}>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
      </Placeholder>
    </View>
  )
}

const ActiveSessions = ({ noNotif, setShowActiveSessions }) => {
  const navigation = useNavigation()
  const token = useSelector((store) => store.user.token)

  const [activeSessions, setActiveSessions] = useState([])

  const get_activeSessions = async () => {
    let url = Config.aioUrl + "/user-active-login"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        console.log(response)
        setActiveSessions(response.data.data)
      })
      .catch(function (error) {
        setShowActiveSessions(false)
        alert("مشکلی در دریافت دستگاه ها هست! مجدد امتحان کنید.")
        console.log(error)
      })
  }

  useEffect(() => {
    get_activeSessions()
  }, [])

  const removeSession = (id) => {
    let url = Config.aioUrl + `/user-active-login/remove?active_id=${id}`

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        alert(response.data.msg)

        get_activeSessions()
      })
      .catch(function (error) {
        alert("مشکلی در اخراج دستگاه هست! مجدد امتحان کنید.")
        console.log(error)
      })
  }

  const removeAnotherSessions = () => {
    let url = Config.aioUrl + `/user-active-login/remove-another-sessions`

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        if (response.data.code == 200) {
          alert("تمام دستگاه ها به غیر از شما از حساب کاربری حذف شدند.")
        } else {
          alert(response.data.msg)
        }

        get_activeSessions()
      })
      .catch(function (error) {
        alert("مشکلی در اخراج دستگاه ها هست! مجدد امتحان کنید.")
        console.log(error)
      })
  }

  const getWrapStyle = () => {
    if (
      Device.osName == "iOS" ||
      Device.osName == "ios" ||
      Device.osName == "IOS"
    ) {
      return {
        position: "absolute",
        top: DESKTOP ? 50 : -22.5,
        left: DESKTOP ? 0 : noNotif ? -30 : -85,
        width: DESKTOP ? 400 : "100vw",
        height: DESKTOP ? 500 : "90vh",
        maxHeight: DESKTOP ? "70vh" : "auto",
        backgroundColor: "#fff",
        borderRadius: DESKTOP ? 15 : 0,
        shadowColor: "rgba(0,0,0,0.3)",
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 3,
        overflow: "hidden",
      }
    } else {
      return {
        position: "absolute",
        top: DESKTOP ? 50 : -55,
        left: DESKTOP ? 0 : noNotif ? -30 : -85,
        width: DESKTOP ? 400 : "100vw",
        height: DESKTOP ? 500 : "100vh",
        maxHeight: DESKTOP ? "70vh" : "auto",
        backgroundColor: "#fff",
        borderRadius: DESKTOP ? 15 : 0,
        shadowColor: "rgba(0,0,0,0.3)",
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 3,
        overflow: "hidden",
      }
    }
  }

  return (
    <View style={getWrapStyle()}>
      <InputNote
        wrapperStyle={{ borderRadius: 0, marginBottom: 0 }}
        title="دستگاه های فعال"
        content="در قسمت پایین لیستی از دستگاه هایی که در حساب کاربری شما فعال هستن رو میتونید مشاهده کنید و در صورت نیاز شخص را از حساب کاربری بیرون کنید."
        icon="information-circle-outline"
      />

      {!DESKTOP && (
        <Ionicons
          name="ios-close"
          size={24}
          color={Colors.text}
          style={{ position: "absolute", top: 8, left: 8 }}
          onPress={() => setShowActiveSessions(false)}
        />
      )}

      <FlatList
        data={activeSessions}
        keyExtractor={(item) => item.id}
        renderItem={(itemData, index) => (
          <View
            style={{
              width: "100%",
              flexDirection: "row-reverse",
              paddingHorizontal: 20,
              paddingVertical: 15,
              borderTopColor: "#F2F2F2",
              backgroundColor: itemData.item.is_me ? "#C4EED0" : "",
              borderTopWidth: index == 0 ? 0 : 1,
            }}
          >
            <View
              style={{
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                width: 35,
                height: 35,
                maxWidth: 35,
                borderRadius: 17.5,
                marginLeft: 7,
                backgroundColor:
                  itemData.item.os.toUpperCase() == "ANDROID"
                    ? "#4CAF50"
                    : itemData.item.os.toUpperCase() == "IOS"
                    ? "#F44336"
                    : itemData.item.os.toUpperCase() == "WINDOWS"
                    ? "#673AB7"
                    : "#2196F3",
                flex: 1,
              }}
            >
              <Ionicons
                name={
                  itemData.item.os.toUpperCase() == "ANDROID"
                    ? "logo-android"
                    : itemData.item.os.toUpperCase() == "IOS"
                    ? "logo-apple"
                    : itemData.item.os.toUpperCase() == "WINDOWS"
                    ? "logo-windows"
                    : "body"
                }
                size={20}
                color="#fff"
              />
            </View>

            <View style={{ height: "100%", justifyContent: "center", flex: 6 }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                    marginRight: 5,
                  }}
                >
                  {itemData.item.os} {itemData.item.os_version} -{" "}
                  {itemData.item.device_model != "null"
                    ? itemData.item.device_model
                    : itemData.item.platform}
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 10,
                  color: Colors.text,
                  marginRight: 5,
                  marginTop: 3,
                }}
              >
                {itemData.item.is_me
                  ? `${moment
                      .unix(itemData.item.logged_in)
                      .fromNow()} ورود کردید و اکنون آنلاین هستید.`
                  : `${moment
                      .unix(itemData.item.logged_in)
                      .fromNow()} ورود کرده و ${moment
                      .unix(itemData.item.last_active)
                      .fromNow()} آنلاین بود.`}
              </Text>
            </View>

            {!itemData.item.is_me ? (
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row-reverse",
                  gap: 5,
                  borderRadius: 5,
                  backgroundColor: Colors.cardBg,
                  flex: 1,
                  paddingHorizontal: 10,
                  paddingVertical: 1,
                }}
                onPress={() => {
                  if (
                    confirm("آیا از اخراج کاربر از حساب کاربری اطمینان دارید؟")
                  ) {
                    removeSession(itemData.item.id)
                  }
                }}
              >
                <Ionicons name="log-out-outline" size={18} color="black" />

                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 10,
                    color: Colors.text,
                  }}
                >
                  اخراج
                </Text>
              </TouchableOpacity>
            ) : (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row-reverse",
                  gap: 5,
                  borderRadius: 5,
                  backgroundColor: Colors.green,
                  flex: 1,
                  paddingHorizontal: 10,
                  paddingVertical: 1,
                }}
              >
                <Ionicons
                  name="chevron-down-circle-outline"
                  size={18}
                  color="white"
                />

                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 10,
                    color: "white",
                  }}
                >
                  شما
                </Text>
              </View>
            )}
          </View>
        )}
      />

      {activeSessions.length > 1 && (
        <TouchableOpacity
          style={{
            width: "100%",
            paddingHorizontal: 10,
            paddingVertical: 18,
            backgroundColor: "#F74D49",
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => {
            if (
              confirm(
                "آیا از اخراج تمام دستگاه ها به غیر از این دستگاه از حساب کاربری اطمینان دارید؟"
              )
            ) {
              removeAnotherSessions()
            }
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color: "white",
              // fontWeight: "bold",
              marginRight: 5,
            }}
          >
            اخراج دیگر دستگاه ها
          </Text>
        </TouchableOpacity>
      )}

      {activeSessions.length <= 0 && <LoadingPlaceHolder />}
    </View>
  )
}

export default ActiveSessions
