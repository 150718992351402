import React, { useReducer } from "react"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import { FlatList, ScrollView, Text, View } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"
import StatBoxChartLine from "../../components/StatBoxChartLine"

const SET_UPTIME_LIST = "SET_UPTIME_LIST"
const SET_ACTIVE_MONTH = "SET_ACTIVE_MONTH"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_UPTIME_LIST:
      return {
        ...state,
        values: {
          ...state.values,
          uptimeList: action.value,
        },
      }
    case SET_ACTIVE_MONTH:
      return {
        ...state,
        values: {
          ...state.values,
          activeMonthe: action.value,
        },
      }
    default:
      return state
  }
}

const UptimeDaysViewer = ({ uptimeList, activeItem, setActiveItem }) => {
  return (
    <View style={{ width: "100%" }}>
      <FlatList
        data={uptimeList}
        keyExtractor={(item) => Math.random()}
        horizontal={true}
        contentContainerStyle={{ gap: 15, padding: 10 }}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={{
              width: 35,
              height: 100,
              backgroundColor: activeItem === item ? Colors.secondary : Colors.primary,
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => setActiveItem(item)}
          >
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 14,
                color: "#fff",
                transform: [{ rotate: "-90deg" }],
              }}
            >
              1403/02/05
            </Text>
          </TouchableOpacity>
        )}
      />
    </View>
  )
}

const ChartViewer = () => {
  return (
    <View style={{ flex: 1 }}>
      <StatBoxChartLine
        fullSize
        data={{
          labels: [
            1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3,
            4, 1, 2, 3, 4,
          ],
          datasets: [
            {
              label: "فعال بودن سایت",
              data: [
                1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1,
                2, 3, 4, 1, 2, 3, 4,
              ],
              borderColor: Colors.secondary,
              backgroundColor: Colors.secondary,
            },
          ],
        }}
      />
    </View>
  )
}

export default function UptimeScreen() {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      uptimeList: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3,
        4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6,
        7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      ],

      activeMonthe: null,
    },
  })

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="وضعیت سایت" noNotif />

      <ScrollView
        style={{
          flex: 1,
          width: "100%",
          marginBottom: 25,
        }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ gap: 10 }}
      >
        <UptimeDaysViewer
          activeItem={formState.values.activeMonthe}
          setActiveItem={(value) =>
            dispatchFormState({ type: SET_ACTIVE_MONTH, value })
          }
          uptimeList={formState.values.uptimeList}
        />

        <ChartViewer />
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}
