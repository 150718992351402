import { createNativeStackNavigator } from "@react-navigation/native-stack"
import DrawerNavigator from "./DrawerNavigation"
import ActivateInactiveProjectScreen from "../screens/Project/ActivateInactiveProjectScreen"
import ProjectScreen, {
  screenOptions as ProjectScreenOptions,
} from "../screens/Project/ProjectScreen"
import SiteInfoScreen, {
  screenOptions as SiteInfoScreenOptions,
} from "../screens/Project/SiteInfoScreen"
import FormSiteScreen, {
  screenOptions as FormSiteScreenOptions,
} from "../screens/Customer/FormSiteScreen"

import { useSelector } from "react-redux"
import FormLogoScreen, {
  screenOptions as FormLogoScreenOptions,
} from "../screens/Customer/FormLogoScreen"

const StaffStackNavigator = createNativeStackNavigator()
const StaffNavigator = () => {
  const role = useSelector((store) => store.user.user_data.role)
  return (
    <StaffStackNavigator.Navigator>
      <StaffStackNavigator.Screen
        name="Drawer"
        component={DrawerNavigator}
        options={{ headerShown: false }}
      />
      {role == "staff" && (
        <StaffStackNavigator.Screen
          name="ActivateInactiveProject"
          component={ActivateInactiveProjectScreen}
          options={{ headerShown: false, title: "فعالسازی پروژه" }}
        />
      )}
      {role == "staff" && (
        <StaffStackNavigator.Screen
          name="Project"
          component={ProjectScreen}
          options={{ ...ProjectScreenOptions, title: "پروژه" }}
        />
      )}
      {role == "staff" && (
        <StaffStackNavigator.Screen
          name="SiteInfo"
          component={SiteInfoScreen}
          options={{ ...SiteInfoScreenOptions, title: "اطلاعات سایت" }}
        />
      )}
      {role == "staff" && (
        <StaffStackNavigator.Screen
          name="FormSite"
          component={FormSiteScreen}
          options={{ ...FormSiteScreenOptions, title: "فرم اطلاعات سایت" }}
        />
      )}
      {role == "staff" && (
        <StaffStackNavigator.Screen
          name="FormLogo"
          component={FormLogoScreen}
          options={{ ...FormLogoScreenOptions, title: "فرم اطلاعات لوگو" }}
        />
      )}
    </StaffStackNavigator.Navigator>
  )
}

export default StaffNavigator
