import React, { useReducer, useCallback, useState } from "react"
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native"
import Colors from "../../../constants/Colors"
import { separate } from "../../../components/Functions"
import Btn from "../../../components/Btn"
import { useEffect } from "react"
import Config from "../../../constants/Config"
import moment from "moment-jalaali"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_INVOICES = "SET_INVOICES"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case ALERT_SHOW:
      return {
        ...state,
        showAlert: action.show,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_INVOICES:
      return {
        ...state,
        sum: action.data.sum,
        invoices: action.data.invoices,
        stage_id: action.data.project.stage_id,
      }
    default:
      return state
  }
}

const Bill = (props) => {
  const { item } = props.item
  const { token, get_invoices } = props
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const method = () => {
    let result = ""
    if (item.method == "online") {
      result += "آنلاین - "
      if (item.gate == "melli") {
        result += "سداد"
      }
      if (item.gate == "zarin") {
        result += "زرین پال"
      }
    } else {
      if (item.gate == "cbc") result += "کارت به کارت"
      if (item.gate == "paya") result += "پایا"
      if (item.gate == "cash") result += "نقدی"
      if (item.gate == "zarinlink") result += "زرین لینک"
    }
    return result
  }
  const delete_invoice = async (order_id) => {
    const url = Config.aioUrl + "/invoice/delete"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("order_id", order_id)

    setLoading(true)
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        setLoading(false)
        alert(response.data.msg)
        get_invoices()
      })
      .catch(function (error) {
        setLoading(false)
      })
  }
  return (
    <TouchableOpacity
      style={{
        width: "100%",
        height: 120,
        backgroundColor: item.status == "paid" ? Colors.green : "#f2f2f2",
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: clicked ? 60 : 10,
      }}
      onPress={() => {
        if (item.status == "paid") {
          window.open("https://pay.aminh.pro?id=" + item.order_id, "_blank")
        }
        setClicked((prev) => !prev)
      }}
    >
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: item.status == "paid" ? "#fff" : Colors.text,
          }}
        >
          #{item.order_id}
        </Text>
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: item.status == "paid" ? "#fff" : Colors.text,
          }}
        >
          {separate(item.amount.toString())} تومان
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <View style={{ alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 12,
              color: item.status == "paid" ? "#fff" : Colors.text,
              marginBottom: 10,
            }}
          >
            نوع پرداختی
          </Text>
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 14,
              color: item.status == "paid" ? "#fff" : Colors.text,
            }}
          >
            {item.status == "paid" ? method() : "پرداخت نشده"}
          </Text>
        </View>
        <View style={{ alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 12,
              color: item.status == "paid" ? "#fff" : Colors.text,
              marginBottom: 10,
            }}
          >
            تاریخ ثبت
          </Text>
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 14,
              color: item.status == "paid" ? "#fff" : Colors.text,
            }}
          >
            {moment.unix(item.creation_date).format("jYYYY/jM/jD HH:mm")}
          </Text>
        </View>
        <View style={{ alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 12,
              color: item.status == "paid" ? "#fff" : Colors.text,
              marginBottom: 10,
            }}
          >
            تاریخ پرداخت
          </Text>
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 14,
              color: item.status == "paid" ? "#fff" : Colors.text,
            }}
          >
            {item.status == "paid"
              ? moment.unix(item.paid_date).format("jYYYY/jM/jD HH:mm")
              : "پرداخت نشده"}
          </Text>
        </View>
      </View>
      {clicked && item.status != "paid" && (
        <View
          style={{
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            marginTop: 12,
          }}
        >
          <Btn
            title="ثبت دستی"
            onPress={() => {
              props.navigation.navigate("AddTransaction", {
                order_id: item.order_id,
                customer_name: "",
                invoice_cost: item.amount,
              })
            }}
            color={Colors.green}
            wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
          />
          <Btn
            title="مشاهده"
            onPress={() => {
              window.open("https://pay.aminh.pro?id=" + item.order_id, "_blank")
            }}
            color={Colors.blue}
            wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
          />
          <Btn
            title="حذف"
            onPress={() => {
              delete_invoice(item.order_id)
            }}
            color={Colors.primary}
            wrapperStyle={{ flex: 1, marginHorizontal: 2 }}
            loading={loading}
          />
        </View>
      )}
    </TouchableOpacity>
  )
}

const BillsTab = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    showAlert: false,
    loading: false,
    invoices: [],
    sum: {
      cost: "",
      paid: "",
      remaining: "",
    },
    stage_id: "",
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_invoices = async () => {
    const url = Config.aioUrl + "/project/invoices?id=" + props.route.params.id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_INVOICES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    get_invoices()
  }, [])
  if (formState.loading) {
    return <ActivityIndicator size="large" color={Colors.primary} />
  } else {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          paddingTop: 20,
        paddingHorizontal: 10,
      }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            marginBottom: 30,
          }}
        >
          <View
            style={{
              backgroundColor: "#4f4f4f",
              flex: 1,
              height: 65,
              borderRadius: 15,
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            <Text style={Styles.topStatTitle}>مبلغ کل پروژه</Text>
            <Text style={Styles.topStatValue}>
              {separate(formState.sum.cost.toString())} تومان
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#4f4f4f",
              flex: 1,
              height: 65,
              borderRadius: 15,
              justifyContent: "center",
              alignItems: "center",
              marginHorizontal: 5,
            }}
          >
            <Text style={Styles.topStatTitle}>واریزی</Text>
            <Text style={Styles.topStatValue}>
              {separate(formState.sum.paid.toString())} تومان
            </Text>
          </View>
          <View
            style={{
              backgroundColor: "#4f4f4f",
              flex: 1,
              height: 65,
              borderRadius: 15,
              justifyContent: "center",
              alignItems: "center",
              marginRight: 5,
            }}
          >
            <Text style={Styles.topStatTitle}>مانده</Text>
            <Text style={Styles.topStatValue}>
              {separate(formState.sum.remaining.toString())} تومان
            </Text>
          </View>
        </View>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={formState.invoices}
          keyExtractor={(item) => item.order_id}
          renderItem={(item, index) => (
            <Bill
              item={item}
              navigation={props.navigation}
              token={token}
              get_invoices={get_invoices}
            />
          )}
        />
        {formState.stage_id != 10 && formState.stage_id != 18 && (
          <Btn
            title="ساخت صورتحساب"
            wrapperStyle={{ marginBottom: 30 }}
            onPress={() => {
              props.navigation.navigate("Sales", {
                screen: "MakeInvoice",
                params: { project_id: props.route.params.id },
              })
            }}
          />
        )}
      </View>
    )
  }
}

export const screenOptions = {
  headerShown: false,
}

const Styles = StyleSheet.create({
  topStatTitle: {
    fontFamily: "IranSans-Medium",
    fontSize: 14,
    color: "#fff",
    marginBottom: 5,
  },
  topStatValue: {
    fontFamily: "IranSans-Regular",
    fontSize: 12,
    color: "#fff",
    marginTop: 5,
  },
})

export default BillsTab
