import { useReducer, useEffect } from "react";
import { View, Text, FlatList, ActivityIndicator, Platform } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import { useSelector } from "react-redux";
import AwesomeAlert from "react-native-awesome-alerts";
import ProjectCard from "../../components/ProjectCard";
import axios from "axios";
import { logOut } from "../../components/Functions";
import Config from "../../constants/Config";

const ALERT_SHOW = "ALERT_SHOW";
const SET_LOADING = "SET_LOADING";
const SET_PROJECTS = "SET_PROJECTS";
const formReducer = (state, action) => {
  switch (action.type) {
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_PROJECTS:
      return {
        ...state,
        values: {
          ...state.values,
          result: action.data,
        },
      };
    default:
      return state;
  }
};

const InactiveProjectsScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      showAlert: false,
      loading: false,
      result: [],
    },
  });

  const get_projects = async () => {
    const url = Config.aioUrl + "/projects/get?inactive=true";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        if (response.data.success) {
          dispatchFormState({ type: SET_PROJECTS, data: response.data.data });
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_projects();
    });

    return focused;
  }, [props.navigation]);

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="لیست پروژه های غیرفعال" noBack />

      {!formState.values.loading && (
        <View style={{ flex: 1 }}>
          <Text
            style={{
              color: Colors.text,
              fontFamily: "Iransans-Medium",
              fontSize: 14,
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            {formState.values.result.length ? formState.values.result.length : 0} مورد یافت شد
          </Text>

          {formState.values.result != 0 && (
            <FlatList
              data={formState.values.result}
              keyExtractor={(item) => item.id}
              renderItem={(item) => (
                <ProjectCard
                  item={item.item}
                  onPress={
                    Platform.OS == "web"
                      ? { screen: "ActivateInactiveProject", params: { id: item.item.id } }
                      : () => {
                          props.navigation.navigate("ActivateInactiveProject", { id: item.item.id });
                        }
                  }
                />
              )}
            />
          )}
        </View>
      )}
      {formState.values.loading && <ActivityIndicator size="large" color={Colors.primary} style={{ marginTop: 30 }} />}
      <AwesomeAlert
        show={formState.values.showAlert}
        showProgress={false}
        title="خطا"
        message="ابتدا خطاها را بررسی کنید!"
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="متوجه شدم"
        confirmButtonColor={Colors.green}
        onConfirmPressed={() => {
          dispatchFormState({ type: ALERT_SHOW, show: false });
        }}
        titleStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        messageStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        confirmButtonTextStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
      />
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default InactiveProjectsScreen;
