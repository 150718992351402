import { useReducer, useCallback, useEffect } from "react";
import { ScrollView } from "react-native";
import { useSelector } from "react-redux";
import Input from "../../../components/Input";
import InputNote from "../../../components/InputNote";
import Btn from "../../../components/Btn";
import Colors from "../../../constants/Colors";
import File from "../../../components/File";
import Config from "../../../constants/Config";
import axios from "axios";
import { logOut } from "../../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FORM = "SET_FORM";
const SET_FORM_SEND = "SET_FORM_SEND";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      };
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      };

    default:
      return state;
  }
};

const IdentityTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities);
  const role = useSelector((store) => store.user.user_data.role);
  const project_id = props.route.params.project_id;
  const is_customer = props.route.params.is_customer;
  const token = useSelector((store) => store.user.token);
  const setActiveIndex = props.route.params.setActiveIndex;
  const setSteps = props.route.params.setSteps;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      first_name: "",
      last_name: "",
      melli_code: "",
      mobile: "",
      melli_card: null,
      shenasname: null,
      form: null,
      is_staff_in_project: null,
      form_send_progress: 0,
      is_form_section_completed: false,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/update";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("project_id", project_id);
    formdata.append("first_name", formState.values.first_name);
    formdata.append("last_name", formState.values.last_name);
    formdata.append("melli_code", formState.values.melli_code);
    formdata.append("mobile", formState.values.mobile);

    dispatchFormState({ type: SET_LOADING, loading: true });
    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        });
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        return response.data.success;
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const get_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/get?project_id=" + project_id;

    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        let is_form_section_completed = false;
        if (
          response.data.data.siteform_first_name &&
          response.data.data.siteform_last_name &&
          response.data.data.siteform_melli_code &&
          response.data.data.siteform_mobile &&
          response.data.data.siteform_melli_card &&
          response.data.data.siteform_shenasname
        ) {
          is_form_section_completed = true;
        }
        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: is_form_section_completed,
        });
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    get_form();
  }, []);

  
  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote title="اطلاعات هویتی تکمیل شده است." icon="information-circle-outline" backgroundColor={Colors.green} textColor="#fff" />
      )}
      {is_customer && (
        <InputNote
          title="توضیح"
          content="اطلاعات این بخش برای خرید دامنه، هاست و درگاه بانکی با نام و مالکیت شما استفاده می شوند."
          icon="information-circle-outline"
        />
      )}
      <Input
        disabled={
          (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        label="نام"
        id="first_name"
        onChange={inputChangeHandler}
        required
        maxLength={15}
        minLength={2}
        insert={formState.values.form ? formState.values.form.siteform_first_name : ""}
      />
      <Input
        disabled={
          (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        label="نام خانوادگی"
        id="last_name"
        onChange={inputChangeHandler}
        required
        maxLength={15}
        minLength={2}
        insert={formState.values.form ? formState.values.form.siteform_last_name : ""}
      />
      {is_customer && (
        <InputNote
          title="نکته"
          content="دقت کنید که باید مالکیت شماره برای شما باشد. سیستم استعلام کد ملی و شماره موبایل را باهم تطبیق داده و اگر سیم کارت به نام خودتان نباشد درخواست هاست، دامنه و سایر موارد از طرف مراجع مربوطه رد خواهد شد و پیگیری مجدد مستلزم پرداخت هزینه می باشد، لذا نهایت دقت را داشته باشید."
          icon="warning-outline"
          backgroundColor={Colors.primary}
          textColor={"#fff"}
        />
      )}

      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="کد ملی"
          id="melli_code"
          onChange={inputChangeHandler}
          required
          fixedLength={10}
          maxLength={10}
          insert={formState.values.form ? formState.values.form.siteform_melli_code : ""}
          convertDigits
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="شماره موبایل"
          id="mobile"
          onChange={inputChangeHandler}
          required
          maxLength={15}
          minLength={8}
          insert={formState.values.form ? formState.values.form.siteform_mobile : ""}
          convertDigits
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <File
          id="melli_card"
          title="تصویر روی کارت ملی"
          extension="jpg"
          content_type="image/jpeg"
          max_size={2048}
          max_width={1080}
          max_height={1080}
          min_width={300}
          min_height={300}
          required
          token={token}
          onChange={inputChangeHandler}
          insert={
            !formState.values.melli_card && formState.values.form
              ? formState.values.form.siteform_melli_card
                ? formState.values.form.siteform_melli_card
                : formState.values.melli_card
              : formState.values.melli_card
          }
          value={formState.values.melli_card}
          wrapperStyle={{ marginTop: 10 }}
          downloadUrl={Config.aioUrl + "/project/forms/getFormFile"}
          downloadParams={[
            {
              key: "bucket",
              value:
                !formState.values.melli_card && formState.values.form
                  ? formState.values.form.siteform_melli_card
                    ? formState.values.form.siteform_melli_card.bucket
                    : formState.values.melli_card
                  : formState.values.melli_card,
            },
            {
              key: "bucket_key",
              value:
                !formState.values.melli_card && formState.values.form
                  ? formState.values.form.siteform_melli_card
                    ? formState.values.form.siteform_melli_card.bucket_key
                    : formState.values.melli_card
                  : formState.values.melli_card,
            },
          ]}
          editOptions={{
            compress: 0.8,
            resizeHeight: 1080,
            resizeWidth: 1080,
          }}
          uploadUrl={Config.aioUrl + "/project/forms/siteform/fileupload"}
          uploadParams={[{ key: "project_id", value: project_id }]}
          standalone
        />
      )}
      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_hosting")) && (
        <File
          id="shenasname"
          title="تصویر صفحه اول شناسنامه"
          extension="jpg"
          content_type="image/jpeg"
          max_size={2048}
          max_width={1080}
          max_height={1080}
          min_width={300}
          min_height={300}
          required
          token={token}
          onChange={inputChangeHandler}
          insert={
            !formState.values.shenasname && formState.values.form
              ? formState.values.form.siteform_shenasname
                ? formState.values.form.siteform_shenasname
                : formState.values.shenasname
              : formState.values.shenasname
          }
          value={formState.values.shenasname}
          wrapperStyle={{ marginTop: 10 }}
          downloadUrl={Config.aioUrl + "/project/forms/getFormFile"}
          downloadParams={[
            {
              key: "bucket",
              value: formState.values.form
                ? formState.values.form.siteform_shenasname
                  ? formState.values.form.siteform_shenasname.bucket
                  : false
                : false,
            },
            {
              key: "bucket_key",
              value: formState.values.form
                ? formState.values.form.siteform_shenasname
                  ? formState.values.form.siteform_shenasname.bucket_key
                  : false
                : false,
            },
          ]}
          editOptions={{
            compress: 0.8,
            resizeHeight: 1080,
            resizeWidth: 1080,
          }}
          uploadUrl={Config.aioUrl + "/project/forms/siteform/fileupload"}
          uploadParams={[{ key: "project_id", value: project_id }]}
          standalone
          doAfterUpload={get_form}
        />
      )}
      <Btn
        title={
          formState.values.form_send_progress > 0 && formState.values.form_send_progress < 1
            ? "در حال ارسال اطلاعات...(" + (formState.values.form_send_progress * 100).toFixed(0) + "%)"
            : "ثبت اطلاعات هویتی"
        }
        onPress={async () => {
          if (
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            capabilities.includes("role_site_full_access") ||
            role == "customer"
          ) {
            let result = await send_form();
            if (result) {
              await get_form();
              setSteps((prev) => {
                let prevState = prev;
                for (let i = 0; i < prevState.length; i++) {
                  if (prevState[i].name == "اطلاعات مکانی") {
                    prevState[i].status = "active";
                  } else {
                    prevState[i].status = "inactive";
                  }
                }
                return prevState;
              });
              props.navigation.navigate("اطلاعات مکانی");
              setActiveIndex(1);
            }
          } else {
            props.navigation.navigate("اطلاعات مکانی");
            setActiveIndex(1);
          }
        }}
        wrapperStyle={{ marginVertical: 50 }}
      />
    </ScrollView>
  );
};

export default IdentityTab;
