import { useState, useEffect } from "react"
import { View, Text, StyleSheet, Platform } from "react-native"
import Colors from "../constants/Colors"
import SelectBox from "react-native-multi-selectbox"
import { xorBy } from "lodash"
import { Ionicons } from "@expo/vector-icons"

const Picker = (props) => {
  const { id, onChange } = props

  const [selectedTeam, setSelectedTeam] = useState({})
  const [selectedTeams, setSelectedTeams] = useState([])
  function onMultiChange() {
    return (item) => {
      onChange(id, xorBy(selectedTeams, [item], "id"), true)
      setSelectedTeams(xorBy(selectedTeams, [item], "id"))
    }
  }
  function onSingleChange() {
    return (val) => {
      onChange(id, val, true)
      setSelectedTeam(val)
    }
  }
  const insert = props.insert ? props.insert : false

  useEffect(() => {
    if (insert) {
      if (props.isMulti) {
        setSelectedTeams(xorBy(selectedTeams, insert, "id"))
      } else {
        setSelectedTeam(insert)
      }
    }
  }, [insert])
  
  if (props.isMulti) {
    return (
      <View
        pointerEvents={props.disabled ? "none" : "auto"}
        style={{ opacity: props.disabled ? 0.6 : 1 }}
      >
        <View style={Styles.textInputWrapper}>
          <SelectBox
            label={props.label}
            options={props.options}
            selectedValues={selectedTeams}
            onMultiSelect={onMultiChange()}
            onTapClose={onMultiChange()}
            isMulti
            labelStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              color: Colors.secondText,
              position: "absolute",
              top: 7,
              right: 20,
              backgroundColor: Colors.bg,
              paddingHorizontal: 7,
              zIndex: 1,
            }}
            containerStyle={{
              width: "100%",
              minHeight: 50,
              borderWidth: 0.8,
              borderBottomWidth: 0.8,
              borderRadius: 7,
              borderColor: Colors.secondText,
              marginVertical: 15,
              direction: "rtl",
              paddingRight: 0,
              flexDirection: "reverse-row",
              paddingLeft: 50,
            }}
            inputPlaceholder=""
            multiListEmptyLabelStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              marginTop: 10,
            }}
            toggleIconColor={Colors.blue}
            selectIcon={
              <Ionicons
                name="caret-down"
                size={20}
                color={Colors.secondText}
                style={{
                  width: 50,
                  position: "absolute",
                  left: -40,
                  top: -30,
                }}
              />
            }
            inputFilterContainerStyle={{
              direction: "rtl",
              display: props.noSearch ? "none" : "flex",
            }}
            inputFilterStyle={
              Platform.OS === "web"
                ? {
                    direction: "rtl",
                    fontFamily: "IranSans-Light",
                    fontSize: 13,
                    outlineStyle: "none",
                  }
                : {
                    direction: "rtl",
                    fontFamily: "IranSans-Light",
                    fontSize: 13,
                  }
            }
            optionsLabelStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              color: Colors.text,
            }}
            optionContainerStyle={{
              direction: "rtl",
              borderColor: "#e8e8e8",
              borderBottomWidth: 0.5,
            }}
            searchIconColor={Colors.blue}
            multiOptionContainerStyle={{
              backgroundColor: Colors.blue,
              direction: "rtl",
              paddingLeft: 0,
              paddingRight: 13,
              height: 25,
              marginTop: 6,
            }}
            multiOptionsLabelStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              marginLeft: 3,
            }}
          />
        </View>
      </View>
    )
  } else {
    return (
      <View
        pointerEvents={props.disabled ? "none" : "auto"}
        style={{ opacity: props.disabled ? 0.6 : 1 }}
      >
        <View style={Styles.textInputWrapper}>
          <SelectBox
            value={selectedTeam}
            onChange={onSingleChange()}
            label={props.label}
            options={props.options}
            labelStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              color: Colors.secondText,
              position: "absolute",
              top: 7,
              right: 20,
              backgroundColor: Colors.bg,
              paddingHorizontal: 7,
              zIndex: 1,
            }}
            containerStyle={{
              width: "100%",
              minHeight: 50,
              borderWidth: 0.8,
              borderBottomWidth: 0.8,
              borderRadius: 7,
              borderColor: Colors.secondText,
              marginVertical: 15,
              direction: "rtl",
              paddingRight: 0,
              flexDirection: "reverse-row",
              paddingLeft: 50,
            }}
            inputPlaceholder=" "
            toggleIconColor={Colors.blue}
            selectIcon={
              <Ionicons
                name="caret-down"
                size={20}
                color={Colors.secondText}
                style={{
                  width: 50,
                  position: "absolute",
                  left: -40,
                  top: -30,
                }}
              />
            }
            inputFilterContainerStyle={{
              direction: "rtl",
              display: props.noSearch ? "none" : "flex",
            }}
            inputFilterStyle={
              Platform.OS == "web"
                ? {
                    direction: "rtl",
                    fontFamily: "IranSans-Light",
                    fontSize: 13,
                    outlineStyle: "none",
                  }
                : {
                    direction: "rtl",
                    fontFamily: "IranSans-Light",
                    fontSize: 13,
                  }
            }
            optionsLabelStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              color: Colors.text,
            }}
            optionContainerStyle={{
              direction: "rtl",
              borderColor: "#e8e8e8",
              borderBottomWidth: 0.5,
            }}
            searchIconColor={Colors.blue}
            selectedItemStyle={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              color: Colors.text,
              marginTop: 10,
            }}
          />
        </View>
      </View>
    )
  }
}

const Styles = StyleSheet.create({
  textInputWrapper: {
    width: "100%",
  },
})

export default Picker
