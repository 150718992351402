import { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";
import Colors from "../constants/Colors";

const ExpoPicker = (props) => {
  const { id, onChange } = props;
  const [selectedItem, setSelectedItem] = useState();
  const options = props.options ? props.options : [];
  const insert = props.insert ? props.insert : false;
  useEffect(() => {
    if (insert) {
      setSelectedItem(insert);
      onChange(id, insert);
    }
  }, [insert]);
  return (
    <View pointerEvents={props.disabled ? "none" : "auto"} style={{ opacity: props.disabled ? 0.6 : 1 }}>
      <Text
        style={{
          fontFamily: "IranSans-Light",
          fontSize: 13,
          position: "absolute",
          top: 8,
          right: 20,
          color: Colors.secondText,
          backgroundColor: "#fff",
          paddingHorizontal: 3,
        }}
      >
        {props.label}
      </Text>
      <Picker
        selectedValue={selectedItem}
        onValueChange={(itemValue, itemIndex) => {
          setSelectedItem(itemValue);
          onChange(id, itemValue, true);
        }}
        style={{
          width: "100%",
          height: 50,
          borderWidth: 0.8,
          borderBottomWidth: 0.8,
          borderRadius: 7,
          borderColor: Colors.secondText,
          marginVertical: 15,
          direction: "rtl",
          paddingRight: 10,
          flexDirection: "reverse-row",
          paddingLeft: 20,
        }}
      >
        <Picker.Item label="انتخاب کنید" value="" />
        {options.map((item, index) => (
          <Picker.Item key={index} label={item.item} value={item.id} />
        ))}
      </Picker>
    </View>
  );
};

export default ExpoPicker;
