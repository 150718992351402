import { createNativeStackNavigator } from "@react-navigation/native-stack";
import GetPriceScreen, { screenOptions as GetPriceScreenOptions } from "../screens/Outsider/GetPriceScreen";
import LogoVideoScreen, { screenOptions as LogoVideoScreenOptions } from "../screens/Outsider/LogoVideoScreen";
import SiteVideoScreen, { screenOptions as SiteVideoScreenOptions } from "../screens/Outsider/SiteVideoScreen";

const OutsiderStackNavigator = createNativeStackNavigator();
const OutsiderNavigator = () => {
  return (
    <OutsiderStackNavigator.Navigator>
      <OutsiderStackNavigator.Screen name="GetPrice" component={GetPriceScreen} options={{ ...GetPriceScreenOptions, title: "دریافت تعرفه" }} />
      <OutsiderStackNavigator.Screen name="LogoVideo" component={LogoVideoScreen} options={{ ...LogoVideoScreenOptions, title: "ویدیو لوگو" }} />
      <OutsiderStackNavigator.Screen name="SiteVideo" component={SiteVideoScreen} options={{ ...SiteVideoScreenOptions, title: "ویدیو سایت" }} />
    </OutsiderStackNavigator.Navigator>
  );
};

export default OutsiderNavigator;
