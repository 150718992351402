import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  FlatList,
  ScrollView,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../../constants/Colors"
import Header from "../../../components/Header"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Btn from "../../../components/Btn"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_TICKETS = "SET_TICKETS"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_TICKETS:
      return {
        ...state,
        values: {
          ...state.values,
          tickets: action.data,
        },
      }
    default:
      return state
  }
}

const SupportListScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      tickets: [],
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_tickets = async () => {
    const url = Config.aioUrl + "/customer/ticket/list"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        let sortedData = response.data.data.sort((a) => {
          if (a.status == "closed") {
            return 1
          }
          if (
            a.status == "answered" ||
            a.status == "needanswer" ||
            a.status == "pending"
          ) {
            return -1
          }
          return 0
        })
        dispatchFormState({ type: SET_TICKETS, data: sortedData })
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_tickets()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="تیکت ها" noNotif noActiveSessions noToggle />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingHorizontal: DESKTOP ? "30%" : 0, paddingBottom: 25 }}
      >
        <View style={{ flexDirection: "row-reverse" }}>
          <View style={{ flex: 1, paddingBottom: 20 }}>
            <Btn
              title="ارسال تیکت جدید"
              color={Colors.secondary}
              titleStyle={{ fontSize: 12 }}
              onPress={() => {
                props.navigation.navigate("AddTicket")
              }}
            />
          </View>
          <View style={{ flex: 2 }}></View>
        </View>
        <FlatList
          data={formState.values.tickets}
          keyExtractor={(item) => item.id}
          renderItem={(item) => (
            <TouchableOpacity
              style={{
                width: "100%",
                backgroundColor: "#F2F2F2",
                borderRadius: 15,
                padding: 20,
                marginBottom: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onPress={() => {
                props.navigation.navigate("Ticket", { ticket_id: item.item.id })
              }}
            >
              <View
                style={{
                  width: 80,
                  height: 25,
                  backgroundColor:
                    item.item.status === "answered"
                      ? Colors.green
                      : item.item.status === "closed"
                      ? Colors.primary
                      : item.item.status === "pending"
                      ? Colors.error
                      : Colors.blue,
                  borderRadius: 7,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-regular",
                    fontSize: 10,
                    color: "#fff",
                  }}
                >
                  {item.item.status === "answered"
                    ? "پاسخ داده شده"
                    : item.item.status === "closed"
                    ? "بسته"
                    : item.item.status === "pending"
                    ? "در حال بررسی"
                    : "در انتظار پاسخ"}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  {item.item.title}
                </Text>
                <Text
                  style={{
                    fontFamily: "IranSans-regular",
                    fontSize: 10,
                    color: Colors.text,
                    marginTop: 7,
                  }}
                >
                  {item.item.department == "site"
                    ? "درخواست پشتیبانی سایت"
                    : "درخواست به فروش"}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        />
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default SupportListScreen
