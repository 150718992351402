import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import { Ionicons } from "@expo/vector-icons"
import InputNote from "../../components/InputNote"
import { logOut } from "../../components/Functions"
import axios from "axios"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_COURSES = "SET_COURSES"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_COURSES:
      return {
        ...state,
        values: {
          ...state.values,
          courses: action.data,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const CoursesListScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const role = useSelector((store) => store.user.user_data.role)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      courses: [],
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_courses = async () => {
    let url = null

    dispatchFormState({ type: SET_LOADING, loading: true })

    if (role === "customer") {
      url = Config.aioUrl + "/customer/lms/course/list"
    } else {
      url = Config.aioUrl + "/employee/lms/course/list"
    }

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_COURSES, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_courses()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="دوره های من" noNotif noActiveSessions noToggle />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <View style={{ flex: 1, paddingBottom: 25 }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ paddingBottom: 50, maxHeight: "80vh" }}
          >
            {formState.values.loading ? (
              <>
                <LoadingPlaceHolder />
              </>
            ) : (
              <>
                {formState.values.courses.map((item) => (
                  <TouchableOpacity
                    key={item.id}
                    style={{
                      width: "100%",
                      backgroundColor: "#F2F2F2",
                      borderRadius: 15,
                      padding: 20,
                      marginBottom: 10,
                      flexDirection: "row-reverse",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      props.navigation.navigate("Course", {
                        course_id: item.id,
                      })
                    }}
                  >
                    <Ionicons name="school" color={Colors.text} size={24} />
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 14,
                        color: Colors.text,
                        marginRight: 5,
                      }}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                ))}
                {formState.values.courses.length == 0 && (
                  <InputNote content="شما هیچ دوره آموزشی فعالی ندارید" />
                )}
              </>
            )}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default CoursesListScreen
