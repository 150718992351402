import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: "",
    capabilities: [],
    user_data: {},
    key_encrypt: "",
    key_decrypt: "",
  },
  reducers: {
    setCred: (state, action) => {
      state.token = action.payload.token;
      state.capabilities = action.payload.capabilities ? action.payload.capabilities : [];
      state.user_data = action.payload.user_data;
      state.key_encrypt = action.payload.key_encrypt ? action.payload.key_encrypt : "";
      state.key_decrypt = action.payload.key_decrypt ? action.payload.key_decrypt : "";
    },
    removeCred: (state, action) => {
      state.token = "";
      state.capabilities = "";
      state.user_data = "";
      state.key_encrypt = "";
      state.key_decrypt = "";
    },
  },
});

export const { setCred, removeCred } = userSlice.actions;

export default userSlice.reducer;
