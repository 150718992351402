import { useCallback, useEffect, useReducer } from "react"
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Input from "../../components/Input"
import { useSelector } from "react-redux"
import Site from "../../components/Site"
import filter from "lodash.filter"
import axios from "axios"
import Config from "../../constants/Config"
import { logOut } from "../../components/Functions"
import FilterGroup, {
  FilterGroupParent,
  SET_ACTIVE_FILTER,
} from "../../components/FilterGroup"
import { Ionicons } from "@expo/vector-icons"

const SEARCH = "SEARCH"
const SET_LOADING = "SET_LOADING"
const SET_SITES = "SET_SITES"
const formReducer = (state, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          query: action.query,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SITES:
      return {
        ...state,
        values: {
          ...state.values,
          sites: action.data,
          asc_latest_version: action.asc_latest_version,
        },
      }
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.id]: action.value,
        },
      }
    default:
      return state
  }
}

const SitesList = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_data = useSelector((store) => store.user.user_data)
  const capabilities = useSelector((store) => store.user.capabilities)
  const key_encrypt = useSelector((store) => store.user.key_encrypt)
  const key_decrypt = useSelector((store) => store.user.key_decrypt)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      data: [],
      sites: [],
      loading: false,
      asc_latest_version: 0,
    },

    filters: {
      status: [
        { id: "all", title: "همه" },
        { id: "active", title: "فعال" },
        { id: "inactive", title: "غیرفعال" },
      ],
      active_status: "all",
    },
  })

  const getSitesList = async () => {
    const url = Config.aioUrl + "/asc/site/list"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_SITES,
          data: response.data.data,
          asc_latest_version: response.data.asc_latest_version,
        })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    getSitesList()
  }, [])

  const handleSearch = useCallback(
    (id, text) => {
      const contains = (item, query) => {
        if (
          `${item.first_name} ${item.last_name} ${
            user_data.department == "lead" ? item.phone || "" : ""
          } ${item.url}`.includes(query)
        ) {
          return true
        }
        return false
      }
      const data = filter(formState.values.sites, (item) =>
        contains(item, text)
      )
      dispatchFormState({ type: SEARCH, data, query: text })
    },
    [dispatchFormState, formState.values.sites]
  )

  // Filter =>
  const activeFilter = formState.filters.active_status
  let wbsiteUpStatus = null
  let isTarget

  const siteListArray = [...formState.values.data].filter((site) => {
    wbsiteUpStatus = site?.uptime?.up == 1 ? "active" : "inactive"

    isTarget = activeFilter === "all" || wbsiteUpStatus === activeFilter

    // Hnadel filter
    return isTarget
  })

  const siteListLength = formState.values.data.length

  const activeSiteListLength = [...formState.values.data].filter(
    (site) => site?.uptime?.up == 1
  ).length

  const inActiveSiteListLength = [...formState.values.data].filter(
    (site) => site?.uptime?.up != 1
  ).length

  return (
    <View style={styles.wrapper}>
      <Header title="لیست سایت ها" noBack />

      <FilterGroupParent>
        <FilterGroup
          id="active_status"
          title="وضعیت"
          dispatchFormState={dispatchFormState}
          list={formState.filters.status}
          active_value={formState.filters.active_status}
        />
      </FilterGroupParent>

      <Input
        label="به دنبال چه می گردی؟"
        id="search"
        onChange={handleSearch}
        maxLength={20}
        validity
      />

      {!formState.values.loading && (
        <>
          <View
            style={{
              flexDirection: "row-reverse",
              gap: 18,
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <View
              style={{
                flexDirection: "row-reverse",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                marginBottom: 10,
              }}
            >
              <Ionicons name="flash" size={18} color={Colors.secondText} />

              <Text
                style={{
                  color: Colors.text,
                  fontFamily: "Iransans-Medium",
                  fontSize: 14,
                }}
              >
                {siteListLength} مورد یافت شد
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row-reverse",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                marginBottom: 10,
              }}
            >
              <Ionicons name="flash" size={18} color={Colors.green} />

              <Text
                style={{
                  color: Colors.text,
                  fontFamily: "Iransans-Medium",
                  fontSize: 14,
                }}
              >
                {activeSiteListLength} مورد سایت فعال
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row-reverse",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                marginBottom: 10,
              }}
            >
              <Ionicons name="flash" size={18} color={Colors.primary} />

              <Text
                style={{
                  color: Colors.text,
                  fontFamily: "Iransans-Medium",
                  fontSize: 14,
                }}
              >
                {inActiveSiteListLength} مورد سایت غیرفعال
              </Text>
            </View>
          </View>

          <View style={{ flex: 1, width: "100%", height: "100%" }}>
            <View style={{ flexDirection: "row-reverse" }}>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  height: 50,
                  borderTopRightRadius: 15,
                  borderBottomRightRadius: 15,
                  flex: 1,
                }}
              >
                <Text style={styles.headerText}>کد سایت</Text>
              </View>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  height: 50,
                  flex: 1,
                }}
              >
                <Text style={styles.headerText}>asc</Text>
              </View>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  height: 50,
                  flex: 1,
                }}
              >
                <Text style={styles.headerText}>وضعیت</Text>
              </View>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  height: 50,
                  flex: 2,
                }}
              >
                <Text style={styles.headerText}>آدرس سایت</Text>
              </View>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  height: 50,
                  flex: 2,
                }}
              >
                <Text style={styles.headerText}>نام</Text>
              </View>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  flex: 2,
                  height: 50,
                }}
              >
                <Text style={styles.headerText}>نام خانوادگی</Text>
              </View>

              {user_data.department == "lead" && (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: Colors.secondary,
                    flex: 2,
                    height: 50,
                  }}
                >
                  <Text style={styles.headerText}>شماره موبایل</Text>
                </View>
              )}

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.secondary,
                  flex: 2,
                  height: 50,
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                }}
              >
                <Text style={styles.headerText}>عملیات</Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <FlatList
                data={siteListArray}
                keyExtractor={(item) => item.id}
                renderItem={(item) => {
                  console.log(item.item?.uptime?.status)

                  return (
                    <Site
                      id={item.item.id}
                      first_name={item.item.first_name}
                      last_name={item.item.last_name}
                      site_url={item.item.url}
                      asc_version={item.item.asc_version}
                      asc_latest_version={formState.values.asc_latest_version}
                      phone={item.item.phone}
                      token={token}
                      key_encrypt={key_encrypt}
                      key_decrypt={key_decrypt}
                      capabilities={capabilities}
                      user_data={user_data}
                      showUpStatus={true}
                      isWebsiteUp={item.item?.uptime?.up == 1}
                      websiteUpStatusCode={item.item?.uptime?.status}
                    />
                  )
                }}
              />
            </View>
          </View>
        </>
      )}

      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
  headerText: {
    fontFamily: "IranSans-Medium",
    fontSize: 14,
    color: "#fff",
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default SitesList
