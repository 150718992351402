export const minutes = [
  { item: "00", id: "00" },
  { item: "01", id: "01" },
  { item: "02", id: "02" },
  { item: "03", id: "03" },
  { item: "04", id: "04" },
  { item: "05", id: "05" },
  { item: "06", id: "06" },
  { item: "07", id: "07" },
  { item: "08", id: "08" },
  { item: "09", id: "09" },
  { item: "10", id: "10" },
  { item: "11", id: "11" },
  { item: "12", id: "12" },
  { item: "13", id: "13" },
  { item: "14", id: "14" },
  { item: "15", id: "15" },
  { item: "16", id: "16" },
  { item: "17", id: "17" },
  { item: "18", id: "18" },
  { item: "19", id: "19" },
  { item: "20", id: "20" },
  { item: "21", id: "21" },
  { item: "22", id: "22" },
  { item: "23", id: "23" },
  { item: "24", id: "24" },
  { item: "25", id: "25" },
  { item: "26", id: "26" },
  { item: "27", id: "27" },
  { item: "28", id: "28" },
  { item: "29", id: "29" },
  { item: "30", id: "30" },
  { item: "31", id: "31" },
  { item: "32", id: "32" },
  { item: "33", id: "33" },
  { item: "34", id: "34" },
  { item: "35", id: "35" },
  { item: "36", id: "36" },
  { item: "37", id: "37" },
  { item: "38", id: "38" },
  { item: "39", id: "39" },
  { item: "40", id: "40" },
  { item: "41", id: "41" },
  { item: "42", id: "42" },
  { item: "43", id: "43" },
  { item: "44", id: "44" },
  { item: "45", id: "45" },
  { item: "46", id: "46" },
  { item: "47", id: "47" },
  { item: "48", id: "48" },
  { item: "49", id: "49" },
  { item: "50", id: "50" },
  { item: "51", id: "51" },
  { item: "52", id: "52" },
  { item: "53", id: "53" },
  { item: "54", id: "54" },
  { item: "55", id: "55" },
  { item: "56", id: "56" },
  { item: "57", id: "57" },
  { item: "58", id: "58" },
  { item: "59", id: "59" },
];

export const hours = [
  { item: "00", id: "00" },
  { item: "01", id: "01" },
  { item: "02", id: "02" },
  { item: "03", id: "03" },
  { item: "04", id: "04" },
  { item: "05", id: "05" },
  { item: "06", id: "06" },
  { item: "07", id: "07" },
  { item: "08", id: "08" },
  { item: "09", id: "09" },
  { item: "10", id: "10" },
  { item: "11", id: "11" },
  { item: "12", id: "12" },
  { item: "13", id: "13" },
  { item: "14", id: "14" },
  { item: "15", id: "15" },
  { item: "16", id: "16" },
  { item: "17", id: "17" },
  { item: "18", id: "18" },
  { item: "19", id: "19" },
  { item: "20", id: "20" },
  { item: "21", id: "21" },
  { item: "22", id: "22" },
  { item: "23", id: "23" },
  { item: "24", id: "24" },
];
