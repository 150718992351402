import { useEffect, useReducer } from "react"
import { View, Text, Image, Dimensions, ActivityIndicator } from "react-native"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import { useSelector } from "react-redux"
import axios from "axios"
import Config from "../../constants/Config"
import { logOut } from "../../components/Functions"
import { Ionicons } from "@expo/vector-icons"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const SET_LOADING = "SET_LOADING"
const SET_SUBSCRIBTION = "SET_SUBSCRIBTION"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      }
    case SET_SUBSCRIBTION:
      return {
        ...state,
        is_subscribed: action.value,
      }
    default:
      return state
  }
}

const WhyNotifItem = (props) => {
  return (
    <View style={{ flexDirection: "row-reverse", alignItems: "center" }}>
      <Ionicons name="checkmark-circle" color={Colors.green} size={26} />
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 14,
          color: Colors.text,
          marginRight: 5,
        }}
      >
        {props.why}
      </Text>
    </View>
  )
}

const EnableNotifScreen = (props) => {
  const user_data = useSelector((store) => store.user.user_data)
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    loading: false,
    is_subscribed: false,
  })
  const notif_support = () => {
    if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
      return false
    } else {
      return true
    }
  }

  const go_next = () => {
    if (user_data.role == "staff") {
      if (user_data.department == "site" || user_data.department == "lead") {
        props.navigation.navigate("Auth", { screen: "SetKey" })
      } else {
        props.navigation.navigate("StaffNavigator")
      }
    }

    if (user_data.role == "customer") {
      props.navigation.navigate("CustomerNavigator")
    }
  }

  const update_user_active_login = async (subscription) => {
    const url = Config.aioUrl + "/notifications/saveSubscription"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, value: true })
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(subscription),
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, value: false })
        go_next()
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, value: false })
        console.log(error)
      })
  }

  const check_is_subscribed = async () => {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((subscription) => {
          if (
            subscription != false &&
            subscription != null &&
            subscription != ""
          ) {
            dispatchFormState({ type: SET_SUBSCRIBTION, value: true })
            update_user_active_login(subscription)
          }
        })
    })
  }

  const enable_notif = async () => {
    const permission = await window.Notification.requestPermission()
    // default: user has dismissed the notification permission popup by clicking on x
    if (permission == "granted") {
      // const swRegistration = await navigator.serviceWorker.register(
      //   "https://aio.aminh.pro/service.js?token=" + encodeURI(token)
      // )
      const swRegistration = await navigator.serviceWorker.register(
        "https://aio.aminh.pro/service.js?token=" + encodeURI(token)
      )
      go_next()
    }
    if (permission == "denied") {
      update_user_active_login({ status: "denied" })
      alert(
        "حیف شد! کلی قابلیت رو از دست دادید. به پشتیبانی پیام بدید تا برای فعالسازی نوتیفیکیشن راهنماییتون کنند."
      )
      go_next()
    }
  }

  useEffect(() => {
    if (!notif_support()) {
      update_user_active_login({ status: "unsupported" })
      go_next()
    }

    check_is_subscribed()
  }, [])

  return (
    <View style={{ width: "100%", height: "100%" }}>
      {formState.is_subscribed && (
        <View
          style={{
            paddingHorizontal: DESKTOP ? "30%" : 25,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: Colors.bg,
          }}
        >
          <ActivityIndicator color={Colors.primary} size="large" />
        </View>
      )}
      {!formState.is_subscribed && (
        <View
          style={{
            paddingHorizontal: DESKTOP ? "30%" : 25,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: Colors.bg,
          }}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Image
              style={{ width: 250, height: 250 }}
              source={require("../../assets/img/push-notifications-illustration.jpg")}
            />
          </View>
          <View style={{ width: "100%", justifyContent: "flex-end" }}>
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 16,
                color: Colors.text,
                marginBottom: 10,
              }}
            >
              با فعالسازی نوتیفیکیشن:
            </Text>
            <WhyNotifItem why="از وضعیت لحظه ای پروژه خود آگاه می شوید" />
            <WhyNotifItem why="روند پشتیبانی شما سریع تر می شود" />
            <WhyNotifItem why="در صورت بروزرسانی دوره آموزشی مطلع می شوید" />
          </View>
          <Btn
            title="فعال کردن نوتیفیکیشن"
            color={Colors.green}
            onPress={async () => {
              await enable_notif()
            }}
            wrapperStyle={{ marginTop: 50 }}
          />
          <Btn
            title="خیر، نمی خواهم فعال کنم"
            color={Colors.secondText}
            onPress={async () => {
              update_user_active_login({ status: "skipped" })
              alert("حیف شد! کلی قابلیت رو از دست دادید.")
              go_next()
            }}
            wrapperStyle={{ marginTop: 20 }}
          />
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default EnableNotifScreen
