import { useReducer, useCallback, useEffect } from "react"
import { View, ScrollView } from "react-native"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import File from "../../../components/File"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import Btn from "../../../components/Btn"
import Colors from "../../../constants/Colors"

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_FILES = "SET_FILES"
const SET_COLORS_LOADING = "SET_COLORS_LOADING"
const SET_COLORS_DATA = "SET_COLORS_DATA"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }

    case SET_COLORS_DATA:
      return {
        ...state,
        hasArsoo: action.value.hasArsoo,
        color_one: action.value.color_one,
        color_two: action.value.color_two,
        color_three: action.value.color_three,
        color_four: action.value.color_four,
      }

    case ALERT_SHOW:
      return {
        ...state,
        showAlert: action.show,
      }

    case SET_COLORS_LOADING:
      return {
        ...state,
        colorsLoading: action.loading,
      }

    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }

    case SET_FILES:
      return {
        ...state,
        files: action.data,
      }

    default:
      return state
  }
}

const FilesTab = (props) => {
  const token = useSelector((store) => store.user.token)
  const id = props.route.params.id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    showAlert: false,
    loading: false,
    files: [],

    // Colors (Arsoo)
    colorsLoading: false,
    hasArsoo: false,
    color_one: "",
    color_two: "",
    color_three: "",
    color_four: "",
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_files = async () => {
    const url = Config.aioUrl + "/project/file/list?id=" + id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_FILES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const colorsGetHandler = () => {
    const url = Config.aioUrl + "/project/colors/get?project_id=" + id

    const reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then((response) => {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        console.log(response.data)

        dispatchFormState({ type: SET_LOADING, loading: false })

        dispatchFormState({
          type: SET_COLORS_DATA,
          value: {
            hasArsoo: response?.data?.hasArsoo,
            color_one: response?.data?.data?.colors?.color1,
            color_two: response?.data?.data?.colors?.color2,
            color_three: response?.data?.data?.colors?.color3,
            color_four: response?.data?.data?.colors?.color4,
          },
        })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => dispatchFormState({ type: SET_LOADING, loading: false }))
  }

  const colorSendHandler = () => {
    if (!formState.hasArsoo) return alert("این پروژه پکیج هویت بصری ندارد.")

    if (
      (formState.color_one && !formState.color_one?.includes("#")) ||
      (formState.color_two && !formState.color_two?.includes("#")) ||
      (formState.color_three && !formState.color_three?.includes("#")) ||
      (formState.color_four && !formState.color_four?.includes("#"))
    )
      return alert("رنگ را باید با فرمت Hex وارد کنید ")

    const url = Config.aioUrl + "/project/colors/set"

    const reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let reqData = {
      project_id: id,
      color1: formState.color_one,
      color2: formState.color_two,
      color3: formState.color_three,
      color4: formState.color_four,
    }

    dispatchFormState({ type: SET_COLORS_LOADING, loading: true })

    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then((response) => {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        alert(
          response.data.msg ||
            response.data.message ||
            (response.data.success ? "عملیات با موفقیت انجام شد" : "عملیات با خطایی مواجه شد")
        )
      })
      .catch((error) => {
        alert("مشکلی در ارسال رنگ ها هست! مجدد امتحان کنید.")
        console.log(error)
      })
      .finally(() => dispatchFormState({ type: SET_COLORS_LOADING, loading: false }))
  }

  useEffect(() => {
    colorsGetHandler()
    get_files()
  }, [])

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingTop: 20,
        paddingHorizontal: 10,
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50, paddingTop: 0 }}>
        {formState.hasArsoo && (
          <View style={{ marginBottom: 25 }}>
            <InputNote
              title="رنگ سازمانی"
              content="در بخش زیر چهار رنگ سازمانی پروژه را جهت اجرای ست اداری و پکیج هویت بصری کاربر انتخاب کنید، توجه داشته باشید رنگ ها باید به شکل Hex وارد شوند و وارد کردن مابقی فرمت های رنگی مجاز نمیباشد"
            />

            <View style={{ flexDirection: "row-reverse", gap: 5 }}>
              <Input
                label="رنگ اول"
                id="color_one"
                color={
                  (formState.color_one?.includes("#") &&
                  !formState.color_one.includes("ffffff") &&
                  formState.color_one?.toLowerCase() !== "#fff"
                    ? formState.color_one
                    : undefined) || undefined
                }
                insert={formState.color_one}
                onChange={inputChangeHandler}
                editable
                textAlign="center"
                containerStyle={{ width: "auto", flex: 1 }}
              />

              <Input
                label="رنگ دوم"
                id="color_two"
                color={
                  formState.color_two?.includes("#") &&
                  !formState.color_two.includes("ffffff") &&
                  formState.color_two?.toLowerCase() !== "#fff"
                    ? formState.color_two
                    : undefined
                }
                insert={formState.color_two}
                onChange={inputChangeHandler}
                editable
                textAlign="center"
                containerStyle={{ width: "auto", flex: 1 }}
              />

              <Input
                label="رنگ سوم"
                id="color_three"
                color={
                  (formState.color_three?.includes("#") &&
                  !formState.color_three.includes("ffffff") &&
                  formState.color_three?.toLowerCase() !== "#fff"
                    ? formState.color_three
                    : undefined) || undefined
                }
                insert={formState.color_three}
                onChange={inputChangeHandler}
                editable
                textAlign="center"
                containerStyle={{ width: "auto", flex: 1 }}
              />

              <Input
                label="رنگ چهارم"
                id="color_four"
                color={
                  (formState.color_four?.includes("#") &&
                  !formState.color_four.includes("ffffff") &&
                  formState.color_four?.toLowerCase() !== "#fff"
                    ? formState.color_four
                    : undefined) || undefined
                }
                insert={formState.color_four}
                onChange={inputChangeHandler}
                editable
                textAlign="center"
                containerStyle={{ width: "auto", flex: 1 }}
              />
            </View>

            <Btn
              loading={formState.colorsLoading}
              title="ثبت رنگ ها"
              onPress={colorSendHandler}
              color={Colors.blue}
            />
          </View>
        )}

        {formState.files.map((item, index) => (
          <File
            standalone
            key={item.id}
            id={item.id}
            title={item.title}
            extension={item.extension}
            max_size={item.max_size}
            max_width={item.max_width}
            max_height={item.max_height}
            min_width={item.min_width}
            min_height={item.min_height}
            content_type={item.content_type}
            required={item.required == 1 ? true : false}
            token={token}
            insert={item.bucket_key ? item : false}
            value={formState[item.id]}
            uploadUrl={Config.aioUrl + "/project/file/upload"}
            downloadUrl={Config.aioUrl + "/project/file/get"}
            downloadParams={[
              {
                key: "id",
                value: item.id,
              },
            ]}
            // editOptions={{
            //   compress: 0.8,
            //   resizeHeight: item.max_height,
            //   resizeWidth: item.max_width,
            // }}
            doAfterUpload={get_files}
          />
        ))}
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default FilesTab
