import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import AuthNavigator from "./AuthNavigation"
import CustomerNavigator from "./CustomerNavigation"
import StaffNavigator from "./StaffNavigation"
import OutsiderNavigator from "./OutsiderNavigation"

const MainStackNavigator = createNativeStackNavigator()
const MainNavigator = () => {
  return (
    <MainStackNavigator.Navigator>
      <MainStackNavigator.Screen
        name="Auth"
        component={AuthNavigator}
        options={{ headerShown: false }}
      />
      <MainStackNavigator.Screen
        name="StaffNavigator"
        component={StaffNavigator}
        options={{ headerShown: false }}
      />
      <MainStackNavigator.Screen
        name="CustomerNavigator"
        component={CustomerNavigator}
        options={{ headerShown: false }}
      />
      <MainStackNavigator.Screen
        name="OutsiderNavigator"
        component={OutsiderNavigator}
        options={{ headerShown: false }}
      />
    </MainStackNavigator.Navigator>
  )
}

export default MainNavigator
