import React, { useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToRaw, EditorState, ContentState, Modifier } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { View } from "react-native"
import { useEffect } from "react"

export default function TextEditor({ value, setValue }) {
  const [draft, setDraft] = useState("")

  const styleString = `
    <style>
      .rdw-editor-toolbar * {
        font-family: "IranSans-Regular" !important;
      }
      
      .rdw-editor-toolbar {
        padding: 15px !important;
        gap: 8px !important;
        background-color: #333;
      }
      
      .rdw-editor-toolbar .rdw-option-wrapper {
        border: unset !important;
        outline: unset !important;
        padding: 8px !important;
        transition: 0.5;
      }
      
      .rdw-editor-toolbar .rdw-option-wrapper:hover {
        opacity: 80% !important;
        border: unset !important;
        outline: unset !important;
      }
    </style>  
  `

  const onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)

    setDraft(editorState)
    setValue(markup)
  }

  const insertValue = (value) => {
    const blocksFromHtml = htmlToDraft(value)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    const editorState = EditorState.createWithContent(contentState)
    // const rawContentState = convertToRaw(editorState.getCurrentContent())
    // const markup = draftToHtml(rawContentState)

    setDraft(editorState)
    // setValue(markup)
  }

  const CustomOption = ({ onChange, editorState }) => {
    const insertDataRed = `[START_SHORT_CODE_RED]متن شما[END_SHORT_CODE_RED]`

    const insertDataYellow = `[START_SHORT_CODE_YELLOW]متن شما[END_SHORT_CODE_YELLOW]`

    const insertDataGreen = `[START_SHORT_CODE_GREEN]متن شما[END_SHORT_CODE_GREEN]`

    const insertDataBlue = `[START_SHORT_CODE_BLUE]متن شما[END_SHORT_CODE_BLUE]`

    const addBox = (value) => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        value,
        editorState.getCurrentInlineStyle()
      )

      onChange(EditorState.push(editorState, contentState, "insert-fragment"))
    }

    return (
      <div class="rdw-history-wrapper" aria-label="rdw-history-control">
        <div
          class="rdw-emoji-wrapper"
          style={{ marginBottom: 0 }}
          onClick={() => addBox(insertDataRed)}
          title="Red Box"
        >
          <div class="rdw-option-wrapper">🔴</div>
        </div>
        <div
          class="rdw-emoji-wrapper"
          style={{ marginBottom: 0 }}
          onClick={() => addBox(insertDataYellow)}
          title="Yellow Box"
        >
          <div class="rdw-option-wrapper">🟡</div>
        </div>
        <div
          class="rdw-emoji-wrapper"
          style={{ marginBottom: 0 }}
          onClick={() => addBox(insertDataGreen)}
          title="Green Box"
        >
          <div class="rdw-option-wrapper">🟢</div>
        </div>
        <div
          class="rdw-emoji-wrapper"
          style={{ marginBottom: 0 }}
          onClick={() => addBox(insertDataBlue)}
          title="Blue Box"
        >
          <div class="rdw-option-wrapper">🔵</div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (value?.trim()) {
      insertValue(value)
    }
  }, [])

  return (
    <View
      style={{
        width: "100%",
        borderWidth: 0.8,
        borderRadius: 7,
        borderColor: "#979494",
        marginVertical: 15,
      }}
    >
      <Editor
        editorState={draft}
        onEditorStateChange={onEditorStateChange}
        toolbarCustomButtons={[<CustomOption />]}
        editorStyle={{
          height: 400,
          width: "100%",
          paddingLeft: 15,
          paddingRight: 15,
          fontFamily: "IranSans-Regular",
          zIndex: 20,
        }}
        toolbar={{
          image: {
            urlEnabled: true,
            alignmentEnabled: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png",
            alt: { present: true, mandatory: true },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },
        }}
      />

      <div dangerouslySetInnerHTML={{ __html: styleString }}></div>
    </View>
  )
}
