import { useReducer, useCallback, useEffect } from "react"
import { ActivityIndicator, ScrollView, View, Text } from "react-native"
import { useSelector } from "react-redux"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import Btn from "../../../components/Btn"
import Colors from "../../../constants/Colors"
import File from "../../../components/File"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_GET_LOADING = "SET_GET_LOADING"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_GET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          get_loading: action.loading,
        },
      }
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      }

    default:
      return state
  }
}

const FavoriteLogosTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const role = useSelector((store) => store.user.user_data.role)
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const setActiveIndex = props.route.params.setActiveIndex
  const setSteps = props.route.params.setSteps

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      // ! NEED TO CHANGE
      first_favorite_logo: null,
      second_favorite_logo: null,
      third_favorite_logo: null,
      // !

      loading: false,
      get_loading: false,
      form: null,
      is_staff_in_project: null,
      form_send_progress: 0,
      is_form_section_completed: false,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/logoform/update"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    if (
      formState.values.first_favorite_logo == null &&
      formState.values.second_favorite_logo == null &&
      formState.values.third_favorite_logo == null
    ) {
      alert("حداقل سه مورد را وارد نمایید!")
      return false
    }

    // ! NEED TO CHANGE
    formdata.append("project_id", project_id)
    formdata.append("first_favorite_logo", formState.values.first_favorite_logo)
    formdata.append(
      "second_favorite_logo",
      formState.values.second_favorite_logo
    )
    formdata.append("third_favorite_logo", formState.values.third_favorite_logo)
    formdata.append(
      "fourth_favorite_logo",
      formState.values.fourth_favorite_logo
    )
    formdata.append("fifth_favorite_logo", formState.values.fifth_favorite_logo)
    // !

    dispatchFormState({ type: SET_LOADING, loading: true })

    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        return response.data
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const checkIsComplete = (response) => {
    let is_form_section_completed = false

    // response.data.data.logoform_fourth_favorite_logo != null &&
    // response.data.data.logoform_fifth_favorite_logo != null

    if (
      response.data.data.logoform_first_favorite_logo != null &&
      response.data.data.logoform_second_favorite_logo != null &&
      response.data.data.logoform_third_favorite_logo != null
    ) {
      is_form_section_completed = true
    }

    return is_form_section_completed
  }

  const get_form = async () => {
    dispatchFormState({ type: SET_GET_LOADING, loading: true })

    const url =
      Config.aioUrl + "/project/forms/logoform/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: checkIsComplete(response),
        })
      })
      .catch(function (error) {})
      .finally(() =>
        dispatchFormState({ type: SET_GET_LOADING, loading: false })
      )
  }

  useEffect(() => {
    get_form()
  }, [])

  const isNotAccessToEditInputs =
    (formState.values.is_staff_in_project &&
      capabilities.includes("project_logoform_update_own_project")) ||
    is_customer ||
    capabilities.includes("project_logoform_update_own_department")
      ? false
      : true

  if (formState.values.get_loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 16,
            color: Colors.text,
            marginTop: 20,
          }}
        >
          در حال بارگذاری اطلاعات...
        </Text>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: 15,
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote
          title="اطلاعات این صفحه تکمیل شده است."
          icon="information-circle-outline"
          backgroundColor={Colors.green}
          textColor="#fff"
        />
      )}

      <InputNote
        wrapperStyle={{ marginBottom: 30 }}
        title="لوگو های مورد علاقه"
        content="لطفا سه مورد از لوگو های مورد علاقه خودتان را در بخش پایین آپلود کنید"
      />

      <File
        id="first_favorite_logo"
        title="تصویر لوگوی اول"
        extension="jpg"
        content_type="image/jpeg"
        max_size={2048}
        // max_width={1080}
        // max_height={1080}
        // min_width={300}
        // min_height={300}
        token={token}
        onChange={inputChangeHandler}
        insert={
          !formState.values.first_favorite_logo && formState.values.form
            ? formState.values.form.logoform_first_favorite_logo
              ? formState.values.form.logoform_first_favorite_logo
              : formState.values.first_favorite_logo
            : formState.values.first_favorite_logo
        }
        value={formState.values.first_favorite_logo}
        wrapperStyle={{ marginTop: 10 }}
        downloadUrl={Config.aioUrl + "/project/forms/getLogoFormFile"}
        downloadParams={[
          {
            key: "bucket",
            value:
              !formState.values.first_favorite_logo && formState.values.form
                ? formState.values.form.logoform_first_favorite_logo
                  ? formState.values.form.logoform_first_favorite_logo.bucket
                  : formState.values.first_favorite_logo
                : formState.values.first_favorite_logo,
          },
          {
            key: "bucket_key",
            value:
              !formState.values.first_favorite_logo && formState.values.form
                ? formState.values.form.logoform_first_favorite_logo
                  ? formState.values.form.logoform_first_favorite_logo
                      .bucket_key
                  : formState.values.first_favorite_logo
                : formState.values.first_favorite_logo,
          },
        ]}
        editOptions={{
          compress: 0.8,
          resizeHeight: 1080,
          resizeWidth: 1080,
        }}
        uploadUrl={Config.aioUrl + "/project/forms/logoform/fileupload"}
        uploadParams={[{ key: "project_id", value: project_id }]}
        standalone
        required={true}
      />

      <File
        id="second_favorite_logo"
        title="تصویر لوگوی دوم"
        extension="jpg"
        content_type="image/jpeg"
        max_size={2048}
        // max_width={1080}
        // max_height={1080}
        // min_width={300}
        // min_height={300}
        token={token}
        onChange={inputChangeHandler}
        insert={
          !formState.values.second_favorite_logo && formState.values.form
            ? formState.values.form.logoform_second_favorite_logo
              ? formState.values.form.logoform_second_favorite_logo
              : formState.values.second_favorite_logo
            : formState.values.second_favorite_logo
        }
        value={formState.values.second_favorite_logo}
        wrapperStyle={{ marginTop: 10 }}
        downloadUrl={Config.aioUrl + "/project/forms/getLogoFormFile"}
        downloadParams={[
          {
            key: "bucket",
            value:
              !formState.values.second_favorite_logo && formState.values.form
                ? formState.values.form.logoform_second_favorite_logo
                  ? formState.values.form.logoform_second_favorite_logo.bucket
                  : formState.values.second_favorite_logo
                : formState.values.second_favorite_logo,
          },
          {
            key: "bucket_key",
            value:
              !formState.values.second_favorite_logo && formState.values.form
                ? formState.values.form.logoform_second_favorite_logo
                  ? formState.values.form.logoform_second_favorite_logo
                      .bucket_key
                  : formState.values.second_favorite_logo
                : formState.values.second_favorite_logo,
          },
        ]}
        editOptions={{
          compress: 0.8,
          resizeHeight: 1080,
          resizeWidth: 1080,
        }}
        uploadUrl={Config.aioUrl + "/project/forms/logoform/fileupload"}
        uploadParams={[{ key: "project_id", value: project_id }]}
        standalone
        required={true}
      />

      <File
        id="third_favorite_logo"
        title="تصویر لوگوی سوم"
        extension="jpg"
        content_type="image/jpeg"
        max_size={2048}
        // max_width={1080}
        // max_height={1080}
        // min_width={300}
        // min_height={300}
        token={token}
        onChange={inputChangeHandler}
        insert={
          !formState.values.third_favorite_logo && formState.values.form
            ? formState.values.form.logoform_third_favorite_logo
              ? formState.values.form.logoform_third_favorite_logo
              : formState.values.third_favorite_logo
            : formState.values.third_favorite_logo
        }
        value={formState.values.third_favorite_logo}
        wrapperStyle={{ marginTop: 10 }}
        downloadUrl={Config.aioUrl + "/project/forms/getLogoFormFile"}
        downloadParams={[
          {
            key: "bucket",
            value:
              !formState.values.third_favorite_logo && formState.values.form
                ? formState.values.form.logoform_third_favorite_logo
                  ? formState.values.form.logoform_third_favorite_logo.bucket
                  : formState.values.third_favorite_logo
                : formState.values.third_favorite_logo,
          },
          {
            key: "bucket_key",
            value:
              !formState.values.third_favorite_logo && formState.values.form
                ? formState.values.form.logoform_third_favorite_logo
                  ? formState.values.form.logoform_third_favorite_logo
                      .bucket_key
                  : formState.values.third_favorite_logo
                : formState.values.third_favorite_logo,
          },
        ]}
        editOptions={{
          compress: 0.8,
          resizeHeight: 1080,
          resizeWidth: 1080,
        }}
        uploadUrl={Config.aioUrl + "/project/forms/logoform/fileupload"}
        uploadParams={[{ key: "project_id", value: project_id }]}
        standalone
        required={true}
      />

      {/* {!isNotAccessToEditInputs && (
        <Btn
          title={
            formState.values.form_send_progress > 0 &&
            formState.values.form_send_progress < 1
              ? "در حال ارسال اطلاعات...(" +
                (formState.values.form_send_progress * 100).toFixed(0) +
                "%)"
              : "ثبت اطلاعات فرم"
          }
          onPress={async () => {
            let result = await send_form()

            alert(result.msg)

            if (is_customer && result.success) props.navigation.goBack()
          }}
          wrapperStyle={{ marginVertical: 50 }}
        />
      )} */}
    </ScrollView>
  )
}

export default FavoriteLogosTab
