import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
} from "react-native"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import Input from "../../components/Input"
import Header from "../../components/Header"
import { useSelector } from "react-redux"
import axios from "axios"
import Config from "../../constants/Config"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import { logOut } from "../../components/Functions"
import File from "../../components/File"
import Picker from "../../components/Picker"
import { minutes, hours } from "../../constants/Clock"

import Datepicker from "../../components/Datepicker"
import moment from "moment-jalaali"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_USER = "SET_USER"

const SET_STATUS_LIST = "SET_STATUS_LIST"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      }
    case SET_STATUS_LIST: {
      const types = action.value

      types.map((type) => {
        type.item = type.title
        delete type.title
      })

      return {
        ...state,
        values: {
          ...state.values,
          statusList: types,
        },
      }
    }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_USER:
      let user_data = action.data
      let worktime = {}

      if (user_data.eam_type === "aminhco" && user_data.worktime) {
        const daysTime = [...user_data.worktime]
        let entranceHour, entranceMinute, exitHour, exitMinute

        daysTime.map((day) => {
          entranceHour = day.entrance_time?.slice(0, 2) // Example: /08/:30
          entranceMinute = day.entrance_time?.slice(3, 5) // Example: 08:/30/

          exitHour = day.exit_time?.slice(0, 2) // Example: /08/:30
          exitMinute = day.exit_time?.slice(3, 5) // Example: 08:/30/

          // Example: saturday_entrance_1_h
          worktime[`${day.day}_entrance_1_h`] = {
            item: entranceHour,
            id: entranceHour,
          }

          // Example: saturday_entrance_1_m
          worktime[`${day.day}_entrance_1_m`] = {
            item: entranceMinute,
            id: entranceMinute,
          }

          // Example: saturday_exit_1_h
          worktime[`${day.day}_exit_1_h`] = { item: exitHour, id: exitHour }

          // Example: saturday_exit_1_m
          worktime[`${day.day}_exit_1_m`] = { item: exitMinute, id: exitMinute }
        })
      }

      switch (user_data.department) {
        case "sales":
          user_data.department = { item: "فروش", id: "sales" }
          break
        case "site":
          user_data.department = { item: "سایت", id: "site" }
          break
        case "graphic":
          user_data.department = { item: "گرافیک", id: "graphic" }
          break
        case "seo":
          user_data.department = { item: "سئو", id: "seo" }
          break
      }
      switch (user_data.eam_type) {
        case "aminhco":
          user_data.eam_type = { item: "حضوری", id: "aminhco" }
          break
        case "remote":
          user_data.eam_type = { item: "دورکار", id: "remote" }
          break
      }

      const userDataEmployeeType = {
        id: user_data.employeeType?.id,
        item: user_data.employeeType?.title,
      }

      console.log("Output Worktime: ", worktime)

      return {
        ...state,
        values: {
          ...state.values,
          user: user_data,
          ...worktime,
          department: user_data.department,
          eam_type: user_data.eam_type,

          recruitment_date: user_data.recruitment_date,

          work_duration: user_data.work_duration,

          selectedStatus: userDataEmployeeType,
        },
      }
    default:
      return state
  }
}

const WorkTime = (props) => {
  const { day_en, day_fa, inputChangeHandler, user_id, formState } = props
  return (
    <View
      style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}
    >
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          {day_fa}
        </Text>
      </View>
      <View
        style={{ flexDirection: "row", flex: 2, columnGap: 3, marginLeft: 3 }}
      >
        <View style={{ flex: 1 }}>
          <Picker
            label="ساعت خروج"
            id={day_en + "_exit_1_h"}
            onChange={inputChangeHandler}
            options={hours}
            insert={
              user_id
                ? formState.values[day_en + "_exit_1_h"]
                : day_en == "friday"
                ? { item: "00", id: "00" }
                : { item: "16", id: "16" }
            }
          />
        </View>
        <View style={{ flex: 1 }}>
          <Picker
            label="دقیقه خروج"
            id={day_en + "_exit_1_m"}
            onChange={inputChangeHandler}
            options={minutes}
            insert={
              user_id
                ? formState.values[day_en + "_exit_1_m"]
                : day_en == "friday"
                ? { item: "00", id: "00" }
                : { item: "30", id: "30" }
            }
          />
        </View>
      </View>
      <View style={{ flexDirection: "row", flex: 2, columnGap: 3 }}>
        <View style={{ flex: 1 }}>
          <Picker
            label="ساعت ورود"
            id={day_en + "_entrance_1_h"}
            onChange={inputChangeHandler}
            options={hours}
            insert={
              user_id
                ? formState.values[day_en + "_entrance_1_h"]
                : day_en == "friday"
                ? { item: "00", id: "00" }
                : { item: "08", id: "08" }
            }
          />
        </View>
        <View style={{ flex: 1 }}>
          <Picker
            label="دقیقه ورود"
            id={day_en + "_entrance_1_m"}
            onChange={inputChangeHandler}
            options={minutes}
            insert={
              user_id
                ? formState.values[day_en + "_entrance_1_m"]
                : day_en == "friday"
                ? { item: "00", id: "00" }
                : { item: "30", id: "30" }
            }
          />
        </View>
      </View>
    </View>
  )
}

const AddUserScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_id = props.route.params.user_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: true,
      name: false,
      phone: false,
      department: false,
      eam_type: false,
      avatar: false,
      user: null,

      statusList: [],
      selectedStatus: false,
      work_duration: false,
      recruitment_date: "EMPTY",

      saturday_entrance_1_h: { item: "08", id: "08" },
      saturday_entrance_1_m: { item: "30", id: "30" },
      saturday_exit_1_h: { item: "16", id: "16" },
      saturday_exit_1_m: { item: "30", id: "30" },
      sunday_entrance_1_h: { item: "08", id: "08" },
      sunday_entrance_1_m: { item: "30", id: "30" },
      sunday_exit_1_h: { item: "16", id: "16" },
      sunday_exit_1_m: { item: "30", id: "30" },
      monday_entrance_1_h: { item: "08", id: "08" },
      monday_entrance_1_m: { item: "30", id: "30" },
      monday_exit_1_h: { item: "16", id: "16" },
      monday_exit_1_m: { item: "30", id: "30" },
      tuesday_entrance_1_h: { item: "08", id: "08" },
      tuesday_entrance_1_m: { item: "30", id: "30" },
      tuesday_exit_1_h: { item: "16", id: "16" },
      tuesday_exit_1_m: { item: "30", id: "30" },
      wednesday_entrance_1_h: { item: "08", id: "08" },
      wednesday_entrance_1_m: { item: "30", id: "30" },
      wednesday_exit_1_h: { item: "16", id: "16" },
      wednesday_exit_1_m: { item: "30", id: "30" },
      thursday_entrance_1_h: { item: "08", id: "08" },
      thursday_entrance_1_m: { item: "30", id: "30" },
      thursday_exit_1_h: { item: "16", id: "16" },
      thursday_exit_1_m: { item: "30", id: "30" },
      friday_entrance_1_h: { item: "00", id: "00" },
      friday_entrance_1_m: { item: "00", id: "00" },
      friday_exit_1_h: { item: "00", id: "00" },
      friday_exit_1_m: { item: "00", id: "00" },
    },
    validities: {},
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const set_user = async () => {
    const url = Config.aioUrl + "/users/set"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    let formdata = new FormData()
    if (user_id) formdata.append("user_id", user_id)
    if (formState.values.avatar)
      formdata.append(
        "avatar",
        formState.values.avatar.file,
        formState.values.avatar.name
      )
    formdata.append("name", formState.values.name)
    formdata.append("phone", formState.values.phone)
    formdata.append("department", formState.values.department.id)

    formdata.append("type_id", formState.values.selectedStatus.id)
    formdata.append("recruitment_date", formState.values.recruitment_date)
    formdata.append("work_duration", formState.values.work_duration)
    formdata.append("eam_type", formState.values.eam_type.id)

    if (formState.values.eam_type.id == "aminhco") {
      formdata.append(
        "saturday_entrance_time_1",
        formState.values.saturday_entrance_1_h.id +
          ":" +
          formState.values.saturday_entrance_1_m.id
      )
      formdata.append(
        "saturday_exit_time_1",
        formState.values.saturday_exit_1_h.id +
          ":" +
          formState.values.saturday_exit_1_m.id
      )
      formdata.append(
        "sunday_entrance_time_1",
        formState.values.sunday_entrance_1_h.id +
          ":" +
          formState.values.sunday_entrance_1_m.id
      )
      formdata.append(
        "sunday_exit_time_1",
        formState.values.sunday_exit_1_h.id +
          ":" +
          formState.values.sunday_exit_1_m.id
      )
      formdata.append(
        "monday_entrance_time_1",
        formState.values.monday_entrance_1_h.id +
          ":" +
          formState.values.monday_entrance_1_m.id
      )
      formdata.append(
        "monday_exit_time_1",
        formState.values.monday_exit_1_h.id +
          ":" +
          formState.values.monday_exit_1_m.id
      )
      formdata.append(
        "tuesday_entrance_time_1",
        formState.values.tuesday_entrance_1_h.id +
          ":" +
          formState.values.tuesday_entrance_1_m.id
      )
      formdata.append(
        "tuesday_exit_time_1",
        formState.values.tuesday_exit_1_h.id +
          ":" +
          formState.values.tuesday_exit_1_m.id
      )
      formdata.append(
        "wednesday_entrance_time_1",
        formState.values.wednesday_entrance_1_h.id +
          ":" +
          formState.values.wednesday_entrance_1_m.id
      )
      formdata.append(
        "wednesday_exit_time_1",
        formState.values.wednesday_exit_1_h.id +
          ":" +
          formState.values.wednesday_exit_1_m.id
      )
      formdata.append(
        "thursday_entrance_time_1",
        formState.values.thursday_entrance_1_h.id +
          ":" +
          formState.values.thursday_entrance_1_m.id
      )
      formdata.append(
        "thursday_exit_time_1",
        formState.values.thursday_exit_1_h.id +
          ":" +
          formState.values.thursday_exit_1_m.id
      )
      formdata.append(
        "friday_entrance_time_1",
        formState.values.friday_entrance_1_h.id +
          ":" +
          formState.values.friday_entrance_1_m.id
      )
      formdata.append(
        "friday_exit_time_1",
        formState.values.friday_exit_1_h.id +
          ":" +
          formState.values.friday_exit_1_m.id
      )
    }

    if (
      !formState.values.name ||
      !formState.values.phone ||
      !formState.values.department ||
      !formState.values.eam_type
    ) {
      alert("اطلاعات نمی تواند خالی باشد.")
    } else {
      dispatchFormState({ type: SET_LOADING, loading: true })
      axios({
        method: "post",
        url: url,
        data: formdata,
        headers: reqHedear,
      })
        .then(function (response) {
          if (response.data.code == 401) {
            alert("نیاز است مجددا به سیستم ورود کنید.")
            logOut()
          }
          dispatchFormState({ type: SET_LOADING, loading: false })

          alert(response.data.msg)
          if (response.data.success) {
            props.navigation.navigate("UsersManagement")
          }
        })
        .catch(function (error) {
          dispatchFormState({ type: SET_LOADING, loading: false })
          console.log(error)
        })
    }
  }

  const get_user = async () => {
    const url = Config.aioUrl + "/users/get?id=" + user_id

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_USER, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        console.log(error)
      })
  }

  const getStatusList = async () => {
    const url = Config.aioUrl + "/employee-type"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios
      .get(url, { headers: reqHedear })
      .then((res) => {
        if (res.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        console.log("mployee-type", res.data)

        dispatchFormState({ type: SET_STATUS_LIST, value: res.data.types })
      })
      .finally(() => dispatchFormState({ type: SET_LOADING, loading: false }))
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      getStatusList()
      if (user_id) get_user()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        paddingHorizontal: 25,
      }}
    >
      <Header
        title={user_id ? "ویرایش کاربر" : "افزودن کاربر"}
        noToggle
        noNotif
        noActiveSessions
      />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "20%" : 0,
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!formState.values.loading && (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ marginTop: 20, width: "100%", marginBottom: 20 }}
          >
            {formState.values.user && (
              <View
                style={{
                  width: "100%",
                  height: 128,
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                <Image
                  source={{ uri: formState.values.user.avatar }}
                  style={{ width: 128, height: 128, borderRadius: 64 }}
                />
              </View>
            )}

            <File
              id="avatar"
              title="آواتار"
              extension="jpg"
              max_size={100}
              max_width={500}
              max_height={500}
              content_type="image/jpeg"
              value={formState.values.avatar}
              onChange={inputChangeHandler}
            />
            <Input
              label="نام کامل"
              id="name"
              onChange={inputChangeHandler}
              insert={
                formState.values.user ? formState.values.user.name : false
              }
            />
            <Input
              label="شماره موبایل"
              id="phone"
              onChange={inputChangeHandler}
              onlyNumber
              convertDigits
              mobile
              insert={
                formState.values.user ? formState.values.user.phone : false
              }
            />
            <Input
              label="ساعت کار در روز"
              onlyNumber
              convertDigits
              id="work_duration"
              onChange={inputChangeHandler}
              insert={
                formState.values.user
                  ? formState.values.user.work_duration
                  : false
              }
            />
            <Picker
              label="دپارتمان"
              id="department"
              onChange={inputChangeHandler}
              options={[
                { item: "فروش", id: "sales" },
                { item: "سایت", id: "site" },
                { item: "گرافیک", id: "graphic" },
                { item: "سئو", id: "seo" },
              ]}
              insert={
                formState.values.user ? formState.values.user.department : false
              }
            />

            <Picker
              label="وضعیت کاربر"
              id="selectedStatus"
              onChange={inputChangeHandler}
              options={formState.values.statusList || []}
              insert={formState.values.selectedStatus}
            />

            {console.log(
              "dddddd",
              moment
                .unix(formState.values.recruitment_date)
                .format("jYYYY/jMM/jDD")
            )}

            {formState.values.recruitment_date != "EMPTY" && (
              <View style={{ zIndex: 3 }}>
                <Datepicker
                  label="تاریخ قرارداد"
                  id="recruitment_date"
                  onChange={(id, value) => {
                    if (value) {
                      inputChangeHandler(id, value, true)
                    }
                  }}
                  preSelected={
                    formState.values.recruitment_date
                      ? moment
                          .unix(formState.values.recruitment_date)
                          .format("jYYYY/jMM/jDD")
                      : false
                  }
                />
              </View>
            )}

            <Picker
              label="نوع حضور"
              id="eam_type"
              onChange={inputChangeHandler}
              options={[
                { item: "حضوری", id: "aminhco" },
                { item: "دورکار", id: "remote" },
              ]}
              insert={
                formState.values.user ? formState.values.user.eam_type : false
              }
            />
            {formState.values.eam_type &&
              formState.values.eam_type.id == "aminhco" && (
                <View>
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 18,
                      color: Colors.text,
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    ساعات کاری
                  </Text>
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="saturday"
                    day_fa="شنبه"
                    user_id={user_id}
                    formState={formState}
                  />
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="sunday"
                    day_fa="یکشنبه"
                    user_id={user_id}
                    formState={formState}
                  />
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="monday"
                    day_fa="دو شنبه"
                    user_id={user_id}
                    formState={formState}
                  />
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="tuesday"
                    day_fa="سه شنبه"
                    user_id={user_id}
                    formState={formState}
                  />
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="wednesday"
                    day_fa="چهارشنبه"
                    user_id={user_id}
                    formState={formState}
                  />
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="thursday"
                    day_fa="پنجشنبه"
                    user_id={user_id}
                    formState={formState}
                  />
                  <WorkTime
                    inputChangeHandler={inputChangeHandler}
                    day_en="friday"
                    day_fa="جمعه"
                    user_id={user_id}
                    formState={formState}
                  />
                </View>
              )}

            <Btn
              title={user_id ? "ثبت تغییرات" : "افزودن"}
              onPress={() => {
                set_user()
              }}
              loading={formState.values.loading}
              wrapperStyle={{ marginTop: 30, zIndex: 1 }}
            />
          </ScrollView>
        )}
        {formState.values.loading && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
}

export default AddUserScreen
