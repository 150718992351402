import { useReducer } from "react"
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import * as Clipboard from "expo-clipboard"
import axios from "axios"
import Config from "../constants/Config"
import {
  encrypt,
  decrypt,
  logOut,
  version_compare,
} from "../components/Functions"

const SET_LOADING = "SET_LOADING"
const CHANGE_ASC_VERSION = "CHANGE_ASC_VERSION"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.section]: action.loading,
          },
        },
      }
    case CHANGE_ASC_VERSION:
      return {
        ...state,
        values: {
          ...state.values,
          asc_version: action.value,
          is_asc_latest: action.is_latest,
        },
      }
    default:
      return state
  }
}

const Site = (props) => {
  const navigation = useNavigation()
  const {
    id,
    asc_version,
    asc_latest_version,
    first_name,
    last_name,
    site_url,
    phone,
    token,
    key_encrypt,
    key_decrypt,
    capabilities,
    user_data,
    showUpStatus,
    isWebsiteUp,
    websiteUpStatusCode,
  } = props

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      asc_version: asc_version,
      is_asc_latest:
        (asc_version == null
          ? -1
          : version_compare(asc_version, asc_latest_version)) == -1
          ? false
          : true,
      loading: {
        clean_access: false,
        login: false,
        update_asc: false,
      },
    },
  })

  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value)
  }

  const clean_access = async (return_wp_admin_only = false) => {
    const url = Config.aioUrl + "/asc/site/preclean?id=" + id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    if (!return_wp_admin_only)
      dispatchFormState({
        type: SET_LOADING,
        section: "clean_access",
        loading: true,
      })

    let response_preclean = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.code == 200) {
          if (return_wp_admin_only) return response.data.data.wp_admin_password
          return response
        } else {
          return false
        }
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          section: "clean_access",
          loading: false,
        })
        console.log(error)
      })

    if (!response_preclean) {
      alert("در دریافت اطلاعات سایت مشکلی رخ داد.")
      dispatchFormState({
        type: SET_LOADING,
        section: "clean_access",
        loading: false,
      })
      return
    }
    if (return_wp_admin_only) return response_preclean

    let formdata = new FormData()
    formdata.append(
      "password",
      await decrypt(
        response_preclean.data.data.wp_admin_password || "",
        key_decrypt
      )
    )
    formdata.append("panel", await response_preclean.data.data.hosting_panel)
    formdata.append(
      "cpanel_username",
      await decrypt(
        response_preclean.data.data.hosting_panel_username || "",
        key_decrypt
      )
    )
    formdata.append(
      "cpanel_api_key",
      await decrypt(
        response_preclean.data.data.hosting_panel_api || "",
        key_decrypt
      )
    )
    formdata.append(
      "cpanel_password",
      await decrypt(
        response_preclean.data.data.hosting_panel_password || "",
        key_decrypt
      )
    )

    let response_clean_all = await axios({
      method: "post",
      url:
        site_url + "/wp-content/plugins/asc-client/api/security/cleanup/all/",
      data: formdata,
    })
      .then(function (response) {
        console.log(response)

        if (response.data.code == 200) {
          alert("بروز رسانی دسترسی از سمت سایت موفق بود.")

          return response
        } else {
          return false
        }
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          section: "clean_access",
          loading: false,
        })
        console.log(error)
      })

    if (!response_clean_all) {
      alert("در پاکسازی سایت مشکلی رخ داد.")
      dispatchFormState({
        type: SET_LOADING,
        section: "clean_access",
        loading: false,
      })
      return
    }

    let formdata_clean = new FormData()
    formdata_clean.append("site_id", id)
    if (response_clean_all.data.data.wp_pass.status) {
      formdata_clean.append(
        "wp_admin_password",
        await encrypt(
          response_clean_all.data.data.wp_pass.new_pass,
          key_encrypt
        )
      )
    }
    if (response_clean_all.data.data.backdoor_pass.status) {
      formdata_clean.append(
        "wp_backdoor",
        await encrypt(
          response_clean_all.data.data.backdoor_pass.new_pass,
          key_encrypt
        )
      )
    }
    if (response_clean_all.data.data.cpanel_pass.status) {
      formdata_clean.append(
        "hosting_panel_password",
        await encrypt(
          response_clean_all.data.data.cpanel_pass.new_pass,
          key_encrypt
        )
      )
    }

    let respone_save_clean = await axios({
      method: "post",
      url: Config.aioUrl + "/asc/site/clean",
      headers: reqHedear,
      data: formdata_clean,
    })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          section: "clean_access",
          loading: false,
        })
        console.log(error)
      })

    alert(respone_save_clean.data.msg)

    dispatchFormState({
      type: SET_LOADING,
      section: "clean_access",
      loading: false,
    })
  }

  const site_log_in = async () => {
    const url = Config.aioUrl + "/asc/site/get?id=" + id
    
    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    
    dispatchFormState({ type: SET_LOADING, section: "login", loading: true })
    let response_site_get = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.code == 200) {
          return response
        } else {
          return false
        }
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          section: "login",
          loading: false,
        })
        console.log(error)
      })
    if (!response_site_get) {
      alert("مشکلی در دریافت ورژن نصب شده روی سایت پیش آمد.")
      dispatchFormState({ type: SET_LOADING, section: "login", loading: false })
      return
    }

    window.open(
      response_site_get.data.data.url +
        "/wp-content/plugins/asc-client/api/remotelogin/?username=" +
        (await decrypt(
          response_site_get.data.data.wp_admin_username,
          key_decrypt
        )) +
        "&password=" +
        encodeURIComponent(
          await decrypt(
            response_site_get.data.data.wp_admin_password,
            key_decrypt
          )
        ),
      "_blank"
    )
    dispatchFormState({ type: SET_LOADING, section: "login", loading: false })
  }

  const update_asc = async () => {
    const url = site_url + "/wp-content/plugins/asc-client/version.php?"

    dispatchFormState({
      type: SET_LOADING,
      section: "asc_update",
      loading: true,
    })

    // let response_version = await axios({
    //   method: "get",
    //   url: url,
    // })

    let response_version = await axios
      .get(url)
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.code == 200) {
          return response
        } else {
          return false
        }
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          section: "asc_update",
          loading: false,
        })
        console.log(error)
      })

    if (!response_version) {
      alert("مشکلی در دریافت ورژن نصب شده روی سایت پیش آمد.")

      dispatchFormState({
        type: SET_LOADING,
        section: "asc_update",
        loading: false,
      })
      return
    }

    let is_asc_latest =
      response_version.data.version == null
        ? -1
        : version_compare(response_version.data.version, asc_latest_version)
    is_asc_latest = is_asc_latest == 1 || is_asc_latest == 0 ? true : false

    if (response_version.data.version != formState.values.asc_version) {
      let reqHedear = {
        Authorization: "Bearer " + token,
      }
      let formdata = new FormData()
      formdata.append("site_id", id)
      formdata.append("asc_version", response_version.data.version)
      axios({
        method: "post",
        url: Config.aioUrl + "/asc/site/changeAscVersion",
        headers: reqHedear,
        data: formdata,
      })
        .then(function (responseData) {
          dispatchFormState({
            type: CHANGE_ASC_VERSION,
            value: response_version.data.version,
            is_latest:
              response_version.data.version == asc_latest_version
                ? true
                : false,
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    if (!is_asc_latest && confirm("آیا بروزرسانی می کنید؟")) {
      let response_preclean = await clean_access(true)
        .then(function (response) {
          if (response) {
            return response
          } else {
            return false
          }
        })
        .catch(function (error) {
          dispatchFormState({
            type: SET_LOADING,
            section: "asc_update",
            loading: false,
          })
          console.log(error)
        })

      if (!response_preclean) {
        alert("مشکلی در دریافت  اطلاعات سایت پیش آمد.")
        dispatchFormState({
          type: SET_LOADING,
          section: "asc_update",
          loading: false,
        })
        return
      }

      let reqHedear = {
        "Content-Type": "application/json",
      }
      let reqData = {
        password: await decrypt(response_preclean, key_decrypt),
        data: [
          {
            remote_file_url: "https://update.aminh.pro/plugins/asc-client.zip",
            target_folder_name: "asc-client",
            type: "plugin",
          },
        ],
      }
      let response_update = await axios({
        method: "post",
        url:
          site_url +
          "/wp-content/plugins/asc-client/api/update/plugins-themes/",
        headers: reqHedear,
        data: reqData,
      })
        .then(function (response) {
          if (response.data.code == 200) {
            return response
          } else {
            return false
          }
        })
        .catch(function (error) {
          dispatchFormState({
            type: SET_LOADING,
            section: "asc_update",
            loading: false,
          })
          console.log(error)
        })

      if (!response_update) {
        console.log(response_update)
        alert("مشکلی در آپدیت کردن افزونه رخ داد.")
        dispatchFormState({
          type: SET_LOADING,
          section: "asc_update",
          loading: false,
        })
        return
      }

      reqHedear = {
        Authorization: "Bearer " + token,
      }

      let formdata = new FormData()
      formdata.append("site_id", id)
      formdata.append("asc_version", asc_latest_version)
      axios({
        method: "post",
        url: Config.aioUrl + "/asc/site/changeAscVersion",
        headers: reqHedear,
        data: formdata,
      })
        .then(function (responseData) {
          dispatchFormState({
            type: CHANGE_ASC_VERSION,
            value: asc_latest_version,
            is_latest: true,
          })
          console.log(responseData)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }

  return (
    <View
      style={{
        height: 80,
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        flexDirection: "row-reverse",
        marginVertical: 5,
      }}
    >
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          {id}
        </Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 28,
            height: 28,
            backgroundColor: formState.values.is_asc_latest
              ? Colors.green
              : Colors.primary,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            update_asc()
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 12,
              color: "#fff",
            }}
          >
            {formState.values.asc_version ? formState.values.asc_version : 0}
          </Text>
        </TouchableOpacity>
      </View>

      {showUpStatus && (
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
          onPress={() => navigation.navigate("Uptime", { site_id: id })}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: 28,
              height: 28,
              backgroundColor: isWebsiteUp ? Colors.green : Colors.primary,
              borderRadius: 3,
              marginHorizontal: 3,
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: "#fff",
              }}
            >
              {websiteUpStatusCode || "?"}
            </Text>
          </View>
        </TouchableOpacity>
      )}

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
        }}
      >
        <Text
          onPress={() => {
            copyToClipboard(site_url)
          }}
          style={styles.headerText}
        >
          {site_url}
        </Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
        }}
      >
        <Text
          onPress={() => {
            copyToClipboard(first_name)
          }}
          style={styles.headerText}
        >
          {first_name}
        </Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
        }}
      >
        <Text
          onPress={() => {
            copyToClipboard(last_name)
          }}
          style={styles.headerText}
        >
          {last_name}
        </Text>
      </View>

      {user_data.department == "lead" && (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 2,
          }}
        >
          <Text
            onPress={() => {
              copyToClipboard(phone)
            }}
            style={styles.headerText}
          >
            {phone}
          </Text>
        </View>
      )}

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 2,
          flexDirection: "row",
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            backgroundColor: Colors.green,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            site_log_in()
          }}
        >
          {!formState.values.loading.login && (
            <Ionicons name="log-in-outline" color="#fff" size={16} />
          )}
          {formState.values.loading.login && (
            <ActivityIndicator size="small" color="#fff" />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            backgroundColor: Colors.secondary,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            navigation.navigate("AddSite", { site_id: id })
          }}
        >
          <Ionicons name="pencil" color="#fff" size={16} />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            backgroundColor: Colors.blue,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            navigation.navigate("SiteInfo", {
              site_id: id,
            })
          }}
        >
          <Ionicons name="information" color="#fff" size={16} />
        </TouchableOpacity>
        {capabilities.includes("asc_site_clean") && (
          <TouchableOpacity
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: 24,
              height: 24,
              backgroundColor: Colors.primary,
              borderRadius: 3,
              marginHorizontal: 3,
            }}
            onPress={() => {
              clean_access()
            }}
          >
            {!formState.values.loading.clean_access && (
              <Ionicons name="shield-checkmark" color="#fff" size={16} />
            )}
            {formState.values.loading.clean_access && (
              <ActivityIndicator size="small" color="#fff" />
            )}
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: "IranSans-Regular",
    fontSize: 12,
    color: Colors.text,
  },
})

export default Site
