import { useReducer, useCallback } from "react"
import { View, StyleSheet, Image, Dimensions, Platform } from "react-native"
import Input from "../../components/Input"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import axios from "axios"
import Config from "../../constants/Config"
import { useDispatch } from "react-redux"
import { setCred } from "../../store/user"
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as Device from "expo-device"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
const LoginTwoScreen = (props) => {
  const phone = props.route.params.phone
  const dispatch = useDispatch()
  const [formState, dispatchFormState] = useReducer(formReducer, {
    otp: null,
    loading: false,
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )
  const saveCred = async (token, capabilities, user_data) => {
    try {
      await AsyncStorage.setItem("token", token)
      await AsyncStorage.setItem("capabilities", capabilities)
      await AsyncStorage.setItem("user_data", JSON.stringify(user_data))
      dispatch(
        setCred({
          token: token,
          capabilities: capabilities,
          user_data: user_data,
        })
      )
      props.navigation.navigate("Auth", { screen: "EnableNotif" })
    } catch (e) {
      // saving error
    }
  }

  const verifyOtp = async () => {
    let reqHedear = new Headers()
    reqHedear.append("Content-Type", "application/json")

    let reqData = new FormData()
    reqData.append("phone", phone)
    reqData.append("otp", formState.otp)
    reqData.append("device_model", Device.modelName)
    reqData.append("os", Device.osName)
    reqData.append("os_version", Device.osVersion)
    reqData.append("platform", Platform.OS)

    const url = Config.aioUrl + "/auth/login/verifyotp"
    if (formState.otp.length !== 6) {
      alert("کد نادرست است")
    } else {
      dispatchFormState({ type: SET_LOADING, loading: true })
      axios({
        method: "post",
        url: url,
        data: reqData,
        headers: reqHedear,
      })
        .then(function (response) {
          dispatchFormState({ type: SET_LOADING, loading: false })
          if (response.data.success) {
            saveCred(
              response.data.data.token,
              response.data.data.capabilities,
              response.data.data.user_data
            )
          } else {
            alert(response.data.msg)
            if (response.data.code == 401) {
              window.location.reload(true)
            }
          }
        })
        .catch(function (error) {
          console.log(error)
          dispatchFormState({ type: SET_LOADING, loading: true })
        })
    }
  }
  return (
    <View style={styles.wrapper}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Image
          style={{ width: 100, height: 100 }}
          source={require("../../assets/img/logoR-500.png")}
        />
      </View>
      <View style={{ width: "100%", marginVertical: 80, flexDirection: "row" }}>
        <View style={{ marginLeft: 10, flex: 1 }}>
          <Input
            autoFocus
            onSubmitEditing={() => {
              verifyOtp()
            }}
            label="کد تایید"
            id="otp"
            onChange={inputChangeHandler}
            textAlign="center"
            convertDigits
          />
        </View>
      </View>
      <Btn
        title="ورود"
        onPress={() => {
          verifyOtp()
        }}
        loading={formState.loading}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: DESKTOP ? "30%" : 15,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
}

export default LoginTwoScreen
