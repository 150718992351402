import { useReducer, useCallback, useEffect } from "react"
import {
  ActivityIndicator,
  ScrollView,
  View,
  Text,
  Image,
  Dimensions,
} from "react-native"
import { useSelector } from "react-redux"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import Btn from "../../../components/Btn"
import Colors from "../../../constants/Colors"
import File from "../../../components/File"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import FancySelector from "../../../components/FancySelector"
import { FlatList } from "react-native-gesture-handler"

const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const logos_style = {
  writing: {
    title: "سبک نوشتاری",
    list: [
      { id: 1, img_id: "01" },
      { id: 2, img_id: "02" },
      { id: 3, img_id: "03" },
    ],
  },
  monogram: {
    title: "سبک مونوگرام",
    list: [
      { id: 1, img_id: "04" },
      { id: 2, img_id: "05" },
      { id: 3, img_id: "06" },
    ],
  },
  iconic: {
    title: "سبک نمادین",
    list: [
      { id: 1, img_id: "07" },
      { id: 2, img_id: "08" },
      { id: 3, img_id: "09" },
    ],
  },
  other: {
    title: "سایر",
    list: [
      { id: 1, img_id: "10" },
      { id: 2, img_id: "11" },
      { id: 3, img_id: "12" },
    ],
  },
}

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_GET_LOADING = "SET_GET_LOADING"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_GET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          get_loading: action.loading,
        },
      }
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          which_style: action.data?.logoform_which_style,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      }

    default:
      return state
  }
}

const DesignStyleTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const role = useSelector((store) => store.user.user_data.role)
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const setActiveIndex = props.route.params.setActiveIndex
  const setSteps = props.route.params.setSteps

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      // ! NEED TO CHANGE
      which_style: "",
      other_explanations: "",
      // !

      loading: false,
      get_loading: false,
      form: null,
      is_staff_in_project: null,
      form_send_progress: 0,
      is_form_section_completed: false,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/logoform/update"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    console.clear()
    console.log(formState.values.which_style)

    // ! NEED TO CHANGE
    formdata.append("project_id", project_id)
    formdata.append("which_style", formState.values.which_style)
    formdata.append("other_explanations", formState.values.other_explanations)
    // !

    dispatchFormState({ type: SET_LOADING, loading: true })

    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        return response.data.success
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const checkIsComplete = (response) => {
    let is_form_section_completed = false

    if (response.data.data.logoform_which_style) {
      is_form_section_completed = true
    }

    return is_form_section_completed
  }

  const get_form = async () => {
    dispatchFormState({ type: SET_GET_LOADING, loading: true })

    const url =
      Config.aioUrl + "/project/forms/logoform/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: checkIsComplete(response),
        })
      })
      .catch(function (error) {})
      .finally(() =>
        dispatchFormState({ type: SET_GET_LOADING, loading: false })
      )
  }

  useEffect(() => {
    get_form()
  }, [])

  const isNotAccessToEditInputs =
    (formState.values.is_staff_in_project &&
      capabilities.includes("project_logoform_update_own_project")) ||
    is_customer ||
    capabilities.includes("project_logoform_update_own_department")
      ? false
      : true

  //! FORM =>
  console.log(formState.values)

  if (formState.values.get_loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 16,
            color: Colors.text,
            marginTop: 20,
          }}
        >
          در حال بارگذاری اطلاعات...
        </Text>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: 15,
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote
          title="اطلاعات این صفحه تکمیل شده است."
          icon="information-circle-outline"
          backgroundColor={Colors.green}
          textColor="#fff"
        />
      )}

      <View
        style={{
          padding: 20,
          borderWidth: 0.8,
          borderRadius: 7,
          borderColor: Colors.secondText,
          marginBottom: 15,
          gap: DESKTOP ? 25 : 10,
        }}
      >
        <InputNote title="توضیحات مربوط به سبک طراحی" />

        {Object.entries(logos_style).map((itemArr) => {
          let objectKey = itemArr[0]
          let objectData = itemArr[1]

          return (
            <View
              key={objectKey}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: DESKTOP ? "row-reverse" : "column",
                paddingHorizontal: DESKTOP ? 10 : 0,
                gap: DESKTOP ? 0 : 15,
              }}
            >
              <View
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  flexGrow: 3,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 18,
                    fontWeight: "bold",
                    color: Colors.text,
                  }}
                >
                  {objectData.title}
                </Text>
              </View>

              <FlatList
                numColumns={3}
                keyExtractor={(item) => item.id}
                data={objectData.list}
                columnWrapperStyle={{
                  justifyContent: "space-between",
                  gap: 10,
                }}
                style={{ maxWidth: 365 }}
                renderItem={(data, index) => (
                  <Image
                    style={{
                      width: DESKTOP ? 100 : 80,
                      height: DESKTOP ? 100 : 80,
                      borderRadius: 10,
                      marginBottom: !DESKTOP ? 10 : 0,
                    }}
                    source={require(`/assets/img/LOGO_FORM/about_logos_style/${data.item.img_id}.jpg`)}
                  />
                )}
              />
            </View>
          )
        })}
      </View>

      <InputNote
        title="نکته"
        icon="information-circle-outline"
        content="سوال بعد را بدون در نظر گرفتن زیبایی لوگوها و صرفا با توجه به سبک های طراحی پاسخ دهید. در صورتی که نمی دانید چه نوع لوگویی مناسب شماست، اصلا نگران نباشید. طراحان ما با استفاده از تخصص و تجربه طراحی با نظر خودشان بهترین لوگو را برای شما طراحی خواهند کرد. لازم به ذکر است پر نکردن این بخش به معنای واگذاری کامل طراحی به نظر طراحان است و امکان تغییر نظر در آینده وجود ندارد."
      />

      {formState.values.form && (
        <>
          <FancySelector
            id="which_style"
            column={2}
            onChange={(id, value) => inputChangeHandler(id, value[0])}
            title="کدام یک از سبک های بالا را انتخاب میکنید؟"
            options={[
              { item: "سبک نوشتاری", id: "writing_style" },
              { item: "سبک مونوگرام", id: "monogram_style" },
              { item: "سبک نمادین", id: "iconic_style" },
              { item: "سایر", id: "other" },
              { item: "نمیدانم!", id: "do_not_know" },
            ]}
            flatListStyle={{ direction: "rtl" }}
            disabled={isNotAccessToEditInputs}
            insert={
              formState.values.form
                ? [`${formState.values.form.logoform_which_style}`]
                  ? [`${formState.values.form.logoform_which_style}`]
                  : false
                : false
            }
            single
          />
        </>
      )}

      {/* {formState.values.which_style?.includes("do_not_know") && ( */}
      <Input
        disabled={isNotAccessToEditInputs}
        label="توضیحات دیگر (اختیاری)"
        id="other_explanations"
        onChange={inputChangeHandler}
        multiline
        insert={
          formState.values.form
            ? formState.values.form.logoform_other_explanations
            : ""
        }
      />
      {/* )} */}

      {!isNotAccessToEditInputs && (
        <Btn
          title={
            formState.values.form_send_progress > 0 &&
            formState.values.form_send_progress < 1
              ? "در حال ارسال اطلاعات...(" +
                (formState.values.form_send_progress * 100).toFixed(0) +
                "%)"
              : "ثبت اطلاعات فرم"
          }
          onPress={async () => {
            let result = await send_form()

            if (result) {
              await get_form()

              setSteps("امتیاز به لوگو")
            }
          }}
          wrapperStyle={{ marginVertical: 50 }}
        />
      )}
    </ScrollView>
  )
}

export default DesignStyleTab
