import React, { useEffect, useReducer } from "react"
import { ActivityIndicator, Text, useWindowDimensions } from "react-native"
import RenderHTML, { defaultSystemFonts } from "react-native-render-html"
import { View } from "react-native-web"

const SET_LOADING = "SET_LOADING"

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.value,
      }
    }

    default: {
      return state
    }
  }
}

const RenderHTMLSource = ({ source }) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
  })

  useEffect(() => {
    dispatch({ type: SET_LOADING, value: true })
  }, [])

  // Get screen width
  const { width } = useWindowDimensions()

  // Replace defaultSystemFonts with Constants.systemFonts if you're using expo
  const systemFonts = [...defaultSystemFonts, "IranSans-Medium"]

  // HTML source theme
  const html_source = {
    html: source,
  }

  // When HTML loaded
  const onLoad = () => dispatch({ type: SET_LOADING, value: false })

  return (
    <>
      {state.loading && (
        <View
          style={{
            width: "100%",
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color="#DD1C1D" />
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 16,
              color: "#333",
              marginTop: 20,
            }}
          >
            در حال بارگذاری اطلاعات...
          </Text>
        </View>
      )}

      <RenderHTML
        systemFonts={systemFonts}
        baseStyle={{ fontFamily: "IranSans-Medium", direction: "rtl" }}
        contentWidth={width}
        source={html_source}
        onDocumentMetadataLoaded={onLoad}
      />
    </>
  )
}

export default RenderHTMLSource
