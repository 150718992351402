import { useReducer, useEffect } from "react"
import { View, Text, StyleSheet, Platform, TouchableOpacity, FlatList } from "react-native"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"

const TOGGLE_SELECTION = "TOGGLE_SELECTION"
const formReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_SELECTION:
      return {
        ...state,
        selected: action.value,
      }
    default:
      return state
  }
}
const FancySelector = (props) => {
  const { options, title, id, onChange, disabled } = props
  const [formState, dispatchFormState] = useReducer(formReducer, {
    selected: props.insert ? props.insert : [],
  })

  const changeHandler = (value) => {
    let selected = formState.selected
    if (props.single) {
      selected = [value]
    } else {
      if (selected.includes(value)) {
        if (selected.indexOf(value) > -1) {
          selected.splice(selected.indexOf(value), 1)
        }
      } else {
        selected.push(value)
      }
    }

    dispatchFormState({ type: TOGGLE_SELECTION, value: selected })
    onChange(id, selected)
  }

  return (
    <View style={{ width: "100%", marginVertical: 10 }}>
      <View style={{ width: "100%" }}>
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 16,
            color: Colors.text,
            marginBottom: 10,
          }}
        >
          {title}
        </Text>
      </View>
      <FlatList
        numColumns={props.column ? props.column : 2}
        style={{ width: "100%", ...props.flatListStyle }}
        keyExtractor={(item) => item.id}
        data={options}
        columnWrapperStyle={props.column != 1 && { justifyContent: "space-between" }}
        renderItem={(itemData, index) => (
          <TouchableOpacity
            style={{
              flexDirection: "row-reverse",
              opacity: disabled ? "70%" : "100%",
              flex: props.column
                ? props.column == 1
                  ? undefined
                  : 1 / props.column - 1 / (props.column * 30)
                : 1,
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
              height: 50,
              borderWidth: 0.8,
              borderColor: formState.selected.includes(itemData.item.id)
                ? Colors.green
                : Colors.secondText,
              borderRadius: 7,
              backgroundColor: formState.selected.includes(itemData.item.id)
                ? Colors.green
                : Colors.bg,
              marginBottom: 10,
            }}
            onPress={() => {
              if (!disabled) {
                changeHandler(itemData.item.id)
              }
            }}
          >
            <Ionicons
              name={itemData.item.icon}
              size={22}
              color={formState.selected.includes(itemData.item.id) ? "#fff" : Colors.secondText}
            />
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 16,
                color: formState.selected.includes(itemData.item.id) ? "#fff" : Colors.secondText,
                marginRight: 3,
              }}
            >
              {itemData.item.item}
            </Text>
          </TouchableOpacity>
        )}
      />
    </View>
  )
}

export default FancySelector
