import { useCallback, useEffect, useReducer } from "react";
import { View, Text, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity, Image, Dimensions } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Input from "../../components/Input";
import { useSelector } from "react-redux";
import filter from "lodash.filter";
import axios from "axios";
import Config from "../../constants/Config";
import { logOut } from "../../components/Functions";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;
import Btn from "../../components/Btn";
import { Ionicons } from "@expo/vector-icons";

const SEARCH = "SEARCH";
const SET_LOADING = "SET_LOADING";
const SET_USERS = "SET_USERS";
const formReducer = (state, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          query: action.query,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_USERS:
      let users = action.data;
      for (let i = 0; i < users.length; i++) {
        switch (users[i].department) {
          case "lead":
            users[i].department = "مدیریت";
            break;
          case "site":
            users[i].department = "سایت";
            break;
          case "graphic":
            users[i].department = "گرافیک";
            break;
          case "sales":
            users[i].department = "فروش";
            break;
          case "seo":
            users[i].department = "سئو";
            break;
        }
        switch (users[i].eam_type) {
          case "aminhco":
            users[i].eam_type = "درون سازمانی";
            break;
          case "remote":
            users[i].eam_type = "دورکاری";
            break;
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          users: users,
        },
      };
    default:
      return state;
  }
};

const UsersManagementScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      data: [],
      users: [],
      loading: false,
    },
  });

  const getUsersList = async () => {
    const url = Config.aioUrl + "/users/list";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_USERS, data: response.data.data });
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      getUsersList();
    });

    return focused;
  }, [props.navigation]);

  const handleSearch = useCallback(
    (id, text) => {
      const contains = (item, query) => {
        if (
          item.id.includes(query) ||
          item.name.includes(query) ||
          item.phone.includes(query) ||
          item.department.includes(query) ||
          item.eam_type.includes(query)
        ) {
          return true;
        }
        return false;
      };
      const data = filter(formState.values.users, (item) => contains(item, text));
      dispatchFormState({ type: SEARCH, data, query: text });
    },
    [dispatchFormState, formState.values.users]
  );
  return (
    <View style={styles.wrapper}>
      <Header title="مدیریت کاربران" noBack />
      <Btn
        title="افزودن کاربر جدید"
        color={Colors.secondary}
        wrapperStyle={{ height: 40, width: 120 }}
        titleStyle={{ fontSize: 12 }}
        onPress={() => {
          props.navigation.navigate("AddUser");
        }}
      />
      <Input label="به دنبال چه می گردی؟" id="search" onChange={handleSearch} maxLength={20} validity />
      <Text
        style={{
          color: Colors.text,
          fontFamily: "Iransans-Medium",
          fontSize: 14,
          marginBottom: 10,
          marginTop: 30,
        }}
      >
        {formState.values.data.length} مورد یافت شد
      </Text>
      {!formState.values.loading && (
        <FlatList
          showsVerticalScrollIndicator={false}
          data={formState.values.data}
          keyExtractor={(item) => item.id}
          renderItem={(item) => (
            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 20,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View style={{ flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Image source={{ uri: item.item.avatar }} style={{ width: 42, height: 42, borderRadius: 21 }} />
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Text style={{ fontFamily: "IranSans-Regular", fontSize: DESKTOP ? 14 : 10, color: Colors.text }}>{item.item.name}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Text style={{ fontFamily: "IranSans-Regular", fontSize: DESKTOP ? 14 : 10, color: Colors.text }}>{item.item.department}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Text style={{ fontFamily: "IranSans-Regular", fontSize: DESKTOP ? 14 : 10, color: Colors.text }}>{item.item.phone}</Text>
                </View>
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Text style={{ fontFamily: "IranSans-Regular", fontSize: DESKTOP ? 14 : 8, color: Colors.text }}>{item.item.eam_type}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: 24,
                      height: 24,
                      backgroundColor: Colors.secondary,
                      borderRadius: 3,
                      marginHorizontal: 3,
                    }}
                    onPress={() => {
                      props.navigation.navigate("AddUser", { user_id: item.item.id });
                    }}
                  >
                    <Ionicons name="pencil-outline" color="#fff" size={16} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: 24,
                      height: 24,
                      backgroundColor: Colors.blue,
                      borderRadius: 3,
                      marginHorizontal: 3,
                    }}
                    onPress={() => {
                      props.navigation.navigate("AnalyseUser", { user_id: item.item.id });
                    }}
                  >
                    <Ionicons name="stats-chart" color="#fff" size={16} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          )}
        />
      )}
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
  headerText: {
    fontFamily: "IranSans-Medium",
    fontSize: 14,
    color: "#fff",
  },
});

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default UsersManagementScreen;
