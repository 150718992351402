import { useReducer, useCallback, useEffect } from "react"
import { ScrollView, View, Text } from "react-native"
import { useSelector } from "react-redux"
import Input from "../../components/Input"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import Config from "../../constants/Config"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Header from "../../components/Header"
import { encrypt, decrypt } from "../../components/Functions"
import InputNote from "../../components/InputNote"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_FORM:
      let form = action.data

      return {
        ...state,
        values: {
          ...state.values,
          form: form,
          is_staff_in_project: action.is_staff_in_project,
        },
      }
    default:
      return state
  }
}

const SiteInfoScreen = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const project_id = props.route.params.project_id
  const token = useSelector((store) => store.user.token)

  const key_encrypt = useSelector((store) => store.user.key_encrypt)
  const key_decrypt = useSelector((store) => store.user.key_decrypt)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      url: "",
      first_name: "",
      last_name: "",
      phone: "",
      melli_code: "",
      hosting_provider: "",
      hosting_email: "",
      hosting_password: "",
      hosting_panel: "",
      hosting_panel_username: "",
      hosting_panel_password: "",
      hosting_panel_api: "",
      wp_admin_username: "",
      wp_admin_password: "",
      wp_backdoor: "",
      nic_email: "",
      nic_handle: "",
      nic_password: "",
      nic_answer: "",
      enamad_username: "",
      enamad_password: "",
      samandehi_username: "",
      samandehi_password: "",
      online_gateway: "",

      wp_customer_username: "",
      wp_customer_password: "",

      description: "",
      form: null,
      is_staff_in_project: null,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/siteinfo/update"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("project_id", project_id)
    formdata.append("url", formState.values.url)
    formdata.append("first_name", formState.values.first_name)
    formdata.append("last_name", formState.values.last_name)
    formdata.append("phone", formState.values.phone)
    formdata.append("melli_code", formState.values.melli_code)
    formdata.append("hosting_provider", formState.values.hosting_provider)
    formdata.append("hosting_email", await encrypt(formState.values.hosting_email, key_encrypt))
    formdata.append(
      "hosting_password",
      await encrypt(formState.values.hosting_password, key_encrypt)
    )
    formdata.append("hosting_panel", formState.values.hosting_panel)
    formdata.append(
      "hosting_panel_username",
      await encrypt(formState.values.hosting_panel_username, key_encrypt)
    )
    formdata.append(
      "hosting_panel_password",
      await encrypt(formState.values.hosting_panel_password, key_encrypt)
    )
    formdata.append(
      "hosting_panel_api",
      await encrypt(formState.values.hosting_panel_api, key_encrypt)
    )
    formdata.append(
      "wp_admin_username",
      await encrypt(formState.values.wp_admin_username, key_encrypt)
    )
    formdata.append(
      "wp_admin_password",
      await encrypt(formState.values.wp_admin_password, key_encrypt)
    )
    formdata.append("wp_backdoor", await encrypt(formState.values.wp_backdoor, key_encrypt))
    formdata.append("nic_email", await encrypt(formState.values.nic_email, key_encrypt))
    formdata.append("nic_handle", await encrypt(formState.values.nic_handle, key_encrypt))
    formdata.append("nic_password", await encrypt(formState.values.nic_password, key_encrypt))
    formdata.append("nic_answer", await encrypt(formState.values.nic_answer, key_encrypt))
    formdata.append("enamad_username", await encrypt(formState.values.enamad_username, key_encrypt))
    formdata.append("enamad_password", await encrypt(formState.values.enamad_password, key_encrypt))
    formdata.append(
      "samandehi_username",
      await encrypt(formState.values.samandehi_username, key_encrypt)
    )
    formdata.append(
      "samandehi_password",
      await encrypt(formState.values.samandehi_password, key_encrypt)
    )

    formdata.append("online_gateway", await encrypt(formState.values.online_gateway, key_encrypt))
    formdata.append("description", await encrypt(formState.values.description, key_encrypt))

    formdata.append("wp_customer_username", formState.values.wp_customer_username)
    formdata.append("wp_customer_password", formState.values.wp_customer_password)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_form = async () => {
    const url = Config.aioUrl + "/project/siteinfo/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    let axios_response = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        return response.data
      })
      .catch(function (error) {})

    let form = await axios_response.data

    for (let key in form) {
      if (
        key == "hosting_email" ||
        key == "hosting_password" ||
        key == "hosting_panel_username" ||
        key == "hosting_panel_password" ||
        key == "hosting_panel_api" ||
        key == "wp_admin_username" ||
        key == "wp_admin_password" ||
        key == "wp_backdoor" ||
        key == "nic_email" ||
        key == "nic_handle" ||
        key == "nic_password" ||
        key == "nic_answer" ||
        key == "enamad_username" ||
        key == "enamad_password" ||
        key == "samandehi_username" ||
        key == "samandehi_password" ||
        key == "online_gateway" ||
        key == "description"
      ) {
        form[key] = await decrypt(form[key], key_decrypt)
      }
    }

    await dispatchFormState({
      type: SET_FORM,
      data: form,
      is_staff_in_project: axios_response.is_staff_in_project,
    })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_form()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title={"اطلاعات پروژه " + "#" + project_id} noToggle />
      <ScrollView
        style={{
          width: "100%",
          height: "100%",
        }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ flexDirection: "row-reverse" }}>
          <View style={{ flex: 1, marginLeft: 10 }}>
            <View
              style={{
                width: "100%",
                height: 60,
                backgroundColor: "#f2f2f2",
                borderRadius: 15,
                paddingHorizontal: 20,
                paddingVertical: 20,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Medium",
                  fontSize: 16,
                  color: Colors.text,
                }}
              >
                هویتی
              </Text>
            </View>
            <Input
              disabled={
                capabilities.includes("role_site_full_access") ||
                capabilities.includes("role_site_hosting")
                  ? false
                  : true
              }
              label="نام"
              id="first_name"
              onChange={inputChangeHandler}
              insert={formState.values.form ? formState.values.form.first_name : ""}
            />
            <Input
              disabled={
                capabilities.includes("role_site_full_access") ||
                capabilities.includes("role_site_hosting")
                  ? false
                  : true
              }
              label="نام خانوادگی"
              id="last_name"
              onChange={inputChangeHandler}
              insert={formState.values.form ? formState.values.form.last_name : ""}
            />
            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_hosting") ||
              capabilities.includes("role_site_coordination") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="شماره موبایل"
                id="phone"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.phone : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              capabilities.includes("role_site_hosting")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="کد ملی"
                id="melli_code"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.melli_code : ""}
              />
            )}
          </View>
          <View style={{ flex: 1, marginHorizontal: 10 }}>
            <View
              style={{
                width: "100%",
                height: 60,
                backgroundColor: "#f2f2f2",
                borderRadius: 15,
                paddingHorizontal: 20,
                paddingVertical: 20,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Medium",
                  fontSize: 16,
                  color: Colors.text,
                }}
              >
                هاست
              </Text>
            </View>
            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_hosting") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="شرکت هاستینگ"
                id="hosting_provider"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_provider : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              capabilities.includes("role_site_hosting")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="ایمیل هاستینگ"
                id="hosting_email"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_email : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              capabilities.includes("role_site_hosting")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="پسورد هاستینگ"
                id="hosting_password"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_password : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_hosting") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="پنل هاست"
                id="hosting_panel"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_panel : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_hosting") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="نام کاربری پنل هاست"
                id="hosting_panel_username"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_panel_username : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_hosting") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="پسورد پنل هاست"
                id="hosting_panel_password"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_panel_password : ""}
              />
            )}
            {(capabilities.includes("role_site_full_access") ||
              capabilities.includes("role_site_hosting")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="کلید سی پنل"
                id="hosting_panel_api"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.hosting_panel_api : ""}
              />
            )}
          </View>
          {(capabilities.includes("role_site_full_access") ||
            capabilities.includes("role_site_hosting")) && (
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  ایرانیک
                </Text>
              </View>
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="ایمیل ایرانیک"
                id="nic_email"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.nic_email : ""}
              />
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="نیک هندل"
                id="nic_handle"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.nic_handle : ""}
              />
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="پسورد ایرانیک"
                id="nic_password"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.nic_password : ""}
              />
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  capabilities.includes("role_site_hosting")
                    ? false
                    : true
                }
                label="پاسخ امنیتی ایرانیک"
                id="nic_answer"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.nic_answer : ""}
              />
            </View>
          )}
          <View style={{ flex: 1, marginHorizontal: 10 }}>
            <View
              style={{
                width: "100%",
                height: 60,
                backgroundColor: "#f2f2f2",
                borderRadius: 15,
                paddingHorizontal: 20,
                paddingVertical: 20,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Medium",
                  fontSize: 16,
                  color: Colors.text,
                }}
              >
                وردپرس
              </Text>
            </View>

            <Input
              disabled={
                capabilities.includes("role_site_full_access") ||
                capabilities.includes("role_site_hosting")
                  ? false
                  : true
              }
              label="آدرس سایت"
              id="url"
              onChange={inputChangeHandler}
              insert={formState.values.form ? formState.values.form.url : ""}
            />

            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_quality_supervisor") ||
              capabilities.includes("role_site_coordination") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="نام کاربری مدیر کل وردپرس"
                id="wp_admin_username"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.wp_admin_username : ""}
              />
            )}

            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project ||
              capabilities.includes("role_site_quality_supervisor") ||
              capabilities.includes("role_site_coordination") ||
              capabilities.includes("role_site_end_project_supervisor")) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="پسورد مدیر کل وردپرس"
                id="wp_admin_password"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.wp_admin_password : ""}
              />
            )}

            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="ایندکس"
                id="wp_backdoor"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.wp_backdoor : ""}
              />
            )}

            <InputNote
              backgroundColor={Colors.primary}
              textColor={"#FFFFFF"}
              content="دسترسی ای که باید مشتری در اختیار داشته باشد را وارد کنید"
            />

            <Input
              disabled={
                capabilities.includes("role_site_full_access") ||
                capabilities.includes("role_site_hosting")
                  ? false
                  : true
              }
              label="نام کاربری مشتری"
              id="wp_customer_username"
              onChange={inputChangeHandler}
              insert={formState.values.form ? formState.values.form.wp_customer_username : ""}
            />

            <Input
              disabled={
                capabilities.includes("role_site_full_access") ||
                capabilities.includes("role_site_hosting")
                  ? false
                  : true
              }
              label="پسورد مشتری"
              id="wp_customer_password"
              onChange={inputChangeHandler}
              insert={formState.values.form ? formState.values.form.wp_customer_password : ""}
            />
          </View>
          {(capabilities.includes("role_site_full_access") ||
            formState.values.is_staff_in_project) && (
            <View style={{ flex: 1, marginHorizontal: 10 }}>
              <View
                style={{
                  width: "100%",
                  height: 60,
                  backgroundColor: "#f2f2f2",
                  borderRadius: 15,
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                  marginBottom: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                  }}
                >
                  نمادها
                </Text>
              </View>
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="نام کاربری اینماد"
                id="enamad_username"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.enamad_username : ""}
              />
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="پسورد اینماد"
                id="enamad_password"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.enamad_password : ""}
              />
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="نام کاربری ساماندهی"
                id="samandehi_username"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.samandehi_username : ""}
              />
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="پسورد ساماندهی"
                id="samandehi_password"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.samandehi_password : ""}
              />
            </View>
          )}
          <View style={{ flex: 1, marginRight: 10 }}>
            <View
              style={{
                width: "100%",
                height: 60,
                backgroundColor: "#f2f2f2",
                borderRadius: 15,
                paddingHorizontal: 20,
                paddingVertical: 20,
                marginBottom: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Medium",
                  fontSize: 16,
                  color: Colors.text,
                }}
              >
                سایر
              </Text>
            </View>
            {(capabilities.includes("role_site_full_access") ||
              formState.values.is_staff_in_project) && (
              <Input
                disabled={
                  capabilities.includes("role_site_full_access") ||
                  formState.values.is_staff_in_project
                    ? false
                    : true
                }
                label="اطلاعات درگاه پرداخت"
                id="online_gateway"
                onChange={inputChangeHandler}
                insert={formState.values.form ? formState.values.form.online_gateway : ""}
                multiline
              />
            )}
            <Input
              label="سایر توضیحات"
              id="description"
              onChange={inputChangeHandler}
              insert={formState.values.form ? formState.values.form.description : ""}
              multiline
            />
          </View>
        </View>

        <Btn
          title="ثبت"
          onPress={async () => {
            send_form()
          }}
          wrapperStyle={{ marginVertical: 50 }}
          loading={formState.values.loading}
        />
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default SiteInfoScreen
